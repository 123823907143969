<template>
  <container-settings-details-body :key="$route.params.setting">
    <div
      slot-scope="{
        selectedSetting,
        items,
        components,
        headers,
        endpoint,
        loadSettings,
      }"
    >
      <v-data-table
        v-if="items"
        :items="items"
        :headers="headers"
        hide-default-footer
        :items-per-page="10000"
        multi-sort
      >
        <template v-slot:item.action="{ item }">
          <v-btn icon>
            <v-icon small @click="editRecord(item)">mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog
        max-width="500"
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :hide-overlay="$vuetify.breakpoint.xsOnly"
      >
        <base-record-modal
          :components="components"
          :preloadedRecord="preloadedRecord"
          :key="modalKey"
          :endpoint="endpoint"
          modalColor="#666"
          :newRecord="newRecord"
          @closeDialog="
            closeDialog();
            loadSettings();
          "
        />
      </v-dialog>
    </div>
  </container-settings-details-body>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      preloadedRecord: null,
      modalKey: null,
      newRecord: false,
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    editRecord(item) {
      this.preloadedRecord = item;
      this.modalKey = this.preloadedRecord.id;
      this.dialog = true;
      this.newRecord = false;
    },
    addNewRecord() {
      this.dialog = true;
      this.newRecord = true;
      this.modalKey = Date();
      this.preloadedRecord = { id: "new" };
    },
  },
};
</script>
