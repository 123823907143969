<template>
  <container-config>
    <div slot-scope="{ alternativeDesign, color }">
      <slot
        :values="values"
        :selectValues="selectValues"
        :endpointValueSingular="endpointValueSingular"
        :enterNewValue="enterNewValue"
        :deleteEntry="deleteEntry"
        :color="color"
        :loading="loading"
        :alternativeDesign="alternativeDesign"
      >
      </slot>
    </div>
  </container-config>
</template>

<script>
import { get } from "lodash";
import axios from "axios";
import Inflector from "inflector-js";

export default {
  data() {
    return {
      loading: false,
    };
  },
  props: {
    recordId: {
      type: [String, Number],
    },
    recordEndpoint: {
      type: String,
    },
    endpointJoin: {
      type: String,
    },
    endpointValue: {
      type: String,
    },
    endpointSort: {
      type: String,
    },
    displayFieldValue: {
      type: String,
    },
  },
  computed: {
    values() {
      return get(this.record, this.endpointJoin);
    },
    record() {
      return this.$store.state[this.recordEndpoint].selectedRecords[
        this.recordId
      ];
    },
    foreignKeyFieldNameValue() {
      return this.endpointValueSingular + "_id";
    },
    foreignKeyFieldNameRecord() {
      return this.endpointRecordSingular + "_id";
    },
    endpointValueSingular() {
      // return "blogtag";
      return Inflector.singularize(this.endpointValue);
    },
    endpointRecordSingular() {
      return Inflector.singularize(this.recordEndpoint);
    },
    selectValues() {
      if (this.$store.state.dropdownValues[this.endpointValue]) {
        let selectValues = this.$store.state.dropdownValues[this.endpointValue];
        return selectValues;
      } else {
        return [];
      }
    },
  },
  methods: {
    enterNewValue(value) {
      let endpoint = this.endpointJoin;
      let payload = {};
      this.loading = true;
      payload["data"] = {};
      if (this.endpointJoin != this.endpointValue) {
        payload["data"][this.foreignKeyFieldNameValue] = value;
      } else {
        payload["data"][this.displayFieldValue] = value;
      }
      payload["data"][this.foreignKeyFieldNameRecord] = this.recordId;
      payload["load"] = true;
      axios.post(endpoint, payload).then((response) => {
        this.pushToItems(response.data);
        this.refreshOriginRecord();
      });
    },
    deleteEntry(input) {
      axios.delete(this.endpointJoin + "/" + input).then(() => {
        this.refreshOriginRecord();
      });
    },
    refreshOriginRecord() {
      let payload = {
        recordId: this.recordId,
        endpoint: this.recordEndpoint,
      };
      this.$store.dispatch("refreshOriginRecord", payload);
      setTimeout(() => (this.loading = false), 500);
    },
    pushToItems(data) {
      this.$store.commit("ADD_OBJECT_TO_SUBARRAY", {
        recordEndpoint: this.recordEndpoint,
        recordId: this.recordId,
        tableEndpoint: this.endpointJoin,
        data,
      });
    },
  },
  mounted() {
    if (this.endpointValue !== null && this.selectValues.length == 0) {
      let endpoint = this.endpointValue;
      let sort = this.endpointSort ? "&sort=" + this.endpointSort : "";
      axios
        .get(endpoint + "?page=all" + sort)
        .then((r) => r.data)
        .then((data) => {
          this.$store.commit("SET_DROPDOWN_VALUES", { endpoint, data });
        });
    }
  },
};
</script>
