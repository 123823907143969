<template>
  <container-query-builder-table-grouping
    :orQueryKey="orQueryKey"
    :tableGroupingKey="tableGroupingKey"
  >
    <div
      slot-scope="{
        color,
        moduleConfig,
        language,
        deleteTableGrouping,
        addNewSubquery,
        includeState,
        changeIncludeState,
        baseTable,
        changeBaseTable,
        query
      }"
    >
      <v-card class="mb-5" :color="color">
        <v-card-title class="py-0 mb-0">
          <v-row>
            <v-col cols="auto" style="max-width: 170px;" class="pr-0 pb-0">
              <v-select
                solo
                dense
                :items="includeExcludeArray[language]"
                item-value="value"
                item-text="label"
                :value="includeState"
                @input="changeIncludeState"
                :background-color="
                  includeState == 'include' ? '#ddd' : 'red lighten-3'
                "
              ></v-select>
            </v-col>
            <v-col class="pr-0 pb-0">
              <v-select
                solo
                dense
                :items="moduleConfig.queryFields"
                item-value="tableName"
                item-text="tableNameLabel"
                :value="baseTable"
                @input="changeBaseTable"
                background-color="#ddd"
              ></v-select>
            </v-col>
            <v-col class="pb-0">
              <span class="subtitle-1 p-0 white--text" v-if="baseTable">{{
                texts["withCriteria"][language]
              }}</span>
            </v-col>
            <v-col cols="auto" class="pb-0">
              <v-btn
                icon
                class="mr-n2 mt-n1"
                @click="deleteTableGrouping(orQueryKey, tableGroupingKey)"
              >
                <v-icon color="#cfcfcf">mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-n2">
          <div v-if="baseTable">
            <app-query-builder-subquery
              v-for="(subquery, key) in query.orQueries[orQueryKey]
                .tableGroupings[tableGroupingKey].andQueries"
              :key="key"
              :orQueryKey="orQueryKey"
              :tableGroupingKey="tableGroupingKey"
              :baseTable="baseTable"
            />
            <v-btn
              text
              small
              class="px-1 mt-n3"
              dark
              @click="addNewSubquery(orQueryKey, tableGroupingKey)"
            >
              <v-icon>mdi-plus</v-icon>
              <span class="white--text">{{
                texts["criterium"][language]
              }}</span>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </container-query-builder-table-grouping>
</template>

<script>
export default {
  props: {
    orQueryKey: {
      type: Number
    }
  },
  data() {
    return {
      tableGroupingKey: this.$vnode.key,
      includeExcludeArray: {
        en: [
          { label: "have", value: "include" },
          { label: "don't have", value: "exclude" }
        ],
        da: [
          { label: "har", value: "include" },
          { label: "har ikke", value: "exclude" }
        ],
        de: [
          { label: "haben", value: "include" },
          { label: "haben keine", value: "exclude" }
        ]
      },
      texts: {
        withCriteria: {
          en: "with the following criteria:",
          da: "med følgende søgekriterier",
          de: "mit den folgenden Kriterien:"
        },
        criterium: {
          en: "criterium",
          da: "søgekriterie",
          de: "Kriterium"
        }
      }
    };
  }
};
</script>
