<template>
  <v-col
    class="py-0"
    :class="{ customClass }"
    cols="12"
    :md="columns"
    :style="{
      'max-width': maxWidth + 'px',
      'border-right': borderRightStyle,
    }"
    v-if="!isHidden"
  >
    <div
      :class="{
        config: configMode,
        columnSelected,
        'elevation-9': configMode && !columnSelected,
        'elevation-2': columnSelected,
      }"
    >
      <div class="configButtons" v-if="configMode">
        <v-btn
          x-small
          icon
          @click="editComponent"
          class="small-icon"
          style="margin-right: 2px; margin-top: 5px"
          :style="{
            'background-color': columnSelected ? 'cornflowerblue' : '#aaa',
          }"
        >
          <v-icon x-small color="white">mdi-pencil</v-icon>
        </v-btn>
        <br />
        <v-btn
          x-small
          icon
          style="margin-top: 0px; margin-left: -5px; margin-bottom: 5px"
        >
          <v-icon small>mdi-drag</v-icon>
        </v-btn>
      </div>
      <app-subcomponents
        :class="{ subcomponentsConfig: configMode }"
        :pathArray="[...pathArray, 'props', 'components']"
        v-bind="{
          components,
          recordId,
          recordEndpoint,
          newRecord,
        }"
        @closeModal="$emit('closeModal')"
      />
      <v-dialog width="1000" v-model="dialog">
        <v-card style="background-color: #f2f2f2">
          <v-card-title style="background-color: lightslategray; color: white">
            Column
          </v-card-title>
          <v-card-text>
            <config-component-editor
              :pathArray="pathArray"
              :component="component"
            />
          </v-card-text>
          <v-card-actions style="background-color: lightslategray">
            <v-spacer />
            <v-btn class="mr-4" @click="dialog = false">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-col>
</template>

<script>
import { isEqual } from "lodash";
import { baseComponentMixin } from "../mixins/baseComponentMixin";

export default {
  mixins: [baseComponentMixin],
  props: {
    columns: {
      type: [Number, String],
      default: "",
    },
    maxWidth: {
      type: Number,
      default: 500,
    },
    borderRight: {
      type: Boolean,
    },
    customClass: {
      type: String,
    },
    component: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    borderRightStyle() {
      return this.borderRight ? "1pt #bbb solid" : "";
    },
    configMode() {
      return this.$store.state.uiState.configMode;
    },
    columnSelected() {
      const selectedArray = this.$store.state.uiState.configPathArray;
      return this.configMode && isEqual(this.pathArray, selectedArray);
    },
  },
  methods: {
    editComponent() {
      this.$store.commit("SET_CONFIG_PATH_ARRAY", this.pathArray);
    },
  },
};
</script>
<style scoped>
.subcomponentsConfig {
  margin-top: -45px;
}
.configButtons {
  text-align: right;
  margin-right: -38px;
}
.config {
  border-radius: 3px;
  /* border: 0.25px solid purple; */
  padding-left: 20px;
  padding-bottom: 5px;
  padding-right: 45px;
  background-color: lavender;
  min-height: 50px;
}
.columnSelected {
  /* background-color: #b7c0c9; */
  border: 0.25px solid #a0a0a0;
  /* background-color: lavender; */
}
</style>
