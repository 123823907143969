<template>
  <container-config>
    <div slot-scope="{ app }">
      <container-input-field
        :recordId="recordId"
        :recordEndpoint="recordEndpoint"
        :field="field"
        :displayField="displayField"
        :newRecord="newRecord"
        :modalColor="modalColor"
        @handleUpdate="edit = false"
      >
        <div slot-scope="scope">
          <div v-if="label == 'empty'"></div>
          <base-label :label="label" v-else-if="label" />
          <div v-else class="mt-5"></div>
          <base-input-file-uploader
            v-if="scope.storedValue == null || edit"
            @triggerEdit="edit = true"
            @input="scope.updateValue"
            @closeEdit="edit = false"
            :newRecord="newRecord"
            :noStoredValue="scope.storedValue == null"
            :validationRules="validationRules"
            :displayName="'whatever'"
          ></base-input-file-uploader>
          <div v-else>
            <v-btn
              @click="download(scope.storedValue)"
              small
              class="mr-3"
              :loading="loading"
              :color="scope.color"
              dark
            >
              <v-icon :left="!minimalButtons" small>mdi-download</v-icon>
              <span v-if="!minimalButtons">{{ scope.value }}</span>
            </v-btn>
            <v-btn @click="edit = true" small>
              <v-icon :left="!minimalButtons" small>mdi-pencil</v-icon>
              <span v-if="!minimalButtons">{{
                texts["attachDifferentFile"][app.appLanguage]
              }}</span>
            </v-btn>
          </div>
        </div>
      </container-input-field>
    </div>
  </container-config>
</template>

<script>
import axios from "axios";
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    displayField: {
      type: String,
      default: "file.name",
    },
    minimalButtons: {
      type: Boolean,
    },
  },
  data() {
    return {
      edit: false,
      loading: false,
      texts: {
        attachDifferentFile: {
          en: "attach other file",
          de: "andere Datei hochladen",
        },
      },
    };
  },
  methods: {
    download(input) {
      this.loading = true;
      axios.get("download/" + input).then((response) => {
        let url = response.data.url;
        let name = response.data.name;
        axios.defaults.withCredentials = false;
        axios({
          url,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          this.loading = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", name);
          document.body.appendChild(fileLink);
          fileLink.click();
          axios.defaults.withCredentials = true;
        });
      });
    },
  },
};
</script>
