<template>
  <container-config>
    <div slot-scope="{ color, moduleName, language }">
      <container-list>
        <div slot-scope="{ filter, changeFilter }">
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            :placeholder="texts['searchIn'][language] + ' ' + moduleName"
            class="pt-2"
            ref="searchField"
            :color="color"
            :value="filter"
            @input="changeFilter"
            @keyup.enter="$refs.searchField.blur()"
            clearable
            onFocus="this.select();"
          ></v-text-field>
          <GlobalEvents
            @keydown.meta.70.exact.prevent="$refs.searchField.focus()"
          />
        </div>
      </container-list>
    </div>
  </container-config>
</template>

<script>
export default {
  data() {
    return {
      texts: {
        searchIn: {
          en: "search in",
          da: "søg blandt",
          de: "Suche in"
        }
      }
    };
  }
};
</script>
