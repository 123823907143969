<template>
  <div class="player-container elevation-5 mb-4 mr-2 mt-2" v-if="false">
    <!-- <v-skeleton-loader
      v-if="src == 'loading'"
      max-height="350"
      type="image"
    ></v-skeleton-loader> -->
    <!-- <vue-plyr v-if="src == 'loading'" :options="options">
      <video controls playsinline :data-poster="posterBaseUrl + poster">
        <source src="https://cdn.plyr.io/static/blank.mp4" />
      </video>
    </vue-plyr> -->
    <!-- <vue-plyr v-else-if="src" :options="options">
      <video controls playsinline :data-poster="posterBaseUrl + poster">
        <source :src="src" />
      </video>
    </vue-plyr> -->
    <!-- <vue-core-video-player
      v-else-if="src"
      :autoplay="false"
      :src="videoSource"
      :cover="cover"
      :showCover="true"
      :controls="true"
    ></vue-core-video-player> -->
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { get } from "lodash";
// import axios from "axios";

export default {
  mixins: [baseComponentMixin],
  props: {
    fieldName: {
      type: String
    },
    apiUrl: {
      type: String
    }
  },
  data() {
    return {
      src: "loading",
      options: {
        hideControls: true,
        controls: [
          "play",
          "play-large",
          "current-time",
          "fullscreen",
          "progress"
        ],
        ratio: "16:9"
      }
    };
  },
  computed: {
    fieldValue() {
      return this.record[this.fieldName];
    },
    apiUrlParsed() {
      return this.apiUrl.replace("{field}", this.fieldValue);
    },
    record() {
      if (this.$store.state[this.recordEndpoint]) {
        return this.$store.state[this.recordEndpoint].selectedRecords[
          this.recordId
        ];
      } else {
        return null;
      }
    },
    poster() {
      return get(this.record, "files.0.file_name");
    },
    posterBaseUrl() {
      return "https://formulare.kurzfilmtage.ch/uploads/140x105/";
    }
  },
  mounted() {
    // if (this.apiUrl) {
    //   let self = this;
    //   axios
    //     .get(this.apiUrlParsed, { crossDomain: true })
    //     .then(r => r.data)
    //     .then(function(data) {
    //       if (data.includes("http")) {
    //         self.src = data;
    //       } else {
    //         self.src = null;
    //       }
    //     });
    // } else {
    //   this.src = this.fieldValue;
    // }
  }
};
</script>
