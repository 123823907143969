var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-uiState',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var changeView = ref.changeView;
return _c('div',{},[_c('container-config',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var design = ref.design;
var moduleName = ref.moduleName;
return _c('div',{},[_c('container-list',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var pageData = ref.pageData;
            var recordCount = ref.recordCount;
            var sortOrder = ref.sortOrder;
            var showProgressIndicator = ref.showProgressIndicator;
            var selectedModuleList = ref.selectedModuleList;
            var page = ref.page;
            var updateSortBy = ref.updateSortBy;
            var updateSortDesc = ref.updateSortDesc;
return _c('div',{},[_c('div',{staticStyle:{"overflow":"scroll"},style:({
                height:
                  'calc(100vh - ' +
                  (design.listHeaderHeight +
                    (recordCount > 100 ? design.listFooterHeight : 0) +
                    design.headerHeight) +
                  'px)'
              })},[_c('v-data-table',{attrs:{"fixed-header":"","hide-default-footer":"","items-per-page":100,"headers":selectedModuleList.headers,"items":pageData,"loading":showProgressIndicator,"multi-sort":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":[function($event){_vm.sortBy=$event},updateSortBy],"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":[function($event){_vm.sortDesc=$event},function($event){return updateSortDesc(_vm.value)}]},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{
                      path: ("/" + moduleName + "/" + (item.id)),
                      query: _vm.$route.query
                    }},on:{"click":function($event){return changeView('masterDetail')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)})],1)])}}],null,true)})],1)}}],null,true)})],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }