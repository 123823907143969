<template>
  <div>
    <container-config>
      <div slot-scope="config">
        <slot
          :language="config.language"
          :color="config.color"
          :performSave="performSave"
          :showSave="showSave"
          :showSaveChanges="showSaveChanges"
          :showSaveAs="showSaveAs"
          :enterButton="enterButton"
          :saveQuery="saveQuery"
          :saveChanges="saveChanges"
          :saveChangesAs="saveChangesAs"
          :dialog="dialog"
          :confirmationDialog="confirmationDialog"
          :closeDialog="closeDialog"
          :closeConfirmationDialog="closeConfirmationDialog"
          :queryName="queryName"
          :updateQueryName="updateQueryName"
        ></slot>
      </div>
    </container-config>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      confirmation: false,
      confirmationDialog: null,
      queryName: null,
      texts: {
        changesSaved: {
          en: "The changes have been saved.",
          da: "Ændringerne er gemt.",
          de: "Die Änderungen wurden gespeichert."
        },
        querySaved: {
          en: "The query has been saved.",
          da: "Denne søgning er blevet gemt.",
          de: "Die Suche wurde gespeichert."
        }
      }
    };
  },
  computed: {
    queryBuilder() {
      return this.$store.state.queryBuilder;
    },
    showSave() {
      return this.queryBuilder.id === null;
    },
    showSaveAs() {
      return this.queryBuilder.id !== null;
    },
    showSaveChanges() {
      return (
        this.queryBuilder.id !== null &&
        this.queryBuilder.user_id === this.auth.user.id
      );
    },
    auth() {
      return this.$store.state.auth;
    }
  },
  methods: {
    saveQuery() {
      this.dialog = true;
    },
    saveChanges() {
      this.$store.dispatch("updateQueryToDatabase");
      this.dialog = false;
      this.confirmationDialog = this.texts["changesSaved"][this.language];
      this.confirmation = true;
    },
    saveChangesAs() {
      this.queryName = null;
      this.dialog = true;
    },
    performSave() {
      this.$store.commit("UPDATE_QUERY_NAME", this.queryName);
      this.$store.commit("REMOVE_QUERY_ID");
      this.$store.dispatch("saveQueryToDatabase");
      this.dialog = false;
      this.confirmationDialog = this.texts["querySaved"][this.language];
      this.confirmation = true;
    },
    enterButton(e) {
      if (e.keyCode === 13) {
        this.performSave();
      }
    },
    language() {
      return this.$store.state.config.app.appLanguage;
    },
    closeDialog() {
      this.dialog = false;
    },
    closeConfirmationDialog() {
      this.confirmationDialog = false;
    },
    updateQueryName(input) {
      this.queryName = input;
    }
  },
  mounted() {
    this.queryName = this.queryBuilder.name;
  }
};
</script>
