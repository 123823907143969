<template>
  <div>
    <slot
      :selectedSetting="selectedSettingObject"
      :items="items"
      :headers="headers"
      :components="components"
      :endpoint="endpoint"
      :loadSettings="loadSettings"
      :width="width"
    ></slot>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      items: null,
    };
  },
  computed: {
    settings() {
      return this.$store.state.config.app.settings;
    },
    selectedSetting() {
      return this.$route.params.setting;
    },
    selectedSettingObject() {
      return this.settings.find((e) => e.name == this.selectedSetting);
    },
    endpoint() {
      return this.selectedSettingObject.endpoint;
    },
    sort() {
      return this.selectedSettingObject.sort;
    },
    settingNames() {
      return this.settings.map((item) => {
        return item.name;
      });
    },
    components() {
      return this.selectedSettingObject.components;
    },
    width() {
      return this.selectedSettingObject.width;
    },
    headers() {
      let headers = this.components.map((component) => {
        return {
          text: component.props.label,
          value: component.props.displayField || component.props.field,
        };
      });
      let actionItem = { value: "action", sortable: false, width: 10 };
      let extendedHeaders = [...headers, actionItem];
      return extendedHeaders;
    },
  },
  methods: {
    loadSettings() {
      let endpoint = this.endpoint;
      axios
        .get(
          endpoint + "?" + (this.sort ? "sort=" + this.sort : "") + "&page=all"
        )
        .then((r) => r.data)
        .then((data) => {
          this.items = data;
          this.$store.commit("SET_DROPDOWN_VALUES", { endpoint, data });
        })
        .catch((error) => this.$store.dispatch("handleError", error));
    },
  },
  mounted() {
    this.loadSettings();
  },
};
</script>
