import router from "./../router/index.js";
import axios from "axios";

export default {
  pushUrlParametersToRoute({ rootState, getters }) {
    let module = rootState.route.params.module;
    const view = rootState.uiState.view;
    const query = rootState[module].query;
    const filter = rootState[module].filter;
    const sort = rootState[module].sort;
    const page = rootState[module].selectedPage;
    let urlQueryParameters = { ...rootState.route.query };
    if (query !== null && query !== "") {
      urlQueryParameters["q"] = query;
    } else {
      delete urlQueryParameters["q"];
    }
    if (filter !== null && filter !== "") {
      urlQueryParameters["filter"] = filter;
    } else {
      delete urlQueryParameters["filter"];
    }
    if (
      sort !== null &&
      sort !== "" &&
      sort !== getters.selectedModuleConfigData.defaultSort
    ) {
      urlQueryParameters["sort"] = sort;
    } else {
      delete urlQueryParameters["sort"];
    }
    if (page !== null && page !== "" && page !== 1) {
      urlQueryParameters["page"] = page;
    } else {
      delete urlQueryParameters["page"];
    }
    if (view !== null && view !== "masterDetail") {
      urlQueryParameters["view"] = view;
    } else {
      delete urlQueryParameters["view"];
    }
    // eslint-disable-next-line
    router.push({ query: urlQueryParameters }).catch(err => {});
  },
  handleError({ rootState, commit }, error) {
    // TO DO: handle errors more elegantly: dispatch message to AB
    let title = "Error!";
    let text =
      rootState.config.app.basicConfig.defaultErrorMessage + "\r\n\r\n" + error;
    commit("SHOW_DIALOG", {
      title,
      text
    });
  },
  loadRecord({ commit, dispatch }, payload) {
    new Promise((resolve, reject) => {
      axios
        .get(payload.endpoint + "/" + payload.recordId)
        .then(r => r.data)
        .then(data => {
          resolve(
            commit("SET_RECORD_DATA", {
              endpoint: payload.endpoint,
              recordId: payload.recordId,
              data
            })
          );
        })
        .catch(error => reject(dispatch("handleError", error)));
    });
  },
  refreshOriginRecord({ dispatch }, payload) {
    let recordId = payload.recordId;
    let endpoint = payload.endpoint;
    dispatch("loadRecord", {
      recordId,
      endpoint
    });
  }
};
