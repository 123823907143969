var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-config',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var color = ref.color;
var language = ref.language;
return _c('div',{},[_c('container-alerts',{on:{"closeDialog":function($event){_vm.dialog = false}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var alerts = ref.alerts;
var saveAlert = ref.saveAlert;
return _c('div',{},[_c('div',[_c('v-card',[_c('v-card-title',{staticClass:"pl-8 white--text",style:({ 'background-color': color })},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-alarm")]),_vm._v(_vm._s(_vm.texts["alerts"][language])+" "),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","dark":""},on:{"click":function($event){return _vm.newItem()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.texts["addNewAlert"][language]))],1)],1),_c('v-card-text',[(alerts)?_c('v-data-table',{staticStyle:{"border-bottom":"solid 1pt #ddd","border-radius":"0px"},attrs:{"items":alerts,"headers":_vm.headers,"fixed-header":"","hide-default-footer":"","items-per-page":100},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)}):_vm._e()],1),_c('v-card-actions',{staticClass:"px-5 pb-7"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":color,"dark":""},on:{"click":function($event){return _vm.$emit('closeAlertsDialog')}}},[_vm._v(" "+_vm._s(_vm.texts["ok"][language])+" ")])],1)],1),_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pl-8 white--text pb-5",style:({ 'background-color': color })},[_vm._v(" "+_vm._s(_vm.texts["pleaseEnterDateAndMessage"][language])+" ")]),_c('v-card-text',{staticClass:"pt-3 pb-0"},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"}},[_c('v-date-picker',{attrs:{"color":color,"label":_vm.texts['alertDate'][language],"first-day-of-week":"1"},model:{value:(_vm.inputFields.date),callback:function ($$v) {_vm.$set(_vm.inputFields, "date", $$v)},expression:"inputFields.date"}})],1),_c('v-col',{staticClass:"pb-0"},[_c('v-textarea',{attrs:{"color":color,"label":_vm.texts['message'][language],"rows":"13","placeholder":_vm.texts['selectDateAndWriteMessage'][language]},model:{value:(_vm.inputFields.message),callback:function ($$v) {_vm.$set(_vm.inputFields, "message", $$v)},expression:"inputFields.message"}})],1)],1)],1),_c('v-card-actions',{staticClass:"px-5 pb-7"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.texts["cancel"][language])+" ")]),_c('v-btn',{attrs:{"color":color,"dark":""},on:{"click":function($event){return saveAlert(_vm.inputFields)}}},[_vm._v(" "+_vm._s(_vm.texts["ok"][language])+" ")])],1)],1)],1)],1)])}}],null,true)})],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }