<template>
  <container-input-field
    :recordId="recordId"
    :recordEndpoint="recordEndpoint"
    :field="field"
    :newRecord="newRecord"
    :modalColor="modalColor"
    :reloadRecordOnChange="reloadRecordOnChange"
    :storeNewRecordImmediately="storeNewRecordImmediately"
    @storeNewRecordImmediately="$emit('storeRecord')"
  >
    <div slot-scope="field" v-if="!isHidden">
      <v-row class="mb-n1" v-if="field.alternativeDesign">
        <v-col class="ml-3 px-0 py-0">
          <base-label :label="label" />
        </v-col>
      </v-row>
      <v-row class="px-0 py-0">
        <v-col class="d-flex pt-1 pb-2" :cols="columns">
          <v-text-field
            :class="{
              alternativeTextFieldDesign: field.alternativeDesign,
              'body-2': field.alternativeDesign,
              p_TextInputReadOnly: isReadonly,
            }"
            elevation="0"
            :label="label"
            :placeholder="field.alternativeDesign ? placeholder || ' ' : label"
            :value="field.value"
            @change="
              setValue($event);
              !isReadonly ? field.updateValue(value) : null;
            "
            :rules="rules"
            hide-details="auto"
            autocomplete="disabled"
            :readonly="isReadonly"
            :solo="field.alternativeDesign"
            :dense="field.alternativeDesign"
            :flat="field.alternativeDesign"
            @click:append="
              click(field.value);
              buttonType == 'copy' ? field.updateValue(copiedValue) : '';
            "
            :append-icon="
              (field.value && !buttonType == 'copy') || showCopyButton
                ? buttonIcon
                : ''
            "
          ></v-text-field>
        </v-col>
      </v-row>

      <v-overlay :value="overlay" opacity="0.8" v-if="buttonType == 'enlarge'">
        <p style="font-size: 128px" class="white--text">
          {{ field.value }}
        </p>

        <v-btn
          absolute
          right
          x-large
          outlined
          class="white--text mt-12"
          @click="overlay = false"
        >
          OK
        </v-btn>
      </v-overlay>
    </div>
  </container-input-field>
</template>
<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";
import { get } from "lodash";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    columns: {
      type: Number,
      default: 12,
    },
    validationRules: {
      type: Array,
      default: () => [],
    },
    buttonType: {
      type: String,
    },
    urlPath: {
      type: String,
      default: "",
    },
    urlSuffix: {
      type: String,
      default: "",
    },
    copyPath: {
      type: String,
      default: "",
    },
    isReadonly: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
    accessorRegEx: {
      type: String,
    },
    reloadRecordOnChange: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
      overlay: false,
      rulesCollection: {
        required: (value) => !!value || "Required value!",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail address!";
        },
        numericOnly: (value) =>
          value > 0 || "The entered value must be numeric!",
      },
    };
  },
  computed: {
    showCopyButton() {
      return (
        this.buttonType == "copy" &&
        get(this.record, this.copyPath) &&
        get(this.record, this.copyPath) != get(this.record, this.field)
      );
    },
    rules() {
      let array = [];
      this.validationRules.forEach((item) => {
        array = [...array, this.rulesCollection[item]];
      });
      return array;
    },
    buttonIcon() {
      if (this.buttonType == "url") {
        return "mdi-web";
      } else if (this.buttonType == "mail") {
        return "mdi-email-outline";
      } else if (this.buttonType == "enlarge") {
        return "mdi-eye-outline";
      } else if (this.buttonType == "copy") {
        return "mdi-arrow-left-bold-outline";
      } else {
        return null;
      }
    },
    copiedValue() {
      if (this.copyPath) {
        return get(this.record, this.copyPath);
      } else {
        return null;
      }
    },
  },
  methods: {
    click(input) {
      if (this.buttonType == "url") {
        let urlPath = this.urlPath + input + this.urlSuffix;
        if (!(urlPath.includes("http://") || urlPath.includes("https://"))) {
          urlPath = "https://" + urlPath;
        }
        window.open(urlPath, "_blank");
      } else if (this.buttonType == "mail") {
        var mail = document.createElement("a");
        mail.href = "mailto:" + input;
        mail.click();
      } else if (this.buttonType == "enlarge") {
        this.overlay = true;
      } else {
        return null;
      }
    },
    setValue(value) {
      if (this.accessorRegEx) {
        this.value = value.match(this.accessorRegEx)
          ? value.match(this.accessorRegEx)[0]
          : null;
      } else {
        this.value = value;
      }
    },
    ignore() {
      return null;
    },
  },
};
</script>
<style>
.alternativeTextFieldDesign .v-input__slot {
  border: 0.25px solid #aaa;
  padding-left: 8px !important;
}
.alternativeTextFieldDesign .v-input__control {
  min-height: 34px !important;
}
.alternativeTextFieldDesign .v-icon {
  margin-top: 0px !important;
  font-size: 18px !important;
  color: #666 !important;
}
.p_TextInputReadOnly > .v-input__control > .v-input__slot {
  background: none !important;
}
</style>
