<template>
  <div style="margin-bottom: 2px; min-height: 24px">
    <span class="caption" style="color: rgba(0, 0, 0, 0.6)">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
  },
};
</script>
