<template>
  <div>
    <v-text-field
      :label="label"
      :value="fieldValue"
      @change="updateValue"
      :append-icon="appendedIcon"
      placeholder="label"
      hide-details
      class="mb-4"
    ></v-text-field>
  </div>
</template>

<script>
import { get } from "lodash";

export default {
  props: {
    getArray: {
      type: Array,
    },
    field: {
      type: String,
    },
    label: {
      type: String,
    },
    selfIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mock: null,
    };
  },
  computed: {
    fieldValue() {
      return get(this.$store.state, [...this.getArray, this.field]);
    },
    appendedIcon() {
      return this.selfIcon ? this.fieldValue : "";
    },
  },
  methods: {
    updateValue(value) {
      let input = value.split(",");
      this.$store.dispatch("updateConfigValue", {
        input,
        field: this.field,
        getArray: this.getArray,
      });
    },
  },
};
</script>
