<template>
  <container-config>
    <div slot-scope="config">
      <slot
        :language="config.language"
        :fields="fields"
        :fieldContent="fieldContent"
        :queryLength="queryLength"
        :updateFieldContent="updateFieldContent"
      ></slot>
    </div>
  </container-config>
</template>

<script>
export default {
  props: {
    orQueryKey: {
      type: Number,
    },
    tableGroupingKey: {
      type: Number,
    },
    subqueryKey: {
      type: Number,
    },
  },
  computed: {
    fields() {
      let self = this;
      let queryFieldsTableArray = this.moduleConfig.queryFields;
      let queryFieldTableArrayForSelectedBaseTable = queryFieldsTableArray.find(
        (e) => e.tableName == this.baseTable
      );
      let queryFieldsForSelectedBaseTable =
        queryFieldTableArrayForSelectedBaseTable.fields;
      let displayedFields = queryFieldsForSelectedBaseTable.map(function (e) {
        let disabled = self.selectedFields.includes(e.value);
        let newObject = { ...e, disabled: disabled };
        return newObject;
      });
      return displayedFields;
    },
    fieldContent() {
      return this.query.orQueries[this.orQueryKey].tableGroupings[
        this.tableGroupingKey
      ].andQueries[this.subqueryKey]["field"];
    },
    queryLength() {
      return this.query.orQueries[this.orQueryKey].tableGroupings[
        this.tableGroupingKey
      ].andQueries.length;
    },
    queryBuilder() {
      return this.$store.state.queryBuilder;
    },
    query() {
      return this.queryBuilder.query;
    },
    baseTable() {
      return this.query.orQueries[this.orQueryKey].tableGroupings[
        this.tableGroupingKey
      ].table;
    },
    moduleName() {
      return this.$route.params.module;
    },
    moduleConfig() {
      let modules = this.$store.state.config.app.modules;
      return modules.find((e) => e.basicConfig.path == this.moduleName);
    },
    selectedFields() {
      let array =
        this.query.orQueries[this.orQueryKey].tableGroupings[
          this.tableGroupingKey
        ].andQueries;
      return array.map((e) => e.field);
    },
  },
  methods: {
    updateFieldContent(value) {
      let payload = {
        field: value,
        orQueryIndex: this.orQueryKey,
        tableGroupingIndex: this.tableGroupingKey,
        subqueryIndex: this.subqueryKey,
      };
      this.$store.commit("UPDATE_FIELD", payload);
      this.$store.commit("CLEAR_VALUE", payload);
      if (
        ["select", "radio", "autocomplete", "switch"].includes(
          this.checkFieldType(value)
        )
      ) {
        payload = { ...payload, operator: "==" };
        this.$store.commit("UPDATE_OPERATOR", payload);
      } else {
        payload = { ...payload, operator: "=" };
        this.$store.commit("UPDATE_OPERATOR", payload);
      }
    },
    checkFieldType(value) {
      let queryFieldsTableObjects = this.moduleConfig.queryFields;
      let queryFieldsTableObject = queryFieldsTableObjects.find(
        (e) => e.tableName == this.baseTable
      );
      let queryFields = queryFieldsTableObject.fields;
      let queryField = queryFields.find((e) => e.value == value);
      let type = queryField.type || "text";
      return type;
    },
  },
};
</script>

<style lang="scss" scoped></style>
