<template>
  <div class="pt-3 pb-2">
    <v-btn small @click="goToLink" v-if="!isHidden">{{ label }}</v-btn>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { get } from "lodash";

export default {
  mixins: [baseComponentMixin],
  props: {
    label: {
      type: String,
    },
    field: {
      type: String,
    },
    path: {
      type: String,
    },
  },
  methods: {
    goToLink() {
      this.$router.push("/" + this.pathComputed);
    },
  },
  computed: {
    pathComputed() {
      let value = get(this.record, this.field);
      let path = this.path.replace("{field}", value);
      return path;
    },
    record() {
      if (this.$store.state[this.recordEndpoint]) {
        return this.$store.state[this.recordEndpoint].selectedRecords[
          this.recordId
        ];
      } else {
        return null;
      }
    },
  },
};
</script>
