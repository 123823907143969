<template>
  <container-app v-if="isReady">
    <container-config>
      <div slot-scope="{ color }" class="pt-12" :style="background">
        <app-login-header />
        <v-container>
          <v-row>
            <v-col
              class="mx-auto"
              sm="8"
              lg="6"
              xl="4"
              style="margin-top: calc(50vh - 300px)"
            >
              <v-card
                class="mt-12"
                style="background-color: rgba(255, 255, 255, 1)"
              >
                <v-card-title
                  class="px-8 pb-4 white--text"
                  :style="{ 'background-color': color }"
                  style="opacity: 1"
                >
                  <h1 class="headline">Zwei-Faktor-Authentifizierung</h1>
                </v-card-title>
                <v-card-text class="px-8 py-6">
                  <v-alert v-if="error" color="red" text class="mb-m10"
                    >Zwei-Faktor-Authentifizierung fehlgeschlagen</v-alert
                  >
                  <v-text-field
                    v-if="!error"
                    label="Bitte den Code eingeben"
                    placeholder=" "
                    :color="color"
                    prepend-inner-icon="mdi-account-circle"
                    v-model="code"
                    @keydown.enter.stop="login"
                  ></v-text-field>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="justify-right">
                      <v-btn
                        v-if="!error"
                        :color="color"
                        class="white--text my-2"
                        @click="login"
                        :disabled="buttonDisabled"
                        >Anmelden</v-btn
                      ><v-btn
                        v-else
                        :color="color"
                        class="white--text my-2"
                        @click="backToLogin"
                        :disabled="buttonDisabled"
                        >zurück zum Login</v-btn
                      >
                    </v-col>
                    <GlobalEvents
                      v-if="!buttonDisabled"
                      @keydown.enter.stop="login"
                    />
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </container-config>
  </container-app>
</template>

<script>
export default {
  data: () => ({
    code: "",
    error: null,
  }),
  computed: {
    isReady() {
      return this.$store.state.config.app !== null;
    },
    buttonDisabled() {
      return this.code == "" || this.code.length < 6;
    },
    background() {
      return {
        height: "100vh",
        background:
          "linear-gradient(rgba(52, 52, 52, 0.6), rgba(52, 52, 52, 0.6)), url(" +
          this.url +
          ") no-repeat center center fixed",
        "background-size": "cover",
      };
    },
    url() {
      return this.$store.state.config.app.basicConfig.backgroundPath;
    },
    firstModulePath() {
      if (this.$store.state.config.app) {
        return this.$store.state.config.app.modules[0].basicConfig.path;
      } else {
        return null;
      }
    },
  },
  methods: {
    login() {
      let code = this.code.replace(/\s/g, "");
      this.$store
        .dispatch("twoFactorChallenge", {
          code,
        })
        .then(() => {
          if (this.$store.state.auth.user.reset_password) {
            this.$router.push({ path: "/change-password" });
          } else {
            this.$router.push({ path: "/" + this.firstModulePath });
          }
        })
        .catch((err) => {
          this.error = err;
        });
    },
    backToLogin() {
      this.$router.push("/login");
    },
  },
  mounted() {
    this.$store.dispatch("loadLoginConfigFromServer");
  },
};
</script>
