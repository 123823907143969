<template>
  <container-ui-state>
    <!-- only mount template once the state endpoint has been prepped -->
    <div slot-scope="uiState">
      <v-row
        :style="{
          height: 'calc(100vh - 64px)'
        }"
        class="mx-0 my-0"
      >
        <v-col
          sm="3"
          xl="2"
          class="py-0 px-0"
          v-if="!isMobile || (isMobile && uiState.mobileView == 'list')"
          style="border-right: 1px solid grey"
        >
          <app-settings-list />
        </v-col>
        <v-col
          v-if="!isMobile || uiState.mobileView == 'form'"
          sm="9"
          xl="10"
          class="py-0 px-0"
        >
          <router-view></router-view>
        </v-col>
      </v-row>
    </div>
  </container-ui-state>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  }
};
</script>
