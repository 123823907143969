<template>
  <div
    class="pa-6"
    style="background-color: #f1f1f1; border-top: 1px solid lightgrey"
  >
    <base-label label="User Name" class="mt-n3" />
    <v-text-field
      v-model="user.name"
      label="User Name"
      class="alternativeTextFieldDesign p_TextInputReadOnly"
      dense
      solo
      flat
      style="max-width: 400px"
      readonly
    />
    <base-label label="User E-mail" class="mt-n3" />
    <v-text-field
      v-model="user.email"
      label="User Mail"
      class="alternativeTextFieldDesign p_TextInputReadOnly"
      dense
      solo
      flat
      style="max-width: 400px"
      readonly
    />
    <base-title label="Module" class="mb-0 mt-n2" />
    <div v-for="module in modules" :key="module.name" style="max-width: 400px">
      <v-divider
        v-if="module.divider"
        style="border-color: #aaa"
        class="mt-6 mb-n2"
      ></v-divider>
      <v-checkbox
        multiple
        v-model="modulesModel"
        @change="store"
        class="mb-n9"
        :value="module.name"
        :label="module.name"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      modulesModel: null,
    };
  },
  props: {
    user: {
      type: Object,
    },
  },
  computed: {
    modules() {
      return this.$store.state.config.app.modules.map(
        (module) => module.basicConfig
      );
    },
  },
  methods: {
    store() {
      let modules = this.modulesModel;
      axios.patch("/users/" + this.user.id, {
        data: { modules },
      });
    },
  },
  mounted() {
    this.modulesModel = JSON.parse(this.user.modules);
  },
};
</script>
