<template>
  <div>
    <v-row>
      <v-col
        v-for="(itemsGroup, key) in groupedArray"
        :key="key"
        class="ml-0 pr-12 py-0 mb-0"
        cols="auto"
      >
        <base-label v-if="key !== 'undefined'" :label="key" />
        <v-row>
          <v-col
            v-for="(itemsChunk, key) in chunkArray(itemsGroup)"
            :key="key"
            class="ml-0 pr-3 py-0"
            cols="auto"
          >
            <v-checkbox
              class="ml-n1 mr-1 mt-0 pianolaGroupCheckbox"
              v-for="checkbox in itemsChunk"
              :key="checkbox.id"
              :input-value="isChecked(checkbox.id)"
              @change="updateValue($event, checkbox.id)"
              :label="checkbox.name"
              :value="checkbox.id"
              :color="color"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";
import { chunk, groupBy } from "lodash";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    values: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    itemsGroupFieldName: {
      type: String
    },
    foreignKeyField: {
      type: String
    },
    chunkSize: {
      type: Number
    },
    color: {
      type: String
    }
  },
  computed: {
    groupedArray() {
      return groupBy(this.items, this.itemsGroupFieldName);
    }
  },
  methods: {
    chunkArray(array) {
      return chunk(array, this.chunkSize);
    },
    isChecked(value) {
      let array = this.values.map(a => a[this.foreignKeyField]);
      return array.includes(value) ? value : null;
    },
    updateValue(value, id) {
      if (value === null) {
        let joinRecordId = this.values.find(
          value => value[this.foreignKeyField] == id
        )["id"];
        this.$emit("delete", joinRecordId);
      } else {
        this.$emit("add", id);
      }
    }
  }
};
</script>

<style>
.pianolaGroupCheckbox .v-label {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.87);
}
.pianolaGroupCheckbox .v-icon {
  font-size: 20px !important;
}
</style>
