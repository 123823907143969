<template>
  <container-config>
    <div slot-scope="config">
      <v-row>
        <v-col cols="auto" class="pl-md-10 pr-0">
          <v-img
            v-if="
              config.module.recordTitleConfig &&
                config.module.recordTitleConfig.avatarField &&
                config.module.recordTitleConfig.avatarTypeTitle == 'tile'
            "
            class="mr-3"
            :src="
              config.module.recordTitleConfig.titleUrl.replace(
                '{field}',
                record[config.module.recordTitleConfig.avatarField]
              )
            "
            :max-height="config.module.recordTitleConfig.avatarMaxHeightTitle"
            :max-width="
              config.module.recordTitleConfig.avatarMaxHeightTitle *
                record[
                  config.module.recordTitleConfig.avatarFactorFieldTitle
                ] || config.module.recordTitleConfig.avatarMaxHeightTitle
            "
            contain
          />
          <v-list-item-avatar
            class="my-0 p-0"
            v-else-if="
              config.module.recordTitleConfig &&
                config.module.recordTitleConfig.avatarField &&
                record[config.module.recordTitleConfig.avatarField]
            "
            :size="config.module.recordTitleConfig.avatarSizeTitle || 48"
          >
            <v-img
              :src="
                config.module.recordTitleConfig.titleUrl.replace(
                  '{field}',
                  record[config.module.recordTitleConfig.avatarField]
                )
              "
              :max-height="config.module.recordTitleConfig.avatarSizeTitle"
              :max-width="config.module.recordTitleConfig.avatarSizeTitle"
            />
          </v-list-item-avatar>
          <v-icon
            v-else
            :large="!config.module.recordTitleConfig.avatarField"
            :x-large="config.module.recordTitleConfig.avatarField != ''"
            left
            :color="config.color"
            class="ml-0"
            style="margin-top:-3px;"
          >
            {{
              config.module.basicConfig.iconRecord ||
                config.module.basicConfig.icon
            }}
          </v-icon>
        </v-col>
        <v-col
          :cols="
            config.module.recordTitleConfig &&
            config.module.recordTitleConfig.avatarField
              ? 7
              : 9
          "
          class="px-0 mt-0"
        >
          <h3
            v-if="config.module.recordTitleConfig.fieldSubtitleBefore"
            class="font-weight-light uppercase"
            style="max-width: 100%; overflow:hidden; white-space:nowrap; text-overflow: ellipsis;"
          >
            {{ record[config.module.recordTitleConfig.fieldSubtitleBefore] }}
          </h3>
          <h1
            class="headline font-weight-light uppercase"
            style="max-width: 100%; overflow:hidden; white-space:nowrap; text-overflow: ellipsis;"
          >
            {{ record[config.module.recordTitleConfig.fieldTitle] }}
          </h1>
          <h3
            v-if="config.module.recordTitleConfig.fieldSubtitleAfter"
            class="font-weight-light uppercase"
            style="max-width: 100%; overflow:hidden; white-space:nowrap; text-overflow: ellipsis;"
          >
            {{ record[config.module.recordTitleConfig.fieldSubtitleAfter] }}
          </h3>
          <h4
            v-if="config.module.recordTitleConfig.fieldSubtitleAfter2"
            class="font-weight-light uppercase"
            style="max-width: 100%; overflow:hidden; white-space:nowrap; text-overflow: ellipsis;"
          >
            {{ record[config.module.recordTitleConfig.fieldSubtitleAfter2] }}
          </h4>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="pa-0">
          <app-record-detail-alert-button
            class="d-none d-sm-flex"
            :title="title"
          />
        </v-col>
        <v-col cols="auto" class="pa-0 mr-5">
          <app-record-detail-deletion-button
            class="d-none d-sm-flex"
            @executeDeletion="executeDeletion()"
            :endpoint="config.module.basicConfig.apiEndpoint"
            :recordId="record.id"
          />
        </v-col>
        <!-- <v-col cols="1" class="px-0 mt-0 pt-0 mr-0">
          <v-row class="px-0 mt-0 pt-0"> </v-row>
        </v-col> -->
      </v-row>
    </div>
  </container-config>
</template>

<script>
export default {
  props: {
    record: {
      type: Object
    }
  },
  computed: {
    //TO DO: move the title field name to the module config
    title() {
      let record = this.record;
      let first_name = record["first_name"] || "";
      let last_name = record["last_name"] || "";
      let name = record["name"] || "";
      let title = record["title"] || "";
      let title_original = record["title_original"] || "";
      return title + title_original + first_name + " " + last_name + " " + name;
    }
  },
  methods: {
    executeDeletion() {
      this.$emit("executeDeletion");
    }
  }
};
</script>
