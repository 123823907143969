<template>
  <container-config>
    <div slot-scope="{ moduleName }">
      <v-row style="width: 1200px">
        <v-col class="pt-0" cols="auto">
          <h4 class="overline font-weight-medium text-uppercase ml-4 mb-2">
            Verfügbare Felder
          </h4>
          <div style="height: calc(100vh - 300px); overflow: scroll">
            <v-data-table
              style="width: 400px"
              dense
              fixed
              class="template-table"
              hide-default-footer
              :items-per-page="1000"
              :headers="headers_fields_de"
              :items="difference"
            >
              <template v-slot:item.value="{ item }">
                <div
                  style="
                    max-width: 305px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ item.value }}
                </div>
              </template>
              <template v-slot:item.copy="{ item }">
                <v-btn small icon @click="addField(item)">
                  <v-icon small>mdi-arrow-right-bold-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </v-col>
        <v-col cols="auto" class="pt-0">
          <div>
            <h4 class="overline font-weight-medium text-uppercase ml-4 mb-2">
              Gewählte Felder
            </h4>
            <div
              style="height: calc(100vh - 300px); overflow: scroll"
              class="pb-3"
            >
              <v-data-table
                dense
                style="width: 400px"
                class="template-table"
                hide-default-footer
                :items-per-page="1000"
                :headers="headers_selection_de"
                :items="selection"
              >
                <template v-slot:item.value="{ item }">
                  <div
                    style="
                      max-width: 240px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ item.value }}
                  </div>
                </template>
                <template v-slot:item.arrowUp="{ item }">
                  <v-btn
                    small
                    icon
                    @click="moveFieldUp(item)"
                    class="mr-n12"
                    v-if="selectionItemIndex(item) > 0"
                  >
                    <v-icon small color="#aaa">mdi-chevron-up</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.arrowDown="{ item }">
                  <v-btn
                    small
                    icon
                    @click="moveFieldDown(item)"
                    class="ml-n2 mr-n12"
                    v-if="selectionItemIndex(item) < selection.length - 1"
                  >
                    <v-icon small color="#aaa">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.delete="{ item }">
                  <v-btn small icon @click="removeField(item)" class="mr-0">
                    <v-icon small color="#aaa">mdi-close-circle-outline</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </div>
          </div>
        </v-col>
        <v-col>
          <div v-if="selection.length > 0">
            <app-excel-exporter
              class="mt-4"
              :headers="selection"
              :moduleName="moduleName"
            >
              <div slot-scope="{ openDialog }">
                <v-btn small @click="openDialog" style="width: 200px">
                  <svg-excel :width="22" class="mr-2 mt-1" />
                  Excel exportieren
                </v-btn>
              </div>
            </app-excel-exporter>
            <div class="mt-3">
              <v-btn x-small @click="emptySelection" style="width: 200px">
                <v-icon class="ml-1" x-small left
                  >mdi-close-circle-outline</v-icon
                >
                Feldauswahl leeren
              </v-btn>
            </div>
            <h4 class="overline font-weight-medium text-uppercase mb-2 mt-6">
              Speicherung
            </h4>
            <div v-if="!storeId">
              <v-btn small @click="storeDialog = true" style="width: 300px">
                <v-icon class="ml-1" small left
                  >mdi-content-save-outline</v-icon
                >
                Feldauswahl speichern
              </v-btn>
            </div>
            <div v-if="storeId">
              <v-icon small>mdi-content-save-outline</v-icon>
              gespeicherte Auswahl: '{{ storeName }}'
            </div>
            <div v-if="storeId && selectionIsDirty">
              <v-btn
                class="mt-3"
                small
                @click="storeChanges"
                v-if="selectionIsDirty"
                style="width: 300px"
              >
                <v-icon class="ml-1" small left
                  >mdi-content-save-outline</v-icon
                >
                Änderungen speichern
              </v-btn>
            </div>
            <div v-if="storeId && selectionIsDirty">
              <v-btn
                class="mt-3"
                small
                @click="storeSelectionAs"
                style="width: 300px"
              >
                <v-icon class="ml-1" small left
                  >mdi-content-save-all-outline</v-icon
                >
                Änderungen speichern als
              </v-btn>
            </div>

            <v-dialog
              :value="storeDialog"
              @input="storeDialog = !storeDialog"
              width="400"
            >
              <v-card style="background-color: #f2f2f2">
                <v-card-title class="py-3 px-6" style="background-color: #555">
                  <h4
                    style="color: white"
                    class="body-2 font-weight-medium text-uppercase"
                  >
                    Export speichern
                  </h4>
                </v-card-title>
                <v-card-text class="pt-4">
                  <base-label label="Name" />
                  <v-text-field
                    solo
                    elevation="0"
                    hide-details="auto"
                    dense
                    flat
                    label="Name"
                    v-model="storeName"
                    style="border: 1pt #aaa solid"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions class="px-6 pb-6">
                  <v-btn small @click="storeDialog = false">abbrechen</v-btn>
                  <v-spacer />
                  <v-btn small :disabled="storeName == ''" @click="saveExcel"
                    >OK</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
    </div>
  </container-config>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      fields: [],
      storeDialog: false,
      headers_fields_de: [
        // {
        //   text: "Label",
        //   value: "label",
        // },
        {
          text: "Feld",
          value: "value",
        },
        {
          text: "",
          value: "copy",
          sortable: false,
          width: 35,
        },
      ],
      headers_selection_de: [
        // {
        //   text: "Label",
        //   value: "label",
        // },
        {
          text: "Feld",
          value: "value",
        },
        {
          text: "",
          value: "arrowUp",
          sortable: false,
          width: 10,
        },
        {
          text: "",
          value: "arrowDown",
          sortable: false,
          width: 10,
        },
        {
          text: "",
          value: "delete",
          sortable: false,
          width: 10,
        },
      ],
    };
  },
  methods: {
    addField(item) {
      this.$store.dispatch("addItemToExcelSelection", item);
    },
    removeField(item) {
      let index = this.selectionItemIndex(item);
      this.selection.splice(index, 1);
    },
    moveFieldDown(item) {
      let index = this.selectionItemIndex(item);
      this.array_move(index, index + 1);
    },
    moveFieldUp(item) {
      let index = this.selectionItemIndex(item);
      this.array_move(index, index - 1);
    },
    loadFields() {
      axios
        .get("fields/" + this.endpoint)
        .then((response) => response.data)
        .then((data) => {
          this.fields = data.basisFields;
        });
    },
    array_move(old_index, new_index) {
      const arr = this.selection;
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      this.selection = arr;
    },
    selectionItemIndex(item) {
      return this.selection.findIndex((field) => field.value == item.value);
    },
    saveExcel() {
      let payload = {
        selection: this.selection,
        name: this.storeName,
        module: this.moduleName,
      };
      this.$store.dispatch("storeExcelFieldsSelection", payload);
      this.storeDialog = false;
    },
    storeChanges() {
      this.saveExcel();
    },
    storeSelectionAs() {
      this.storeId = null;
      this.storeName = null;
      this.storeDialog = true;
    },
    emptySelection() {
      this.$store.dispatch("emptyExcelSelection");
    },
  },
  computed: {
    storeName: {
      get() {
        return this.$store.state.excelExporter.name;
      },
      set(input) {
        this.$store.commit("SET_STORED_EXCEL_NAME", input);
      },
    },
    storeId: {
      get() {
        return this.$store.state.excelExporter.id;
      },
      set(input) {
        this.$store.commit("SET_STORED_EXCEL_ID", input);
      },
    },
    endpoint() {
      return this.$store.getters.moduleEndpoint;
    },
    recordId() {
      return this.$route.params.record_id;
    },
    moduleName() {
      return this.$route.params.module;
    },
    difference() {
      let difference = this.fields.filter((x) => !this.selection.includes(x));
      return difference;
    },
    selection() {
      return this.$store.state.excelExporter.selection;
    },

    storedSelection() {
      return this.$store.state.excelExporter.storedSelection;
    },
    selectionIsDirty() {
      return (
        JSON.stringify(this.selection) != JSON.stringify(this.storedSelection)
      );
    },
  },
  mounted() {
    this.loadFields();
  },
};
</script>
<style lang="scss" scoped>
.template-table {
  border: 0.25px #aaa solid;
}
</style>
