var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-config',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var color = ref.color;
var moduleName = ref.moduleName;
var language = ref.language;
return _c('div',{},[_c('container-list',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var filter = ref.filter;
var changeFilter = ref.changeFilter;
return _c('div',{},[_c('v-text-field',{ref:"searchField",staticClass:"pt-2",attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":_vm.texts['searchIn'][language] + ' ' + moduleName,"color":color,"value":filter,"clearable":"","onFocus":"this.select();"},on:{"input":changeFilter,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$refs.searchField.blur()}}}),_c('GlobalEvents',{on:{"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==70){ return null; }if(!$event.metaKey){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey){ return null; }$event.preventDefault();return _vm.$refs.searchField.focus()}}})],1)}}],null,true)})],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }