<template>
  <v-app v-if="isLogin">
    <router-view></router-view>
  </v-app>
  <v-app v-else-if="isPrint">
    <router-view></router-view>
  </v-app>
  <v-app v-else-if="isSettings">
    <app-navigation-drawer />
    <app-settings-header />
    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
  <v-app v-else-if="isUsers">
    <app-navigation-drawer />
    <app-users-header />
    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
  <v-app v-else-if="isHome">
    <app-config-loader>
      <app-home-redirect />
    </app-config-loader>
  </v-app>
  <v-app v-else>
    <app-config-loader>
      <app-navigation-drawer />
      <app-navigation-header />
      <v-content>
        <router-view></router-view>
      </v-content>
      <config-drawer v-if="configMode" />
    </app-config-loader>
  </v-app>
</template>

<script>
export default {
  name: "App",
  computed: {
    isLogin() {
      return (
        this.$route.path.includes("login") ||
        this.$route.path.includes("2fa") ||
        this.$route.path.includes("change-password")
      );
    },
    isHome() {
      let path = this.$route.path;
      return path == "/";
    },
    isPrint() {
      return this.$route.path.includes("print");
    },
    isSettings() {
      return this.$route.path.includes("settings");
    },
    isUsers() {
      return this.$route.path.includes("users");
    },
    configMode() {
      return this.$store.state.uiState.configMode;
    },
  },
};
</script>
