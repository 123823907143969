<template>
  <div @mouseover="hovered = true" @mouseout="hovered = false">
    <v-img
      :src="thumbnailPath"
      @click="openDialog(asset)"
      :class="{ 'elevation-3': hovered, 'elevation-1': !hovered }"
      :style="{ transform: 'scale(1' + (hovered ? '.01' : '.0') + ')' }"
      style="cursor:pointer;"
    >
      <div v-if="hovered == true" @mouseover="hovered = true">
        <v-footer
          v-if="imageInformationCarriageReturn"
          style="background-color: rgba(255,255,255,0.7);"
          absolute
        >
          <span class="caption" v-html="imageInformationCarriageReturn"></span>
        </v-footer>
      </div>
    </v-img>

    <v-dialog
      v-model="dialog"
      :width="Math.max(650, Math.min((width / height) * 600 + 45, 1250))"
    >
      <base-image-gallery-tile-modal
        v-bind="$props"
        @closeDialog="
          dialog = false;
          $emit('refreshOriginRecord');
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
export default {
  mixins: [baseComponentMixin],
  props: {
    asset: {
      type: Object
    },
    endpoint: {
      type: String
    },
    pathFields: {
      type: Array
    },
    urlThumbnail: {
      type: String
    },
    urlLarge: {
      type: String
    },
    urlOriginal: {
      type: String
    },
    imageInformationField: {
      type: String
    },
    imageRatioField: {
      type: String
    },
    uploadIdentifierField: {
      type: String
    }
  },
  data() {
    return {
      dialog: false,
      selectedAsset: {},
      hovered: false,
      width: null,
      height: null
    };
  },
  computed: {
    thumbnailPath() {
      return (
        this.urlThumbnail.replaceAll("{field}", this.asset[this.pathFields]) +
        (this.urlLarge.includes("?") ? "&" : "?") +
        "timestamp=" +
        this.asset.updated_at
      );
    },
    imageInformationCarriageReturn() {
      return this.asset[this.imageInformationField].replaceAll(", ", "<br>");
    }
  },
  methods: {
    openDialog(asset) {
      this.dialog = true;
      this.selectedAsset = asset;
    },
    checkImageDimensions() {
      let img = new Image();
      let self = this;
      img.onload = function() {
        // here you got the width and height
        var width = img.width;
        var height = img.height;
        self.width = width;
        self.height = height;
      };
      img.src = this.thumbnailPath;
    }
  },
  mounted() {
    this.checkImageDimensions();
  }
};
</script>

<style lang="scss" scoped></style>
