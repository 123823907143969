<template>
  <container-ui-state>
    <div slot-scope="uiState">
      <container-config>
        <div slot-scope="{ logoPath, language }">
          <v-app-bar
            :clipped-left="$vuetify.breakpoint.lgAndUp"
            app
            color="#666"
            dark
          >
            <v-toolbar-title class="headline text-uppercase ml-n1">
              <v-row>
                <v-col class="ml-1" style="padding-top: 9px;">
                  <img
                    v-if="logoPath"
                    :src="logoPath"
                    style="max-height: 50px; max-width: 80px; vertical-align: middle;"
                    @click="uiState.toggleDrawer"
                  />
                  <v-icon large v-else @click="uiState.toggleDrawer"
                    >mdi-menu</v-icon
                  >
                </v-col>
                <v-col class="pl-1" style="padding-top: 18px;">
                  <span class="font-weight-light">
                    {{ texts["settings"][language] }}
                  </span>
                </v-col>
              </v-row>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-alert
              type="error"
              class="mt-4 py-2 pl-3"
              v-if="
                appEnvironment == 'staging' || appEnvironment == 'development'
              "
              >{{ texts["stagingEnvironment"][language] }}</v-alert
            >
          </v-app-bar>
        </div>
      </container-config>
    </div>
  </container-ui-state>
</template>

<script>
export default {
  data() {
    return {
      texts: {
        stagingEnvironment: {
          en: "Staging Environment",
          de: "Testumgebung",
          da: "Testversion"
        },
        settings: {
          en: "Settings",
          da: "Indstillinger",
          de: "Einstellungen"
        }
      },
      appEnvironment: process.env.VUE_APP_ENV
    };
  }
};
</script>
