<template>
  <div>
    <container-config>
      <div slot-scope="config">
        <slot
          :language="config.language"
          :color="config.color"
          :queryFilter="queryFilter"
          :updateQueryFilter="updateQueryFilter"
          :myQueriesOnly="myQueriesOnly"
          :toggleMyQueriesOnly="toggleMyQueriesOnly"
          :queries="queries"
          :filteredQueries="filteredQueries"
          :deletionDialog="deletionDialog"
          :runQuery="runQuery"
          :editQuery="editQuery"
          :deleteQuery="deleteQuery"
          :executeDeletion="executeDeletion"
          :user="user"
        ></slot>
      </div>
    </container-config>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      queries: null,
      deletionDialog: false,
      deletionId: null
    };
  },
  methods: {
    runQuery(id) {
      this.$emit("switchTabs");
      this.$store.dispatch("loadAndPerformQuery", id);
    },
    editQuery(id) {
      this.$store.dispatch("loadQuery", id);
      this.$emit("switchTabs");
    },
    deleteQuery(id) {
      this.deletionDialog = true;
      this.deletionId = id;
    },
    executeDeletion() {
      this.deletionDialog = false;
      axios
        .delete("queries/" + this.deletionId)
        .then(r => r.data)
        .then(() => (this.deletionId = null))
        .then(() => this.loadQueries());
    },
    loadQueries() {
      axios
        .get("queries?base_table=" + this.selectedModuleEndpoint)
        .then(r => r.data)
        .then(data => (this.queries = data));
    },
    returnText(input) {
      let language = this.$store.state.config.app.appLanguage;
      let text = this.texts.find(e => e.placeholder == input);
      return text[language];
    },
    updateQueryFilter(value) {
      this.$store.dispatch("updateQueryFilter", value);
    },
    toggleMyQueriesOnly() {
      this.$store.dispatch("toggleMyQueriesOnly");
    }
  },
  computed: {
    filteredQueries() {
      let filteredQueries = this.queries;
      let self = this;
      if (this.myQueriesOnly) {
        filteredQueries = filteredQueries.filter(function(e) {
          return e.user_id === self.auth.user.id;
        });
      }
      if (this.queryFilter !== null) {
        filteredQueries = filteredQueries.filter(function(e) {
          return (
            e.name.toLowerCase().includes(self.queryFilter.toLowerCase()) ||
            e.user_name.toLowerCase().includes(self.queryFilter.toLowerCase())
          );
        });
      }
      return filteredQueries;
    },
    myQueriesOnly() {
      return this.$store.state.queryBuilder.myQueriesOnly;
    },
    queryFilter() {
      return this.$store.state.queryBuilder.queryFilter;
    },
    selectedModuleEndpoint() {
      return this.$store.getters.selectedModuleConfigData.apiEndpoint;
    },
    user() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    this.loadQueries();
  }
};
</script>

<style lang="scss" scoped></style>
