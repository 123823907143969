var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-query-builder-subquery-value-input',{attrs:{"orQueryKey":_vm.orQueryKey,"tableGroupingKey":_vm.tableGroupingKey,"subqueryKey":_vm.subqueryKey},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var items = ref.items;
    var itemValue = ref.itemValue;
    var itemText = ref.itemText;
    var fieldContent = ref.fieldContent;
    var updateFieldContent = ref.updateFieldContent;
    var fieldType = ref.fieldType;
    var operator = ref.operator;
return _c('div',{},[(!(operator.includes('∅') || operator.includes('*')))?_c('div',[(fieldType == 'autocomplete')?_c('v-autocomplete',{attrs:{"items":items,"item-value":itemValue,"item-text":itemText,"label":"Value","solo":"","dense":"","value":fieldContent,"autocomplete":"disabled"},on:{"input":updateFieldContent}}):(fieldType == 'switch')?_c('v-switch',{staticClass:"mt-1 ml-2 querySwitch",attrs:{"solo":"","inset":"","color":"white","input-value":fieldContent,"autocomplete":"disabled"},on:{"change":updateFieldContent}}):(fieldType == 'select')?_c('v-select',{attrs:{"items":items,"label":"Value","solo":"","dense":"","value":fieldContent,"autocomplete":"disabled"},on:{"input":updateFieldContent}}):(fieldType == 'radio')?_c('v-radio-group',{staticClass:"mt-1 ml-3 mb-0",attrs:{"value":fieldContent,"row":"row"},on:{"input":updateFieldContent}},_vm._l((items),function(item){return _c('v-radio',{key:item,attrs:{"label":item,"value":item,"color":"black"}})}),1):_c('v-text-field',{attrs:{"dense":"","solo":"","label":"Value","value":fieldContent,"autocomplete":"disabled"},on:{"input":updateFieldContent}})],1):_vm._e()])}}])})}
var staticRenderFns = []

export { render, staticRenderFns }