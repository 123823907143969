<template>
  <div class="container">
    <v-dialog persistent v-model="fileUploading" width="140">
      <v-card width="140">
        <v-card-text class="pa-9">
          <v-progress-circular
            :value="uploadProgress"
            width="1"
            class="mb-1"
            size="60"
          >
            {{ uploadProgress }}
          </v-progress-circular>
          <br />
          <div class="mt-4">Datei wird auf Server geladen.</div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="editor-wrapper pa-2 pt-3 white">
      <VueTrix
        v-if="!uploadIdentifierField"
        :class="{
          'small-vue-trix': isSmall,
          'no-upload': true,
        }"
        v-model="content"
        placeholder="Enter your content"
        @trix-focus="handleEditorFocus"
        @trix-blur="handleEditorBlur"
        @trix-file-accept="fileAccept"
      />
      <VueTrix
        v-else
        v-model="content"
        placeholder="Enter your content"
        @trix-focus="handleEditorFocus"
        @trix-blur="handleEditorBlur"
        @trix-attachment-add="handleAttachmentChanges"
      />
    </div>
  </div>
</template>

<script>
import VueTrix from "vue-trix";
import config from "../config/appConfig";
import upperFirst from "lodash/upperFirst";
import axios from "axios";

export default {
  name: "Editor",
  components: {
    VueTrix,
  },
  props: {
    value: {
      type: String,
    },
    isSmall: {
      type: Boolean,
    },
    inputName: {
      type: String,
      required: false,
      default: "content",
    },
    uploadIdentifierField: {
      type: String,
    },
    record: {
      type: Object,
    },
  },
  data() {
    return {
      content: this.value,
      fileUploading: false,
      uploadProgress: 0,
    };
  },
  methods: {
    fileAccept(event) {
      event.preventDefault();
    },
    handleAttachmentChanges(event) {
      // 1. get file object
      let file = event.attachment.file;
      // 2. upload file to remote server with FormData
      axios
        .post(
          "/vapor/signed-storage-url",
          {
            content_type: file.type,
            parameters: {
              unique_serial_id: this.record[this.uploadIdentifierField],
              filename: file.name,
            },
          },
          {
            baseURL:
              config.basicConfig[
                "apiUrl" + upperFirst(process.env.VUE_APP_ENV)
              ],
          }
        )
        .then((signedStorageResponse) => {
          this.fileUploading = true;
          axios.defaults.withCredentials = false;
          axios
            .put(signedStorageResponse.data.url, file, {
              onUploadProgress: (progressEvent) => {
                this.uploadProgress = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                );
              },
            })
            .then(() => {
              let attributes = {
                url:
                  "https://fotomuseum.imgix.net/" +
                  signedStorageResponse.data.uuid +
                  "?w=1200",
              };
              event.attachment.setAttributes(attributes);
              axios
                .post("file-upload", {
                  uuid: signedStorageResponse.data.uuid,
                  key: signedStorageResponse.data.key,
                  bucket: signedStorageResponse.data.bucket,
                  name: file.name,
                  content_type: file.type,
                })
                .then((fileResponse) => {
                  this.fileUploading = false;
                  // 3. if upload success, set back the attachment's URL attribute
                  // @param object data from remote server response data after upload.
                  let attributes = {
                    url:
                      "https://fotomuseum.imgix.net/" +
                      fileResponse.data.path +
                      "?w=1200",
                  };
                  event.attachment.setAttributes(attributes);
                });
            });
          axios.defaults.withCredentials = true;
        });
    },
    handleEditorFocus() {
      this.$store.commit("TOGGLE_SUPPRESS_CLOSE_ON_ENTER", true);
    },
    handleEditorBlur() {
      this.$store.commit("TOGGLE_SUPPRESS_CLOSE_ON_ENTER", false);
      this.$emit("change", this.content);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
  width: 100%;
  padding: 0px 0 15px 0;
}
.editor-wrapper {
  max-width: 1000px;
  border: 0.25px solid #aaa !important;
  border-radius: 3px;
}
.small-vue-trix {
  max-height: 92px;
  overflow-y: auto;
}

input,
button {
  margin: 10px 0;
  font-size: 15px;
}

input[type="text"] {
  width: 100%;
  padding: 10px 0;
}

button {
  min-width: 120px;
}
</style>

<style>
.trix-content {
  border: 0pt;
  font-size: 16px;
  min-height: 200px;
}
trix-toolbar {
  font-size: 14px;
  padding-left: 4px;
  padding-right: 8px;
  border-bottom: 0.25pt solid #aaa;
}
.no-upload trix-toolbar .trix-button--icon-attach {
  display: none;
}
.trix-button,
.trix-button-group {
  border: 0pt !important;
}
figcaption.attachment__caption {
  display: none !important;
}
</style>
