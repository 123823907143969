<template>
  <container-config>
    <div slot-scope="{ color, language }">
      <v-col cols="auto" class="mr-3">
        <v-btn icon text color="#999" @click="toggleDeletionDialog">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
      <v-dialog v-model="deletionDialog" width="500">
        <v-card>
          <v-card-title>{{ texts["delete"][language] }}</v-card-title>
          <v-card-text>
            <p class="mb-0">
              {{ texts["reallyDeleteEntry"][language] }}
            </p>
          </v-card-text>
          <v-card-actions class="px-5 pb-5">
            <v-spacer></v-spacer>
            <v-btn color="grey lighten-1" @click="toggleDeletionDialog">
              {{ texts["cancel"][language] }}
            </v-btn>
            <v-btn :color="color" @click="executeDeletion" dark>
              {{ texts["yes"][language] }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </container-config>
</template>

<script>
export default {
  data() {
    return {
      deletionDialog: false,
      texts: {
        delete: {
          en: "Delete?",
          da: "Slet?",
          de: "Löschen?"
        },
        reallyDeleteEntry: {
          en: "Do you really want to delete this entry?",
          da: "Vil du virkelig slette dette emne?",
          de: "Soll dieser Eintrag wirklich gelöscht werden?"
        },
        cancel: {
          en: "cancel",
          da: "afbryd",
          de: "abbrechen"
        },
        yes: {
          en: "Yes",
          da: "Ja",
          de: "Ja"
        }
      }
    };
  },
  methods: {
    deleteRecord() {
      this.deletionDialog = true;
    },
    toggleDeletionDialog() {
      this.deletionDialog = !this.deletionDialog;
    },
    executeDeletion() {
      this.deletionDialog = false;
      this.$emit("executeDeletion");
    }
  }
};
</script>
