var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"draggable":_vm.configMode},on:{"dragstart":function($event){return _vm.pickupComponent($event, _vm.pathArray)}}},[_c('v-row',{class:{
      configMode: _vm.configMode,
      pickedUp: _vm.pickedUp,
      componentSelected: _vm.componentSelected,
      'elevation-9': _vm.configMode && !_vm.componentSelected,
      'elevation-1': _vm.componentSelected,
    }},[_c('v-col',{staticClass:"py-0"},[_vm._t("default")],2),(_vm.configMode)?_c('v-col',{staticClass:"pr-2 pl-1 py-0 ma-0",attrs:{"cols":"auto"}},[(_vm.configMode)?_c('v-btn',{staticClass:"small-icon",staticStyle:{"margin-right":"0px","margin-left":"-2px","margin-top":"-2px","margin-bottom":"-2px"},style:({
          'background-color': _vm.componentSelected ? 'cornflowerblue' : '#aaa',
        }),attrs:{"x-small":"","icon":""},on:{"click":_vm.editComponent}},[_c('v-icon',{attrs:{"x-small":"","color":"white"}},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.configMode)?_c('br'):_vm._e(),_c('v-btn',{staticStyle:{"margin-top":"-6px","margin-left":"-5px","margin-right":"0px"},attrs:{"x-small":"","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-drag")])],1),_c('v-dialog',{attrs:{"width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"background-color":"#f2f2f2"}},[_c('v-card-title',{staticStyle:{"background-color":"lightslategray","color":"white"}},[_vm._v(" "+_vm._s(_vm.component.componentType)+" ")]),_c('v-card-text',[_c('config-component-editor',{attrs:{"pathArray":_vm.pathArray,"component":_vm.component}})],1),_c('v-card-actions',{staticStyle:{"background-color":"lightslategray"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",on:{"click":function($event){_vm.dialog = false}}},[_vm._v("OK")])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }