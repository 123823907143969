<template>
  <container-input-field
    :recordId="recordId"
    :recordEndpoint="recordEndpoint"
    :field="field"
    :newRecord="newRecord"
    :modalColor="modalColor"
    :storeNewRecordImmediately="storeNewRecordImmediately"
    @storeNewRecordImmediately="$emit('storeRecord')"
  >
    <div slot-scope="field" v-if="!isHidden">
      <v-row class="mb-n1" v-if="field.alternativeDesign">
        <v-col class="ml-3 px-0 py-0">
          <base-label :label="label" />
        </v-col>
      </v-row>
      <v-row class="px-0 py-0">
        <v-col class="d-flex pt-1 pb-2" :cols="columns">
          <!-- <v-text-field
            :class="{
              alternativeTextFieldDesign: field.alternativeDesign,
              'body-2': field.alternativeDesign,
            }"
            elevation="0"
            :label="label"
            :placeholder="field.alternativeDesign ? ' ' : label"
            :value="field.value"
            @change="setValue($event)"
            hide-details="auto"
            autocomplete="disabled"
            :solo="field.alternativeDesign"
            :dense="field.alternativeDesign"
            :flat="field.alternativeDesign"
          ></v-text-field> -->
          <v-select
            :class="{
              alternativeSelectFieldDesign: field.alternativeDesign,
              'body-2': field.alternativeDesign,
            }"
            :label="label"
            :placeholder="field.alternativeDesign ? ' ' : label"
            :value="field.value"
            @change="setValue($event)"
            :color="field.color"
            :items="items"
            :solo="field.alternativeDesign"
            :dense="field.alternativeDesign"
            :flat="field.alternativeDesign"
            autocomplete="disabled"
            hide-details
          ></v-select>
        </v-col>
      </v-row>
    </div>
  </container-input-field>
</template>
<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";
import axios from "axios";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    columns: {
      type: Number,
      default: 12,
    },
  },
  data() {
    return {
      value: null,
      items: [2019, 2020, 2021],
    };
  },
  methods: {
    setValue(value) {
      this.value = value;
      axios
        .post("session-globals", {
          key: this.field,
          value,
        })
        .then(() => this.refreshOriginRecord());
    },
    refreshOriginRecord() {
      let payload = {
        recordId: this.recordId,
        endpoint: this.recordEndpoint,
      };
      if (this.recordEndpoint) {
        this.$store.dispatch("refreshOriginRecord", payload);
      }
    },
  },
};
</script>
<style>
.alternativeTextFieldDesign .v-input__slot {
  border: 0.25px solid #aaa;
  padding-left: 8px !important;
}
.alternativeTextFieldDesign .v-input__control {
  min-height: 34px !important;
}
.alternativeTextFieldDesign .v-icon {
  margin-top: 0px !important;
  font-size: 18px !important;
  color: #666 !important;
}
.p_TextInputReadOnly > .v-input__control > .v-input__slot {
  background: none !important;
}

.alternativeSelectFieldDesign .v-input__slot {
  border: 0.25px solid #aaa;
  padding-left: 8px !important;
}
.alternativeSelectFieldDesign .v-input__control {
  min-height: 34px !important;
}
.alternativeSelectFieldDesign .v-icon {
  margin-top: 0px !important;
  font-size: 18px !important;
  color: #666 !important;
}
.alternativeSelectFieldDesign .mdi-close,
.alternativeSelectFieldDesign .mdi-menu-down {
  margin-top: 0px !important;
  margin-right: -10px;
  font-size: 14px !important;
  color: #aaa !important;
}
.alternativeSelectFieldDesign .mdi-close {
  margin-right: -30px;
}
</style>
