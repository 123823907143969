<template>
  <div>
    <slot :width="width"></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: null,
    };
  },
  computed: {
    settings() {
      return this.$store.state.config.app.settings;
    },
    selectedSetting() {
      return this.$route.params.setting;
    },
    selectedSettingObject() {
      return this.settings.find((e) => e.name == this.selectedSetting);
    },
    width() {
      return this.selectedSettingObject.width || 1200;
    },
  },
};
</script>
