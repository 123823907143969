var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"inputRow"},[_vm._l((_vm.components),function(component,i){return _c('td',{key:i,staticClass:"inputRow"},[_c('BaseRecordDataTableDirectEntry' +
        component.componentType.replace('BaseInput', ''),_vm._b({tag:"component"},'component',Object.assign({}, component.props,
        {recordId: _vm.recordId,
        recordEndpoint: _vm.recordEndpoint,
        originEndpoint: _vm.originEndpoint,
        originRecordId: _vm.originRecordId,
        newRecord: true}),false))],1)}),_c('td',{staticClass:"pl-1"},[_c('base-record-data-table-direct-entry-save',_vm._b({key:_vm.key,on:{"handleNewRecordResponse":_vm.handleNewRecordResponse}},'base-record-data-table-direct-entry-save',{
        recordId: _vm.recordId,
        recordEndpoint: _vm.recordEndpoint,
        originEndpoint: _vm.originEndpoint,
        originRecordId: _vm.originRecordId,
        newRecord: true,
      },false))],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }