<template>
  <container-config>
    <div slot-scope="config">
      <slot
        :language="config.language"
        :color="config.color"
        :moduleName="config.moduleName"
        :queryName="queryName"
        :queryBuilderIsActive="queryBuilderIsActive"
        :query="query"
        :loading="loading"
        :addNewQuery="addNewQuery"
        :performFind="performFind"
        :showQueryBuilder="showQueryBuilder"
        :toggleQueryBuilder="toggleQueryBuilder"
        :showAllRecords="showAllRecords"
      ></slot>
    </div>
  </container-config>
</template>

<script>
export default {
  computed: {
    queryBuilder() {
      return this.$store.state.queryBuilder;
    },
    showQueryBuilder() {
      return this.queryBuilder.showQueryBuilder;
    },
    queryName() {
      return this.queryBuilder.name;
    },
    queryBuilderIsActive() {
      let module = this.moduleName;
      if (this.$store.state[module]) {
        if (this.$store.state[module].query != null) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    query() {
      return this.queryBuilder.query;
    },
    loading() {
      return null;
    },
    moduleName() {
      return this.$route.params.module;
    },
    moduleConfig() {
      let modules = this.$store.state.config.app.modules;
      return modules.find(e => e.basicConfig.path == this.moduleName);
    },
    moduleEndpoint() {
      return this.moduleConfig.basicConfig.apiEndpoint;
    }
  },
  methods: {
    addNewQuery() {
      this.$store.commit("ADD_NEW_QUERY");
    },
    toggleQueryBuilder() {
      this.$store.commit("TOGGLE_QUERY_BUILDER");
    },
    performFind() {
      let query = this.$store.getters.encodedQuery;
      this.$store.commit("SET_QUERY", { module: this.moduleName, query });
      this.$store.commit("SET_FILTER", {
        module: this.moduleName,
        value: null
      });
      this.$store.dispatch("changeHandler", { clearRecords: true });
      this.toggleQueryBuilder();
    },
    showAllRecords() {
      this.$store.commit("SET_QUERY", { module: this.moduleName, query: null });
      this.$store.commit("CLEAR_QUERY");
      this.$store.dispatch("changeHandler", { clearRecords: true });
      this.toggleQueryBuilder();
    }
  },
  mounted() {
    this.$store.commit("CHANGE_BASE_TABLE", this.moduleEndpoint);
  }
};
</script>
