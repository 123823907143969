<template>
  <container-ui-state>
    <div slot-scope="uiState">
      <container-config>
        <div slot-scope="config">
          <v-app-bar
            :clipped-left="$vuetify.breakpoint.lgAndUp"
            app
            :color="config.color"
            :dark="config.design.headerIsDark"
          >
            <v-toolbar-title class="headline text-uppercase ml-n1">
              <v-row>
                <v-col
                  class="ml-1 pb-0"
                  :class="{
                    'pt-1': config.app.logoPadding == 1,
                    'pt-4': config.app.logoPadding == 4,
                  }"
                >
                  <img
                    v-if="config.logoPath"
                    :src="config.logoPath"
                    style="max-height: 50px; max-width: 80px"
                    @click="uiState.toggleDrawer"
                  />
                  <div v-else style="max-height: 50px" class="pt-2">
                    <v-icon large @click="uiState.toggleDrawer"
                      >mdi-menu</v-icon
                    >
                  </div>
                </v-col>
                <v-col class="pl-1" style="padding-top: 14px">
                  <span class="font-weight-light">
                    {{ config.module.basicConfig.name }}
                  </span>
                </v-col>
              </v-row>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-alert
              type="error"
              class="mt-4 py-2 pl-3 d-none d-sm-flex"
              v-if="appEnvironment == 'staging'"
              >{{ texts["stagingEnvironment"][config.language] }}
            </v-alert>
            <v-spacer v-if="appEnvironment == 'development'"></v-spacer>
            <app-config-header-tools
              class="mt-5 ml-2"
              v-if="appEnvironment == 'development'"
            />
            <v-spacer
              v-if="config.module.basicConfig.variablePreFilter"
            ></v-spacer>
            <app-navigation-global-filter
              :filterField="
                config.module.basicConfig.variablePreFilterFieldPath
              "
              :key="'filter' + $route.params.module"
              v-if="config.module.basicConfig.variablePreFilter"
            />
            <v-spacer></v-spacer>
            <app-navigation-view-buttons
              class="d-none d-sm-flex"
              v-if="config.module.basicConfig.isDashboardModule != true"
            />

            <app-query-builder
              v-if="
                config.module.basicConfig.isDashboardModule != true &&
                $store.state[$route.params.module]
              "
              :key="$route.params.module"
            />
            <app-exporter></app-exporter>
            <app-global-search
              v-if="config.design.headerHasSearchbar"
              class="mr-3 d-none d-sm-flex"
            />
            <app-record-dialog></app-record-dialog>
          </v-app-bar>
        </div>
      </container-config>
    </div>
  </container-ui-state>
</template>

<script>
export default {
  data() {
    return {
      texts: {
        stagingEnvironment: {
          en: "Staging",
          de: "Testumgebung",
          da: "Testversion",
        },
      },
      appEnvironment: process.env.VUE_APP_ENV,
    };
  },
};
</script>
