var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-query-builder-table-grouping',{attrs:{"orQueryKey":_vm.orQueryKey,"tableGroupingKey":_vm.tableGroupingKey},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var color = ref.color;
    var moduleConfig = ref.moduleConfig;
    var language = ref.language;
    var deleteTableGrouping = ref.deleteTableGrouping;
    var addNewSubquery = ref.addNewSubquery;
    var includeState = ref.includeState;
    var changeIncludeState = ref.changeIncludeState;
    var baseTable = ref.baseTable;
    var changeBaseTable = ref.changeBaseTable;
    var query = ref.query;
return _c('div',{},[_c('v-card',{staticClass:"mb-5",attrs:{"color":color}},[_c('v-card-title',{staticClass:"py-0 mb-0"},[_c('v-row',[_c('v-col',{staticClass:"pr-0 pb-0",staticStyle:{"max-width":"170px"},attrs:{"cols":"auto"}},[_c('v-select',{attrs:{"solo":"","dense":"","items":_vm.includeExcludeArray[language],"item-value":"value","item-text":"label","value":includeState,"background-color":includeState == 'include' ? '#ddd' : 'red lighten-3'},on:{"input":changeIncludeState}})],1),_c('v-col',{staticClass:"pr-0 pb-0"},[_c('v-select',{attrs:{"solo":"","dense":"","items":moduleConfig.queryFields,"item-value":"tableName","item-text":"tableNameLabel","value":baseTable,"background-color":"#ddd"},on:{"input":changeBaseTable}})],1),_c('v-col',{staticClass:"pb-0"},[(baseTable)?_c('span',{staticClass:"subtitle-1 p-0 white--text"},[_vm._v(_vm._s(_vm.texts["withCriteria"][language]))]):_vm._e()]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mr-n2 mt-n1",attrs:{"icon":""},on:{"click":function($event){return deleteTableGrouping(_vm.orQueryKey, _vm.tableGroupingKey)}}},[_c('v-icon',{attrs:{"color":"#cfcfcf"}},[_vm._v("mdi-close-circle-outline")])],1)],1)],1)],1),_c('v-card-text',{staticClass:"mt-n2"},[(baseTable)?_c('div',[_vm._l((query.orQueries[_vm.orQueryKey]
              .tableGroupings[_vm.tableGroupingKey].andQueries),function(subquery,key){return _c('app-query-builder-subquery',{key:key,attrs:{"orQueryKey":_vm.orQueryKey,"tableGroupingKey":_vm.tableGroupingKey,"baseTable":baseTable}})}),_c('v-btn',{staticClass:"px-1 mt-n3",attrs:{"text":"","small":"","dark":""},on:{"click":function($event){return addNewSubquery(_vm.orQueryKey, _vm.tableGroupingKey)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.texts["criterium"][language]))])],1)],2):_vm._e()])],1)],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }