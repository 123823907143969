<template>
  <div>
    <slot
      :items="items"
      :changeSearch="changeSearch"
      :isLoading="isLoading"
      :clearItems="clearItems"
    ></slot>
  </div>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";

export default {
  data() {
    return {
      items: [],
      search: null,
      isLoading: false
    };
  },
  methods: {
    changeSearch(input) {
      if (input !== null) {
        this.search = input;
      }
    },
    clearItems() {
      this.items = [];
    }
  },
  watch: {
    search: debounce(function() {
      if (this.search == null) {
        return null;
      }
      this.isLoading = true;
      axios
        .get("global?filter=" + this.search)
        .then(r => r.data)
        .then(data => {
          this.items = data;
          this.isLoading = false;
        })
        .catch(error => this.$store.dispatch("handleError", error));
    }, 200)
  }
};
</script>
