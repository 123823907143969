<template>
  <div>
    <v-row>
      <v-col class="ml-3 px-0 py-0">
        <base-label :label="label" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="py-0 mr-4">
        <v-menu
          v-model="menuDatepicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :class="{
                alternativeDatepickerFieldDesign: true,
                'body-2': true,
                p_TextInputReadOnly: isReadonly,
              }"
              :value="formatDate(dateFromValue)"
              solo
              dense
              flat
              append-icon="mdi-calendar"
              readonly
              hide-details
              class="pb-2"
              v-on="on"
              :clearable="!isReadonly"
              @click:clear="clearValue"
              :color="color"
            ></v-text-field>
          </template>
          <v-date-picker
            v-if="!isReadonly"
            :value="dateFromValue"
            @change="updateDate"
            @input="menuDatepicker = false"
            :first-day-of-week="1"
            :locale="locale"
            :color="color"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="py-0" cols="2">
        <v-select
          :class="{
            alternativeSelectFieldDesign: true,
            'body-2': true,
          }"
          placeholder="hh"
          :value="String(hoursFromValue)"
          @change="updateHour"
          :items="hours"
          solo
          dense
          flat
          :color="color"
          autocomplete="disabled"
          hide-details
          :disabled="!dateFromValue"
        ></v-select>
      </v-col>
      <v-col class="py-0 pt-1 px-0" cols="auto">:</v-col>
      <v-col class="py-0" cols="2">
        <v-select
          :class="{
            alternativeSelectFieldDesign: true,
            'body-2': true,
          }"
          placeholder="mm"
          :value="String(minutesFromValue)"
          @change="updateMinute"
          :items="minutes"
          solo
          dense
          flat
          :color="color"
          autocomplete="disabled"
          hide-details
          :disabled="!hoursFromValue"
        ></v-select
      ></v-col>
    </v-row>
  </div>
</template>

<script>
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
// eslint-disable-next-line
import { da } from "date-fns/locale";
// eslint-disable-next-line
import { de } from "date-fns/locale";
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  data() {
    return {
      // date: new Date().toISOString().substr(0, 10),
      date: this.dateFromValue,
      hour: this.hoursFromValue,
      minute: this.minutesFromValue,
      menuDatepicker: false,
      hours: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      minutes: ["00", "15", "30", "45"],
    };
  },
  props: {
    dateFormattingString: {
      type: String,
      default: "eee, d MMMM y",
    },
    isReadonly: {
      type: Boolean,
    },
    value: {
      type: String,
    },
    color: {
      type: String,
    },
  },
  computed: {
    language() {
      return this.$store.state.config.app.basicConfig.appLanguage;
    },
    locale() {
      let localeArray = {
        en: "en-us",
        de: "de-ch",
      };
      return localeArray[this.language];
    },
    dateTimeObject() {
      return new Date(this.value.replace(/-/g, "/"));
    },
    dateFromValue() {
      if (this.value) {
        var d = this.dateTimeObject,
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
      } else {
        return "";
      }
    },
    hoursFromValue() {
      if (this.secondsFromValue == 1) {
        return null;
      } else if (this.value) {
        let hours = this.dateTimeObject.getHours();
        return hours < 10 ? "0" + hours : hours;
      } else {
        return null;
      }
    },
    minutesFromValue() {
      if (this.secondsFromValue == 1 || this.secondsFromValue == 2) {
        return null;
      } else if (this.value) {
        let minutes = this.dateTimeObject.getMinutes();
        return minutes < 10 ? "0" + minutes : minutes;
      } else {
        return null;
      }
    },
    secondsFromValue() {
      if (this.value) {
        return this.dateTimeObject.getSeconds();
      } else {
        return null;
      }
    },
  },
  methods: {
    formatDate(input) {
      if (input) {
        let parsedDate = parseISO(input);
        let languageObject =
          this.language == "de" ? de : this.language == "da" ? da : null;
        return format(parsedDate, this.dateFormattingString, {
          locale: languageObject,
        });
      } else {
        return null;
      }
    },
    updateValue(input) {
      // alert(input);
      this.$emit("updateValue", input);
    },
    updateDate(input) {
      let date = input;
      let hours = this.hoursFromValue || 0;
      let minutes = this.minutesFromValue || 0;
      let seconds = this.hoursFromValue && this.minutesFromValue ? "00" : "01";
      let result = date + " " + hours + ":" + minutes + ":" + seconds;
      this.updateValue(result);
    },
    updateHour(input) {
      let date = this.dateFromValue;
      let hours = input;
      let minutes = this.minutesFromValue || 0;
      let seconds = this.minutesFromValue ? "00" : "02";
      let result = date + " " + hours + ":" + minutes + ":" + seconds;
      this.updateValue(result);
    },
    updateMinute(input) {
      let date = this.dateFromValue;
      let hours = this.hoursFromValue || 0;
      let minutes = input;
      let seconds = this.hoursFromValue ? "00" : "03";
      let result = date + " " + hours + ":" + minutes + ":" + seconds;
      this.updateValue(result);
    },
    clearValue() {
      this.updateValue(null);
    },
  },
};
</script>
<style scope="local">
.alternativeDatepickerFieldDesign .mdi-close {
  margin-top: 0px !important;
  margin-right: -24px !important;
  font-size: 14px !important;
  color: #aaa !important;
}
</style>
