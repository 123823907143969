<template>
  <div>
    <v-row>
      <v-col style="max-width: 500px;">
        <base-input-text field="name" label="Name" />
        <base-input-text field="email" label="E-Mail" type="email" />
        <base-input-checkbox
          field="is_active"
          :label="returnText('isActive')"
        />
        <base-input-checkbox field="is_admin" :label="returnText('isAdmin')" />
        <v-btn color="primary" class="mt-6" @click="resetPassword">{{
          returnText("resetPassword")
        }}</v-btn>
      </v-col>
    </v-row>
    <!-- change dialog -->
    <v-dialog v-model="changeDialog" width="550">
      <v-card>
        <v-card-title>
          <p class="pt-2">
            {{ returnText("resetPasswordConfirmationDialog") }}
          </p>
        </v-card-title>
        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-1" @click="changeDialog = false">
            {{ returnText("cancel") }}
          </v-btn>
          <v-btn color="primary" @click="executePasswordChange">
            {{ returnText("yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- confirmation dialog -->
    <v-dialog v-model="confirmation" width="500">
      <v-card class="pa-2">
        <v-card-title>
          {{ returnText("passwordWasResetTitle") }}
        </v-card-title>
        <v-card-text class="body-1 pt-3 text-justify">
          <p>
            {{ returnText("passwordWasResetDialog1")
            }}{{ config.design.defaultPassword
            }}{{ returnText("passwordWasResetDialog2") }}
          </p>
          <p>
            {{ selectedRecord.name }}
            {{ returnText("passwordWasResetDialog3") }}
          </p>
        </v-card-text>
        <v-card-actions class="px-5 pb-6">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="confirmation = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- error dialog -->
    <v-dialog v-model="error" width="500">
      <v-alert class="ma-0" color="error" type="error"
        >Es gab ein Problem beim zurücksetzen des Passworts. Bitte Andrew
        kontaktieren.</v-alert
      >
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      changeDialog: false,
      confirmation: false,
      error: false,
      texts: [
        {
          placeholder: "resetPassword",
          en: "reset password",
          da: "nulstil kodeord",
          de: "Passwort zurücksetzen"
        },
        {
          placeholder: "isActive",
          en: "is active",
          da: "er aktiv",
          de: "ist aktiv"
        },
        {
          placeholder: "isAdmin",
          en: "has admin rights",
          da: "har administratorrettigheder",
          de: "hat Admin-Rechte"
        },
        {
          placeholder: "resetPasswordConfirmationDialog",
          en: "Do you really want to reset this password?",
          da: "Vil du virkelig nulstille dette kodeord?",
          de: "Soll dieses Passwort wirklich zurückgesetzt werden?"
        },
        {
          placeholder: "cancel",
          en: "cancel",
          da: "afbryd",
          de: "abbrechen"
        },
        {
          placeholder: "yes",
          en: "Yes",
          da: "Ja",
          de: "Ja"
        },
        {
          placeholder: "passwordWasResetTitle",
          en: "The password has been reset.",
          da: "Dette kodeord er blevet nulstillet",
          de: "Passwort zurückgesetzt."
        },
        {
          placeholder: "passwordWasResetDialog1",
          en: "The password has been temporarily changed to '",
          da: "Kodeord er blevet midlertidigt ændret til '",
          de: "Das Password wurde zwischenzeitlich auf '"
        },
        {
          placeholder: "passwordWasResetDialog2",
          en: "'.",
          da: "'.",
          de: "' zurückgesetzt."
        },
        {
          placeholder: "passwordWasResetDialog3",
          en: "will be asked to set a new password after logging in next time.",
          da: "vil blive anmodet om at ændre kodeordet ved næste login.",
          de:
            "wird beim ersten Login aufgefordert, ein neues Passwort zu erstellen."
        }
      ]
    };
  },
  methods: {
    resetPassword() {
      this.changeDialog = true;
    },
    executePasswordChange() {
      let reset_password = 1;
      let password = this.config.design.defaultPassword;
      let payload = { reset_password, password };
      axios
        .patch("users/" + this.selectedRecord.id, payload)
        .then(() => {
          this.confirmation = true;
          this.changeDialog = false;
        })
        .catch(() => {
          this.error = true;
          this.changeDialog = false;
        });
    },
    returnText(input) {
      let language = this.$store.state.config.app.basicConfig.appLanguage;
      let text = this.texts.find(e => e.placeholder == input);
      return text[language];
    }
  },
  computed: {
    selectedRecord() {
      let endpoint = this.$store.getters.selectedModuleEndpoint;
      let record = this.$store.state[endpoint]["selectedRecord"];
      return record;
    },
    ...mapState(["config"])
  }
};
</script>

<style lang="scss" scoped></style>
