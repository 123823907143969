<template>
  <v-row
    :class="[
      customClass,
      {
        configRow: configMode,
        rowSelected,
        'elevation-9': configMode && !rowSelected,
        'elevation-2': rowSelected,
      },
    ]"
    :dense="configMode || hasNarrowGutter"
    :style="{
      'max-width': maxWidth + 'px',
    }"
    :justify="justify"
    v-if="!isHidden"
  >
    <component
      v-for="(component, key) in components"
      :key="key"
      :pathArray="[...pathArray, 'props', 'components', key]"
      v-bind:is="component.componentType"
      v-bind="{
        ...component.props,
        recordId,
        recordEndpoint,
        component,
        modalColor,
        newRecord,
      }"
      @closeModal="$emit('closeModal')"
    ></component>
    <div style="margin-right: -32px; margin-left: 3px" v-if="configMode">
      <v-btn
        x-small
        icon
        @click="editComponent"
        class="small-icon"
        style="color: white; margin-top: -6px; margin-left: 3px; z-index: 10"
        :style="{ 'background-color': rowSelected ? 'cornflowerblue' : '#aaa' }"
      >
        <v-icon x-small color="white" class="small-icon">mdi-pencil</v-icon>
      </v-btn>
      <br />
      <v-btn
        x-small
        icon
        class="small-icon"
        style="
          background-color: #aaa;
          color: white;
          margin-top: -10px;
          margin-left: 3px;
          z-index: 10;
        "
      >
        <v-icon x-small color="white" class="small-icon"
          >mdi-shape-square-plus</v-icon
        >
      </v-btn>
      <br />
      <v-btn
        x-small
        icon
        style="margin-top: -10px; margin-right: 8px; margin-bottom: 5px"
      >
        <v-icon small>mdi-drag</v-icon>
      </v-btn>
    </div>
    <v-dialog width="1000" v-model="dialog">
      <v-card style="background-color: #f2f2f2">
        <v-card-title style="background-color: lightslategray; color: white">
          Row
        </v-card-title>
        <v-card-text>
          <config-component-editor
            :pathArray="pathArray"
            :component="component"
          />
        </v-card-text>
        <v-card-actions style="background-color: lightslategray">
          <v-spacer />
          <v-btn class="mr-4" @click="dialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { isEqual } from "lodash";
import { baseComponentMixin } from "../mixins/baseComponentMixin";

export default {
  mixins: [baseComponentMixin],
  props: {
    customClass: {
      type: String,
    },
    maxWidth: {
      type: Number,
    },
    hasNarrowGutter: {
      type: Boolean,
    },
    justify: {
      type: String,
      default: "start",
    },
    component: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    configMode() {
      return this.$store.state.uiState.configMode;
    },
    rowSelected() {
      const selectedArray = this.$store.state.uiState.configPathArray;
      return this.configMode && isEqual(this.pathArray, selectedArray);
    },
  },
  methods: {
    editComponent() {
      this.$store.commit("SET_CONFIG_PATH_ARRAY", this.pathArray);
    },
  },
};
</script>

<style scoped>
.configRow {
  padding: 5px;
  padding-right: 30px;
  margin-top: 5px;
  margin-left: -12px;
  margin-right: -12px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  /* border: 0.25px solid purple; */
  border-radius: 3px;
  background-color: #ebdfeb;
}
.rowSelected {
  /* background-color: #b7c0c9; */
  border: 0.25px solid #a0a0a0;
  /* background-color: thistle; */
}
</style>
<style>
.v-btn--icon.v-size--x-small.small-icon {
  height: 15px !important;
  width: 15px !important;
}
.small-icon .v-icon {
  font-size: 10px !important;
}
</style>
