<template>
  <div
    slot-scope="{ record }"
    v-if="record && !isHidden"
    :style="{
      'padding-top':
        calculatedFontSize < 0.875
          ? -(0.4375 - calculatedFontSize) + 'rem'
          : 0 + 'rem',
    }"
  >
    <h4
      class="font-weight-medium text-uppercase ml-0 mt-3 mb-2"
      :class="{ 'px-2 py-1 mb-2': backgroundColor != null }"
      style="letter-spacing: 1.25px; color: white; border-radius: 3px"
      :style="{
        'font-size': calculatedFontSize + 'rem',
        'text-decoration': underline ? 'underline' : 'none',
        'background-color': backgroundColor,
        color: backgroundColor != null ? 'white' : 'rgba(0, 0, 0, 0.87)',
      }"
    >
      {{ text.replace("{field}", record[field]) }}
    </h4>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";

export default {
  mixins: [baseComponentMixin],
  props: {
    text: {
      type: String,
      default: "Title",
    },
    fontSize: {
      type: Number,
    },
    backgroundColor: {
      type: String,
    },
    field: {
      type: String,
    },
    underline: {
      type: Boolean,
    },
  },
  computed: {
    calculatedFontSize() {
      return this.fontSize || 0.875;
    },
  },
};
</script>
