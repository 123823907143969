var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.record && !_vm.isHidden)?_c('div',{style:({
    'padding-top':
      _vm.calculatedFontSize < 0.875
        ? -(0.4375 - _vm.calculatedFontSize) + 'rem'
        : 0 + 'rem',
  })},[_c('h4',{staticClass:"font-weight-medium text-uppercase ml-0 mt-3 mb-2",class:{ 'px-2 py-1 mb-2': _vm.backgroundColor != null },staticStyle:{"letter-spacing":"1.25px","color":"white","border-radius":"3px"},style:({
      'font-size': _vm.calculatedFontSize + 'rem',
      'text-decoration': _vm.underline ? 'underline' : 'none',
      'background-color': _vm.backgroundColor,
      color: _vm.backgroundColor != null ? 'white' : 'rgba(0, 0, 0, 0.87)',
    })},[_vm._v(" "+_vm._s(_vm.text.replace("{field}", _vm.record[_vm.field]))+" ")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }