var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-settings-details-body',{key:_vm.$route.params.setting,scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var selectedSetting = ref.selectedSetting;
    var items = ref.items;
    var components = ref.components;
    var headers = ref.headers;
    var endpoint = ref.endpoint;
    var loadSettings = ref.loadSettings;
return _c('div',{},[(items)?_c('v-data-table',{attrs:{"items":items,"headers":headers,"hide-default-footer":"","items-per-page":10000,"multi-sort":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editRecord(item)}}},[_vm._v("mdi-pencil")])],1)]}}],null,true)}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500","fullscreen":_vm.$vuetify.breakpoint.xsOnly,"hide-overlay":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('base-record-modal',{key:_vm.modalKey,attrs:{"components":components,"preloadedRecord":_vm.preloadedRecord,"endpoint":endpoint,"modalColor":"#666","newRecord":_vm.newRecord},on:{"closeDialog":function($event){_vm.closeDialog();
          loadSettings();}}})],1)],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }