<template>
  <div class="pt-7 mb-3">
    <v-btn small @click="copyRecord">{{ label }}</v-btn>
  </div>
</template>

<script>
import axios from "axios";
import { baseComponentMixin } from "../mixins/baseComponentMixin";

export default {
  mixins: [baseComponentMixin],
  props: {
    label: {
      type: String,
      default: "copy record",
    },
    descriptionFieldName: {
      type: String,
      default: "copy record",
    },
    urlParameters: {
      type: String,
    },
    confirmationDialog: {
      type: String,
    },
  },
  methods: {
    copyRecord() {
      if (this.confirmationDialog) {
        let confirmation = confirm(this.confirmationDialog);
        if (!confirmation) {
          return;
        }
      }
      let path =
        "pianola-duplicate/" +
        this.recordEndpoint +
        "/" +
        this.recordId +
        (this.urlParameters ? "?" + this.urlParameters : "");
      let payload = {};
      if (this.descriptionFieldName) {
        payload = { ...payload, field: this.descriptionFieldName };
      }
      axios
        .post(path, payload)
        .then((r) => r.data)
        .then((data) => {
          this.routeToCopiedRecord(data.id);
          this.refreshPageRecords();
        });
    },
    refreshPageRecords() {
      this.$store.dispatch("changeHandler", {
        clearRecords: false,
        doNotSelectFirstRecord: true,
      });
    },
    routeToCopiedRecord(newId) {
      let module = this.$route.params.module;
      this.$router.push("/" + module + "/" + newId);
    },
  },
};
</script>
