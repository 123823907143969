<template>
  <container-config>
    <div slot-scope="config">
      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        :color="config.color"
        :dark="true"
      >
        <v-toolbar-title class="headline text-uppercase ml-n1">
          <v-row>
            <v-col
              class="ml-1 pb-0"
              :class="{
                'pt-1': config.app.logoPadding == 1,
                'pt-4': config.app.logoPadding == 4,
              }"
            >
              <img
                :src="config.app.logoPath"
                style="max-height: 50px; max-width: 80px"
              />
            </v-col>
            <v-col class="pl-1" style="padding-top: 14px">
              <span class="font-weight-light" v-if="!isMobile">
                {{ texts["database"][config.language] }}
                {{ config.app.appName }} – Login
              </span>
            </v-col>
          </v-row>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-alert
          type="error"
          class="mt-4 py-2 pl-3"
          v-if="appEnvironment == 'staging' || appEnvironment == 'development'"
          >{{ texts["stagingEnvironment"][config.language] }}</v-alert
        >
      </v-app-bar>
    </div>
  </container-config>
</template>

<script>
export default {
  data() {
    return {
      texts: {
        stagingEnvironment: {
          en: "Staging Environment",
          de: "Testumgebung",
          da: "Testversion",
        },
        database: {
          en: "Database",
          de: "Datenbank",
          da: "Database",
        },
      },
      appEnvironment: process.env.VUE_APP_ENV,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>
