var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-config',{scopedSlots:_vm._u([{key:"default",fn:function(config){return _c('div',{staticClass:"mb-4"},[(_vm.noPrivateRecord)?_c('v-btn',{staticClass:"mb-n4",attrs:{"small":"","color":"white"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v("Privatadresse eintragen")]):_c('v-card',{staticClass:"mt-1 elevation-1",staticStyle:{"border":"0.25px solid #aaa"}},[_c('v-card-text',{staticClass:"black--text"},[_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"10"}},[_c('div',{staticClass:"font-weight-bold",staticStyle:{"font-size":"0.9375rem"},style:({ color: config.app.colorPrimary })},[_vm._v(" "+_vm._s(_vm.record.full_name)+" ")])]),_c('v-spacer'),_c('v-col',{staticClass:"py-0 pr-4",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1)],1),_c('div',{attrs:{"id":"addressBlock"}},[(
              _vm.record.street_1 ||
              (_vm.record.addresses && _vm.record.addresses[0].street_1)
            )?_c('div',{staticClass:"body-2 mt-3"},[_vm._v(" "+_vm._s(_vm.record.street_1 || _vm.record.addresses[0].street_1)+" ")]):_vm._e(),(
              _vm.record.street_2 ||
              (_vm.record.addresses && _vm.record.addresses[0].street_2)
            )?_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.record.street_2 || _vm.record.addresses[0].street_2)+" ")]):_vm._e(),_c('div',{staticClass:"body-2"},[(
                _vm.record.post_code ||
                (_vm.record.addresses && _vm.record.addresses[0].post_code)
              )?_c('span',[_vm._v(" "+_vm._s(_vm.record.post_code || _vm.record.addresses[0].post_code)+" ")]):_vm._e(),(
                _vm.record.city || (_vm.record.addresses && _vm.record.addresses[0].city)
              )?_c('span',[_vm._v(" "+_vm._s(_vm.record.city || _vm.record.addresses[0].city)+" ")]):_vm._e()]),(
              _vm.record.country ||
              (_vm.record.addresses && _vm.record.addresses[0].country)
            )?_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.record.country ? _vm.record.country.name_de : _vm.record.addresses ? _vm.record.addresses[0].country.name_de : "")+" ")]):_vm._e()]),_c('div',{staticClass:"mt-2"},[(
              _vm.record.email ||
              _vm.record.mail ||
              (_vm.record.addresses &&
                (_vm.record.addresses[0].email || _vm.record.addresses[0].mail))
            )?_c('div',{staticClass:"body-2 ml-n1"},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":_vm.sendEmail}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email")])],1),_vm._v(" "+_vm._s(_vm.record.email || _vm.record.mail || _vm.record.addresses[0].email || _vm.record.addresses[0].mail)+" ")],1):_vm._e(),(
              _vm.record.telephone ||
              _vm.record.phone ||
              (_vm.record.addresses &&
                (_vm.record.addresses[0].telephone || _vm.record.addresses[0].phone))
            )?_c('div',{staticClass:"body-2 ml-n1"},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.showInOverlay(
                  _vm.record.telephone ||
                    _vm.record.phone ||
                    _vm.record.addresses[0].telephone ||
                    _vm.record.addresses[0].phone
                )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-phone")])],1),_vm._v(" "+_vm._s(_vm.record.telephone || _vm.record.phone || _vm.record.addresses[0].telephone || _vm.record.addresses[0].phone)+" ")],1):_vm._e(),(
              _vm.record.mobile ||
              (_vm.record.addresses && _vm.record.addresses[0].mobile)
            )?_c('div',{staticClass:"body-2 ml-n1"},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.showInOverlay(_vm.record.mobile || _vm.record.addresses[0].mobile)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cellphone")])],1),_vm._v(" "+_vm._s(_vm.record.mobile || _vm.record.addresses[0].mobile)+" ")],1):_vm._e(),(_vm.record.fax || (_vm.record.addresses && _vm.record.addresses[0].fax))?_c('div',{staticClass:"body-2 ml-n1"},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.showInOverlay(_vm.record.fax || _vm.record.addresses[0].fax)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-fax")])],1),_vm._v(" "+_vm._s(_vm.record.fax || _vm.record.addresses[0].fax)+" ")],1):_vm._e(),(
              _vm.record.website ||
              (_vm.record.addresses && _vm.record.addresses[0].website)
            )?_c('div',{staticClass:"body-2 ml-n1"},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":_vm.openWebsite}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-web")])],1),_vm._v(" "+_vm._s(_vm.record.website || _vm.record.addresses[0].website)+" ")],1):_vm._e()]),_c('v-overlay',{attrs:{"value":_vm.showOverlay,"opacity":"0.8"}},[_c('p',{staticClass:"white--text display-4"},[_vm._v(" "+_vm._s(_vm.overlayData)+" ")]),_c('v-btn',{staticClass:"white--text",attrs:{"right":"","x-large":"","outlined":""},on:{"click":function($event){_vm.showOverlay = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":_vm.maxDialogWidth,"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"hide-overlay":_vm.$vuetify.breakpoint.xsOnly},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{style:({ 'background-color': config.app.colorBackground })},[_c('v-card-title',{staticClass:"px-md-9",staticStyle:{"margin":"0px"},style:({ 'background-color': config.app.colorPrimary })},[_c('h4',{staticClass:"white--text font-weight-medium text-uppercase",staticStyle:{"letter-spacing":"1.25px","font-size":"18px"}},[_vm._v(" Privatadresse ")])]),_c('v-card-text',{staticClass:"pt-4 px-9"},[_c('app-subcomponents',_vm._b({on:{"storeRecord":function($event){return _vm.$emit('storeRecord')}}},'app-subcomponents',{
              components: _vm.components,
              recordId: _vm.recordId,
              recordEndpoint: _vm.recordEndpoint,
              storeNewRecordImmediately: _vm.storeNewRecordImmediately,
              newRecord: _vm.newRecord,
              modalColor: _vm.modalColor,
              pathArray: _vm.pathArray.concat( ['props'], ['components']),
            },false))],1),_c('v-card-actions',{staticClass:"pb-6 px-md-9 px-5"},[_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-medium",attrs:{"color":_vm.modalColor,"dark":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("OK")])],1)],1)],1)],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }