<template>
  <container-query-builder-save-buttons>
    <div
      slot-scope="{
        language,
        color,
        performSave,
        showSave,
        showSaveChanges,
        showSaveAs,
        enterButton,
        saveQuery,
        saveChanges,
        saveChangesAs,
        dialog,
        confirmationDialog,
        closeDialog,
        closeConfirmationDialog,
        queryName,
        updateQueryName
      }"
    >
      <div>
        <v-row v-if="showSave">
          <v-btn class="ml-3" :color="color" outlined @click="saveQuery">{{
            texts["saveQuery"][language]
          }}</v-btn>
        </v-row>

        <v-row v-if="showSaveChanges">
          <v-btn
            class="mt-3 ml-3"
            :color="color"
            outlined
            @click="saveChanges"
            >{{ texts["saveChanges"][language] }}</v-btn
          >
        </v-row>

        <v-row v-if="showSaveAs">
          <v-btn
            :color="color"
            outlined
            @click="saveChangesAs"
            class="mt-3 ml-3"
            >{{ texts["saveChangesAs"][language] }}</v-btn
          >
        </v-row>
      </div>

      <v-dialog :value="dialog" width="700">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            {{ texts["saveQuery"][language] }}
          </v-card-title>

          <v-card-text>
            <p class="pt-2">{{ texts["pleaseEnterName"][language] }}</p>
            <v-text-field
              :label="texts['Name'][language]"
              :color="color"
              autocomplete="disabled"
              v-on:keyup="enterButton"
              :value="queryName"
              @input="updateQueryName"
            ></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="grey lighten-1"
              class="elevation-1 mr-3"
              @click="closeDialog"
            >
              {{ texts["cancel"][language] }}
            </v-btn>
            <v-btn :color="color" @click="performSave" dark>
              {{ texts["save"][language] }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="confirmation" width="700">
        <v-card>
          <v-card-title>
            <p class="pt-2">
              {{ confirmationDialog }}
            </p>
          </v-card-title>
          <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <v-btn dark :color="color" @click="closeConfirmationDialog">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </container-query-builder-save-buttons>
</template>

<script>
export default {
  data() {
    return {
      confirmation: false,
      confirmationDialog: null,
      queryName: null,
      texts: {
        saveQuery: {
          en: "Save Query",
          da: "Gem søgning",
          de: "Suche speichern"
        },
        saveChanges: {
          en: "Save Changes",
          da: "Gem ændringer",
          de: "Änderungen speichern"
        },
        saveChangesAs: {
          en: "Save changes as...",
          da: "Gem ændringer som...",
          de: "Suche speichern als..."
        },
        pleaseEnterName: {
          en: "Please enter a name for this query.",
          da: "Gem som",
          de: "Bitte einen Namen für die Suchabfrage eingeben."
        },
        Name: {
          en: "Name",
          da: "Navn",
          de: "Namen"
        },
        cancel: {
          en: "cancel",
          da: "afbryd",
          de: "abbrechen"
        },
        save: {
          en: "save",
          da: "gem",
          de: "speichern"
        }
      }
    };
  }
};
</script>
