<template>
  <container-record
    :endpoint="recordEndpoint"
    recordId="new"
    :newRecord="true"
    :preloadedRecordData="{ id: 'new' }"
    :originEndpoint="originEndpoint"
    :originRecordId="originRecordId"
    @handleNewRecordResponse="handleNewRecordResponse"
  >
    <div slot-scope="record">
      <v-btn
        small
        icon
        @click="record.saveNewRecord()"
        @keyup.enter="record.saveNewRecord()"
      >
        <v-icon small>mdi-content-save-outline</v-icon>
      </v-btn>
    </div>
  </container-record>
</template>
<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    originEndpoint: {
      type: String
    },
    originRecordId: {
      type: [String, Number]
    },
    validationRules: {
      type: Array,
      default: () => []
    },
    accessorRegEx: {
      type: String
    }
  },
  methods: {
    handleNewRecordResponse(data) {
      this.$emit("handleNewRecordResponse", data);
    }
  }
};
</script>
