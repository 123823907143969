var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-input-field',{attrs:{"recordId":_vm.recordId,"recordEndpoint":_vm.recordEndpoint,"field":_vm.field,"newRecord":_vm.newRecord,"modalColor":_vm.modalColor,"reloadRecordOnChange":_vm.reloadRecordOnChange,"storeNewRecordImmediately":_vm.storeNewRecordImmediately},on:{"storeNewRecordImmediately":function($event){return _vm.$emit('storeRecord')}},scopedSlots:_vm._u([{key:"default",fn:function(field){return (!_vm.isHidden)?_c('div',{},[(field.alternativeDesign)?_c('v-row',{staticClass:"mb-n1"},[_c('v-col',{staticClass:"ml-3 px-0 py-0"},[_c('base-label',{attrs:{"label":_vm.label}})],1)],1):_vm._e(),_c('v-row',{staticClass:"px-0 py-0"},[_c('v-col',{staticClass:"d-flex pt-1 pb-2",attrs:{"cols":_vm.columns}},[_c('v-text-field',{class:{
            alternativeTextFieldDesign: field.alternativeDesign,
            'body-2': field.alternativeDesign,
            p_TextInputReadOnly: _vm.isReadonly,
          },attrs:{"elevation":"0","label":_vm.label,"placeholder":field.alternativeDesign ? _vm.placeholder || ' ' : _vm.label,"value":field.value,"rules":_vm.rules,"hide-details":"auto","autocomplete":"disabled","readonly":_vm.isReadonly,"solo":field.alternativeDesign,"dense":field.alternativeDesign,"flat":field.alternativeDesign,"append-icon":(field.value && !_vm.buttonType == 'copy') || _vm.showCopyButton
              ? _vm.buttonIcon
              : ''},on:{"change":function($event){_vm.setValue($event);
            !_vm.isReadonly ? field.updateValue(_vm.value) : null;},"click:append":function($event){_vm.click(field.value);
            _vm.buttonType == 'copy' ? field.updateValue(_vm.copiedValue) : '';}}})],1)],1),(_vm.buttonType == 'enlarge')?_c('v-overlay',{attrs:{"value":_vm.overlay,"opacity":"0.8"}},[_c('p',{staticClass:"white--text",staticStyle:{"font-size":"128px"}},[_vm._v(" "+_vm._s(field.value)+" ")]),_c('v-btn',{staticClass:"white--text mt-12",attrs:{"absolute":"","right":"","x-large":"","outlined":""},on:{"click":function($event){_vm.overlay = false}}},[_vm._v(" OK ")])],1):_vm._e()],1):_vm._e()}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }