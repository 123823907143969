<template>
  <div>
    <slot :alerts="alerts" :saveAlert="saveAlert"></slot>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      alerts: null
    };
  },
  computed: {
    recordPath() {
      return this.$route.params.module;
    },
    recordEndpoint() {
      return this.$store.getters.selectedModuleConfigData.apiEndpoint;
    },
    recordId() {
      return this.$route.params.record_id;
    }
  },
  methods: {
    loadSettings() {
      let endpoint = "/alerts/" + this.recordEndpoint + "/" + this.recordId;
      axios
        .get(endpoint)
        .then(r => r.data)
        .then(data => {
          this.alerts = data;
        })
        .catch(error => this.$store.dispatch("handleError", error));
    },
    saveAlert(inputFields) {
      inputFields["record_id"] = this.recordId;
      inputFields["endpoint"] = this.recordEndpoint;
      inputFields["path"] = this.recordPath;
      if (inputFields.id === "new") {
        delete inputFields["id"];
        axios
          .post("/alerts/", inputFields)
          .then(r => r.data)
          .then(data => {
            this.alerts = data;
            this.$emit("closeDialog");
          })
          .catch(error => this.$store.dispatch("handleError", error));
      } else {
        axios
          .patch("/alerts/" + inputFields.id, inputFields)
          .then(r => r.data)
          .then(data => {
            this.alerts = data;
            this.$emit("closeDialog");
          })
          .catch(error => this.$store.dispatch("handleError", error));
      }
    }
  },
  mounted() {
    this.loadSettings();
  }
};
</script>

<style lang="scss" scoped></style>
