import { render, staticRenderFns } from "./BaseInputWysiwygTrix.vue?vue&type=template&id=2ae9c10d&scoped=true&"
import script from "./BaseInputWysiwygTrix.vue?vue&type=script&lang=js&"
export * from "./BaseInputWysiwygTrix.vue?vue&type=script&lang=js&"
import style0 from "./BaseInputWysiwygTrix.vue?vue&type=style&index=0&id=2ae9c10d&scoped=true&lang=scss&"
import style1 from "./BaseInputWysiwygTrix.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules.nosync/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ae9c10d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules.nosync/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VCard,VCardText,VDialog,VProgressCircular})
