<template>
  <container-config>
    <div slot-scope="{ alternativeDesign, language }">
      <slot
        :loading="loading"
        :items="filteredItems"
        :extendedHeaders="extendedHeaders"
        :alternativeDesign="alternativeDesign"
        :pushToItems="pushToItems"
        :record="record"
        :language="language"
      ></slot>
    </div>
  </container-config>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  props: {
    recordId: {
      type: [String, Number],
    },
    recordEndpoint: {
      type: String,
    },
    tableEndpoint: {
      type: String,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    },
    hasActionButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    record() {
      return this.$store.state[this.recordEndpoint].selectedRecords[
        this.recordId
      ];
    },
    items() {
      if (this.record) {
        return this.record[this.tableEndpoint] || [];
      } else {
        return [];
      }
    },
    filteredItems() {
      let self = this;
      return this.items.filter(function (item) {
        let filters = self.filters;
        let checkArray = [];
        for (let i = 0; i < filters.length; i++) {
          let filter = filters[i];
          if (
            (item[filter.field] != undefined &&
              //match for "==" operator or no defined operator (equivalent to "==")
              (((filter.operator == "==" || filter.operator == undefined) &&
                item[filter.field] == filter.value) ||
                //match for ">=" operator
                (filter.operator == ">=" &&
                  item[filter.field] >= filter.value) ||
                //match for ">" operator
                (filter.operator == ">" && item[filter.field] > filter.value) ||
                //match for "<=" operator
                (filter.operator == "<=" &&
                  item[filter.field] <= filter.value) ||
                //match for "<" operator
                (filter.operator == "<" && item[filter.field] < filter.value) ||
                //match for "contains" operator
                (filter.operator == "contains" &&
                  item[filter.field].includes(filter.value) > 0) ||
                //match for "!=" operator
                (filter.operator == "!=" &&
                  item[filter.field] != filter.value))) ||
            //match for unequal on undefined field
            (item[filter.field] == undefined && filter.operator == "!=")
          ) {
            checkArray.push(true);
          } else {
            checkArray.push(false);
          }
        }
        return checkArray.every((v) => v === true);
      });
    },
    extendedHeaders() {
      let actionItem = {
        value: "action",
        sortable: false,
        width: 20,
        align: "right",
      };
      let extendedHeaders = [...this.headers, actionItem];
      let actionButton = {
        value: "actionButton",
        sortable: false,
        width: 20,
      };
      if (this.hasActionButton) {
        extendedHeaders = [...this.headers, actionItem, actionButton];
      }
      return extendedHeaders;
    },
  },
  methods: {
    pushToItems(data) {
      this.$store.commit("ADD_OBJECT_TO_SUBARRAY", {
        recordEndpoint: this.recordEndpoint,
        recordId: this.recordId,
        tableEndpoint: this.tableEndpoint,
        data,
      });
    },
  },
};
</script>
