import Vue from "vue";
import VueRouter from "vue-router";
import AppLogin from "../components/AppLogin.vue";
import AppLogin2fa from "../components/AppLogin2fa.vue";
import AppLogin2faSetup from "../components/AppLogin2faSetup.vue";
import AppLoginChangePassword from "../components/AppLoginChangePassword.vue";
import AppBase from "../components/AppBase.vue";
import AppRecordDetail from "../components/AppRecordDetail.vue";
import AppHomeRedirect from "../components/AppHomeRedirect.vue";
// import AppConfig from "../config/appConfig";
import AppSettings from "../components/AppSettings";
import AppUsers from "../components/AppUsers";
import AppListPrint from "../components/AppListPrint";
import AppSettingsDetails from "../components/AppSettingsDetails";
import AppUsersDetails from "../components/AppUsersDetails";
// import store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: AppLogin
  },
  {
    path: "/2fa",
    component: AppLogin2fa
  },
  {
    path: "/2fa-setup",
    component: AppLogin2faSetup
  },
  {
    path: "/change-password",
    component: AppLoginChangePassword
  },
  {
    path: "/settings",
    component: AppSettings,
    children: [
      {
        path: ":setting",
        component: AppSettingsDetails,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: "/users",
    component: AppUsers,
    children: [
      {
        path: ":user",
        component: AppUsersDetails,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: "/print/list/:module",
    component: AppListPrint
  },
  {
    path: "/",
    component: AppHomeRedirect
    // redirect: store["state"]["config"]["app"]["modules"][0]["basicConfig"]["path"]
  },
  {
    path: "/:module",
    component: AppBase,
    props: route => ({ module: route.params.module }),
    meta: { requiresAuth: true },
    children: [
      {
        path: ":record_id",
        component: AppRecordDetail,
        meta: { requiresAuth: true }
      },
      {
        path: "",
        component: AppRecordDetail,
        meta: { requiresAuth: true }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next("/login");
  }
  next();
});

export default router;
