var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-query-builder-save-buttons',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var language = ref.language;
    var color = ref.color;
    var performSave = ref.performSave;
    var showSave = ref.showSave;
    var showSaveChanges = ref.showSaveChanges;
    var showSaveAs = ref.showSaveAs;
    var enterButton = ref.enterButton;
    var saveQuery = ref.saveQuery;
    var saveChanges = ref.saveChanges;
    var saveChangesAs = ref.saveChangesAs;
    var dialog = ref.dialog;
    var confirmationDialog = ref.confirmationDialog;
    var closeDialog = ref.closeDialog;
    var closeConfirmationDialog = ref.closeConfirmationDialog;
    var queryName = ref.queryName;
    var updateQueryName = ref.updateQueryName;
return _c('div',{},[_c('div',[(showSave)?_c('v-row',[_c('v-btn',{staticClass:"ml-3",attrs:{"color":color,"outlined":""},on:{"click":saveQuery}},[_vm._v(_vm._s(_vm.texts["saveQuery"][language]))])],1):_vm._e(),(showSaveChanges)?_c('v-row',[_c('v-btn',{staticClass:"mt-3 ml-3",attrs:{"color":color,"outlined":""},on:{"click":saveChanges}},[_vm._v(_vm._s(_vm.texts["saveChanges"][language]))])],1):_vm._e(),(showSaveAs)?_c('v-row',[_c('v-btn',{staticClass:"mt-3 ml-3",attrs:{"color":color,"outlined":""},on:{"click":saveChangesAs}},[_vm._v(_vm._s(_vm.texts["saveChangesAs"][language]))])],1):_vm._e()],1),_c('v-dialog',{attrs:{"value":dialog,"width":"700"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.texts["saveQuery"][language])+" ")]),_c('v-card-text',[_c('p',{staticClass:"pt-2"},[_vm._v(_vm._s(_vm.texts["pleaseEnterName"][language]))]),_c('v-text-field',{attrs:{"label":_vm.texts['Name'][language],"color":color,"autocomplete":"disabled","value":queryName},on:{"keyup":enterButton,"input":updateQueryName}})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{staticClass:"elevation-1 mr-3",attrs:{"outlined":"","color":"grey lighten-1"},on:{"click":closeDialog}},[_vm._v(" "+_vm._s(_vm.texts["cancel"][language])+" ")]),_c('v-btn',{attrs:{"color":color,"dark":""},on:{"click":performSave}},[_vm._v(" "+_vm._s(_vm.texts["save"][language])+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}},[_c('v-card',[_c('v-card-title',[_c('p',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(confirmationDialog)+" ")])]),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":color},on:{"click":closeConfirmationDialog}},[_vm._v(" OK ")])],1)],1)],1)],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }