<template>
  <div v-if="record && !isHidden">
    <div
      class="body-2"
      style="
        color: rgba(0, 0, 0, 0.6);
        border-radius: 4px;
        white-space: pre-wrap;
      "
      :style="{ 'font-size': fontSize + ' !important' }"
      :class="customClass"
      v-html="text.replace('{field}', value)"
    ></div>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { get } from "lodash";

export default {
  mixins: [baseComponentMixin],
  props: {
    text: {
      type: String,
      default: "Title",
    },
    fontSize: {
      type: String,
      default: "0.875rem",
    },
    field: {
      type: String,
    },
    customClass: {
      type: String,
    },
  },
  computed: {
    value() {
      return get(this.record, this.field);
    },
  },
};
</script>
