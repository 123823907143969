<template>
  <container-config>
    <div slot-scope="{ color, language }">
      <container-list>
        <div slot-scope="{ recordCount }">
          <v-btn icon>
            <v-icon @click="showPrintDialog(recordCount)">mdi-printer</v-icon>
          </v-btn>
          <v-dialog max-width="600px" v-model="dialog">
            <v-card>
              <v-card-title
                class=" pl-6 white--text"
                :style="{ 'background-color': color }"
              >
                <span class="headline">Liste drucken</span>
              </v-card-title>

              <v-card-text>
                <p class="pt-4 pb-2">
                  {{ texts["pleaseEnterTitle"][language] }}
                </p>
                <v-text-field
                  :label="texts['title'][language]"
                  v-model="printTitle"
                  autocomplete="disabled"
                  :color="color"
                  class="py-1"
                  @focus="$event.target.select()"
                ></v-text-field>
              </v-card-text>

              <v-card-actions class="pb-5 px-6">
                <v-spacer></v-spacer>
                <v-btn
                  tile
                  class="font-weight-medium mr-4"
                  @click="dialog = false"
                  >abbrechen</v-btn
                >
                <v-btn
                  tile
                  class="font-weight-medium"
                  :color="color"
                  @click="print()"
                  dark
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </container-list>
    </div>
  </container-config>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      printTitle: null,
      texts: {
        pleaseEnterTitle: {
          en: "Please enter a title for the printout",
          da: "Tilføj en titel til udskriften",
          de: "Bitte einen Titel für den Ausdruck eingeben:"
        },
        title: {
          en: "Title",
          da: "Titel",
          de: "Titel"
        }
      }
    };
  },
  props: {
    headers: {
      type: Array
    },
    moduleName: {
      type: String,
      default: "Export"
    },
    list: {
      type: Number
    }
  },
  methods: {
    showPrintDialog(recordCount) {
      if (recordCount > 2000) {
        alert(
          "Es können nicht mehr als 2000 Datensätze gedruckt werden. Bitte die Suche einengen."
        );
        return;
      }
      this.printTitle = this.$store.state.queryBuilder.name;
      this.dialog = true;
    },
    print() {
      let params = this.$route.query;
      let list = "list=" + this.list;
      let f = params.filter ? "&filter=" + params.filter : "";
      let s = this.$store.getters.sort
        ? "&sort=" + this.$store.getters.sort
        : "";
      let q = params.q ? "&q=" + params.q : "";
      let title = this.printTitle ? "&title=" + this.printTitle : "";
      let query_params = list + f + s + q + title;
      window.open(
        "/print/list/" + this.moduleName + "?" + query_params,
        "_blank"
      );
      this.dialog = false;
    }
  }
};
</script>
