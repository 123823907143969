<template>
  <div v-if="!isHidden">
    <container-portal
      :endpoint="endpoint"
      :filters="filters"
      :originRecordId="recordId"
      :originRecordEndpoint="recordEndpoint"
    >
      <div slot-scope="portalRecord">
        <container-record
          :key="portalRecord.recordId"
          :endpoint="portalRecord.recordEndpoint"
          :recordId="portalRecord.recordId"
          :preloadedRecordData="portalRecord.record"
          :originEndpoint="recordEndpoint"
          :originRecordId="recordId"
          :newRecord="portalRecord.newRecord"
          :foreignKeyField="foreignKeyFieldOverride"
          :load="!suppressRecordLoad"
          @handleNewRecordResponse="
            handleNewRecordResponse($event, portalRecord.recordId)
          "
        >
          <div slot-scope="record">
            <component
              v-for="(component, key) in components"
              :key="key"
              v-bind:is="component.componentType"
              v-bind="{
                ...component.props,
                recordId: portalRecord.recordId,
                recordEndpoint: portalRecord.recordEndpoint,
                newRecord: portalRecord.newRecord,
                storeNewRecordImmediately: true,
                modalColor: modalColor,
              }"
              @storeRecord="record.saveNewRecord"
            ></component>
          </div>
        </container-record>
      </div>
    </container-portal>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";

export default {
  mixins: [baseComponentMixin],
  props: {
    endpoint: {
      type: String,
    },
    filters: {
      type: Array,
    },
    suppressRecordLoad: {
      type: Boolean,
      default: false,
    },
    foreignKeyFieldOverride: {
      type: String,
    },
  },
  methods: {
    handleNewRecordResponse(event) {
      let originalData =
        this.$store.state[this.recordEndpoint]["selectedRecords"][
          this.recordId
        ];
      originalData[this.endpoint] = event;
    },
  },
};
</script>
