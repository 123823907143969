<template>
  <div>
    <h1
      class="font-weight-medium text-uppercase mt-6 mb-4"
      style="letter-spacing: 1.25px; font-size: 14px"
    >
      {{ title }}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped></style>
