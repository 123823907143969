<template>
  <container-input-field
    :recordId="recordId"
    :recordEndpoint="recordEndpoint"
    :field="field"
    :newRecord="newRecord"
    :modalColor="modalColor"
    :reloadRecordOnChange="reloadRecordOnChange"
    :storeNewRecordImmediately="storeNewRecordImmediately"
    @storeNewRecordImmediately="$emit('storeRecord')"
  >
    <div
      class="pb-3"
      :class="{ 'mt-n2': !includeTopSpacer, 'pt-3': includeTopSpacer }"
      slot-scope="field"
      v-if="!isHidden"
    >
      <v-switch
        :input-value="field.value"
        @change="field.updateValue"
        hide-details
        dense
        :color="field.color"
      >
        <template v-slot:label>
          <span class="body-2 mt-n1 ml-n1">{{ label }}</span>
        </template>
      </v-switch>
    </div>
  </container-input-field>
</template>
<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    includeTopSpacer: {
      type: Boolean,
    },
    reloadRecordOnChange: {
      type: Boolean,
    },
  },
};
</script>
