import Vue from "vue";

export default {
  state: {},
  mutations: {
    SET_DROPDOWN_VALUES(state, payload) {
      Vue.set(state, payload.endpoint, payload.data);
    }
  }
};
