<template>
  <div>
    <!-- <div class="pt-8 pb-7"></div> -->
    <div style="height: 34px;"></div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 2
    }
  }
};
</script>
