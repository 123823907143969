<template>
  <container-query-builder-query>
    <div
      slot-scope="{
        language,
        query,
        moduleName,
        deleteQuery,
        addNewTableGrouping,
        color
      }"
    >
      <v-card class="mb-3" color="#eee">
        <v-card-title
          class="py-2"
          :color="color"
          :style="{ 'background-color': color }"
        >
          <span
            class="subtitle-1 font-weight-medium pl-1"
            style="color: #fff !important;"
            >{{ texts["query"][language] }} {{ orQueryKey + 1 }}:</span
          >
          <v-spacer></v-spacer>
          <v-btn
            icon
            class="mr-n2 mt-n1"
            @click="deleteQuery(orQueryKey)"
            v-if="query.length > 1"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <div
          class="subtitle-1 font-weight-medium pl-5 pt-3"
          style="text-color: black !important; "
        >
          {{ texts["theQueried"][language] }}
          {{ titleCase(moduleName) }}...
        </div>
        <v-card-text>
          <div
            v-for="(tableGrouping, key) in query.orQueries[orQueryKey]
              .tableGroupings"
            :key="key"
          >
            <app-query-builder-table-grouping
              :key="key"
              :orQueryKey="orQueryKey"
            />
            <p
              class="my-3 ml-3 subtitle-1 font-weight-medium"
              style="color:black !important;"
              v-if="
                key != query.orQueries[orQueryKey].tableGroupings.length - 1
              "
            >
              {{ texts["AND"][language] }}
            </p>
          </div>
          <v-btn
            text
            small
            class="px-1 my-1"
            @click="addNewTableGrouping(orQueryKey)"
          >
            <v-icon>mdi-plus</v-icon>
            <span>{{ texts["newConstraint"][language] }}</span>
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </container-query-builder-query>
</template>

<script>
export default {
  data() {
    return {
      orQueryKey: this.$vnode.key,
      operators: ["=", "==", "≠", ">", "≥", "<", "≤", "*", "∅"],
      texts: {
        query: {
          en: "Query",
          da: "Søgning",
          de: "Suchabfrage"
        },
        theQueried: {
          en: "The queried",
          da: "De søgte",
          de: "Die gesuchten"
        },
        newConstraint: {
          en: "new query constraint",
          da: "tilføj søgekriterie",
          de: "neue Suchbedingung"
        },
        AND: {
          en: "AND",
          da: "OG",
          de: "UND"
        }
      }
    };
  },
  methods: {
    //TO DO: mixin?
    titleCase(str) {
      str = str.toLowerCase().split(" ");
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(" ");
    }
  }
};
</script>
