import axios from "axios";
import router from "../../router/index.js";

export default {
  state: {
    user: null
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
      localStorage.setItem("user", JSON.stringify(userData));
    },
    LOGOUT(state) {
      localStorage.removeItem("user");
      state.user = null;
      router.push("/login");
    }
  },
  actions: {
    login({ commit, dispatch }, credentials) {
      return new Promise((resolve, reject) => {
        axios.get("../sanctum/csrf-cookie").then(() => {
          axios
            .post("../login", credentials)
            .then(r => {
              if (r.data.two_factor) {
                router.push({ path: "/2fa" });
              } else {
                dispatch("loadConfigFromServer").then(() =>
                  axios.get("/user").then(response => {
                    resolve(commit("SET_USER_DATA", response.data));
                  })
                );
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      });
    },
    twoFactorChallenge({ commit, dispatch }, code) {
      return new Promise((resolve, reject) => {
        axios
          .post("../two-factor-challenge", code)
          .then(() => {
            dispatch("loadConfigFromServer").then(() =>
              axios.get("/user").then(response => {
                resolve(commit("SET_USER_DATA", response.data));
              })
            );
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    enable2fa({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        // /user/two-factor-authentication
        axios
          .post("../user/two-factor-authentication")
          .then(() => {
            dispatch("loadConfigFromServer").then(() =>
              axios.get("/user").then(response => {
                resolve(commit("SET_USER_DATA", response.data));
              })
            );
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    logout({ commit }) {
      axios
        .post("../logout")
        .then(() => {
          commit("LOGOUT");
        })
        .catch(() => {
          commit("LOGOUT");
        });
    }
  }
};
