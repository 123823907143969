<template>
  <div class="mb-6">
    <config-section-title :title="label" />
    <v-data-table
      class="ml-n4 mt-n2"
      :headers="extendedHeaders"
      :items="tableArray"
      disable-pagination
      hide-default-footer
      style="
        border-bottom: 0.25px solid #bbb;
        border-radius: 0px;
        max-width: 1200px;
        background-color: transparent;
      "
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
    </v-data-table>
    <v-btn x-small text left class="mt-3 ml-n2" @click="newItem"
      >+ new row</v-btn
    >
    <v-divider class="ml-n4 my-3" style="max-width: 1200px" />
    <v-dialog v-model="dialog" max-width="450px">
      <v-card class="px-3 py-6">
        <v-card-text>
          <div style="max-width: 450px">
            <div v-for="(header, key) in headers" :key="key">
              <config-input-text
                v-if="header.type == 'String'"
                :getArray="itemGetArray"
                :field="header.value"
                :label="header.text"
              ></config-input-text>
              <config-input-array
                v-if="header.type == 'Array'"
                :getArray="itemGetArray"
                :field="header.value"
                :label="header.text"
              ></config-input-array>
              <config-input-switch
                v-if="header.type == 'Boolean'"
                :getArray="itemGetArray"
                :field="header.value"
                :label="header.text"
              ></config-input-switch>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="px-6">
          <div></div>
          <v-btn color="red darken-4" dark @click="deleteItem">delete</v-btn>
          <v-spacer></v-spacer
          ><v-btn color="primary" @click="dialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
      <GlobalEvents v-if="dialog" @keydown.enter.prevent="dialog = false" />
    </v-dialog>
  </div>
</template>

<script>
"lodash";
import { get } from "lodash";

export default {
  data() {
    return {
      dialog: false,
      itemGetArray: null,
      itemIndex: null,
    };
  },
  props: {
    label: {
      type: String,
    },
    headers: {
      type: Array,
    },
    itemsName: {
      type: String,
      default: "headers",
    },
    getArray: {
      type: Array,
    },
  },
  methods: {
    editItem(item) {
      let items = this.tableArray;
      let editedIndex = items.indexOf(item);
      this.itemIndex = editedIndex;
      this.itemGetArray = [...this.getArray, this.itemsName, editedIndex];
      this.dialog = true;
    },
    newItem() {
      let items = this.tableArray;
      let editedIndex = items.length;
      this.itemGetArray = [...this.getArray, this.itemsName, editedIndex];
      this.dialog = true;
    },
    deleteItem() {
      let getArray = [...this.getArray, this.itemsName];
      // let items = get(this.$store.state, getArray);
      // let index = items.indexOf(item);
      let index = this.itemIndex;
      let payload = { getArray, index };
      this.$store.dispatch("deleteConfigObject", payload);
      this.dialog = false;
    },
  },
  computed: {
    tableArray() {
      return get(this.$store.state, [...this.getArray, this.itemsName]) || [];
    },
    appendedIcon() {
      return this.selfIcon ? this.fieldValue : "";
    },
    extendedHeaders() {
      let headers = this.headers.filter((header) => {
        return header.showInTable !== false;
      });
      let actionItem = { value: "actions", sortable: false, width: 40 };
      return [...headers, actionItem];
    },
  },
};
</script>
