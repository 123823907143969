<template>
  <div
    :class="{ 'mt-2': !hasExtraTopPadding, 'mt-6': hasExtraTopPadding }"
    v-if="!isHidden"
  >
    <v-btn
      small
      @click="executeExcelButtonRequest"
      :disabled="isLoading"
      style="height: 32px; margin-top: 2px"
    >
      <div v-if="isLoading" style="margin-left: -1px; margin-right: 7px">
        <span class="custom-loader">
          <v-icon small>mdi-cached</v-icon>
        </span>
      </div>
      <v-icon v-else-if="icon" small left>{{ icon }}</v-icon>
      <svg-excel v-else :width="25" class="mr-2" style="margin-bottom: -3px" />
      {{ computedLabel }}
    </v-btn>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import axios from "axios";
import { get } from "lodash";
import SvgExcel from "./SvgExcel.vue";
import zipcelx from "zipcelx";
export default {
  components: { SvgExcel },
  data() {
    return {
      isLoading: false,
      records: [],
      fileName: "text.xlsx",
      loadedChunks: 0,
      chunkSize: 100,
    };
  },
  mixins: [baseComponentMixin],
  props: {
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    path: {
      type: String,
    },
    field: {
      type: String,
    },
    labelField: {
      type: String,
    },
    hasExtraTopPadding: {
      type: Boolean,
    },
    confirmationDialog: {
      type: String,
    },
    recordCountField: {
      type: String,
    },
  },
  computed: {
    computedLabel() {
      let field = get(this.record, this.labelField);
      return this.label.replace("{field}", field);
    },
    chunks() {
      let field = this.recordCountField;
      return Math.ceil(this.record[field] / this.chunkSize);
    },
  },
  methods: {
    executeExcelButtonRequest() {
      let chunks = this.chunks;
      for (let chunk = 1; chunk <= chunks; chunk++) {
        this.axiosCall(chunk);
      }
    },
    axiosCall(chunk) {
      this.isLoading = true;
      let path =
        this.path.replace("{recordId}", this.recordId) + ("&chunk=" + chunk);
      let self = this;
      axios
        .get(path)
        .then((r) => r.data)
        .then(function (data) {
          self.records[chunk - 1] = data;
          self.loadedChunks++;
        })
        .then(() => {
          if (self.loadedChunks == self.chunks) {
            self.isLoading = false;
            self.download();
          }
        });
    },
    download() {
      let recordsFlattened = this.records.flat();
      let records = recordsFlattened.map((record) => {
        let values = Object.values(record);
        let object = values.map((column) => {
          let value = column;
          let type = "string";
          return { value, type };
        });
        return object;
      });
      let headers = Object.keys(recordsFlattened[0]);
      let column_headers = headers.map((header) => {
        let value = header;
        let type = "string";
        return { value, type };
      });
      records.unshift(column_headers);
      let config = {
        filename: this.fileName.replace(".xlsx", ""),
        sheet: {
          data: records,
        },
      };
      zipcelx(config);
      this.records = [];
      this.loadedChunks = 0;
    },
  },
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
