<template>
  <container-config>
    <div class="pt-4 pl-3 " slot-scope="{ language }">
      <v-btn small @click="addNewRecord">
        <v-icon small>mdi-plus</v-icon>
        {{ texts["newEntry"][language] }}
      </v-btn>
    </div>
  </container-config>
</template>

<script>
export default {
  data() {
    return {
      texts: {
        newEntry: {
          de: "neuer Eintrag",
          en: "new entry",
          da: "tilføj"
        }
      }
    };
  },
  methods: {
    addNewRecord() {
      this.$emit("addNewRecord");
    }
  }
};
</script>
