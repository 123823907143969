<template>
  <div>
    <container-tagger
      :recordId="recordId"
      :recordEndpoint="recordEndpoint"
      :endpointValue="endpointValue"
      :endpointJoin="endpointJoin"
      :endpointSort="endpointSort"
      :displayFieldValue="displayFieldValue"
    >
      <div slot-scope="scope" :class="customClass">
        <div
          class="body-1 black--text pb-0"
          style="margin-top: 2pt; margin-bottom: 2pt"
        >
          <div>
            <v-row class="pt-0">
              <v-col cols="auto" class="pt-0 pb-0">
                <h4
                  v-if="!label"
                  class="font-weight-medium text-uppercase mt-3 mb-1"
                  style="letter-spacing: 1.25px"
                >
                  {{ label }}
                </h4>
                <div v-else class="caption mb-0" style="color: #666">
                  {{ label }}
                </div>
                <v-row style="padding-top: 2px">
                  <v-col
                    class="py-0 pr-0 mr-n3"
                    cols="auto"
                    style="overflow: scroll"
                  >
                    <v-chip
                      v-for="(item, key) in scope.values"
                      :key="key"
                      class="mr-1 pianolaChip mt-1"
                      outlined
                      close
                      close-icon="mdi-close"
                      small
                      @click:close="scope.deleteEntry(item.id)"
                    >
                      {{
                        endpointJoin != endpointValue
                          ? item[scope.endpointValueSingular][displayFieldName]
                          : item[displayFieldName]
                      }}
                    </v-chip>
                  </v-col>
                  <v-col class="py-0" cols="auto" v-if="!scope.loading">
                    <v-combobox
                      id="pianolaChipAutocomplete"
                      :key="scope.values ? scope.values.length : 0"
                      :items="scope.selectValues"
                      :item-value="displayFieldValue"
                      :item-text="displayFieldName"
                      class="pianolaChipAutocomplete mt-1"
                      dense
                      hide-details="auto"
                      placeholder="+ Tag"
                      @change="scope.enterNewValue"
                      :return-object="false"
                    ></v-combobox>
                  </v-col>
                  <v-col class="py-0" v-else>
                    <div id="pianolaChipAutocompleteSpinner" class="px-8 mt-1">
                      <v-progress-circular
                        indeterminate
                        size="14"
                        width="2"
                        color="#aaa"
                      ></v-progress-circular>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </container-tagger>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
export default {
  mixins: [baseComponentMixin],
  data() {
    return {
      newSelector: null,
    };
  },
  props: {
    endpointJoin: {
      type: String,
    },
    endpointValue: {
      type: String,
    },
    displayFieldName: {
      type: String,
      default: "name",
    },
    displayFieldValue: {
      type: String,
      default: "id",
    },
    endpointSort: {
      type: String,
    },
    label: {
      type: String,
    },
    customClass: {
      type: String,
    },
  },
  methods: {
    emptyField() {
      this.newSelector = null;
    },
  },
};
</script>

<style>
.pianolaChip.v-chip.v-chip--outlined.v-chip.v-chip {
  background-color: white !important;
  border: 0.25px solid #aaa;
}
.pianolaChip .v-chip__close {
  font-size: 12px !important;
}
.pianolaChipAutocomplete .v-input__slot::before {
  border-style: none !important;
}

.pianolaChipAutocomplete .v-icon {
  display: none;
}

#pianolaChipAutocomplete,
#pianolaChipAutocompleteSpinner {
  font-size: 12px !important;
  border: #ddd solid 1pt !important;
  border-radius: 30px;
  height: 24px;
  min-height: 24px;
  padding-left: 10px;
  padding-top: 0px;
  width: 80px;
  min-width: 80px;
}
</style>
