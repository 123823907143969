<template>
  <div>
    <container-input-field
      :recordId="recordId"
      :recordEndpoint="recordEndpoint"
      :field="field"
      :newRecord="newRecord"
      :modalColor="modalColor"
      :storeNewRecordImmediately="storeNewRecordImmediately"
      @storeNewRecordImmediately="$emit('storeRecord')"
    >
      <div slot-scope="field">
        <base-input-timestamp-fields
          v-bind="$props"
          @change="field.updateValue"
          :value="field.value"
        />
      </div>
    </container-input-field>
  </div>
</template>

<script>
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
// eslint-disable-next-line
import { da } from "date-fns/locale";
// eslint-disable-next-line
import { de } from "date-fns/locale";
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      menuDatepicker: false,
    };
  },
  props: {
    dateFormattingString: {
      type: String,
      default: "eee, d MMMM y",
    },
  },
  computed: {
    language() {
      return this.$store.state.config.app.basicConfig.appLanguage;
    },
  },
  methods: {
    formatDate(input) {
      if (input) {
        let parsedDate = parseISO(input);
        let languageObject =
          this.language == "de" ? de : this.language == "da" ? da : null;
        return format(parsedDate, this.dateFormattingString, {
          locale: languageObject,
        });
      } else {
        return null;
      }
    },
  },
};
</script>
<style>
.alternativeDatepickerFieldDesign .v-icon {
  margin-top: 0px !important;
  font-size: 18px !important;
  color: #666 !important;
}
</style>
