<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  computed: {
    appConfig() {
      return this.$store.state.config.app.basicConfig;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  },
  methods: {
    changeFavicon() {
      var link = document.createElement("link"),
        oldLink = document.getElementById("dynamic-favicon");
      link.id = "dynamic-favicon";
      link.rel = "shortcut icon";
      link.href = this.appConfig.faviconPath;
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
    },
    changeTitle() {
      document.title = this.appConfig.appName;
    }
  },
  mounted() {
    this.changeFavicon();
    this.changeTitle();
  }
};
</script>
