<template>
  <div>
    <container-tagger
      :recordId="recordId"
      :recordEndpoint="recordEndpoint"
      :endpointValue="endpointValue"
      :endpointJoin="endpointJoin"
      :endpointSort="endpointSort"
    >
      <div slot-scope="scope" :class="customClass">
        <base-label
          class="ml-0"
          :label="label"
          v-if="scope.alternativeDesign"
        />
        <base-title v-else :label="label" />
        <v-simple-table
          :class="{ taggerPortalAlternativeDesign: scope.alternativeDesign }"
          :style="{
            'background-color': scope.alternativeDesign
              ? 'white'
              : 'transparent'
          }"
        >
          <template v-slot:default>
            <tbody>
              <tr v-for="(item, key) in scope.values" :key="key">
                <td style="padding-left: 10px;" class="body-2 my-0">
                  {{ item[scope.endpointValueSingular][displayFieldName] }}
                </td>
                <td width="30">
                  <v-btn
                    icon
                    x-small
                    class="ml-3"
                    @click="scope.deleteEntry(item.id)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td style="padding-left: 0px;">
                  <v-autocomplete
                    :key="scope.values ? scope.values.length : 0"
                    :items="scope.selectValues"
                    item-value="id"
                    :item-text="displayFieldName"
                    :class="{
                      taggerPortalInputAlternativeDesign:
                        scope.alternativeDesign,
                      'body-2': scope.alternativeDesign
                    }"
                    class="mr-1"
                    dense
                    solo
                    flat
                    hide-details="auto"
                    :placeholder="newTagText"
                    @change="scope.enterNewValue"
                  ></v-autocomplete>
                </td>
                <td></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </container-tagger>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
export default {
  mixins: [baseComponentMixin],
  data() {
    return {
      newSelector: null
    };
  },
  props: {
    endpointJoin: {
      type: String
    },
    endpointValue: {
      type: String
    },
    displayFieldName: {
      type: String,
      default: "name"
    },
    endpointSort: {
      type: String
    },
    label: {
      type: String
    },
    customClass: {
      type: String
    },
    newTagText: {
      type: String,
      default: "+ new category"
    }
  },
  methods: {
    emptyField() {
      this.newSelector = null;
    }
  }
};
</script>
<style>
.taggerPortalAlternativeDesign {
  border: 0.25px solid #aaa;
  margin-bottom: 7px;
}
.taggerPortalAlternativeDesign td,
.taggerPortalInputAlternativeDesign td {
  height: 34px !important;
  max-height: 34px !important;
}
.taggerPortalAlternativeDesign tr:hover {
  background-color: transparent !important;
}
.taggerPortalAlternativeDesign .mdi-close {
  margin-top: 0px !important;
  font-size: 14px !important;
  color: #999 !important;
}
.taggerPortalInputAlternativeDesign {
  height: 32px !important;
}
.taggerPortalInputAlternativeDesign .v-input__control {
  min-height: 32px !important;
}
.taggerPortalInputAlternativeDesign .v-text-field .v-input--dense {
  padding-top: 0px !important;
}
.taggerPortalInputAlternativeDesign .mdi-menu-down {
  display: none;
}
</style>
