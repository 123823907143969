<template>
  <div>
    <container-input-field
      :recordId="recordId"
      :recordEndpoint="recordEndpoint"
      :field="field"
      :newRecord="newRecord"
      :modalColor="modalColor"
      :storeNewRecordImmediately="storeNewRecordImmediately"
      @storeNewRecordImmediately="$emit('storeRecord')"
    >
      <div slot-scope="field" v-if="!isHidden">
        <v-row class="mb-n1" v-if="field.alternativeDesign">
          <v-col class="ml-3 px-0 py-0">
            <base-label :label="label" v-if="label" />
            <div v-else style="height: 6px"></div>
          </v-col>
        </v-row>
        <v-row class="px-0 py-0">
          <v-col class="d-flex pt-1 pb-2" :cols="columns">
            <v-textarea
              :class="{
                'mt-n2': noLabel,
                alternativeTextareaFieldDesign: field.alternativeDesign,
                'body-2': field.alternativeDesign,
                p_TextAreaInputReadOnly: isReadonly,
              }"
              :label="labelText"
              :placeholder="field.alternativeDesign ? ' ' : label"
              :value="field.value"
              @change="field.updateValue"
              hide-details="auto"
              :solo="field.alternativeDesign"
              :flat="field.alternativeDesign"
              :color="field.color"
              autocomplete="disabled"
              :readonly="isReadonly"
              :rows="rows"
            ></v-textarea>
          </v-col>
        </v-row>
      </div>
    </container-input-field>
  </div>
</template>
<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    columns: {
      type: Number,
      default: 12,
    },
    rows: {
      type: Number,
      default: 20,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    isReadonly: {
      type: Boolean,
    },
  },
  methods: {
    test() {
      alert("test");
    },
  },
  computed: {
    labelText() {
      if (!this.noLabel) {
        return this.label;
      } else {
        return null;
      }
    },
  },
};
</script>
<style>
.alternativeTextareaFieldDesign .v-input__slot {
  border: 0.25px solid #aaa;
  padding-left: 9px !important;
}
.alternativeTextareaFieldDesign .v-input__control {
  min-height: 34px !important;
}
.alternativeTextareaFieldDesign textarea {
  margin-top: 4px !important;
  padding-right: 9px !important;
}
.p_TextAreaInputReadOnly > .v-input__control > .v-input__slot {
  background: none !important;
}
</style>
