var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.components),function(component,key){return _c('app-subcomponent-configurator',_vm._b({key:key,attrs:{"pathArray":_vm.pathArray.concat( [key])}},'app-subcomponent-configurator',{
      component: component,
      recordId: _vm.recordId,
      recordEndpoint: _vm.recordEndpoint,
      newRecord: _vm.newRecord,
    },false),[_c(component.componentType,_vm._b({tag:"component",attrs:{"pathArray":_vm.pathArray.concat( [key])},on:{"closeModal":function($event){return _vm.$emit('closeModal')},"storeRecord":function($event){return _vm.$emit('storeRecord')}}},'component',Object.assign({}, component.props,
        {recordId: _vm.recordId,
        recordEndpoint: _vm.recordEndpoint,
        storeNewRecordImmediately: _vm.storeNewRecordImmediately,
        newRecord: _vm.newRecord,
        component: component}),false))],1)}),(_vm.configMode)?_c('v-row',[_c('v-col',{staticClass:"pt-1 pb-2"},[_c('v-btn',{staticClass:"small-icon",staticStyle:{"background-color":"#aaa"},attrs:{"x-small":"","icon":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{staticClass:"small-icon",attrs:{"x-small":"","color":"white"}},[_vm._v("mdi-plus")])],1)],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }