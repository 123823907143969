<template>
  <div>
    <svg
      width="296"
      height="296"
      viewBox="0 0 296 296"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:ev="http://www.w3.org/2001/xml-events"
    >
      <rect x="0" y="0" width="296" height="296" fill="#ffffff" />
      <defs>
        <rect id="p" width="8" height="8" />
      </defs>
      <g fill="#000000">
        <use xlink:href="#p" x="32" y="32" />
        <use xlink:href="#p" x="32" y="40" />
        <use xlink:href="#p" x="32" y="48" />
        <use xlink:href="#p" x="32" y="56" />
        <use xlink:href="#p" x="32" y="64" />
        <use xlink:href="#p" x="32" y="72" />
        <use xlink:href="#p" x="32" y="80" />
        <use xlink:href="#p" x="32" y="96" />
        <use xlink:href="#p" x="32" y="120" />
        <use xlink:href="#p" x="32" y="128" />
        <use xlink:href="#p" x="32" y="168" />
        <use xlink:href="#p" x="32" y="184" />
        <use xlink:href="#p" x="32" y="192" />
        <use xlink:href="#p" x="32" y="208" />
        <use xlink:href="#p" x="32" y="216" />
        <use xlink:href="#p" x="32" y="224" />
        <use xlink:href="#p" x="32" y="232" />
        <use xlink:href="#p" x="32" y="240" />
        <use xlink:href="#p" x="32" y="248" />
        <use xlink:href="#p" x="32" y="256" />
        <use xlink:href="#p" x="40" y="32" />
        <use xlink:href="#p" x="40" y="80" />
        <use xlink:href="#p" x="40" y="96" />
        <use xlink:href="#p" x="40" y="112" />
        <use xlink:href="#p" x="40" y="128" />
        <use xlink:href="#p" x="40" y="144" />
        <use xlink:href="#p" x="40" y="152" />
        <use xlink:href="#p" x="40" y="160" />
        <use xlink:href="#p" x="40" y="168" />
        <use xlink:href="#p" x="40" y="176" />
        <use xlink:href="#p" x="40" y="184" />
        <use xlink:href="#p" x="40" y="208" />
        <use xlink:href="#p" x="40" y="256" />
        <use xlink:href="#p" x="48" y="32" />
        <use xlink:href="#p" x="48" y="48" />
        <use xlink:href="#p" x="48" y="56" />
        <use xlink:href="#p" x="48" y="64" />
        <use xlink:href="#p" x="48" y="80" />
        <use xlink:href="#p" x="48" y="96" />
        <use xlink:href="#p" x="48" y="104" />
        <use xlink:href="#p" x="48" y="120" />
        <use xlink:href="#p" x="48" y="152" />
        <use xlink:href="#p" x="48" y="160" />
        <use xlink:href="#p" x="48" y="176" />
        <use xlink:href="#p" x="48" y="192" />
        <use xlink:href="#p" x="48" y="208" />
        <use xlink:href="#p" x="48" y="224" />
        <use xlink:href="#p" x="48" y="232" />
        <use xlink:href="#p" x="48" y="240" />
        <use xlink:href="#p" x="48" y="256" />
        <use xlink:href="#p" x="56" y="32" />
        <use xlink:href="#p" x="56" y="48" />
        <use xlink:href="#p" x="56" y="56" />
        <use xlink:href="#p" x="56" y="64" />
        <use xlink:href="#p" x="56" y="80" />
        <use xlink:href="#p" x="56" y="96" />
        <use xlink:href="#p" x="56" y="104" />
        <use xlink:href="#p" x="56" y="152" />
        <use xlink:href="#p" x="56" y="160" />
        <use xlink:href="#p" x="56" y="168" />
        <use xlink:href="#p" x="56" y="176" />
        <use xlink:href="#p" x="56" y="184" />
        <use xlink:href="#p" x="56" y="192" />
        <use xlink:href="#p" x="56" y="208" />
        <use xlink:href="#p" x="56" y="224" />
        <use xlink:href="#p" x="56" y="232" />
        <use xlink:href="#p" x="56" y="240" />
        <use xlink:href="#p" x="56" y="256" />
        <use xlink:href="#p" x="64" y="32" />
        <use xlink:href="#p" x="64" y="48" />
        <use xlink:href="#p" x="64" y="56" />
        <use xlink:href="#p" x="64" y="64" />
        <use xlink:href="#p" x="64" y="80" />
        <use xlink:href="#p" x="64" y="104" />
        <use xlink:href="#p" x="64" y="128" />
        <use xlink:href="#p" x="64" y="152" />
        <use xlink:href="#p" x="64" y="176" />
        <use xlink:href="#p" x="64" y="184" />
        <use xlink:href="#p" x="64" y="192" />
        <use xlink:href="#p" x="64" y="208" />
        <use xlink:href="#p" x="64" y="224" />
        <use xlink:href="#p" x="64" y="232" />
        <use xlink:href="#p" x="64" y="240" />
        <use xlink:href="#p" x="64" y="256" />
        <use xlink:href="#p" x="72" y="32" />
        <use xlink:href="#p" x="72" y="80" />
        <use xlink:href="#p" x="72" y="136" />
        <use xlink:href="#p" x="72" y="160" />
        <use xlink:href="#p" x="72" y="184" />
        <use xlink:href="#p" x="72" y="208" />
        <use xlink:href="#p" x="72" y="256" />
        <use xlink:href="#p" x="80" y="32" />
        <use xlink:href="#p" x="80" y="40" />
        <use xlink:href="#p" x="80" y="48" />
        <use xlink:href="#p" x="80" y="56" />
        <use xlink:href="#p" x="80" y="64" />
        <use xlink:href="#p" x="80" y="72" />
        <use xlink:href="#p" x="80" y="80" />
        <use xlink:href="#p" x="80" y="96" />
        <use xlink:href="#p" x="80" y="112" />
        <use xlink:href="#p" x="80" y="128" />
        <use xlink:href="#p" x="80" y="144" />
        <use xlink:href="#p" x="80" y="160" />
        <use xlink:href="#p" x="80" y="176" />
        <use xlink:href="#p" x="80" y="192" />
        <use xlink:href="#p" x="80" y="208" />
        <use xlink:href="#p" x="80" y="216" />
        <use xlink:href="#p" x="80" y="224" />
        <use xlink:href="#p" x="80" y="232" />
        <use xlink:href="#p" x="80" y="240" />
        <use xlink:href="#p" x="80" y="248" />
        <use xlink:href="#p" x="80" y="256" />
        <use xlink:href="#p" x="88" y="104" />
        <use xlink:href="#p" x="88" y="120" />
        <use xlink:href="#p" x="88" y="136" />
        <use xlink:href="#p" x="88" y="144" />
        <use xlink:href="#p" x="88" y="152" />
        <use xlink:href="#p" x="88" y="160" />
        <use xlink:href="#p" x="88" y="176" />
        <use xlink:href="#p" x="88" y="192" />
        <use xlink:href="#p" x="96" y="32" />
        <use xlink:href="#p" x="96" y="48" />
        <use xlink:href="#p" x="96" y="56" />
        <use xlink:href="#p" x="96" y="64" />
        <use xlink:href="#p" x="96" y="80" />
        <use xlink:href="#p" x="96" y="96" />
        <use xlink:href="#p" x="96" y="104" />
        <use xlink:href="#p" x="96" y="112" />
        <use xlink:href="#p" x="96" y="120" />
        <use xlink:href="#p" x="96" y="128" />
        <use xlink:href="#p" x="96" y="144" />
        <use xlink:href="#p" x="96" y="160" />
        <use xlink:href="#p" x="96" y="168" />
        <use xlink:href="#p" x="96" y="176" />
        <use xlink:href="#p" x="96" y="192" />
        <use xlink:href="#p" x="96" y="200" />
        <use xlink:href="#p" x="96" y="232" />
        <use xlink:href="#p" x="96" y="240" />
        <use xlink:href="#p" x="96" y="248" />
        <use xlink:href="#p" x="96" y="256" />
        <use xlink:href="#p" x="104" y="32" />
        <use xlink:href="#p" x="104" y="56" />
        <use xlink:href="#p" x="104" y="64" />
        <use xlink:href="#p" x="104" y="72" />
        <use xlink:href="#p" x="104" y="88" />
        <use xlink:href="#p" x="104" y="104" />
        <use xlink:href="#p" x="104" y="128" />
        <use xlink:href="#p" x="104" y="152" />
        <use xlink:href="#p" x="104" y="176" />
        <use xlink:href="#p" x="104" y="192" />
        <use xlink:href="#p" x="104" y="200" />
        <use xlink:href="#p" x="104" y="208" />
        <use xlink:href="#p" x="104" y="216" />
        <use xlink:href="#p" x="104" y="224" />
        <use xlink:href="#p" x="104" y="232" />
        <use xlink:href="#p" x="104" y="240" />
        <use xlink:href="#p" x="104" y="248" />
        <use xlink:href="#p" x="104" y="256" />
        <use xlink:href="#p" x="112" y="64" />
        <use xlink:href="#p" x="112" y="80" />
        <use xlink:href="#p" x="112" y="136" />
        <use xlink:href="#p" x="112" y="152" />
        <use xlink:href="#p" x="112" y="160" />
        <use xlink:href="#p" x="112" y="184" />
        <use xlink:href="#p" x="112" y="192" />
        <use xlink:href="#p" x="112" y="200" />
        <use xlink:href="#p" x="112" y="256" />
        <use xlink:href="#p" x="120" y="48" />
        <use xlink:href="#p" x="120" y="56" />
        <use xlink:href="#p" x="120" y="64" />
        <use xlink:href="#p" x="120" y="88" />
        <use xlink:href="#p" x="120" y="120" />
        <use xlink:href="#p" x="120" y="128" />
        <use xlink:href="#p" x="120" y="136" />
        <use xlink:href="#p" x="120" y="152" />
        <use xlink:href="#p" x="120" y="160" />
        <use xlink:href="#p" x="120" y="168" />
        <use xlink:href="#p" x="120" y="184" />
        <use xlink:href="#p" x="120" y="200" />
        <use xlink:href="#p" x="120" y="208" />
        <use xlink:href="#p" x="128" y="48" />
        <use xlink:href="#p" x="128" y="72" />
        <use xlink:href="#p" x="128" y="80" />
        <use xlink:href="#p" x="128" y="120" />
        <use xlink:href="#p" x="128" y="128" />
        <use xlink:href="#p" x="128" y="144" />
        <use xlink:href="#p" x="128" y="160" />
        <use xlink:href="#p" x="128" y="192" />
        <use xlink:href="#p" x="128" y="200" />
        <use xlink:href="#p" x="128" y="248" />
        <use xlink:href="#p" x="128" y="256" />
        <use xlink:href="#p" x="136" y="32" />
        <use xlink:href="#p" x="136" y="48" />
        <use xlink:href="#p" x="136" y="56" />
        <use xlink:href="#p" x="136" y="72" />
        <use xlink:href="#p" x="136" y="120" />
        <use xlink:href="#p" x="136" y="128" />
        <use xlink:href="#p" x="136" y="136" />
        <use xlink:href="#p" x="136" y="144" />
        <use xlink:href="#p" x="136" y="160" />
        <use xlink:href="#p" x="136" y="168" />
        <use xlink:href="#p" x="136" y="216" />
        <use xlink:href="#p" x="136" y="224" />
        <use xlink:href="#p" x="136" y="256" />
        <use xlink:href="#p" x="144" y="40" />
        <use xlink:href="#p" x="144" y="48" />
        <use xlink:href="#p" x="144" y="56" />
        <use xlink:href="#p" x="144" y="64" />
        <use xlink:href="#p" x="144" y="72" />
        <use xlink:href="#p" x="144" y="80" />
        <use xlink:href="#p" x="144" y="88" />
        <use xlink:href="#p" x="144" y="104" />
        <use xlink:href="#p" x="144" y="152" />
        <use xlink:href="#p" x="144" y="160" />
        <use xlink:href="#p" x="144" y="168" />
        <use xlink:href="#p" x="144" y="176" />
        <use xlink:href="#p" x="144" y="184" />
        <use xlink:href="#p" x="144" y="200" />
        <use xlink:href="#p" x="144" y="208" />
        <use xlink:href="#p" x="144" y="216" />
        <use xlink:href="#p" x="144" y="240" />
        <use xlink:href="#p" x="144" y="256" />
        <use xlink:href="#p" x="152" y="48" />
        <use xlink:href="#p" x="152" y="64" />
        <use xlink:href="#p" x="152" y="96" />
        <use xlink:href="#p" x="152" y="136" />
        <use xlink:href="#p" x="152" y="152" />
        <use xlink:href="#p" x="152" y="168" />
        <use xlink:href="#p" x="152" y="176" />
        <use xlink:href="#p" x="152" y="224" />
        <use xlink:href="#p" x="152" y="232" />
        <use xlink:href="#p" x="152" y="256" />
        <use xlink:href="#p" x="160" y="32" />
        <use xlink:href="#p" x="160" y="40" />
        <use xlink:href="#p" x="160" y="80" />
        <use xlink:href="#p" x="160" y="104" />
        <use xlink:href="#p" x="160" y="120" />
        <use xlink:href="#p" x="160" y="136" />
        <use xlink:href="#p" x="160" y="144" />
        <use xlink:href="#p" x="160" y="152" />
        <use xlink:href="#p" x="160" y="160" />
        <use xlink:href="#p" x="160" y="176" />
        <use xlink:href="#p" x="160" y="184" />
        <use xlink:href="#p" x="160" y="200" />
        <use xlink:href="#p" x="160" y="208" />
        <use xlink:href="#p" x="160" y="216" />
        <use xlink:href="#p" x="160" y="232" />
        <use xlink:href="#p" x="160" y="248" />
        <use xlink:href="#p" x="168" y="32" />
        <use xlink:href="#p" x="168" y="56" />
        <use xlink:href="#p" x="168" y="64" />
        <use xlink:href="#p" x="168" y="88" />
        <use xlink:href="#p" x="168" y="96" />
        <use xlink:href="#p" x="168" y="128" />
        <use xlink:href="#p" x="168" y="144" />
        <use xlink:href="#p" x="168" y="184" />
        <use xlink:href="#p" x="168" y="192" />
        <use xlink:href="#p" x="168" y="208" />
        <use xlink:href="#p" x="168" y="224" />
        <use xlink:href="#p" x="168" y="232" />
        <use xlink:href="#p" x="176" y="32" />
        <use xlink:href="#p" x="176" y="40" />
        <use xlink:href="#p" x="176" y="48" />
        <use xlink:href="#p" x="176" y="64" />
        <use xlink:href="#p" x="176" y="72" />
        <use xlink:href="#p" x="176" y="80" />
        <use xlink:href="#p" x="176" y="88" />
        <use xlink:href="#p" x="176" y="96" />
        <use xlink:href="#p" x="176" y="120" />
        <use xlink:href="#p" x="176" y="128" />
        <use xlink:href="#p" x="176" y="144" />
        <use xlink:href="#p" x="176" y="160" />
        <use xlink:href="#p" x="176" y="176" />
        <use xlink:href="#p" x="176" y="184" />
        <use xlink:href="#p" x="176" y="192" />
        <use xlink:href="#p" x="176" y="232" />
        <use xlink:href="#p" x="176" y="248" />
        <use xlink:href="#p" x="184" y="32" />
        <use xlink:href="#p" x="184" y="48" />
        <use xlink:href="#p" x="184" y="56" />
        <use xlink:href="#p" x="184" y="64" />
        <use xlink:href="#p" x="184" y="72" />
        <use xlink:href="#p" x="184" y="88" />
        <use xlink:href="#p" x="184" y="104" />
        <use xlink:href="#p" x="184" y="136" />
        <use xlink:href="#p" x="184" y="144" />
        <use xlink:href="#p" x="184" y="152" />
        <use xlink:href="#p" x="184" y="160" />
        <use xlink:href="#p" x="184" y="192" />
        <use xlink:href="#p" x="184" y="208" />
        <use xlink:href="#p" x="184" y="256" />
        <use xlink:href="#p" x="192" y="48" />
        <use xlink:href="#p" x="192" y="72" />
        <use xlink:href="#p" x="192" y="80" />
        <use xlink:href="#p" x="192" y="104" />
        <use xlink:href="#p" x="192" y="152" />
        <use xlink:href="#p" x="192" y="160" />
        <use xlink:href="#p" x="192" y="176" />
        <use xlink:href="#p" x="192" y="192" />
        <use xlink:href="#p" x="192" y="200" />
        <use xlink:href="#p" x="192" y="208" />
        <use xlink:href="#p" x="192" y="216" />
        <use xlink:href="#p" x="192" y="224" />
        <use xlink:href="#p" x="192" y="232" />
        <use xlink:href="#p" x="192" y="240" />
        <use xlink:href="#p" x="192" y="248" />
        <use xlink:href="#p" x="192" y="256" />
        <use xlink:href="#p" x="200" y="96" />
        <use xlink:href="#p" x="200" y="104" />
        <use xlink:href="#p" x="200" y="112" />
        <use xlink:href="#p" x="200" y="160" />
        <use xlink:href="#p" x="200" y="168" />
        <use xlink:href="#p" x="200" y="176" />
        <use xlink:href="#p" x="200" y="192" />
        <use xlink:href="#p" x="200" y="224" />
        <use xlink:href="#p" x="200" y="232" />
        <use xlink:href="#p" x="200" y="240" />
        <use xlink:href="#p" x="200" y="248" />
        <use xlink:href="#p" x="208" y="32" />
        <use xlink:href="#p" x="208" y="40" />
        <use xlink:href="#p" x="208" y="48" />
        <use xlink:href="#p" x="208" y="56" />
        <use xlink:href="#p" x="208" y="64" />
        <use xlink:href="#p" x="208" y="72" />
        <use xlink:href="#p" x="208" y="80" />
        <use xlink:href="#p" x="208" y="104" />
        <use xlink:href="#p" x="208" y="112" />
        <use xlink:href="#p" x="208" y="136" />
        <use xlink:href="#p" x="208" y="144" />
        <use xlink:href="#p" x="208" y="192" />
        <use xlink:href="#p" x="208" y="208" />
        <use xlink:href="#p" x="208" y="224" />
        <use xlink:href="#p" x="216" y="32" />
        <use xlink:href="#p" x="216" y="80" />
        <use xlink:href="#p" x="216" y="128" />
        <use xlink:href="#p" x="216" y="168" />
        <use xlink:href="#p" x="216" y="184" />
        <use xlink:href="#p" x="216" y="192" />
        <use xlink:href="#p" x="216" y="224" />
        <use xlink:href="#p" x="216" y="240" />
        <use xlink:href="#p" x="216" y="248" />
        <use xlink:href="#p" x="216" y="256" />
        <use xlink:href="#p" x="224" y="32" />
        <use xlink:href="#p" x="224" y="48" />
        <use xlink:href="#p" x="224" y="56" />
        <use xlink:href="#p" x="224" y="64" />
        <use xlink:href="#p" x="224" y="80" />
        <use xlink:href="#p" x="224" y="96" />
        <use xlink:href="#p" x="224" y="104" />
        <use xlink:href="#p" x="224" y="112" />
        <use xlink:href="#p" x="224" y="152" />
        <use xlink:href="#p" x="224" y="160" />
        <use xlink:href="#p" x="224" y="176" />
        <use xlink:href="#p" x="224" y="192" />
        <use xlink:href="#p" x="224" y="200" />
        <use xlink:href="#p" x="224" y="208" />
        <use xlink:href="#p" x="224" y="216" />
        <use xlink:href="#p" x="224" y="224" />
        <use xlink:href="#p" x="224" y="232" />
        <use xlink:href="#p" x="224" y="248" />
        <use xlink:href="#p" x="232" y="32" />
        <use xlink:href="#p" x="232" y="48" />
        <use xlink:href="#p" x="232" y="56" />
        <use xlink:href="#p" x="232" y="64" />
        <use xlink:href="#p" x="232" y="80" />
        <use xlink:href="#p" x="232" y="96" />
        <use xlink:href="#p" x="232" y="128" />
        <use xlink:href="#p" x="232" y="160" />
        <use xlink:href="#p" x="232" y="168" />
        <use xlink:href="#p" x="232" y="192" />
        <use xlink:href="#p" x="232" y="200" />
        <use xlink:href="#p" x="232" y="208" />
        <use xlink:href="#p" x="232" y="216" />
        <use xlink:href="#p" x="232" y="232" />
        <use xlink:href="#p" x="232" y="248" />
        <use xlink:href="#p" x="240" y="32" />
        <use xlink:href="#p" x="240" y="48" />
        <use xlink:href="#p" x="240" y="56" />
        <use xlink:href="#p" x="240" y="64" />
        <use xlink:href="#p" x="240" y="80" />
        <use xlink:href="#p" x="240" y="96" />
        <use xlink:href="#p" x="240" y="112" />
        <use xlink:href="#p" x="240" y="128" />
        <use xlink:href="#p" x="240" y="136" />
        <use xlink:href="#p" x="240" y="144" />
        <use xlink:href="#p" x="240" y="168" />
        <use xlink:href="#p" x="240" y="176" />
        <use xlink:href="#p" x="240" y="184" />
        <use xlink:href="#p" x="240" y="192" />
        <use xlink:href="#p" x="240" y="200" />
        <use xlink:href="#p" x="240" y="224" />
        <use xlink:href="#p" x="240" y="240" />
        <use xlink:href="#p" x="248" y="32" />
        <use xlink:href="#p" x="248" y="80" />
        <use xlink:href="#p" x="248" y="112" />
        <use xlink:href="#p" x="248" y="136" />
        <use xlink:href="#p" x="248" y="144" />
        <use xlink:href="#p" x="248" y="152" />
        <use xlink:href="#p" x="248" y="160" />
        <use xlink:href="#p" x="248" y="168" />
        <use xlink:href="#p" x="248" y="200" />
        <use xlink:href="#p" x="248" y="208" />
        <use xlink:href="#p" x="248" y="216" />
        <use xlink:href="#p" x="248" y="248" />
        <use xlink:href="#p" x="248" y="256" />
        <use xlink:href="#p" x="256" y="32" />
        <use xlink:href="#p" x="256" y="40" />
        <use xlink:href="#p" x="256" y="48" />
        <use xlink:href="#p" x="256" y="56" />
        <use xlink:href="#p" x="256" y="64" />
        <use xlink:href="#p" x="256" y="72" />
        <use xlink:href="#p" x="256" y="80" />
        <use xlink:href="#p" x="256" y="96" />
        <use xlink:href="#p" x="256" y="104" />
        <use xlink:href="#p" x="256" y="120" />
        <use xlink:href="#p" x="256" y="136" />
        <use xlink:href="#p" x="256" y="144" />
        <use xlink:href="#p" x="256" y="200" />
        <use xlink:href="#p" x="256" y="224" />
        <use xlink:href="#p" x="256" y="232" />
        <use xlink:href="#p" x="256" y="240" />
      </g>
      <g></g>
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
