<template>
  <container-query-builder-subquery-value-input
    :orQueryKey="orQueryKey"
    :tableGroupingKey="tableGroupingKey"
    :subqueryKey="subqueryKey"
  >
    <div
      slot-scope="{
        items,
        itemValue,
        itemText,
        fieldContent,
        updateFieldContent,
        fieldType,
        operator,
      }"
    >
      <div v-if="!(operator.includes('∅') || operator.includes('*'))">
        <v-autocomplete
          v-if="fieldType == 'autocomplete'"
          :items="items"
          :item-value="itemValue"
          :item-text="itemText"
          label="Value"
          solo
          dense
          :value="fieldContent"
          @input="updateFieldContent"
          autocomplete="disabled"
        ></v-autocomplete>
        <v-switch
          v-else-if="fieldType == 'switch'"
          class="mt-1 ml-2 querySwitch"
          solo
          inset
          color="white"
          :input-value="fieldContent"
          @change="updateFieldContent"
          autocomplete="disabled"
        ></v-switch>
        <v-select
          v-else-if="fieldType == 'select'"
          :items="items"
          label="Value"
          solo
          dense
          :value="fieldContent"
          @input="updateFieldContent"
          autocomplete="disabled"
        ></v-select>
        <v-radio-group
          v-else-if="fieldType == 'radio'"
          :value="fieldContent"
          @input="updateFieldContent"
          row="row"
          class="mt-1 ml-3 mb-0"
        >
          <v-radio
            v-for="item in items"
            :key="item"
            :label="item"
            :value="item"
            color="black"
          ></v-radio>
        </v-radio-group>
        <v-text-field
          v-else
          dense
          solo
          label="Value"
          :value="fieldContent"
          @input="updateFieldContent"
          autocomplete="disabled"
        ></v-text-field>
      </div>
    </div>
  </container-query-builder-subquery-value-input>
</template>

<script>
export default {
  props: {
    orQueryKey: {
      type: Number,
    },
    tableGroupingKey: {
      type: Number,
    },
    subqueryKey: {
      type: Number,
    },
  },
};
</script>

<style lang="scss">
.querySwitch .v-input--switch__track {
  background-color: rgba(255, 255, 255, 0.9);
}
</style>
