var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-config',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var color = ref.color;
return _c('div',{},[_c('container-record',{attrs:{"endpoint":_vm.endpoint,"recordId":_vm.preloadedRecord.id,"preloadedRecordData":_vm.preloadedRecord,"newRecord":_vm.newRecord,"originEndpoint":_vm.recordEndpoint,"suppressAutoForeignKey":true,"storeNewRecordImmediately":_vm.storeNewRecordImmediately},on:{"storeNewRecordImmediately":function($event){return _vm.$emit('storeRecord')},"handleNewRecordResponse":function($event){return _vm.$emit('ok')},"handlePostDeletion":function($event){return _vm.$emit('ok')}},scopedSlots:_vm._u([{key:"default",fn:function(record){return (record.record)?_c('div',{},[_c('v-card',[_c('v-card-title',{staticClass:"px-9",staticStyle:{"margin":"0px"},style:({ 'background-color': _vm.modalColor || color })},[_c('span',{staticClass:"headline white--text"},[_vm._v(_vm._s(_vm.newEntryLabel))])]),_c('v-card-text',{staticClass:"px-9 py-2"},[_c('v-radio-group',{staticClass:"mb-3",attrs:{"value":_vm.direction,"row":"","hide-details":"auto"},on:{"change":function($event){_vm.changeDirection($event);
                record.updateRecordState(_vm.freshRecordState);}}},[_c('v-radio',{attrs:{"label":_vm.labelA,"value":"a","color":color}}),_c('v-radio',{attrs:{"label":_vm.labelB,"value":"b","color":color}})],1),(_vm.direction)?_c('base-input-search-or-create',{key:_vm.key + 1,attrs:{"displayField":"full_name","endpoint":_vm.recordEndpoint,"recordId":_vm.preloadedRecord.id,"field":_vm.searchField,"recordEndpoint":_vm.endpoint,"newRecord":_vm.newRecord,"modalColor":_vm.modalColor,"label":"link to contact","headers":[
                {
                  text: 'Name',
                  value: 'full_name'
                },
                {
                  text: 'City',
                  value: 'city'
                }
              ],"components":_vm.newRecordComponents}}):_c('v-text-field',{staticClass:"mt-4 mb-2",attrs:{"prepend-inner-icon":"mdi-link","disabled":"","hide-details":"auto","label":"link to contact"}})],1),_c('v-card-actions',{staticClass:"pt-3 pb-6 px-9"},[_c('v-btn',{on:{"click":function($event){return _vm.$emit('ok')}}},[_vm._v("cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":color,"dark":"","disabled":!record.record[_vm.searchField]},on:{"click":function($event){return record.saveNewRecord()}}},[_vm._v("OK")])],1)],1)],1):_vm._e()}}],null,true)})],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }