var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('container-tagger',{attrs:{"recordId":_vm.recordId,"recordEndpoint":_vm.recordEndpoint,"endpointValue":_vm.endpointValue,"endpointJoin":_vm.endpointJoin,"endpointSort":_vm.endpointSort},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _c('div',{class:_vm.customClass},[(scope.alternativeDesign)?_c('base-label',{staticClass:"ml-0",attrs:{"label":_vm.label}}):_c('base-title',{attrs:{"label":_vm.label}}),_c('v-simple-table',{class:{ taggerPortalAlternativeDesign: scope.alternativeDesign },style:({
          'background-color': scope.alternativeDesign
            ? 'white'
            : 'transparent'
        }),scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_vm._l((scope.values),function(item,key){return _c('tr',{key:key},[_c('td',{staticClass:"body-2 my-0",staticStyle:{"padding-left":"10px"}},[_vm._v(" "+_vm._s(item[scope.endpointValueSingular][_vm.displayFieldName])+" ")]),_c('td',{attrs:{"width":"30"}},[_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","x-small":""},on:{"click":function($event){return scope.deleteEntry(item.id)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])}),_c('tr',[_c('td',{staticStyle:{"padding-left":"0px"}},[_c('v-autocomplete',{key:scope.values ? scope.values.length : 0,staticClass:"mr-1",class:{
                    taggerPortalInputAlternativeDesign:
                      scope.alternativeDesign,
                    'body-2': scope.alternativeDesign
                  },attrs:{"items":scope.selectValues,"item-value":"id","item-text":_vm.displayFieldName,"dense":"","solo":"","flat":"","hide-details":"auto","placeholder":_vm.newTagText},on:{"change":scope.enterNewValue}})],1),_c('td')])],2)]},proxy:true}],null,true)})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }