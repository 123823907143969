<template>
  <div class="print" style="margin: 0px auto;">
    <h1 class="mt-4 ml-4 mb-3">{{ title }}</h1>
    <v-data-table
      v-if="records"
      :headers="listHeaders"
      :items="records"
      hide-default-footer
      disable-pagination
      disable-sort
      dense
    ></v-data-table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      records: null
    };
  },
  computed: {
    moduleName() {
      return this.$route.params.module;
    },
    moduleConfig() {
      let modules = this.$store.state.config.app.modules;
      return modules.find(e => e.basicConfig.path == this.moduleName);
    },
    listHeaders() {
      return this.moduleConfig.lists[this.$route.query.list].headers;
    },
    endpoint() {
      return this.moduleConfig.basicConfig.apiEndpoint;
    },
    title() {
      return this.$route.query.title;
    }
  },
  methods: {
    loadRecords() {
      return new Promise(resolve => {
        let params = this.$route.query;
        let p = "page=all";
        let f = params.filter ? "&filter=" + params.filter : "";
        let s = params.sort ? "&sort=" + params.sort : "";
        let q = params.q ? "&q=" + params.q : "";
        let query_params = p + f + s + q;
        let self = this;
        axios
          .get(this.endpoint + "?" + query_params)
          .then(r => r.data)
          .then(function(data) {
            self.records = data;
            resolve();
          });
      });
    }
  },
  mounted() {
    this.loadRecords().then(function() {
      window.print();
      window.close();
    });
  }
};
</script>
<style>
.print {
  height: 21cm;
  width: 28.6cm;
}
.print tr td:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
.print tr td {
  padding: 3px 12px;
  white-space: pre-wrap;
  /* border-top: thin solid rgba(0, 0, 0, 0.12); */
  border-right: thin solid rgba(0, 0, 0, 0.12);
  word-break: break-word;
  max-width: 320px;
}
.print th {
  background-color: #ccc;
}
.print tr:last-child td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.print tr > *:last-child {
  display: none;
}
.list_telephone {
  min-width: 150px;
}
.print .list_telephone {
  min-width: 145px;
}
.print .list_function {
  min-width: 145px !important;
}
.print .list_email {
  min-width: 200px !important;
}
</style>
