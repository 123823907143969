<template>
  <div>
    <v-divider :class="classObject"></v-divider>
  </div>
</template>

<script>
export default {
  props: {
    marginTop: {
      type: String,
      default: "0",
    },
    marginBottom: {
      type: String,
      default: "3",
    },
  },
  computed: {
    classObject() {
      return "ml-0 " + "mb-" + this.marginBottom + " mt-" + this.marginTop;
    },
  },
};
</script>
