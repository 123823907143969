<template>
  <v-card>
    <container-ui-state>
      <div slot-scope="{ changeMobileView }">
        <container-config>
          <div
            slot-scope="{ app, module, color, language }"
            :style="{ 'background-color': app.colorBackground }"
          >
            <container-record
              :endpoint="module.basicConfig.apiEndpoint"
              recordId="new"
              :preloadedRecordData="{}"
              :newRecord="true"
              @closeDialog="$emit('closeDialog')"
            >
              <div slot-scope="{ saveNewRecord }">
                <v-card-title :style="{ 'background-color': color }">
                  <h4
                    class="pl-2 text-uppercase font-weight-regular"
                    dark
                    style="color: white"
                  >
                    {{ module.basicConfig.newRecordText }}
                  </h4>
                </v-card-title>

                <v-card-text class="px-6 py-3">
                  <v-form v-model="valid" ref="form">
                    <component
                      v-for="(
                        component, key
                      ) in module.newRecordModalComponents"
                      :key="key"
                      v-bind:is="component.componentType"
                      v-bind="{
                        ...component.props,
                        newRecord: true,
                        recordId: 'new',
                        recordEndpoint: module.basicConfig.apiEndpoint,
                        modalColor: color,
                      }"
                    ></component>
                  </v-form>
                </v-card-text>
                <base-divider class="pt-8"></base-divider>
                <v-card-actions class="px-6 pb-4">
                  <v-spacer></v-spacer>
                  <div>
                    <v-btn
                      text
                      class="font-weight-medium mr-4"
                      @click="$emit('closeDialog')"
                      >{{ texts["cancel"][language] }}</v-btn
                    >
                    <v-btn
                      tile
                      class="font-weight-medium"
                      :color="color"
                      :dark="valid"
                      @click="
                        saveNewRecord();
                        changeMobileView('form');
                      "
                      :disabled="!valid"
                      >{{ texts["save"][language] }}</v-btn
                    >
                  </div>
                </v-card-actions>
              </div>
            </container-record>
          </div>
        </container-config>
      </div>
    </container-ui-state>
  </v-card>
</template>

<script>
export default {
  data: function () {
    return {
      valid: true,
      texts: {
        save: {
          en: "Save",
          da: "Gem",
          de: "Speichern",
        },
        cancel: {
          en: "Cancel",
          da: "Afbryd",
          de: "abbrechen",
        },
      },
    };
  },
};
</script>
