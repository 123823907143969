<template>
  <div>
    <slot :items="returnedItems"></slot>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    endpoint: {
      type: String,
    },
    endpointSort: {
      type: String,
    },
    itemText: {
      type: String,
    },
    itemHeader: {
      type: String,
    },
    items: {
      type: Array,
    },
    filters: {
      type: Array,
    },
  },
  computed: {
    transformedEndpoint() {
      let recordId = this.$route.params.record_id;
      return this.endpoint.replace("{recordId}", recordId);
    },
    returnedItems() {
      if (this.items) {
        return this.items;
      } else if (this.$store.state.dropdownValues[this.transformedEndpoint]) {
        let items = this.$store.state.dropdownValues[this.transformedEndpoint];
        let filteredItems = items.filter((e) => {
          return e[this.itemText] !== null;
        });
        if (this.filters && this.filters.length > 0) {
          //TO DO: first filter respected only, rework for several filters
          //TO DO: add operator to allow for non-equal operators
          let field = this.filters[0]["field"];
          let value = this.filters[0]["value"];
          filteredItems = items.filter((e) => {
            return e[field] == value;
          });
        }
        if (this.itemHeader) {
          return this.addHeaders(filteredItems);
        } else {
          return filteredItems;
        }
      } else {
        return null;
      }
    },
  },
  methods: {
    addHeaders(items) {
      var newArray = [];
      let self = this;
      let oldHeader = null;
      items.forEach(function (item) {
        let header = item[self.itemHeader];
        if (header != oldHeader) {
          let headerObject = { header };
          newArray.push(headerObject);
          oldHeader = header;
        }
        newArray.push(item);
      });
      return newArray;
    },
  },
  mounted() {
    if (this.returnedItems == undefined) {
      let endpoint = this.transformedEndpoint;
      let sort = this.endpointSort ? this.endpointSort : this.itemText;
      axios
        .get(endpoint + "?sort=" + sort + "&page=all")
        .then((r) => r.data)
        .then((data) => {
          this.$store.commit("SET_DROPDOWN_VALUES", { endpoint, data });
        });
    }
  },
};
</script>
