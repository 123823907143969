import Vue from "vue";

export default {
  SET_FILTER(state, payload) {
    state[payload.module].filter = payload.value;
  },
  SET_MODULE_VARIABLE_PRE_FILTER(state, payload) {
    state[payload.module].variablePreFilterValue = payload.value;
  },
  SET_PAGE(state, payload) {
    state[payload.module].selectedPage = payload.value;
  },
  SET_LIST(state, payload) {
    Vue.set(state[payload.module], "selectedList", payload.value);
  },
  SET_QUERY(state, payload) {
    Vue.set(state[payload.module], "query", payload.query);
  },
  SET_RECORD_DATA(state, payload) {
    // state[payload.endpoint].selectedRecords[payload.recordId] = Object.assign({}, state[payload.endpoint].selectedRecords[payload.recordId], payload.data);
    Vue.set(
      state[payload.endpoint].selectedRecords,
      payload.recordId,
      payload.data
    );
  },
  SET_RERENDER_KEY(state) {
    const rerenderKey = (state.recordComponentRerenderKey || 0) + 1;
    // state[payload.endpoint].selectedRecords[payload.recordId] = Object.assign({}, state[payload.endpoint].selectedRecords[payload.recordId], payload.data);
    Vue.set(state, "recordComponentRerenderKey", rerenderKey);
  },
  ADD_STORED_ID_TO_TEMPORARY_RECORD(state, payload) {
    let recordObject =
      state[payload.endpoint].selectedRecords[payload.recordId];
    Vue.set(
      state[payload.endpoint].selectedRecords[payload.recordId],
      "id",
      payload.newId
    );
    Vue.set(
      state[payload.endpoint].selectedRecords,
      payload.newId,
      recordObject
    );
  },
  TOGGLE_STORING_FLAG_TO_TEMPORARY_RECORD(state, payload) {
    Vue.set(
      state[payload.endpoint].selectedRecords[payload.recordId],
      "recordIsStoring",
      payload.value
    );
  },
  SET_PAGE_DATA(state, payload) {
    Vue.set(state[payload.module].data, payload.page, payload.data);
  },
  CLEAR_DATA(state, endpoint) {
    state[endpoint].data = {};
  },
  SET_RECORD_COUNT(state, payload) {
    state[payload.module].recordCount = payload.recordCount;
  },
  SET_SELECTED_RECORD_ID(state, payload) {
    state[payload.module]["selectedRecordId"] = payload.recordId;
  },
  SWITCH_TAB(state, payload) {
    state[payload.module].selectedTab = payload.input;
  },
  SET_NEW_ENDPOINT(state, payload) {
    Vue.set(state, payload.endpoint, payload.data);
  },
  SET_FIELD_VALUE(state, payload) {
    Vue.set(
      state[payload.endpoint].selectedRecords[payload.recordId],
      payload.field,
      payload.value
    );
  },
  REPLACE_PAGE_RECORD(state, payload) {
    Vue.set(
      state[payload.endpoint].data[payload.page],
      payload.recordIndex,
      payload.record
    );
  },
  ADD_OBJECT_TO_SUBARRAY(state, payload) {
    let previousArray =
      state[payload.recordEndpoint]["selectedRecords"][payload.recordId][
        payload.tableEndpoint
      ];
    let newArray = [...previousArray, payload.data];
    Vue.set(
      state[payload.recordEndpoint]["selectedRecords"][payload.recordId],
      payload.tableEndpoint,
      newArray
    );
  }
};
