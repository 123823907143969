<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
// TO DO: revisit this component's logic!!
export default {
  computed: {
    query() {
      return this.$route.query;
    },
    module() {
      return this.$route.params.module;
    },
    moduleConfigData() {
      let modules = this.$store.state.config.app.modules;
      return modules.find(e => e.basicConfig.path == this.module)[
        "basicConfig"
      ];
    },
    state() {
      return this.$store.state[this.module];
    }
  },
  //here, the state is resumed if browser back button is selected
  //NB: something broke here, the mutation "realignStateWithUrl" no longer exists
  // watch: {
  //   $route() {
  //     if (
  //       //filter mismatch
  //       this.query.filter != this.state.filter ||
  //       //query mismatch
  //       this.query.q != this.state.query ||
  //       //page mismatch
  //       (this.query.page != this.state.selectedPage &&
  //         !(this.state.selectedPage == 1 && this.query.page == null)) ||
  //       //sort mismatch
  //       (this.query.sort != this.state.sort &&
  //         !(
  //           this.query.sort == null &&
  //           this.state.sort === this.moduleConfigData.defaultSort
  //         ))
  //     ) {
  //       this.$store.dispatch("realignStateWithUrl", this.module);
  //     }
  //   }
  // },
  mounted() {
    //prep module module in store if not established
    if (!this.state) {
      this.$store.registerModule(this.module, {
        state: {
          recordCount: null,
          selectedPage: parseInt(this.$route.query.page) || 1,
          selectedTab: 0,
          selectedRecordId: null,
          selectedRecords: {},
          selectedList: 0,
          sort:
            this.$route.query.sort || this.moduleConfigData.defaultSort || null,
          filter: this.$route.query.filter || null,
          query: this.$route.query.q || null,
          reloadTrigger: Date.now(),
          data: {},
          loadingState: "idle"
        }
      });
      //force view if defined in url
      if (this.$route.query.view) {
        this.$store.commit("TOGGLE_VIEW", this.$route.query.view);
      }
      //push all parameters to ensure that global parameters (e.g. view) are preserved
      this.$store.dispatch("pushUrlParametersToRoute");
    }
    // otherwise set URL query parameters
    else {
      let query = {};
      let selectedRecordId = this.$route.query.link
        ? this.$route.params.record_id
        : this.$store.state[this.module].selectedRecordId;
      let path = this.$route.path;
      if (!this.$route.params.record_id) {
        path = this.$route.params.module + "/" + selectedRecordId;
        // eslint-disable-next-line
        // this.$router.push({ path }).catch(err => {});
      }
      if (this.state.filter !== null) {
        query.filter = this.state.filter;
      }
      if (this.state.query !== null) {
        query.q = this.state.query;
      }
      if (
        this.state.sort !== this.moduleConfigData.defaultSort &&
        this.state.sort != null
      ) {
        query.sort = this.state.sort;
      }
      if (this.state.selectedPage > 1) {
        query.page = this.state.selectedPage;
      }
      if (this.$store.state.uiState.view != "masterDetail") {
        query.view = this.$store.state.uiState.view;
      }
      if (this.$route.query.link == "true") {
        query.link = "true";
      }
      // eslint-disable-next-line
      this.$router.push({ query, path }).catch(err => {});
    }
    if (this.state.recordCount == 0) {
      this.$store.dispatch("fetchRecordCount", this.module);
    }
    if (this.state.query !== null) {
      this.$store.commit("LOAD_URL_QUERY_TO_STATE", this.state.query);
    } else {
      this.$store.commit("CLEAR_QUERY");
    }
  }
};
</script>
