<template>
  <container-ui-state>
    <!-- only mount template once the state endpoint has been prepped -->
    <div slot-scope="uiState" v-if="$store.state[$route.params.module]">
      <v-row
        :style="{
          height: 'calc(100vh - 64px)',
        }"
        class="mx-0 my-0"
      >
        <v-col
          sm="3"
          xl="2"
          class="py-0 px-0"
          v-if="
            (!isMobile && uiState.view === 'masterDetail') ||
            (isMobile && uiState.mobileView == 'list')
          "
          style="border-right: 0.25px solid #aaa"
        >
          <app-record-master-list ref="masterList" />
        </v-col>
        <v-col
          v-if="!isMobile || uiState.mobileView == 'form'"
          :sm="uiState.view === 'masterDetail' ? 9 : 12"
          :xl="uiState.view === 'masterDetail' ? 10 : 12"
          class="py-0 px-0"
        >
          <router-view @refreshPage="refreshPage()"></router-view>
        </v-col>
      </v-row>
    </div>
  </container-ui-state>
</template>

<script>
export default {
  methods: {
    refreshPage() {
      this.$refs.masterList.refreshPage();
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>
