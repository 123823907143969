<template>
  <div>
    <slot
      :recordId="recordId"
      :recordEndpoint="recordEndpoint"
      :record="record"
      :newRecord="newRecord"
    ></slot>
  </div>
</template>

<script>
import Inflector from "inflector-js";

export default {
  props: {
    originRecordId: {
      type: [Number, String],
    },
    originRecordEndpoint: {
      type: String,
    },
    endpoint: {
      type: String,
    },
    filters: {
      type: Array,
    },
  },
  computed: {
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    recordId() {
      let result;
      if (this.newRecord) {
        result = this.uuidv4;
      } else if (
        this.filteredRecords &&
        this.filteredRecords instanceof Array &&
        this.filteredRecords[0]
      ) {
        result = this.filteredRecords[0].id;
      } else if (
        this.filteredRecords &&
        this.filteredRecords instanceof Object
      ) {
        result = this.filteredRecords.id;
      }
      return result;
    },
    newRecord() {
      let result;
      if (
        this.filteredRecords instanceof Array &&
        this.filteredRecords.length == 0
      ) {
        result = true;
      } else if (
        (this.filteredRecords instanceof Array && this.filteredRecords[0]) ||
        this.filteredRecords instanceof Object ||
        this.portalRecordStoredId ||
        this.portalRecordIsStoring
      ) {
        result = false;
      } else {
        result = true;
      }
      return result;
    },
    recordEndpoint() {
      return Inflector.pluralize(this.endpoint);
    },
    record() {
      if (this.newRecord) {
        let filterObject = {};
        if (this.filters) {
          this.filters.forEach(function (item) {
            if (
              item.operator == "==" ||
              item.operator == "=" ||
              item.operator == undefined ||
              item.operator == ""
            ) {
              filterObject[item.field] = item.value;
            }
          });
        }
        let fkFieldName =
          Inflector.singularize(this.originRecordEndpoint) + "_id";
        filterObject[fkFieldName] = this.originRecordId;
        return filterObject;
      } // if relationship is multiple records (one-to-many)
      else if (
        this.filteredRecords instanceof Array &&
        this.filteredRecords[0]
      ) {
        return this.filteredRecords[0];
      } // if relationship is a single record (one-to-one)
      else if (this.filteredRecords instanceof Object) {
        return this.filteredRecords;
      } else {
        return null;
      }
    },
    portalRecordStoredId() {
      if (
        this.$store.state[this.endpoint] &&
        this.$store.state[this.endpoint]["selectedRecords"][this.recordId]
      ) {
        return this.$store.state[this.endpoint]["selectedRecords"][
          this.recordId
        ]["id"];
      } else {
        return null;
      }
    },
    portalRecordIsStoring() {
      if (
        this.$store.state[this.endpoint] &&
        this.$store.state[this.endpoint]["selectedRecords"][this.recordId]
      ) {
        return this.$store.state[this.endpoint]["selectedRecords"][
          this.recordId
        ]["recordIsStoring"];
      } else {
        return null;
      }
    },
    relatedRecords() {
      if (
        this.$store.state[this.originRecordEndpoint] &&
        this.$store.state[this.originRecordEndpoint]["selectedRecords"][
          this.originRecordId
        ][this.endpoint]
      ) {
        return this.$store.state[this.originRecordEndpoint]["selectedRecords"][
          this.originRecordId
        ][this.endpoint];
      } else {
        return [];
      }
    },
    filteredRecords() {
      if (this.filters) {
        let self = this;
        return this.relatedRecords.filter(function (item) {
          let filters = self.filters;
          let checkArray = [];
          for (let i = 0; i < filters.length; i++) {
            let filter = filters[i];
            if (
              (item[filter.field] != undefined &&
                //match for "==" operator or no defined operator (equivalent to "==")
                (((filter.operator == "==" ||
                  filter.operator == "=" ||
                  filter.operator == undefined) &&
                  item[filter.field] == filter.value) ||
                  //match for ">=" operator
                  (filter.operator == ">=" &&
                    item[filter.field] >= filter.value) ||
                  //match for ">" operator
                  (filter.operator == ">" &&
                    item[filter.field] > filter.value) ||
                  //match for "<=" operator
                  (filter.operator == "<=" &&
                    item[filter.field] <= filter.value) ||
                  //match for "<" operator
                  (filter.operator == "<" &&
                    item[filter.field] < filter.value) ||
                  //match for "contains" operator
                  (filter.operator == "contains" &&
                    item[filter.field].includes(filter.value) > 0) ||
                  //match for "!=" operator
                  (filter.operator == "!=" &&
                    item[filter.field] != filter.value))) ||
              //match for unequal on undefined field
              (item[filter.field] == undefined && filter.operator == "!=")
            ) {
              checkArray.push(true);
            } else {
              checkArray.push(false);
            }
          }
          return checkArray.every((v) => v === true);
        });
      } else {
        return this.relatedRecords;
      }
    },
  },
};
</script>
