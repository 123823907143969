<template>
  <container-config>
    <div slot-scope="{ color, language }">
      <container-recursive-link
        :endpoint="endpoint"
        :recordEndpoint="recordEndpoint"
        :recordId="recordId"
        :fieldA="fieldA"
        :fieldB="fieldB"
        @refreshOriginRecord="refreshOriginRecord"
      >
        <div slot-scope="scope">
          <div v-if="scope.display">
            <div>
              <base-title
                :label="titleCase(scope.displayLabel == 'a' ? labelA : labelB)"
              />
            </div>
            <v-card class="mt-3 elevation-1" style="border: 0.25px solid #aaa">
              <v-card-text class="black--text">
                <v-row>
                  <v-col class="py-1" cols="auto">
                    <router-link
                      class="font-weight-bold"
                      :style="{ color: color }"
                      style="font-size: 15px"
                      :to="'/' + recordEndpoint + '/' + scope.display.id"
                      >{{ scope.display.full_name }}</router-link
                    >
                    <div v-if="scope.joinRecord['employee_' + scope.aOrB]">
                      <div class="body-2 mt-3">
                        {{
                          scope.joinRecord["employee_" + scope.aOrB].jobfunction
                        }}
                      </div>
                      <div
                        class="body-2"
                        v-if="scope.joinRecord['employee_' + scope.aOrB].email"
                      >
                        <v-icon left small>mdi-email</v-icon
                        >{{ scope.joinRecord["employee_" + scope.aOrB].email }}
                      </div>
                      <div
                        class="body-2"
                        v-if="
                          scope.joinRecord['employee_' + scope.aOrB].telephone
                        "
                      >
                        <v-icon left small>mdi-phone</v-icon
                        >{{
                          scope.joinRecord["employee_" + scope.aOrB].telephone
                        }}
                      </div>
                      <div
                        class="body-2"
                        v-if="scope.joinRecord['employee_' + scope.aOrB].mobile"
                      >
                        <v-icon left small>mdi-cellphone</v-icon
                        >{{ scope.joinRecord["employee_" + scope.aOrB].mobile }}
                      </div>
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="auto" class="py-1">
                    <v-btn
                      small
                      icon
                      @click="overlay = true"
                      v-if="scope.joinRecord['employee_' + scope.aOrB]"
                    >
                      <v-icon small>mdi-eye</v-icon>
                    </v-btn>
                    <!-- <v-btn
                      small
                      icon
                      :to="'/' + recordEndpoint + '/' + scope.display.id"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn> -->
                    <v-btn small icon @click="deletionDialog = true">
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-overlay :value="overlay" opacity="0.8">
                <div v-if="scope.joinRecord['employee_' + scope.aOrB]">
                  <p
                    class="white--text display-4"
                    v-if="scope.joinRecord['employee_' + scope.aOrB].email"
                  >
                    {{ scope.joinRecord["employee_" + scope.aOrB].email }}
                  </p>
                  <p
                    class="white--text display-4"
                    v-if="scope.joinRecord['employee_' + scope.aOrB].telephone"
                  >
                    {{ scope.joinRecord["employee_" + scope.aOrB].telephone }}
                  </p>
                  <p
                    class="white--text display-4"
                    v-if="scope.joinRecord['employee_' + scope.aOrB].mobile"
                  >
                    {{ scope.joinRecord["employee_" + scope.aOrB].mobile }}
                  </p>
                </div>

                <v-btn
                  right
                  x-large
                  outlined
                  class="white--text"
                  @click="overlay = false"
                >
                  OK
                </v-btn>
              </v-overlay>
            </v-card>
            <!-- <div
              class="body-1 black--text pb-0"
              style="border-bottom: 1pt #aaa solid;"
            >
              <div class="caption" style="color: #666;">
                {{ scope.displayLabel == "a" ? labelA : labelB }}
              </div>
              <v-row>
                <v-col class="py-0">
                  <router-link
                    :style="{ color: color }"
                    style="text-decoration:none;"
                    :to="'/' + recordEndpoint + '/' + scope.display.id"
                    >{{ scope.display.full_name }}</router-link
                  >
                </v-col>
                <v-spacer class="py-0"></v-spacer>
                <v-col class="py-0" cols="auto">
                  <v-btn right icon small @click="deletionDialog = true">
                    <v-icon small color="#999">mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div> -->
          </div>

          <div v-else>
            <div>
              <base-title :label="titleCase(endpoint)" />
            </div>
            <v-btn
              tile
              text
              small
              color="grey"
              class="pl-1 pt-4 pb-4"
              @click="createNewRecord()"
            >
              <v-icon left class="mr-1">mdi-plus</v-icon
              >{{ newEntryLabel }}</v-btn
            >
          </div>

          <v-dialog v-model="dialog" width="500">
            <base-input-recursive-link-modal
              v-bind="$props"
              @ok="
                dialog = false;
                refreshOriginRecord();
              "
              :preloadedRecord="preloadedRecord"
              :newRecord="newSubrecord"
              :selectedLabel="selectedLabel"
            />
          </v-dialog>

          <v-dialog v-model="deletionDialog" width="500">
            <v-card>
              <v-card-title>
                <p class="pt-2">
                  {{ texts["reallyDeleteEntry"][language] }}
                </p>
              </v-card-title>
              <v-card-actions class="pa-5">
                <v-spacer></v-spacer>
                <v-btn @click="deletionDialog = false">
                  {{ texts["cancel"][language] }}
                </v-btn>
                <v-btn
                  dark
                  :color="modalColor || color"
                  @click="
                    scope.deleteRecord();
                    deletionDialog = false;
                  "
                >
                  {{ texts["yes"][language] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </container-recursive-link>
    </div>
  </container-config>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";

export default {
  mixins: [baseComponentMixin],
  props: {
    endpoint: {
      type: String,
    },
    fieldA: {
      type: String,
    },
    fieldB: {
      type: String,
    },
    labelA: {
      type: String,
    },
    labelB: {
      type: String,
    },
    newEntryLabel: {
      type: String,
    },
  },
  data() {
    return {
      dialog: false,
      preloadedRecord: null,
      overlay: null,
      newSubrecord: null,
      selectedLabel: null,
      deletionDialog: null,
      texts: {
        deleteEntry: {
          en: "Delete Entry",
          da: "Slet emne",
          de: "Eintrag löschen",
        },
        cancel: {
          en: "cancel",
          da: "afbryd",
          de: "abbrechen",
        },
        reallyDeleteEntry: {
          en: "Do you really want to delete this entry?",
          da: "Vil du slette emnet?",
          de: "Soll dieser Eintrag wirklich gelöscht werden?",
        },
        yes: {
          en: "Yes",
          da: "Ja",
          de: "Ja",
        },
        continue: {
          en: "continue",
          da: "videre",
          de: "weiter",
        },
      },
    };
  },
  methods: {
    createNewRecord() {
      this.preloadedRecord = { id: "new" };
      this.newSubrecord = true;
      this.dialog = true;
    },
    refreshOriginRecord() {
      let payload = {
        recordId: this.recordId,
        endpoint: this.recordEndpoint,
      };
      this.$store.dispatch("refreshOriginRecord", payload);
    },
    //TO DO: mixin?
    titleCase(str) {
      str = str.toLowerCase().split(" ");
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(" ");
    },
  },
};
</script>
