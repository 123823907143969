<template>
  <container-settings-details :key="$route.params.setting">
    <div slot-scope="{ width }">
      <div
        :style="{ 'max-width': width + 'px' }"
        style="border-right: 1px solid lightgrey;"
      >
        <div
          :style="{
            height: 'calc(' + windowHeight + 'px' + ' - ' + (64 + 57) + 'px)'
          }"
          style="background-color: white; overflow: scroll"
        >
          <app-settings-details-body ref="body"></app-settings-details-body>
        </div>
        <div style="height: 57px; border-top: 1px solid lightgrey;">
          <app-settings-details-footer
            @addNewRecord="addNewRecord"
          ></app-settings-details-footer>
        </div>
      </div>
    </div>
  </container-settings-details>
</template>

<script>
export default {
  data() {
    return {
      windowHeight: null
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  },
  methods: {
    handleResize() {
      this.windowHeight = window.innerHeight;
    },
    addNewRecord() {
      this.$refs.body.addNewRecord();
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>
