<template>
  <container-ui-state>
    <div slot-scope="uiState">
      <container-config>
        <div slot-scope="config">
          <div>
            <v-btn-toggle
              class="mr-4"
              mandatory
              :value="uiState.view"
              color="white"
              :style="{ 'background-color': config.color }"
            >
              <v-tooltip
                bottom
                open-on-hover
                open-delay="500"
                v-for="(button, key) in buttons"
                :key="key"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="mr-1 viewbutton"
                    :value="button.value"
                    style="border: 0px;"
                    :style="{ 'background-color': config.color }"
                    @click="uiState.changeView(button.value)"
                  >
                    <v-icon color="#eee">{{ button.icon }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ button.tooltip[config.language] }}</span>
              </v-tooltip>
            </v-btn-toggle>
          </div>
        </div>
      </container-config>
    </div>
  </container-ui-state>
</template>

<script>
export default {
  data() {
    return {
      buttons: [
        {
          icon: "mdi-window-maximize",
          tooltip: {
            en: "single record view",
            da: "enkel",
            de: "Einzelansicht"
          },
          value: "form"
        },
        {
          icon: "mdi-view-split-vertical",
          tooltip: {
            en: "mixed view",
            da: "blandet",
            de: "gemischte Ansicht"
          },
          value: "masterDetail"
        },
        {
          icon: "mdi-view-headline",
          tooltip: {
            en: "list view",
            da: "liste",
            de: "Listenansicht"
          },
          value: "list"
        }
      ]
    };
  }
};
</script>
