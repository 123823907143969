<template>
  <container-query-builder-subquery-operator-selector
    :orQueryKey="orQueryKey"
    :tableGroupingKey="tableGroupingKey"
    :subqueryKey="subqueryKey"
  >
    <div slot-scope="{ selectedOperator, changeOperator }">
      <v-select
        dense
        solo
        autocomplete="disabled"
        :items="operators"
        :value="selectedOperator"
        @input="changeOperator"
      ></v-select>
    </div>
  </container-query-builder-subquery-operator-selector>
</template>

<script>
export default {
  props: {
    orQueryKey: {
      type: Number
    },
    tableGroupingKey: {
      type: Number
    },
    subqueryKey: {
      type: Number
    },
    baseTable: {
      type: String
    }
  },
  data() {
    return {
      operators: ["=", "==", "≠", ">", "≥", "<", "≤", "*", "∅"],
      texts: []
    };
  }
};
</script>
