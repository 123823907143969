<template>
  <container-og-importer
    :urlField="urlField"
    :titleField="titleField"
    :descriptionField="descriptionField"
    :imageField="imageField"
    :recordEndpoint="recordEndpoint"
    :recordId="recordId"
  >
    <div slot-scope="{ importOg, loading }">
      <v-btn small class="mt-5" @click="importOg" :loading="loading">{{
        buttonLabel
      }}</v-btn>
    </div>
  </container-og-importer>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";

export default {
  mixins: [baseComponentMixin],
  props: {
    buttonLabel: {
      type: String
    },
    urlField: {
      type: String
    },
    titleField: {
      type: String
    },
    descriptionField: {
      type: String
    },
    imageField: {
      type: String
    }
  }
};
</script>
