<template>
  <div>
    <slot
      :deleteSubquery="deleteSubquery"
      :queryLength="queryLength"
      :fieldName="fieldName"
    ></slot>
  </div>
</template>

<script>
export default {
  props: {
    orQueryKey: {
      type: Number
    },
    tableGroupingKey: {
      type: Number
    },
    subqueryKey: {
      type: Number
    }
  },
  computed: {
    query() {
      return this.$store.state.queryBuilder.query;
    },
    fieldName() {
      return this.query.orQueries[this.orQueryKey].tableGroupings[
        this.tableGroupingKey
      ].andQueries[this.subqueryKey]["field"];
    },
    queryLength() {
      return this.query.orQueries[this.orQueryKey].tableGroupings[
        this.tableGroupingKey
      ].andQueries.length;
    }
  },
  methods: {
    deleteSubquery(orQueryKey, tableGroupingKey, subqueryKey) {
      let payload = {
        orQueryIndex: orQueryKey,
        tableGroupingIndex: tableGroupingKey,
        subQueryIndex: subqueryKey
      };
      this.$store.commit("DELETE_SUBQUERY", payload);
    }
  }
};
</script>
