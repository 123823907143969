<template>
  <container-config>
    <div slot-scope="config">
      <slot
        :language="config.language"
        :color="config.color"
        :exporterIsActive="config.exporterIsActive"
      ></slot>
    </div>
  </container-config>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
