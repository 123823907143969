var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-config',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var app = ref.app;
var color = ref.color;
return (!_vm.isHidden)?_c('div',{staticClass:"mt-6",class:_vm.customClass},[_c('v-btn',{staticClass:"mt-1",attrs:{"icon":_vm.buttonType == 'icon',"tile":_vm.buttonType == 'tile',"text":_vm.buttonType == 'text',"small":"","x-small":_vm.buttonSize == 'small'},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{staticClass:"grey--text lighten-3",attrs:{"small":"","x-small":_vm.buttonSize == 'small',"left":_vm.buttonText.length > 0}},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm.buttonText)+" ")],1),_c('v-dialog',{attrs:{"width":_vm.modalWidth},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{style:({ 'background-color': app.colorBackground })},[(_vm.title)?_c('v-card-title',{staticClass:"py-3",style:({ 'background-color': color })},[(_vm.title)?_c('h4',{staticClass:"white--text font-weight-medium text-uppercase",staticStyle:{"letter-spacing":"1.25px","font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()]):_vm._e(),_c('v-card-text',{staticClass:"py-4"},_vm._l((_vm.components),function(component,key){return _c(component.componentType,_vm._b({key:key,tag:"component",on:{"closeModal":function($event){_vm.dialog = false}}},'component',Object.assign({}, component.props,
              {recordId: _vm.recordId,
              recordEndpoint: _vm.recordEndpoint,
              newRecord: _vm.newRecord}),false))}),1),_c('v-card-actions',{staticClass:"justify-end pt-1 pb-4 pr-6"},[_c('v-btn',{on:{"click":function($event){_vm.dialog = false;
              _vm.refreshOriginRecord();}}},[_vm._v("OK")])],1)],1)],1)],1):_vm._e()}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }