<template>
  <container-config>
    <div slot-scope="{ color, language }">
      <v-col cols="auto" class="mr-n6">
        <v-tooltip bottom open-on-hover open-delay="500">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon text color="#999" @click="toggleAlertsDialog">
              <v-icon>mdi-alarm</v-icon>
            </v-btn>
          </template>
          <span>{{ texts["setAlert"][language] }}</span>
        </v-tooltip>
      </v-col>
      <v-dialog v-model="alertsDialog" width="800">
        <app-record-alerts
          :title="title"
          @closeAlertsDialog="alertsDialog = !alertsDialog"
        />
      </v-dialog>
    </div>
  </container-config>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  },
  data() {
    return {
      alertsDialog: false,
      texts: {
        setAlert: {
          en: "Alerts",
          da: "Notifikationer",
          de: "Reminder"
        }
      }
    };
  },
  methods: {
    toggleAlertsDialog() {
      this.alertsDialog = !this.alertsDialog;
    }
  }
};
</script>
