<template>
  <div>
    <app-loading v-if="isLoading" />
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    configIsLoaded() {
      return this.$store.state.config.app !== null;
    },
  },
  mounted() {
    if (!this.configIsLoaded) {
      this.$store
        .dispatch("loadConfigFromServer")
        .then(() => (this.isLoading = false));
    } else {
      this.isLoading = false;
    }
  },
};
</script>
