<template>
  <container-datatable
    :recordId="recordId"
    :recordEndpoint="recordEndpoint"
    :tableEndpoint="endpoint"
    :hasActionButton="hasActionButton"
    :headers="headers"
    :filters="filters"
  >
    <div
      slot-scope="dataTable"
      :key="maxWidth"
      :style="{ 'max-width': maxWidth + 'px' }"
    >
      <h4
        class="font-weight-medium text-uppercase px-0 mt-3"
        :class="{
          'mb-n6': noHeader && smallTitle,
          'mb-2': noHeader && !smallTitle,
          'mb-3': !noHeader,
        }"
        style="letter-spacing: 1.25px; font-size: 0.875rem"
        v-if="title && !smallTitle"
      >
        {{ title }}
        <v-btn
          v-if="newSubrecordButtonByTitle && !noNewRecord"
          small
          icon
          class="mt-n1"
          color="#666"
          @click="createNewSubrecord"
        >
          <v-icon small>mdi-plus-circle-outline</v-icon>
        </v-btn>
      </h4>
      <div v-else-if="smallTitle" class="ml-3">
        <v-row>
          <v-col cols="auto" class="pl-0 pa-0">
            <base-label :label="title" />
          </v-col>
          <v-col cols="auto" class="pl-1 pa-0">
            <v-btn
              v-if="newSubrecordButtonByTitle"
              x-small
              icon
              color="#666"
              @click="createNewSubrecord"
            >
              <v-icon x-small>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-if="newRecord">
        <v-divider></v-divider>
        <!-- TO DO: make this text translatable -->
        <p class="px-1 pt-3" v-if="dataTable.language == 'en'">
          Please fill in the other fields and press "continue" to be able to
          enter data here.
        </p>
        <p class="px-1 pt-3" v-if="dataTable.language == 'de'">
          Bitte die anderen Felder ausfüllen und "weiter drücken", um hier Daten
          eingeben zu können.
        </p>
        <v-divider></v-divider>
      </div>
      <div v-else>
        <v-data-table
          hide-default-footer
          :search="search"
          :items-per-page="1000"
          :items="dataTable.items"
          :headers="dataTable.extendedHeaders"
          :loading="dataTable.loading"
          multi-sort
          :dense="dense"
          :hide-default-header="noHeader"
          :class="{
            'data-table-top-align': topAlign,
            'mt-6': noHeader && !dataTable.alternativeDesign,
            borderedTable: hasBorder,
            alternativeDataTableDesign: dataTable.alternativeDesign,
            'font-weight-bold': cellStyle.includes('bold'),
            'text-decoration-underline': cellStyle.includes('underline'),
          }"
          class="data-table-dense"
          :style="{
            'background-color': whiteTable ? 'white' : 'transparent',
            'text-decoration': cellStyle.includes('underline')
              ? 'underline'
              : 'none',
          }"
          style="margin-bottom: 7px"
        >
          <template v-slot:item="{ item, index }">
            <tr>
              <td
                v-for="(header, key) in dataTable.extendedHeaders"
                :key="'item' + index + 'header' + key"
                :style="{
                  'text-align': header.align || 'left',
                  color: header.fontColor || 'rgba(0, 0, 0, 0.87)',
                }"
                :class="{
                  'font-weight-thin': header.fontWeight == 'thin',
                  'font-weight-light': header.fontWeight == 'light',
                  'font-weight-medium': header.fontWeight == 'medium',
                  'font-weight-bold': header.fontWeight == 'bold',
                }"
              >
                <!-- action pencil to open modal -->
                <div v-if="header.value == 'action'">
                  <v-btn
                    :class="{
                      'mr-n6': hasActionButton,
                      'mr-n2': !hasActionButton,
                    }"
                    icon
                    small
                    @click="editRecord(item)"
                    v-if="!noEdit"
                  >
                    <v-icon small>{{ editIcon }}</v-icon>
                  </v-btn>
                </div>
                <!-- action button -->
                <div v-else-if="header.value == 'actionButton'">
                  <v-btn
                    class="mr-n2"
                    icon
                    small
                    @click="executeActionButtonRequest(item)"
                    :disabled="actionButtonIsLoading"
                  >
                    <span class="custom-loader" v-if="actionButtonIsLoading">
                      <v-icon>mdi-cached</v-icon>
                    </span>
                    <v-icon v-else small>{{ actionButtonIcon }}</v-icon>
                  </v-btn>
                </div>
                <!-- link -->
                <div v-else-if="header.isLink">
                  <base-record-data-table-link-item
                    :item="item"
                    :linkColor="modalColor"
                    :linkObject="getLinkObject(header)"
                  />
                </div>
                <!-- note -->
                <div v-else-if="header.type == 'note'">
                  <base-record-data-table-notes
                    :notesMaxStringLength="header.noteLength"
                    :text="item[header['value']]"
                  ></base-record-data-table-notes>
                </div>
                <!-- note -->
                <div v-else-if="header.type == 'icon'">
                  <v-icon
                    medium
                    icon
                    :color="getNthPipeValue(item[header['value']], 2)"
                    class="mr-n2"
                  >
                    {{ getNthPipeValue(item[header["value"]], 1) }}
                  </v-icon>
                </div>
                <!-- boolean -->
                <div
                  v-else-if="header.type == 'Boolean'"
                  style="text-align: center"
                >
                  <v-icon v-if="item[header['value']]"> mdi-check </v-icon>
                </div>
                <!-- boolean edit-->
                <div v-else-if="header.type == 'BooleanEdit'">
                  <base-record-data-table-checkbox
                    :recordEndpoint="endpoint"
                    :recordId="item.id"
                    :field="header.value"
                  />
                </div>
                <div v-else-if="header.type == 'Download'">
                  <base-record-data-table-download-button
                    :linkPath="header.linkPath"
                    :record="item"
                    :field="header.value"
                    :buttonText="header.text"
                  />
                </div>
                <div v-else-if="header.type == 'actionButton'">
                  <base-action-button
                    :path="header.linkPath"
                    :recordId="item.id"
                    :recordEndpoint="endpoint"
                    :originRecordId="recordId"
                    :field="header.value"
                    :label="header.text"
                    type="dataTableButton"
                  />
                </div>
                <div
                  v-else-if="
                    header.type == 'date' && displayValue(item, header) != null
                  "
                >
                  <span>{{ formatDate(displayValue(item, header)) }}</span>
                </div>
                <div v-else-if="header.type == 'time'">
                  <span>{{
                    (displayValue(item, header) || "").substring(0, 5)
                  }}</span>
                </div>
                <div v-else-if="header.type == 'urlButton'">
                  <v-btn
                    :href="displayValue(item, header)"
                    target="_blank"
                    x-small
                    tile
                  >
                    <v-icon left small class="mr-1 ml-n1">mdi-link</v-icon>
                    open URL
                  </v-btn>
                </div>
                <div v-else-if="header.type == 'url'">
                  <v-btn
                    :href="item.url"
                    target="_blank"
                    x-small
                    tile
                    v-if="item.url"
                  >
                    <v-icon left small class="mr-1 ml-n1">mdi-link</v-icon>
                    open URL
                  </v-btn>
                  <base-record-data-table-download-button
                    v-else-if="item.file_uuid"
                    :fileUuid="item.file_uuid"
                  />
                  <span v-else-if="displayValue(item, header)">
                    {{ displayValue(item, header) }}
                    <v-btn
                      icon
                      small
                      :href="cleanUrl(displayValue(item, header))"
                      target="_blank"
                      class="mt-n1"
                    >
                      <v-icon small>mdi-earth</v-icon>
                    </v-btn>
                  </span>
                  <!-- <span v-else>{{ item[header["value"]] }}</span> -->
                </div>
                <div
                  v-else
                  style="white-space: pre-wrap"
                  v-html="displayValue(item, header)"
                />
              </td>
            </tr>
          </template>
          <template slot="body.append" v-if="newSubrecordDirectEntry">
            <base-record-data-table-direct-entry
              @handleNewRecordResponse="dataTable.pushToItems"
              :originRecordId="recordId"
              :originEndpoint="recordEndpoint"
              :recordEndpoint="endpoint"
              :components="components"
            />
          </template>
          <template
            slot="body.append"
            v-if="includeSumFooter && dataTable.items.length > 0"
          >
            <tr>
              <td
                align="right"
                v-for="(header, key) in dataTable.extendedHeaders"
                :key="key"
                class="font-weight-medium"
                :style="{ color: header.fontColor || 'rgba(0, 0, 0, 0.87)' }"
              >
                <span
                  v-if="header.sumField"
                  :style="
                    header.sumStyle == 'underline'
                      ? { 'text-decoration': 'underline' }
                      : { 'text-decoration': 'none' }
                  "
                >
                  {{
                    dataTable.items
                      .map((item) => item[header.sumField])
                      .reduce((prev, curr) => prev + curr, 0)
                      .toFixed(header.sumDecimals || 0)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + separator)
                  }}
                </span>
              </td>
            </tr>
          </template>
          <template
            slot="body.append"
            v-if="includeAverageFooter && dataTable.items.length > 0"
          >
            <tr>
              <td
                align="right"
                v-for="(header, key) in dataTable.extendedHeaders"
                :key="key"
                class="font-weight-medium"
                :style="{ color: header.fontColor || 'rgba(0, 0, 0, 0.87)' }"
              >
                <span
                  v-if="header.sumField"
                  :style="
                    header.sumStyle == 'underline'
                      ? { 'text-decoration': 'underline' }
                      : { 'text-decoration': 'none' }
                  "
                >
                  Ø
                  {{ getAverage(dataTable, header) }}
                  <!-- {{
                    dataTable.items
                      .map((item) => {
                        return getSumFieldFromItem(item, header.sumField);
                      })
                      .reduce((prev, curr) => prev + curr, 0)
                      .toFixed(header.sumDecimals || 0)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + separator)
                  }} -->
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-btn
          v-if="!newSubrecordButtonByTitle && !noNewRecord"
          small
          text
          class="my-2 pl-2"
          color="#666"
          @click="createNewSubrecord"
        >
          <v-icon left small>mdi-plus-circle-outline</v-icon
          >{{ newSubrecordButtonText }}
        </v-btn>
        <v-dialog
          :max-width="maxDialogWidth"
          v-model="dialog"
          :fullscreen="$vuetify.breakpoint.xsOnly"
          :hide-overlay="$vuetify.breakpoint.xsOnly"
        >
          <base-record-modal
            v-if="selectedRecord"
            :components="components"
            :preloadedRecord="selectedRecord"
            :key="modalKey"
            :pathArray="pathArray"
            :endpoint="
              manyToMany
                ? manyToManyEndpoint
                : dialogEndpoint
                ? dialogEndpoint
                : endpoint
            "
            :newRecord="newSubrecord"
            :noDelete="noDelete"
            :originEndpoint="recordEndpoint"
            :originRecordId="recordId"
            :manyToMany="manyToMany"
            :modalTitleText="modalTitleText"
            :modalTitleField="modalTitleField"
            :foreignKeyField="foreignKeyField"
            :joinEndpoint="manyToMany ? endpoint : null"
            :modalColor="modalColor"
            :suppressCloseOnEnterProp="suppressCloseOnEnter"
            :keepModalOpenOnNewRecordCommit="keepModalOpenOnNewRecordCommit"
            @closeDialog="closeDialog"
            @handleNewRecordResponse="handleNewRecordResponse"
          />
        </v-dialog>
      </div>
    </div>
  </container-datatable>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseSubrecordsComponentMixin } from "../mixins/baseSubrecordsComponentMixin";
import axios from "axios";
import Inflector from "inflector-js";
import { get } from "lodash";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

export default {
  mixins: [baseComponentMixin, baseSubrecordsComponentMixin],
  props: {
    headers: {
      type: Array,
    },
    filters: {
      type: Array,
    },
    defaultValues: {
      type: Array,
    },
    toolbar: {
      type: Boolean,
      default: false,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
    includeSumFooter: {
      type: Boolean,
      default: false,
    },
    includeAverageFooter: {
      type: Boolean,
      default: false,
    },
    noNewRecord: {
      type: Boolean,
      default: false,
    },
    noDelete: {
      type: Boolean,
      default: false,
    },
    editIcon: {
      type: String,
      default: "mdi-pencil",
    },
    marginTop: {
      type: String,
      default: "0",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 2000,
    },
    maxDialogWidth: {
      type: Number,
      default: 750,
    },
    newSubrecordButtonText: {
      type: String,
      default: "new entry",
    },
    newSubrecordButtonByTitle: {
      type: Boolean,
      default: false,
    },
    newSubrecordDirectEntry: {
      type: Boolean,
      default: false,
    },
    modalTitleText: {
      type: String,
    },
    modalTitleField: {
      type: String,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    whiteTable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    smallTitle: {
      type: Boolean,
    },
    topAlign: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    cellStyle: {
      type: String,
      default: "",
    },
    hasActionButton: {
      type: Boolean,
      default: false,
    },
    actionButtonIcon: {
      type: String,
      default: "mdi-pencil",
    },
    actionButtonPath: {
      type: String,
    },
    actionButtonField: {
      type: String,
    },
    actionButtonRefreshRecordAfterResponse: {
      type: Boolean,
    },
    actionButtonConfirmationDialog: {
      type: String,
    },
    suppressCloseOnEnter: {
      type: Boolean,
    },
  },
  data() {
    return {
      search: null,
      date: Date(),
      actionButtonIsLoading: false,
      notesMaxStringLength: 40,
    };
  },
  computed: {
    separator() {
      return "'";
    },
  },
  methods: {
    getAverage(datatable, header) {
      // return field;
      let sum = datatable.items
        .map((item) => get(item, header.sumField))
        .reduce((prev, curr) => prev + curr, 0);
      let count = datatable.items.length;
      let average = sum / count;
      return average
        .toFixed(header.sumDecimals || 0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + this.separator);
    },
    formatDate(date) {
      let parsedDate = parseISO(date);
      return format(parsedDate, "dd.MM.Y");
    },
    getLinkObject(header) {
      let newObject = {};
      newObject["fieldName"] = header.value;
      newObject["fieldNameFallback"] = header.fallback;
      let fieldNameArray = header.value.split(".");
      if (header.linkPath) {
        var linkPath = header.linkPath;
      } else {
        let linkEndpoint =
          fieldNameArray.length > 1
            ? Inflector.pluralize(fieldNameArray[fieldNameArray.length - 2])
            : this.endpoint;
        newObject["linkEndpoint"] = linkEndpoint;
        let moduleConfig = this.$store.state.config.app.modules.find(
          (e) => e.basicConfig.apiEndpoint == linkEndpoint
        );
        linkPath = moduleConfig.basicConfig.path;
      }
      newObject["linkPath"] = linkPath;
      let idFieldNameArray = JSON.parse(JSON.stringify(fieldNameArray));
      idFieldNameArray[idFieldNameArray.length - 1] = "id";
      newObject["idFieldName"] = idFieldNameArray.join(".");
      return newObject;
    },
    getNthPipeValue(item, n) {
      return item.split("|")[n - 1];
    },
    displayValue(item, header) {
      let fieldName = header.value;
      let fieldNameFallback = header.fallback;
      let linkValue = get(item, fieldName);
      let fallbackValue = get(item, fieldNameFallback);
      if (linkValue) {
        return linkValue;
      } else {
        return fallbackValue;
      }
    },
    cleanUrl(link) {
      return "https://" + link.replace("https://", "").replace("http://", "");
    },
    executeActionButtonRequest(item) {
      if (this.actionButtonConfirmationDialog) {
        let confirmed = confirm(this.actionButtonConfirmationDialog);
        if (!confirmed) {
          return;
        }
      }
      this.actionButtonIsLoading = true;
      let field = item[this.actionButtonField];
      let path = this.actionButtonPath
        .replace("{recordId}", this.recordId)
        .replace("{field}", field);
      axios.post(path).then(() => {
        this.refreshOriginRecord();
        this.refreshPage();
        this.actionButtonIsLoading = false;
        this.$emit("closeModal");
      });
    },
    refreshOriginRecord() {
      let payload = {
        recordId: this.recordId,
        endpoint: this.recordEndpoint,
      };
      this.$store.dispatch("refreshOriginRecord", payload);
    },
    refreshPage() {
      let payload = {};
      this.$store.dispatch("refreshPage", payload);
    },
  },
};
</script>

<style>
.data-table-search .v-text-field__details {
  display: none !important;
}
.filter .v-text-field__details,
.filter .v-messages {
  display: none;
}
.data-table-top-align td {
  vertical-align: top !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  white-space: pre-wrap !important;
}
.data-table td,
.data-table-dense td,
.data-table th,
.data-table-dense th {
  padding-left: 9px !important;
}
.data-table td {
  padding-top: 8px;
  padding-bottom: 8px;
}
.data-table th,
.data-table-dense th {
  padding-top: 12px;
  padding-bottom: 3px;
}
.data-table-dense td {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.borderedTable {
  border: 0.25px #aaa solid;
}
.alternativeDataTableDesign th {
  font-weight: normal;
  padding-top: 3px;
  height: 34px;
}
.alternativeDataTableDesign td {
  height: 34px;
}
</style>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
