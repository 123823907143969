var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-config',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var color = ref.color;
var language = ref.language;
var app = ref.app;
return _c('div',{},[_c('container-input-field',{attrs:{"recordId":_vm.recordId,"recordEndpoint":_vm.recordEndpoint,"field":_vm.field,"displayField":_vm.displayFieldConverted,"newRecord":_vm.newRecord,"modalColor":_vm.modalColor,"storeNewRecordImmediately":_vm.storeNewRecordImmediately},on:{"storeNewRecordImmediately":function($event){return _vm.$emit('storeRecord')}},scopedSlots:_vm._u([{key:"default",fn:function(field){return (!_vm.isHidden)?_c('div',{staticClass:"mt-0 mb-2"},[_c('div',{staticClass:"body-1 black--text pb-0",class:{
            standardSearchOrCreateFieldDesign: !field.alternativeDesign,
          }},[_c('base-label',{attrs:{"label":_vm.label}}),(_vm.temporaryDisplay == null && (_vm.newRecord || !field.value))?_c('div',[_c('v-row',{staticClass:"pt-1",class:{
                alternativeSearchOrCreateFieldDesign: field.alternativeDesign,
                readOnly: _vm.readOnly,
              }},[_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"auto"}},[(!_vm.readOnly)?_c('div',{staticClass:"body-2",staticStyle:{"color":"#666","padding-top":"2px","cursor":"pointer"},on:{"click":_vm.openDialog}},[_c('v-icon',{staticClass:"ml-0 mr-0",attrs:{"small":"","left":""}},[_vm._v("mdi-link")]),_vm._v(" "+_vm._s(_vm.label)+" ")],1):_vm._e()])],1)],1):_c('div',[_c('v-row',{class:{
                alternativeSearchOrCreateFieldDesign: field.alternativeDesign,
                readOnly: _vm.readOnly,
              },attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"py-0 pl-2 pr-0",attrs:{"cols":"10"}},[(_vm.displayAsLink)?_c('router-link',{staticStyle:{"cursor":"pointer","text-decoration":"underline","height":"22px !important","display":"block","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"},style:({
                    color: app.colorPrimary,
                  }),attrs:{"to":'/' +
                    (_vm.linkPath || _vm.endpoint) +
                    '/' +
                    field.storedValue +
                    '?link=true'}},[_vm._v(" "+_vm._s(field.value || _vm.temporaryDisplay)+" ")]):_c('div',{staticStyle:{"overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(field.value || _vm.temporaryDisplay)+" ")])],1),(!_vm.readOnly)?_c('v-col',{staticClass:"py-0 pr-1 px-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"right":"","icon":"","small":""},on:{"click":_vm.openDialog}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"700"},model:{value:(_vm.searchOrCreateDialog),callback:function ($$v) {_vm.searchOrCreateDialog=$$v},expression:"searchOrCreateDialog"}},[(!_vm.newEntry)?_c('v-card',{staticStyle:{"min-height":"650px"}},[_c('v-card-text',{staticClass:"px-6 pt-6 pb-0"},[_c('v-text-field',{staticClass:"mb-2",attrs:{"solo":"","placeholder":'search ' + _vm.label,"prepend-inner-icon":"mdi-magnify","color":field.color,"hide-details":"auto"},on:{"input":_vm.loadResults},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('v-data-table',{attrs:{"headers":_vm.extendedHeaders,"items":_vm.results},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){field.updateValue(item.id);
                      _vm.searchOrCreateDialog = false;
                      _vm.temporaryDisplay = item[_vm.displayField];
                      _vm.refreshRecord();}}},[_vm._v(" select ")])]}}],null,true)})],1),_c('v-card-actions',{staticClass:"pb-6 px-6"},[_c('v-btn',{staticClass:"font-weight-medium ml-2",attrs:{"disabled":_vm.searchTerm == null,"tile":""},on:{"click":_vm.enterNewRecord}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.newRecordLabel || _vm.texts["newRecord"][language]))],1),_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-medium mr-2",attrs:{"tile":""},on:{"click":function($event){_vm.searchOrCreateDialog = false}}},[_vm._v(_vm._s(_vm.texts["cancel"][language]))])],1)],1):_vm._e(),(_vm.newEntry)?_c('div',[_c('container-record',{key:_vm.modalKey,attrs:{"endpoint":_vm.endpoint,"recordId":"new","newRecord":true,"suppressAutoForeignKey":true,"originEndpoint":_vm.recordEndpoint,"preloadedRecordData":_vm.selectedRecord},on:{"handleNewRecordResponse":function($event){field.updateValue($event.id);
                _vm.newRecordButtonLoading = false;
                _vm.searchOrCreateDialog = false;
                _vm.newEntry = false;
                _vm.temporaryDisplay = $event[_vm.displayField];
                _vm.refreshRecord();}},scopedSlots:_vm._u([{key:"default",fn:function(record){return (record.record)?_c('v-card',{staticStyle:{"min-height":"450px","background-color":"#efefef"}},[_c('v-card-title',{staticStyle:{"margin":"0px"},style:({ 'background-color': _vm.modalColor || color })},[_c('span',{staticClass:"white--text title font-weight-regular"},[_vm._v(_vm._s(( _vm.newRecordLabel || _vm.texts["newRecord"][language] ).toUpperCase()))])]),_c('v-card-text',{staticClass:"pa-6"},_vm._l((_vm.components),function(component,key){return _c(component.componentType,_vm._b({key:key,tag:"component"},'component',Object.assign({}, component.props,
                      {recordId: 'new',
                      recordEndpoint: _vm.endpoint,
                      newRecord: true,
                      modalColor: _vm.modalColor,
                      pathArray: _vm.pathArray.concat( ['props'], ['components'], [key])}),false))}),1),_c('v-card-actions',{staticClass:"pa-6",staticStyle:{"background-color":"#efefef"}},[_c('v-btn',{staticClass:"font-weight-medium mr-2",attrs:{"tile":""},on:{"click":function($event){_vm.searchOrCreateDialog = false;
                      _vm.newEntry = false;}}},[_vm._v(_vm._s(_vm.texts["cancel"][language]))]),_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-medium mr-2",attrs:{"color":_vm.modalColor,"dark":"","tile":"","loading":_vm.newRecordButtonLoading},on:{"click":function($event){_vm.newRecordButtonLoading = true;
                      record.saveNewRecord();}}},[_vm._v("OK")])],1)],1):_vm._e()}}],null,true)})],1):_vm._e()],1)],1):_vm._e()}}],null,true)})],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }