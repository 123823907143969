<template>
  <container-input-field
    :recordId="recordId"
    :recordEndpoint="recordEndpoint"
    :field="field"
    :newRecord="newRecord"
    :modalColor="modalColor"
  >
    <div slot-scope="field">
      <v-text-field
        :class="{
          alternativeTextFieldDesign: field.alternativeDesign,
          'body-2': field.alternativeDesign
        }"
        class="directEntryTextField"
        elevation="0"
        :label="label"
        ref="textInput"
        :placeholder="'+ ' + label"
        :value="field.value"
        @change="
          setValue($event);
          field.updateValue(value);
        "
        :rules="rules"
        hide-details="auto"
        autocomplete="disabled"
        :solo="field.alternativeDesign"
        :dense="field.alternativeDesign"
        :flat="field.alternativeDesign"
      ></v-text-field>
    </div>
  </container-input-field>
</template>
<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    columns: {
      type: Number,
      default: 12
    },
    originEndpoint: {
      type: String
    },
    originRecordId: {
      type: [String, Number]
    },
    validationRules: {
      type: Array,
      default: () => []
    },
    accessorRegEx: {
      type: String
    }
  },
  data() {
    return {
      value: null,
      overlay: false,
      rulesCollection: {
        required: value => !!value || "Required value!",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail address!";
        },
        numericOnly: value => value > 0 || "The entered value must be numeric!"
      }
    };
  },
  computed: {
    rules() {
      let array = [];
      this.validationRules.forEach(item => {
        array = [...array, this.rulesCollection[item]];
      });
      return array;
    }
  },
  methods: {
    setValue(value) {
      if (this.accessorRegEx) {
        this.value = value.match(this.accessorRegEx)[0];
      } else {
        this.value = value;
      }
    },
    ignore() {
      return null;
    },
    focusField() {
      this.$refs.textInput.$refs.input.focus();
      // alert("selectThisField: ");
    }
  }
};
</script>
<style>
.directEntryTextField .v-input__slot {
  border-right: 0.25px solid #aaa;
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-radius: 0px;
  padding-left: 8px !important;
  border-bottom-left-radius: 5px !important;
}
.alternativeTextFieldDesign .v-input__control {
  min-height: 34px !important;
}
.alternativeTextFieldDesign .v-icon {
  margin-top: 0px !important;
  font-size: 18px !important;
  color: #666 !important;
}
</style>
