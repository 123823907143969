<template>
  <div>
    <container-input-field
      :recordEndpoint="recordEndpoint"
      :recordId="recordId"
      :field="field"
      :newRecord="newRecord"
      :modalColor="modalColor"
      :reloadRecordOnChange="reloadRecordOnChange"
      :storeNewRecordImmediately="storeNewRecordImmediately"
      @storeNewRecordImmediately="$emit('storeRecord')"
    >
      <div slot-scope="field">
        <v-row class="mb-n1" v-if="field.alternativeDesign">
          <v-col class="ml-3 px-0 py-0">
            <base-label :label="label" />
          </v-col>
        </v-row>
        <v-row class="px-0 py-0">
          <v-col class="d-flex pt-1 pb-2" :cols="columns">
            <v-select
              :class="{
                alternativeSelectFieldDesign: field.alternativeDesign,
                'body-2': field.alternativeDesign,
              }"
              :label="label"
              :placeholder="field.alternativeDesign ? ' ' : label"
              :value="String(field.value)"
              @change="field.updateValue"
              :color="field.color"
              :items="items"
              :solo="field.alternativeDesign"
              :dense="field.alternativeDesign"
              :flat="field.alternativeDesign"
              autocomplete="disabled"
              clearable
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </container-input-field>
  </div>
</template>
<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    items: {
      type: Array,
    },
    columns: {
      type: Number,
      default: 12,
    },
    reloadRecordOnChange: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
.alternativeSelectFieldDesign .v-input__slot {
  border: 0.25px solid #aaa;
  padding-left: 8px !important;
}
.alternativeSelectFieldDesign .v-input__control {
  min-height: 34px !important;
}
.alternativeSelectFieldDesign .v-icon {
  margin-top: 0px !important;
  font-size: 18px !important;
  color: #666 !important;
}
.alternativeSelectFieldDesign .mdi-close,
.alternativeSelectFieldDesign .mdi-menu-down {
  margin-top: 0px !important;
  margin-right: -10px;
  font-size: 14px !important;
  color: #aaa !important;
}
.alternativeSelectFieldDesign .mdi-close {
  margin-right: -30px;
}
.alternativeSelectFieldDesign .v-select__selections {
  margin-right: -30px;
}
</style>
