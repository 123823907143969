<template>
  <div class="mb-0 mt-0">
    <container-tagger
      :recordId="recordId"
      :recordEndpoint="recordEndpoint"
      :endpointValue="endpointValue"
      :endpointJoin="endpointJoin"
      :endpointSort="endpointSort"
    >
      <div slot-scope="scope">
        <base-label class="ml-0" :label="label" v-if="label" />
        <base-input-checkbox-group-items
          :items="scope.selectValues"
          :values="scope.values"
          :color="scope.color"
          :foreignKeyField="foreignKeyField"
          :itemsGroupFieldName="itemsGroupFieldName"
          :chunkSize="chunkSize"
          @add="scope.enterNewValue"
          @delete="scope.deleteEntry"
        />
      </div>
    </container-tagger>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";
import Inflector from "inflector-js";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    endpointJoin: {
      type: String,
    },
    endpointValue: {
      type: String,
    },
    endpointSort: {
      type: String,
    },
    itemsGroupFieldName: {
      type: String,
      default: "group",
    },
    chunkSize: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    foreignKeyField() {
      return Inflector.singularize(this.endpointValue) + "_id";
    },
  },
};
</script>

<style lang="scss" scoped></style>
