<template>
  <container-config>
    <div slot-scope="config">
      <v-row>
        <v-col cols="auto" class="pl-md-10 pr-0">
          <v-icon
            left
            large
            :color="config.color"
            class="ml-0"
            style="margin-top: -3px"
          >
            mdi-account
          </v-icon>
        </v-col>
        <v-col cols="9" class="px-0 mt-0">
          <h1
            class="headline font-weight-light uppercase"
            style="
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
          >
            {{ user.name }}
          </h1>
        </v-col>
      </v-row>
    </div>
  </container-config>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
    },
  },
};
</script>
