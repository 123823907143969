<template>
  <container-query-builder-subquery-field-selector
    :orQueryKey="orQueryKey"
    :tableGroupingKey="tableGroupingKey"
    :subqueryKey="subqueryKey"
  >
    <div
      slot-scope="{
        fields,
        fieldContent,
        updateFieldContent,
        language,
        queryLength
      }"
    >
      <v-autocomplete
        dense
        class="mb-0 py-0"
        label="Feld"
        autocomplete="disabled"
        :items="fields"
        item-text="text"
        item-value="value"
        :value="fieldContent"
        @input="updateFieldContent"
        solo
        :messages="subqueryKey != queryLength - 1 ? texts['AND'][language] : ''"
      ></v-autocomplete>
    </div>
  </container-query-builder-subquery-field-selector>
</template>

<script>
export default {
  data() {
    return {
      texts: {
        AND: {
          en: "AND",
          da: "OG",
          de: "UND"
        }
      }
    };
  },
  props: {
    orQueryKey: {
      type: Number
    },
    tableGroupingKey: {
      type: Number
    },
    subqueryKey: {
      type: Number
    },
    baseTable: {
      type: String
    }
  }
};
</script>
