<template>
  <div>
    <config-section-title v-if="sectionTitle" :title="sectionTitle" />
    <v-row class="mb-n1">
      <v-col class="ml-3 px-0 py-0">
        <base-label :label="label" />
      </v-col>
    </v-row>
    <v-row class="px-0 py-0">
      <v-col class="d-flex pt-1">
        <v-text-field
          :label="labelConverted"
          :value="fieldValue"
          @change="updateValue"
          :append-icon="appendedIcon"
          hide-details
          dense
          solo
          flat
          class="alternativeTextFieldDesign"
          :style="{ 'max-width': fullWidth ? '100%' : '400px' }"
          :placeholder="labelConverted"
        >
          <template v-slot:append-outer v-if="documentation">
            <base-documentation :documentation="documentation" />
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get, startCase } from "lodash";

export default {
  props: {
    getArray: {
      type: Array,
    },
    field: {
      type: String,
    },
    label: {
      type: String,
    },
    selfIcon: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    documentation: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    sectionTitle: {
      type: String,
    },
  },
  data() {
    return {
      mock: null,
    };
  },
  computed: {
    fieldValue() {
      return get(this.$store.state, [...this.getArray, this.field]);
    },
    appendedIcon() {
      return this.selfIcon ? this.fieldValue : "";
    },
    labelConverted() {
      return this.label || startCase(this.field) + (this.required ? " *" : "");
    },
  },
  methods: {
    updateValue(input) {
      this.$store.dispatch("updateConfigValue", {
        input,
        field: this.field,
        getArray: this.getArray,
      });
    },
  },
};
</script>
