<template>
  <div>
    <container-input-field
      :recordEndpoint="recordEndpoint"
      :recordId="recordId"
      :field="field"
      :newRecord="newRecord"
      :modalColor="modalColor"
      :reloadRecordOnChange="reloadRecordOnChange"
      :storeNewRecordImmediately="storeNewRecordImmediately"
      @storeNewRecordImmediately="$emit('storeRecord')"
    >
      <div slot-scope="field" v-if="!isHidden">
        <base-label v-if="label" :label="label" />
        <div v-else style="height: 24px"></div>
        <v-radio-group
          :value="field.value"
          @change="field.updateValue"
          :row="row"
          style="margin-top: 4px; margin-bottom: 4px"
          hide-details
        >
          <v-radio
            v-for="item in items"
            :key="item.value"
            :label="item.label"
            :value="convertValue(item.value)"
            class="mb-2"
            color="grey darken-2"
          >
            <template v-slot:label>
              <span class="body-2 ml-n1">{{ item.label }}</span>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </container-input-field>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    items: {
      type: Array,
    },
    row: {
      type: Boolean,
      default: false,
    },
    fieldIsInteger: {
      type: Boolean,
      default: false,
    },
    reloadRecordOnChange: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    convertValue(value) {
      if (this.fieldIsInteger) {
        return parseInt(value);
      } else {
        return value;
      }
    },
  },
};
</script>
