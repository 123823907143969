<template>
  <div>
    <v-card>
      <v-card-title style="background-color: #333; color: white" class="px-6"
        >Template hochladen</v-card-title
      >
      <v-card-text class="px-6 pt-3">
        <div class="font-weight-medium text-uppercase mt-3">Datei</div>
        <base-input-file-uploader
          :newRecord="true"
          @input="handleInput"
          :key="fileInputKey"
        ></base-input-file-uploader>
        <div class="font-weight-medium text-uppercase mt-6 mb-n2">
          Template-Typ
        </div>
        <v-radio-group v-model="type" row>
          <v-radio
            v-for="(item, n) in items"
            :key="n"
            :label="item.label"
            :value="item.value"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions class="px-6 pb-6 mt-n3">
        <v-btn @click="closeDialog">abbrechen</v-btn>
        <v-spacer />
        <v-btn :disabled="buttonDisabled" @click="saveTemplate">OK</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      uuid: null,
      type: null,
      fileInputKey: Date(),
      items: [
        { label: "einzelner Datensatz", value: "single" },
        { label: "alle aufgerufenen Datensätze", value: "multiple" },
      ],
    };
  },
  computed: {
    buttonDisabled() {
      return this.uuid == null || this.type == null;
    },
    endpoint() {
      return this.$store.getters.moduleEndpoint;
    },
  },
  methods: {
    handleInput(uuid) {
      this.uuid = uuid;
    },
    saveTemplate() {
      let user_id = this.$store.state.auth.user.id;
      let file_uuid = this.uuid;
      let type = this.type;
      let endpoint = this.endpoint;
      let payload = { user_id, file_uuid, type, endpoint };
      axios.post("document-templates", payload).then(() => {
        this.emptyFields();
        this.closeDialog();
      });
    },
    closeDialog() {
      this.$emit("refreshTemplateList");
      this.$emit("closeDialog");
    },
    emptyFields() {
      this.file_uuid = null;
      this.type = null;
      this.fileInputKey = Date();
    },
  },
};
</script>

<style lang="scss" scoped></style>
