<template>
  <div>
    <app-subcomponent-configurator
      v-for="(component, key) in components"
      :key="key"
      :pathArray="[...pathArray, key]"
      v-bind="{
        component,
        recordId,
        recordEndpoint,
        newRecord,
      }"
    >
      <component
        v-bind:is="component.componentType"
        v-bind="{
          ...component.props,
          recordId,
          recordEndpoint,
          storeNewRecordImmediately,
          newRecord,
          component,
        }"
        :pathArray="[...pathArray, key]"
        @closeModal="$emit('closeModal')"
        @storeRecord="$emit('storeRecord')"
      ></component>
    </app-subcomponent-configurator>
    <v-row v-if="configMode">
      <v-col class="pt-1 pb-2">
        <v-btn
          x-small
          icon
          @click="dialog = true"
          class="small-icon"
          style="background-color: #aaa"
        >
          <v-icon x-small color="white" class="small-icon">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
export default {
  mixins: [baseComponentMixin],
  props: {
    components: {
      type: Array,
    },
  },
  computed: {
    configMode() {
      return this.$store.state.uiState.configMode;
    },
    dateKey() {
      let endpoint = this.$store.getters.moduleEndpoint;
      return this.$store.state[endpoint]["currentRecordTimestamp"];
    },
  },
};
</script>
