<template>
  <div class="mt-3 mb-n3">
    <v-alert dense text type="error" icon="mdi-pencil-outline">{{
      text
    }}</v-alert>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped></style>
