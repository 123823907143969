<template>
  <div>
    <v-menu
      v-model="menuDatepicker"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-row>
          <v-col class="ml-3 px-0 py-0">
            <base-label :label="label" />
          </v-col>
        </v-row>
        <v-text-field
          class="alternativeDatepickerFieldDesign body-2 pb-2"
          :value="value"
          @input="field.updateValue"
          :color="field.color"
          :solo="field.alternativeDesign"
          :dense="field.alternativeDesign"
          :flat="field.alternativeDesign"
          append-icon="mdi-calendar"
          readonly
          hide-details
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        :color="field.color"
        :value="field.value"
        @change="field.updateValue"
        @input="menuDatepicker = false"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
// eslint-disable-next-line
import { da } from "date-fns/locale";
// eslint-disable-next-line
import { de } from "date-fns/locale";
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      menuDatepicker: false,
    };
  },
  props: {
    dateFormattingString: {
      type: String,
      default: "eee, d MMMM y",
    },
  },
  computed: {
    language() {
      return this.$store.state.config.app.basicConfig.appLanguage;
    },
  },
  methods: {
    formatDate(input) {
      if (input) {
        let parsedDate = parseISO(input);
        let languageObject =
          this.language == "de" ? de : this.language == "da" ? da : null;
        return format(parsedDate, this.dateFormattingString, {
          locale: languageObject,
        });
      } else {
        return null;
      }
    },
  },
};
</script>
<style>
.alternativeDatepickerFieldDesign .v-icon {
  margin-top: 0px !important;
  margin-right: -12px;
  font-size: 18px !important;
  color: #888 !important;
}
.alternativeDatepickerFieldDesign .v-input__slot {
  border: 0.25px solid #aaa;
  padding-left: 8px !important;
}
.alternativeDatepickerFieldDesign .v-input__control {
  min-height: 34px !important;
}
</style>
