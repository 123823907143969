var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-query-builder-stored-queries',{on:{"switchTabs":_vm.switchTabs},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var color = ref.color;
    var language = ref.language;
    var queryFilter = ref.queryFilter;
    var updateQueryFilter = ref.updateQueryFilter;
    var myQueriesOnly = ref.myQueriesOnly;
    var toggleMyQueriesOnly = ref.toggleMyQueriesOnly;
    var queries = ref.queries;
    var filteredQueries = ref.filteredQueries;
    var deletionDialog = ref.deletionDialog;
    var toggleDeletionDialog = ref.toggleDeletionDialog;
    var runQuery = ref.runQuery;
    var editQuery = ref.editQuery;
    var deleteQuery = ref.deleteQuery;
    var executeDeletion = ref.executeDeletion;
    var user = ref.user;
return _c('div',{},[_c('v-card-text',{staticClass:"pt-3 white",staticStyle:{"height":"83vh","overflow":"scroll"}},[_c('v-row',{staticClass:"pl-3 pr-5 py-0 mb-1"},[_c('v-col',{staticClass:"py-0 mb-0",attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"queryFilter",attrs:{"prepend-inner-icon":"mdi-magnify","clearable":"","dense":"","width":"300","value":queryFilter,"autocomplete":"disabled","color":color},on:{"input":updateQueryFilter}})],1),_c('v-spacer'),_c('v-col',{staticClass:"py-0 mb-0",attrs:{"cols":"auto"}},[_c('v-switch',{staticClass:"mt-1 mb-0 queryFilter",attrs:{"value":myQueriesOnly,"label":_vm.texts['myQueriesOnly'][language],"color":color},on:{"input":toggleMyQueriesOnly}})],1)],1),_c('v-divider'),(queries)?_c('v-data-table',{attrs:{"hide-default-footer":"","disable-pagination":"","items":filteredQueries,"headers":_vm.headers,"sort-by":"name","dense":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',{staticClass:"pa-0 mr-9 ml-3",attrs:{"icon":"","color":color}},[_c('v-icon',_vm._g({attrs:{"color":color},on:{"click":function($event){return runQuery(item.id)}}},on),[_vm._v("mdi-play")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.texts["performQuery"][language]))])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return editQuery(item.id)}}},on),[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.texts["editQuery"][language]))])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(item.user_id == user.id || user.is_admin === 1)?_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return deleteQuery(item.id)}}},[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.texts["deleteQuery"][language]))])])]}}],null,true)}):_vm._e()],1),_c('v-dialog',{attrs:{"value":deletionDialog,"width":"500"}},[_c('v-card',[_c('v-card-title',[_c('p',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm.texts["confirmDeleteQuery"][language])+" ")])]),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey lighten-1"},on:{"click":toggleDeletionDialog}},[_vm._v(" "+_vm._s(_vm.texts["cancel"][language])+" ")]),_c('v-btn',{attrs:{"dark":"","color":color},on:{"click":executeDeletion}},[_vm._v(" "+_vm._s(_vm.texts["yes"][language])+" ")])],1)],1)],1)],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }