var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-query-builder-query',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var language = ref.language;
    var query = ref.query;
    var moduleName = ref.moduleName;
    var deleteQuery = ref.deleteQuery;
    var addNewTableGrouping = ref.addNewTableGrouping;
    var color = ref.color;
return _c('div',{},[_c('v-card',{staticClass:"mb-3",attrs:{"color":"#eee"}},[_c('v-card-title',{staticClass:"py-2",style:({ 'background-color': color }),attrs:{"color":color}},[_c('span',{staticClass:"subtitle-1 font-weight-medium pl-1",staticStyle:{"color":"#fff !important"}},[_vm._v(_vm._s(_vm.texts["query"][language])+" "+_vm._s(_vm.orQueryKey + 1)+":")]),_c('v-spacer'),(query.length > 1)?_c('v-btn',{staticClass:"mr-n2 mt-n1",attrs:{"icon":""},on:{"click":function($event){return deleteQuery(_vm.orQueryKey)}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1):_vm._e()],1),_c('div',{staticClass:"subtitle-1 font-weight-medium pl-5 pt-3",staticStyle:{"text-color":"black !important"}},[_vm._v(" "+_vm._s(_vm.texts["theQueried"][language])+" "+_vm._s(_vm.titleCase(moduleName))+"... ")]),_c('v-card-text',[_vm._l((query.orQueries[_vm.orQueryKey]
            .tableGroupings),function(tableGrouping,key){return _c('div',{key:key},[_c('app-query-builder-table-grouping',{key:key,attrs:{"orQueryKey":_vm.orQueryKey}}),(
              key != query.orQueries[_vm.orQueryKey].tableGroupings.length - 1
            )?_c('p',{staticClass:"my-3 ml-3 subtitle-1 font-weight-medium",staticStyle:{"color":"black !important"}},[_vm._v(" "+_vm._s(_vm.texts["AND"][language])+" ")]):_vm._e()],1)}),_c('v-btn',{staticClass:"px-1 my-1",attrs:{"text":"","small":""},on:{"click":function($event){return addNewTableGrouping(_vm.orQueryKey)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.texts["newConstraint"][language]))])],1)],2)],1)],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }