<template>
  <container-uiState>
    <div slot-scope="uiState">
      <container-config>
        <div
          slot-scope="config"
          v-if="config.module.tabs.length > 1 || configMode"
          :style="{
            height: config.design.recordTabsHeight + 'px',
          }"
          class="px-md-4"
        >
          <v-tabs
            :color="config.color"
            background-color="transparent"
            class="align-end"
            :value="uiState.tab"
            @change="uiState.changeTab"
            show-arrows
          >
            <v-tab
              v-for="(tab, key) in filteredTabs(config.module.tabs)"
              :key="key + 1"
              :disabled="$route.params.record_id == 'new' || tab.disabled"
            >
              {{ tab.name }}
            </v-tab>
            <v-tab v-if="configMode" @click.prevent="addNewTab">
              <v-icon>mdi-plus</v-icon>
            </v-tab>
          </v-tabs>
        </div>
      </container-config>
    </div>
  </container-uiState>
</template>

<script>
export default {
  props: {
    record: {
      type: Object,
    },
  },
  computed: {
    configMode() {
      return this.$store.state.uiState.configMode;
    },
  },
  methods: {
    filteredTabs(tabs) {
      return tabs.filter((tab) => {
        return !this.hideTab(tab.hideIf || null);
      });
      // return tabs.map(tab=>!this.hideTab(tab.hideIf));
    },
    hideTab(hideIfConditions) {
      let record = this.record;
      if (
        !hideIfConditions ||
        !hideIfConditions.length > 0 ||
        this.configMode
      ) {
        return false;
      }
      let checkArray = [];
      for (let i = 0; i < hideIfConditions.length; i++) {
        let condition = hideIfConditions[i];
        if (
          (record[condition.field] != undefined &&
            //match for "==" operator or no defined operator (equivalent to "==")
            (((condition.operator == "==" || condition.operator == undefined) &&
              record[condition.field] == condition.value) ||
              //match for ">=" operator
              (condition.operator == ">=" &&
                record[condition.field] >= condition.value) ||
              //match for ">" operator
              (condition.operator == ">" &&
                record[condition.field] > condition.value) ||
              //match for "<=" operator
              (condition.operator == "<=" &&
                record[condition.field] <= condition.value) ||
              //match for "<" operator
              (condition.operator == "<" &&
                record[condition.field] < condition.value) ||
              //match for "contains" operator
              (condition.operator == "contains" &&
                record[condition.field].includes(condition.value) > 0) ||
              //match for "!contains" operator
              (condition.operator == "!contains" &&
                record[condition.field].includes(condition.value) == 0) ||
              //match for "!=" operator
              (condition.operator == "!=" &&
                record[condition.field] != condition.value))) ||
          //match for unequal on undefined field
          (record[condition.field] == undefined && condition.operator == "!=")
        ) {
          checkArray.push(true);
        } else {
          checkArray.push(false);
        }
      }
      return checkArray.some((v) => v === true);
    },
    addNewTab() {
      alert("here");
    },
  },
};
</script>
