<template>
  <div>
    <v-tabs color="#444" v-model="tab" show-arrows>
      <v-tab>Stammdaten</v-tab>
      <v-tab v-for="(relatedTable, key) in relatedFields" :key="key">
        {{ key }}
      </v-tab>
    </v-tabs>

    <div
      v-if="tab == 0"
      class="pt-4"
      style="height: calc(100vh - 310px); overflow: scroll"
    >
      <v-data-table
        class="template-table"
        dense
        style="width: 700px"
        hide-default-footer
        :items-per-page="1000"
        :headers="headers_fields_de"
        :items="basisFields"
      >
        <template v-slot:item.copy="{ item }">
          <v-btn small icon @click="copyField(item)">
            <v-icon small>mdi-content-copy</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <div v-for="(relatedTable, key, index) in relatedFields" :key="key">
      <div
        v-if="tab == index + 1"
        style="height: calc(100vh - 310px); overflow: scroll"
        class="pt-4"
      >
        <v-row class="ml-4 mb-2 mt-n1" style="width: 400px">
          <v-col class="pa-0 mr-4 mt-1" cols="auto">
            <span>Tags:</span>
          </v-col>
          <v-col cols="auto pa-0" style="font-family: Courier">
            <div style="background-color: #eee" class="px-3 py-1">
              <div class="mb-1">
                {{ "${" + key + "}" }}
              </div>
              <div class="mb-1">
                {{ "${/" + key + "}" }}
              </div>
            </div>
          </v-col>
        </v-row>
        <v-data-table
          class="template-table"
          dense
          style="width: 850px"
          hide-default-footer
          :items-per-page="1000"
          :headers="headers_fields_de"
          :items="relatedTable"
        >
          <template v-slot:item.copy="{ item }">
            <v-btn small icon @click="copyField(item)">
              <v-icon small>mdi-content-copy</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      basisFields: [],
      relatedFields: [],
      tab: 0,
      headers_fields_de: [
        // {
        //   text: "Label",
        //   value: "label",
        // },
        {
          text: "Feld",
          value: "value",
        },
        {
          text: "Merge Feld",
          value: "merge_name",
        },
        {
          text: "",
          value: "copy",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    loadFields() {
      axios
        .get("fields/" + this.endpoint)
        .then((response) => response.data)
        .then((data) => {
          this.basisFields = data.basisFields;
          this.relatedFields = data.relatedFields;
        });
    },
    copyField(item) {
      var textArea = document.createElement("textarea");
      textArea.value = item.merge_name;
      document.body.appendChild(textArea);
      navigator.clipboard.writeText(textArea.value);
      document.body.removeChild(textArea);
    },
  },
  computed: {
    endpoint() {
      return this.$store.getters.moduleEndpoint;
    },
    recordId() {
      return this.$route.params.record_id;
    },
    moduleName() {
      return this.$route.params.module;
    },
  },
  mounted() {
    this.loadFields();
  },
};
</script>
<style lang="scss" scoped>
.template-table {
  border: 0.25px #aaa solid;
}
</style>
