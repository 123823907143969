<template>
  <container-app v-if="isReady">
    <container-config>
      <div slot-scope="{ color }" class="pt-12" :style="background">
        <app-login-header />
        <v-container>
          <v-row>
            <v-col
              class="mx-auto"
              sm="8"
              lg="6"
              xl="4"
              style="margin-top: calc(50vh - 300px)"
            >
              <v-card
                class="mt-12"
                style="background-color: rgba(255, 255, 255, 1)"
              >
                <v-card-title
                  class="px-8 pb-4 white--text"
                  :style="{ 'background-color': color }"
                  style="opacity: 1"
                >
                  <h1 class="headline">2-Faktor-Authentifizierung</h1>
                </v-card-title>
                <v-card-text class="px-8 py-6">
                  <div v-if="!qrCode">
                    <p>
                      Für die weitere Nutzung der Datenbank muss die
                      2-Faktor-Authentifizierung eingerichtet werden. Dafür muss
                      eine Authentifizerungs-App auf dem Handy installiert sein.
                    </p>
                    <p>
                      Falls schon Google Authenticator oder Microsoft
                      Authenticator o.ä. benutzt wird, kann die enstprechende
                      App auch hier genutzt werden.
                    </p>
                    <p>
                      Falls noch keine Authentifizierungs-App installiert ist,
                      empfiehlt sich dafür "Authy". Die QR-Codes für den
                      Download-link für "Authy" können
                      <a @click="openAuthyModal()">hier</a> bezogen werden.
                    </p>
                    <v-checkbox
                      v-model="confirmation"
                      label="Ich habe eine App auf mein Handy heruntergeladen."
                    ></v-checkbox>
                    <v-checkbox
                      class="mt-n4"
                      v-model="confirmation2"
                      label="Die App ist offen und bereit, um den QR-Code zu scannen."
                    ></v-checkbox>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col cols="auto" class="justify-right">
                        <v-btn
                          :disabled="!confirmation || !confirmation2"
                          :color="color"
                          class="white--text my-2"
                          @click="turnOn2fa"
                          >weiter</v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else-if="!showFinalMessage">
                    <p class="body-1 pt-2">
                      Bitte den folgenden Code mit der App scannen:
                    </p>
                    <v-row class="py-1">
                      <v-col></v-col>
                      <v-col>
                        <td v-html="qrCode"></td>
                      </v-col>
                      <v-col></v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-model="confirmation3"
                          label="Ich habe den QR-Code mit der App gescannt."
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="auto" class="justify-right">
                        <v-btn
                          :disabled="!confirmation3"
                          :color="color"
                          class="white--text mt-3"
                          @click="showFinalMessage = 1"
                          >weiter</v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else>
                    <p class="body-1 pt-2">
                      Vielen Dank!
                      <br />
                      <br />
                      Beim nächsten Login wirst du aufgefordert, den Code
                      einzugeben, der von der gewählten Authentifizierungs-App
                      angezeigt wird. Du kannst jetzt mit der Datenbank
                      weiterarbeiten.
                    </p>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col cols="auto" class="justify-right">
                        <v-btn
                          :color="color"
                          class="white--text my-2"
                          @click="continueToApp"
                          >zur Datenbank</v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-dialog v-model="authyModal" width="1000">
            <v-card style="background-color: rgba(255, 255, 255, 1)">
              <v-card-title
                class="px-8 pb-4 white--text"
                :style="{ 'background-color': color }"
                style="opacity: 1"
              >
                <h1 class="headline">Authy Download-Links</h1>
              </v-card-title>
              <v-card-text class="px-8 py-6">
                <v-row>
                  <v-col>
                    <h1 class="ml-8 title">Download für iOS</h1>
                    <app-login-2-fa-setup-authy-ios-svg />
                  </v-col>
                  <v-col>
                    <h1 class="ml-8 title">Download für Android</h1>
                    <app-login-2-fa-setup-authy-android-svg />
                  </v-col>
                  <v-col>
                    <h1 class="ml-8 title">Desktop-App / authy.com</h1>
                    <v-row class="mt-12 ml-12">
                      <v-col>
                        <a href="https://authy.com/download/" target="_blank">
                          <app-login-2-fa-setup-authy-svg />
                        </a>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-container>
      </div>
    </container-config>
  </container-app>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    showPassword: false,
    email: "",
    password: "",
    error: null,
    qrCode: null,
    confirmation: false,
    confirmation2: false,
    confirmation3: false,
    showFinalMessage: false,
    authyModal: false,
  }),
  computed: {
    isReady() {
      return this.$store.state.config.app !== null;
    },
    background() {
      return {
        height: "100vh",
        background:
          "linear-gradient(rgba(52, 52, 52, 0.6), rgba(52, 52, 52, 0.6)), url(" +
          this.url +
          ") no-repeat center center fixed",
        "background-size": "cover",
      };
    },
    url() {
      return this.$store.state.config.app.basicConfig.backgroundPath;
    },
    firstModulePath() {
      if (this.$store.state.config.app) {
        return this.$store.state.config.app.modules[0].basicConfig.path;
      } else {
        return null;
      }
    },
  },
  methods: {
    openAuthyModal() {
      this.authyModal = true;
    },
    turnOn2fa() {
      this.$store
        .dispatch("enable2fa")
        .then(() => {
          // display QR code
          axios
            .get("../user/two-factor-qr-code")
            .then((r) => (this.qrCode = r.data.svg));
        })
        .catch((err) => {
          this.error = err;
        });
    },
    continueToApp() {
      this.$router.push({ path: "/" + this.firstModulePath });
    },
  },
  mounted() {
    this.$store.dispatch("loadLoginConfigFromServer");
  },
};
</script>
