var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('container-ui-state',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var changeMobileView = ref.changeMobileView;
return _c('div',{},[_c('container-config',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var app = ref.app;
var module = ref.module;
var color = ref.color;
var language = ref.language;
return _c('div',{style:({ 'background-color': app.colorBackground })},[_c('container-record',{attrs:{"endpoint":module.basicConfig.apiEndpoint,"recordId":"new","preloadedRecordData":{},"newRecord":true},on:{"closeDialog":function($event){return _vm.$emit('closeDialog')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var saveNewRecord = ref.saveNewRecord;
return _c('div',{},[_c('v-card-title',{style:({ 'background-color': color })},[_c('h4',{staticClass:"pl-2 text-uppercase font-weight-regular",staticStyle:{"color":"white"},attrs:{"dark":""}},[_vm._v(" "+_vm._s(module.basicConfig.newRecordText)+" ")])]),_c('v-card-text',{staticClass:"px-6 py-3"},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},_vm._l((module.newRecordModalComponents),function(component,key){return _c(component.componentType,_vm._b({key:key,tag:"component"},'component',Object.assign({}, component.props,
                      {newRecord: true,
                      recordId: 'new',
                      recordEndpoint: module.basicConfig.apiEndpoint,
                      modalColor: color}),false))}),1)],1),_c('base-divider',{staticClass:"pt-8"}),_c('v-card-actions',{staticClass:"px-6 pb-4"},[_c('v-spacer'),_c('div',[_c('v-btn',{staticClass:"font-weight-medium mr-4",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_vm._v(_vm._s(_vm.texts["cancel"][language]))]),_c('v-btn',{staticClass:"font-weight-medium",attrs:{"tile":"","color":color,"dark":_vm.valid,"disabled":!_vm.valid},on:{"click":function($event){saveNewRecord();
                      changeMobileView('form');}}},[_vm._v(_vm._s(_vm.texts["save"][language]))])],1)],1)],1)}}],null,true)})],1)}}],null,true)})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }