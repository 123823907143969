<template>
  <container-config>
    <div slot-scope="{ color }">
      <router-link
        v-if="isLink && showLink"
        :style="{ color: linkColor || color }"
        :to="'/' + linkObject.linkPath + '/' + idValue + '?link=true'"
      >
        {{ displayValue }}
      </router-link>
      <!-- the fallback value is displayed if there is no linked value -->
      <div v-else>{{ displayValue }}</div>
    </div>
  </container-config>
</template>

<script>
import { get } from "lodash";
export default {
  props: {
    item: {
      type: Object,
    },
    linkObject: {
      type: Object,
    },
    linkColor: {
      type: String,
    },
  },
  computed: {
    displayValue() {
      let fieldName = this.linkObject.fieldName;
      let fieldNameFallback = this.linkObject.fieldNameFallback;
      let linkValue = get(this.item, fieldName);
      let fallbackValue = get(this.item, fieldNameFallback);
      if (linkValue) {
        return linkValue;
      } else {
        return fallbackValue;
      }
    },
    isLink() {
      let fieldName = this.linkObject.fieldName;
      return get(this.item, fieldName) != null;
    },
    idValue() {
      let idFieldName = this.linkObject.idFieldName;
      return get(this.item, idFieldName);
    },
    moduleConfigs() {
      const modules = this.$store.state.config.app.modules;
      return modules.map((module) => module.basicConfig);
    },
    pathModule() {
      const path = this.linkObject.linkPath;
      const module = this.moduleConfigs.find((module) => module.path == path);
      return module.name;
    },
    showLink() {
      return (
        JSON.parse(this.$store.state.auth.user.modules).includes(
          this.pathModule
        ) || JSON.parse(this.$store.state.auth.user.modules).length == 0
      );
    },
  },
};
</script>
