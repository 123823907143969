<template>
  <container-input-field
    :recordId="recordId"
    :recordEndpoint="recordEndpoint"
    :field="field"
    :newRecord="newRecord"
    :modalColor="modalColor"
    :storeNewRecordImmediately="storeNewRecordImmediately"
    @storeNewRecordImmediately="$emit('storeRecord')"
    ><div slot-scope="field">
      <base-input-birthday-fields
        :value="field.value"
        @change="field.updateValue"
      />
    </div>
  </container-input-field>
</template>
<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin]
};
</script>
