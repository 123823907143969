<template>
  <container-config>
    <div>
      <container-datatable
        :recordId="recordId"
        :recordEndpoint="recordEndpoint"
        :tableEndpoint="endpoint"
      >
        <div
          slot-scope="dataTable"
          v-if="!(suppressIfOneRecord && dataTable.items.length == 1)"
        >
          <div v-for="portalItem in dataTable.items" :key="portalItem.id">
            <h4
              class="font-weight-medium text-uppercase mt-3 mb-3"
              style="letter-spacing: 1.25px; font-size: 14px;"
              v-if="titleField && dataTable.items.length > 1"
            >
              {{ getTitle(portalItem) }}
            </h4>
            <container-record
              :endpoint="endpoint"
              :recordId="portalItem.id"
              :preloadedRecordData="portalItem"
              v-slot="record"
            >
              <div v-if="record.record">
                <component
                  v-for="(component, key) in components"
                  :key="key"
                  v-bind:is="component.componentType"
                  v-bind="{
                    ...component.props,
                    recordId: record.record.id,
                    recordEndpoint: endpoint
                  }"
                ></component>
              </div>
            </container-record>
          </div>
        </div>
      </container-datatable>
    </div>
  </container-config>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseSubrecordsComponentMixin } from "../mixins/baseSubrecordsComponentMixin";
import { get } from "lodash";

export default {
  mixins: [baseComponentMixin, baseSubrecordsComponentMixin],
  props: {
    titleField: {
      type: String
    },
    linkPath: {
      type: String
    },
    suppressIfOneRecord: {
      type: Boolean
    }
  },
  methods: {
    getTitle(record) {
      return get(record, this.titleField);
    }
  }
};
</script>
