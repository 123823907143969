<template>
  <container-config>
    <div slot-scope="{ color }">
      <slot :openDialog="openDialog"></slot>
      <v-dialog max-width="600px" v-model="dialog">
        <v-card>
          <v-card-title style="background-color: #eee" class="py-0">
            <svg-excel :width="25" class="mt-2 mr-2" /><span class="headline"
              >Excel speichern</span
            >
          </v-card-title>

          <v-card-text>
            <p class="pt-4 pb-2">Bitte den Dateinamen eingeben (ohne .xlsx):</p>
            <v-text-field
              label="Dateiname"
              v-model="fileName"
              autocomplete="disabled"
              :color="color"
              class="pt-2"
              @focus="$event.target.select()"
              @keydown.space.prevent
              @keydown.190.prevent
            ></v-text-field>
          </v-card-text>

          <v-card-actions class="pb-5 px-6 mt-n6">
            <v-row class="pr-2">
              <v-col>
                <v-btn tile class="font-weight-medium mr-4" @click="closeDialog"
                  >abbrechen</v-btn
                ></v-col
              >
              <v-col cols="auto">
                <v-row>
                  <v-btn
                    tile
                    class="font-weight-medium"
                    :color="color"
                    :dark="loadedChunks == chunks"
                    @click="download"
                    :loading="loadedChunks != chunks"
                    :disabled="loadedChunks != chunks"
                    >Datei herunterladen</v-btn
                  >
                </v-row>
                <v-row>
                  <v-progress-linear
                    class="mt-n1"
                    color="#1D6F42"
                    :value="(loadedChunks / chunks) * 100"
                    v-if="loadedChunks != chunks"
                  />
                </v-row>
              </v-col>
            </v-row>
            <!-- <v-spacer></v-spacer> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </container-config>
</template>

<script>
import zipcelx from "zipcelx";
import axios from "axios";

export default {
  data() {
    return {
      dialog: false,
      fileName: this.titleCase(this.moduleName),
      records: [],
      loadedChunks: 0,
      chunkSize: 100,
    };
  },
  props: {
    headers: {
      type: Array,
    },
    moduleName: {
      type: String,
      default: "Export",
    },
  },
  computed: {
    flattenedRecords() {
      return this.records.flat();
    },
    endpoint() {
      return this.$store.getters.selectedModuleConfigData.apiEndpoint;
    },
    recordCount() {
      return this.$store.state[this.moduleName].recordCount;
    },
    chunks() {
      if (this.$store.state[this.moduleName]) {
        return Math.ceil(this.recordCount / this.chunkSize);
      } else {
        return null;
      }
    },
  },
  methods: {
    getProperty(propertyName, object) {
      var parts = propertyName.split("."),
        length = parts.length,
        i,
        property = object || this;

      for (i = 0; i < length; i++) {
        if (property) {
          property = property[parts[i]];
        }
      }
      return property;
    },
    titleCase(str) {
      str = str.toLowerCase().split(" ");
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(" ");
    },
    openDialog() {
      this.dialog = true;
      this.loadData();
      this.loadedChunks = 0;
      this.records = [];
    },
    closeDialog() {
      this.dialog = false;
    },
    loadData() {
      let chunks = this.chunks;
      for (let chunk = 1; chunk <= chunks; chunk++) {
        this.axiosCall(chunk);
      }
    },
    axiosCall(chunk) {
      const endpoint = this.endpoint;
      const moduleName = this.moduleName;
      const getters = this.$store.getters;
      const query = this.$store.state[moduleName].query;
      const filter = this.$store.state[moduleName].filter;
      const sort = this.$store.state[moduleName].sort;
      const q = query ? "&q=" + query : "";
      const f = filter ? "&filter=" + filter : "";
      const s = sort ? "&sort=" + sort : "";
      const c = getters.constantPreFilter
        ? "&c=" + getters.constantPreFilter
        : "";
      const v = getters.variablePreFilter
        ? "&v=" + getters.variablePreFilter
        : "";
      const ch = "&chunk=" + chunk;
      // const p = "&page=all";
      let column_headers = this.headers.map((header) => {
        let value = header.text || header.value;
        return value;
      });
      const headers = "&headers=" + column_headers.join("|");
      const excel = "&excel=true";
      let self = this;
      let url = endpoint + "?" + q + f + s + c + v + excel + headers + ch;
      axios
        .get(encodeURI(url))
        .then((r) => r.data)
        .then(function (data) {
          self.records[chunk - 1] = data;
          self.loadedChunks++;
          // if (self.loadedChunks < self.chunks) {
          //   self.loadData(chunk + 1);
          // }
        });
    },
    download() {
      let headers = this.headers;
      let recordsFlattened = this.records.flat();
      let records = recordsFlattened.map((record) => {
        let object = headers.map((header) => {
          let fieldName = header.value;
          let value = this.getProperty(fieldName, record);
          let type = header.excelFieldType || "string";
          return { value, type };
        });
        return object;
      });
      let column_headers = headers.map((header) => {
        let value = header.text || header.value;
        let type = "string";
        return { value, type };
      });
      records.unshift(column_headers);
      let config = {
        filename: this.fileName.replace(".xlsx", ""),
        sheet: {
          data: records,
        },
      };
      zipcelx(config);
      this.dialog = false;
    },
  },
};
</script>
