<template>
  <container-config>
    <div slot-scope="{ language }">
      <container-global-search>
        <div slot-scope="container">
          <v-autocomplete
            hide-details
            ref="globalSearch"
            :placeholder="texts['searchDatabase'][language]"
            :loading="container.isLoading"
            @update:search-input="container.changeSearch"
            prepend-inner-icon="mdi-magnify"
            :items="container.items"
            item-text="name"
            v-model="selection"
            return-object
            class="hidden-sm-and-down globalsearch ml-md-6"
            style="width: 270px"
            color="white"
            @input="
              select();
              container.clearItems();
            "
            hide-no-data
            clearable
          >
            <template v-slot:item="{ item }">
              <v-list-item-avatar
                :style="{ 'background-color': item.color }"
                class="headline font-weight-light"
              >
                <v-icon color="white">{{ item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="singularize(item.endpoint)"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <GlobalEvents
            @keydown.meta.shift.70.exact.prevent="keyboardShortcutCmdShiftF"
          />
        </div>
      </container-global-search>
    </div>
  </container-config>
</template>

<script>
import Inflector from "inflector-js";

export default {
  data() {
    return {
      selection: {},
      texts: {
        searchDatabase: {
          en: "search entire database",
          de: "in allen Modulen suchen",
          da: "søg i hele databasen",
        },
      },
    };
  },
  methods: {
    keyboardShortcutCmdShiftF() {
      this.$refs.globalSearch.focus();
    },
    singularize(input) {
      return Inflector.singularize(input);
    },
    select() {
      if (this.selection != null) {
        this.items = [];
        let path =
          "/" + this.selection.path + "/" + this.selection.id + "?link=true";
        this.selection = {};
        this.$refs.globalSearch.blur();
        this.$router.push(path).catch(() => {});
      }
    },
    returnText(input) {
      let language = this.$store.state.config.app.appLanguage;
      let text = this.texts.find((e) => e.placeholder == input);
      return text[language];
    },
  },
};
</script>
<style>
.globalsearch .mdi-menu-down {
  display: none !important;
}
</style>
