<template>
  <div v-if="!isHidden">
    <v-btn small class="mt-4" @click="clickButton">
      <v-icon small left>mdi-email-edit-outline</v-icon>
      {{ buttonLabelField ? record[buttonLabelField] : buttonLabel }}
    </v-btn>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { get } from "lodash";
import axios from "axios";

export default {
  mixins: [baseComponentMixin],
  props: {
    buttonLabelField: {
      type: String,
    },
    buttonLabel: {
      type: String,
    },
    toField: {
      type: String,
    },
    subjectField: {
      type: String,
    },
    bodyField: {
      type: String,
    },
    bcc: {
      type: String,
    },
    scriptEndpoint: {
      type: String,
    },
  },
  computed: {
    href() {
      return encodeURI(
        "mailto: " +
          this.mailTo +
          "?subject=" +
          this.mailSubject +
          "&body=" +
          this.mailBody +
          "&bcc=" +
          this.bcc
      );
    },
    mailTo() {
      return get(this.record, this.toField);
    },
    mailSubject() {
      return get(this.record, this.subjectField);
    },
    mailBody() {
      return get(this.record, this.bodyField);
    },
  },
  methods: {
    clickButton() {
      window.location.href = this.href;
      if (this.scriptEndpoint) {
        this.executeScript();
      }
    },
    executeScript() {
      let path = this.scriptEndpoint.replace("{recordId}", this.recordId);
      axios.post(path).then(() => {
        this.refreshOriginRecord();
        this.refreshPage();
        this.isLoading = false;
      });
    },
    refreshOriginRecord() {
      let payload = {
        recordId: this.recordId,
        endpoint: this.recordEndpoint,
      };
      this.$store.dispatch("refreshOriginRecord", payload);
    },
    refreshPage() {
      let payload = {};
      this.$store.dispatch("refreshPage", payload);
    },
  },
};
</script>

<style lang="scss" scoped></style>
