<template>
  <container-settings-list>
    <div slot-scope="{ settingNames }">
      <v-list class="py-0">
        <v-list-item-group>
          <div v-for="setting in settingNames" :key="setting">
            <v-list-item
              :to="{
                path: `/settings/${setting}`
              }"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ setting }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </div>
  </container-settings-list>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
