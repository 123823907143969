<template>
  <container-config>
    <div slot-scope="config">
      <slot
        :language="config.language"
        :color="config.color"
        :moduleName="config.moduleName"
        :deleteQuery="deleteQuery"
        :addNewTableGrouping="addNewTableGrouping"
        :query="query"
      ></slot>
    </div>
  </container-config>
</template>

<script>
export default {
  computed: {
    queryBuilder() {
      return this.$store.state.queryBuilder;
    },
    query() {
      return this.queryBuilder.query;
    }
  },
  methods: {
    deleteQuery(orQueryKey) {
      this.$store.commit("DELETE_QUERY", orQueryKey);
    },
    addNewTableGrouping(orQueryKey) {
      this.$store.commit("ADD_NEW_TABLE_GROUPING", orQueryKey);
    },
    addNewQuery() {
      this.$store.commit("ADD_NEW_QUERY");
    }
  }
};
</script>

<style lang="scss" scoped></style>
