<template>
  <div
    :class="{ 'mt-2': !hasExtraTopPadding, 'mt-6': hasExtraTopPadding }"
    v-if="!isHidden"
    class="mb-4"
  >
    <v-btn
      small
      @click="executeDownload"
      :disabled="isLoading"
      style="height: 32px; margin-top: 2px"
    >
      <div v-if="isLoading" style="margin-left: -1px; margin-right: 7px">
        <span class="custom-loader">
          <v-icon small>mdi-cached</v-icon>
        </span>
      </div>
      <v-icon small left>mdi-download</v-icon>
      {{ label }}
    </v-btn>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import axios from "axios";
import { get } from "lodash";
export default {
  mixins: [baseComponentMixin],
  props: {
    label: {
      type: String,
    },
    path: {
      type: String,
    },
    field: {
      type: String,
    },
    hasExtraTopPadding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    pathComputed() {
      let field = get(this.record, this.field);
      let path = this.path.replace("{field}", field);
      path = path.replace("{recordId}", this.record.id);
      return path;
    },
  },
  methods: {
    executeDownload(url) {
      this.isLoading = true;
      url = this.pathComputed;
      axios({
        url,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          this.isLoading = false;
          this.refreshOriginRecord();
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "export.docx");
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loadingTemplateId = 0;
        })
        .else(() => {
          alert("Es gab ein Problem mit dem Download");
          this.loadingTemplateId = 0;
        });
    },
    refreshOriginRecord() {
      let payload = {
        recordId: this.recordId,
        endpoint: this.recordEndpoint,
      };
      this.$store.dispatch("refreshOriginRecord", payload);
    },
  },
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
