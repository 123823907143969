<template>
  <container-config>
    <div slot-scope="config">
      <slot
        :color="config.color"
        :moduleConfig="config.module"
        :language="config.language"
        :query="query"
        :includeState="includeState"
        :baseTable="baseTable"
        :addNewSubquery="addNewSubquery"
        :deleteTableGrouping="deleteTableGrouping"
        :changeIncludeState="changeIncludeState"
        :changeBaseTable="changeBaseTable"
      ></slot>
    </div>
  </container-config>
</template>

<script>
export default {
  props: {
    orQueryKey: {
      type: Number
    },
    tableGroupingKey: {
      type: Number
    }
  },
  computed: {
    queryBuilder() {
      return this.$store.state.queryBuilder;
    },
    query() {
      return this.queryBuilder.query;
    },
    includeState() {
      return this.query.orQueries[this.orQueryKey].tableGroupings[
        this.tableGroupingKey
      ].type;
    },
    baseTable() {
      return this.query.orQueries[this.orQueryKey].tableGroupings[
        this.tableGroupingKey
      ].table;
    }
  },
  methods: {
    addNewSubquery(orQueryKey, tableGroupingKey) {
      let payload = {
        orQueryIndex: orQueryKey,
        tableGroupingIndex: tableGroupingKey
      };
      this.$store.commit("ADD_NEW_SUBQUERY", payload);
    },
    deleteTableGrouping(orQueryKey, tableGroupingKey) {
      let payload = {
        orQueryIndex: orQueryKey,
        tableGroupingIndex: tableGroupingKey
      };
      this.$store.commit("DELETE_TABLE_GROUPING", payload);
    },
    changeIncludeState(value) {
      let payload = {
        orQueryIndex: this.orQueryKey,
        tableGroupingIndex: this.tableGroupingKey,
        value: value
      };
      this.$store.commit("TOGGLE_INCLUDE_STATE", payload);
    },
    changeBaseTable(value) {
      let payload = {
        orQueryIndex: this.orQueryKey,
        tableGroupingIndex: this.tableGroupingKey,
        value: value
      };
      this.$store.commit("SELECT_BASE_TABLE", payload);
      this.$store.commit("DELETE_ASSOCIATED_SUBQUERIES", payload);
    }
  }
};
</script>
