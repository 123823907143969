<template>
  <div>
    <container-record :endpoint="recordEndpoint" :recordId="recordId">
      <container-input-field
        :recordEndpoint="recordEndpoint"
        :recordId="recordId"
        :field="field"
        :newRecord="newRecord"
        :storeNewRecordImmediately="storeNewRecordImmediately"
        @storeNewRecordImmediately="$emit('storeRecord')"
      >
        <div slot-scope="field" style="height: 35px; margin-top: -14px">
          <v-checkbox
            color="#666"
            @change="field.updateValue"
            :input-value="field.value"
            value
            hide-details="auto"
            :label="label"
          />
        </div>
      </container-input-field>
    </container-record>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    items: {
      type: Array,
    },
    row: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
