import axios from "axios";

export default {
  state: {
    id: null,
    name: null,
    selection: [],
    storedSelection: null
  },
  mutations: {
    ADD_ITEM_TO_SELECTION(state, payload) {
      state.selection.push(payload);
    },
    SET_STORED_EXCEL_NAME(state, payload) {
      state.name = payload;
    },
    SET_STORED_EXCEL_ID(state, payload) {
      state.id = payload;
    },
    SET_STORED_SELECTION(state, payload) {
      state.storedSelection = payload;
    },
    SET_ALL_EXCEL_EXPORT_STATE_FIELDS(state, payload) {
      state.id = payload.id;
      state.name = payload.name;
      state.selection = JSON.parse(payload.selection);
      state.storedSelection = JSON.parse(payload.selection);
    },
    EMPTY_SELECTION(state) {
      state.storedSelection = null;
      state.selection = [];
      state.id = null;
      state.name = null;
    }
  },
  actions: {
    addItemToExcelSelection({ commit }, payload) {
      commit("ADD_ITEM_TO_SELECTION", payload);
    },
    emptyExcelSelection({ commit }) {
      commit("EMPTY_SELECTION");
    },
    storeExcelFieldsSelection({ state, commit }, payload) {
      let url = "/excel-exports";
      if (state.id) {
        axios
          .patch(url + "/" + state.id, payload)
          .then(r => r.data)
          .then(data => {
            commit("SET_STORED_SELECTION", data.selection);
          });
      } else {
        axios
          .post(url, payload)
          .then(r => r.data)
          .then(data => {
            commit("SET_STORED_EXCEL_ID", data.id);
            commit("SET_STORED_SELECTION", data.selection);
          });
      }
    }
  }
};
