<template>
  <container-record
    :endpoint="recordEndpoint"
    :recordId="recordId"
    :load="false"
  >
    <div slot-scope="record">
      <base-dropzone
        :recordId="recordId"
        :recordEndpoint="recordEndpoint"
        :uniqueSerialId="record.record[identifierField]"
      ></base-dropzone>
    </div>
  </container-record>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    identifierField: {
      type: String
    }
  }
};
</script>
