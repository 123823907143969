import Inflector from "inflector-js";
import axios from "axios";

export const baseSubrecordsComponentMixin = {
  data() {
    return {
      overlay: false,
      dialog: false,
      selectedRecord: null,
      newSubrecord: false,
      modalKey: null
    };
  },
  props: {
    endpoint: {
      type: String
    },
    dialogEndpoint: {
      type: String
    },
    manyToMany: {
      type: Boolean
    },
    manyToManyEndpoint: {
      type: String
    },
    modalColor: {
      type: String
    },
    newRecord: {
      type: Boolean
    },
    foreignKeyField: {
      type: String
    },
    keepModalOpenOnNewRecordCommit: {
      type: Boolean
    },
    commitNewSubrecordImmediately: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }
  },
  methods: {
    createNewSubrecord() {
      if (this.commitNewSubrecordImmediately) {
        return this.createNewSubrecordWithImmediateCommit();
      }
      //include filtered values, if present
      let filterObject = {};
      if (this.filters) {
        this.filters.forEach(function(item) {
          if (
            item.operator == "==" ||
            item.operator == undefined ||
            item.operator == ""
          ) {
            filterObject[item.field] = item.value;
          }
        });
      }
      //include default values, if present
      let defaultObject = {};
      if (this.defaultValues) {
        this.defaultValues.forEach(function(item) {
            filterObject[item.field] = item.value;
        });
      }
      this.newSubrecord = true;
      this.selectedRecord = { ...filterObject, ...defaultObject, id: this.uuidv4 };
      this.modalKey = Date();
      if (this.manyToMany) {
        this.joinEndpoint = this.endpoint;
      }
      this.dialog = true;
    },
    createNewSubrecordWithImmediateCommit() {
      let endpoint = this.endpoint;
      let payload = {};
      if (this.filters) {
        this.filters.forEach(function(item) {
          if (
            item.operator == "==" ||
            item.operator == undefined ||
            item.operator == ""
          ) {
            payload[item.field] = item.value;
          }
        });
      }
      let foreignKeyField =
        this.foreignKeyField ||
        Inflector.singularize(this.recordEndpoint) + "_id";
      payload[foreignKeyField] = this.recordId;
      let payloadData = {};
      payloadData["data"] = payload;
      payloadData["load"] = true;
      let self = this;
      axios
        .post(endpoint, payloadData)
        .then(r => r.data)
        .then(data => {
          self.newSubrecord = false;
          self.selectedRecord = data;
          self.modalKey = this.selectedRecord.id;
          self.dialog = true;
        });
    },
    editRecord(record) {
      this.newSubrecord = false;
      if (this.manyToMany) {
        this.selectedRecord =
          record[Inflector.singularize(this.manyToManyEndpoint)];
      } else {
        this.selectedRecord = record;
      }
      this.modalKey = this.selectedRecord.id;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    handleNewRecordResponse(data) {
      this.newSubrecord = false;
      this.selectedRecord = data;
    }
  }
};
