<template>
  <tr class="inputRow">
    <td class="inputRow" v-for="(component, i) in components" :key="i">
      <component
        v-bind:is="
          'BaseRecordDataTableDirectEntry' +
          component.componentType.replace('BaseInput', '')
        "
        v-bind="{
          ...component.props,
          recordId,
          recordEndpoint,
          originEndpoint,
          originRecordId,
          newRecord: true,
        }"
      ></component>
      <!-- {{ component.componentType }} -->
    </td>
    <td class="pl-1">
      <base-record-data-table-direct-entry-save
        :key="key"
        @handleNewRecordResponse="handleNewRecordResponse"
        v-bind="{
          recordId,
          recordEndpoint,
          originEndpoint,
          originRecordId,
          newRecord: true,
        }"
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    components: {
      type: Array,
    },
    originEndpoint: {
      type: String,
    },
    originRecordId: {
      type: [String, Number],
    },
    recordEndpoint: {
      type: String,
    },
  },
  data() {
    return {
      recordId: "new",
      key: Date(),
    };
  },
  methods: {
    handleNewRecordResponse(data) {
      this.key = Date();
      this.$children[0].focusField();
      this.$emit("handleNewRecordResponse", data);
    },
  },
};
</script>

<style>
.inputRow td {
  padding: 0px !important;
}
.inputRow:hover {
  background-color: transparent !important;
}
</style>
