<template>
  <div>
    <slot
      :view="view"
      :mobileView="mobileView"
      :toggleDrawer="toggleDrawer"
      :changeView="changeView"
      :changeMobileView="changeMobileView"
      :closeDialog="closeDialog"
      :showDialog="showDialog"
      :dialogText="dialogText"
      :dialogTitle="dialogTitle"
      :drawerState="drawerState"
      :pageIsLoading="pageIsLoading"
      :recordIsLoading="recordIsLoading"
      :user="user"
      :canSeeSettings="canSeeSettings"
      :logout="logout"
      :tab="tab"
      :changeTab="changeTab"
    ></slot>
  </div>
</template>

<script>
export default {
  computed: {
    view() {
      return this.$store.state.uiState.view;
    },
    mobileView() {
      return this.$store.state.uiState.mobileView;
    },
    drawerState() {
      return this.$store.state.uiState.drawerState;
    },
    showDialog() {
      return this.$store.state.uiState.showDialog;
    },
    dialogTitle() {
      return this.$store.state.uiState.dialogTitle;
    },
    dialogText() {
      return this.$store.state.uiState.dialogText;
    },
    pageIsLoading() {
      return this.$store.state.uiState.pageIsLoading;
    },
    recordIsLoading() {
      return this.$store.state.uiState.recordIsLoading;
    },
    tab() {
      return this.$store.state[this.module]
        ? this.$store.state[this.module].selectedTab
        : 0;
    },
    module() {
      return this.$route.params.module;
    },
    user() {
      return this.$store.state.auth.user;
    },
    canSeeSettings() {
      return true;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    toggleDrawer() {
      if (this.isMobile && this.drawerState == "full") {
        this.$store.commit("TOGGLE_DRAWER", "hide");
      } else if (this.isMobile && this.drawerState == "hide") {
        this.$store.commit("TOGGLE_DRAWER", "full");
      } else if (this.drawerState == "full") {
        this.$store.commit("TOGGLE_DRAWER", "mini");
      } else if (this.drawerState == "mini") {
        this.$store.commit("TOGGLE_DRAWER", "hide");
      } else if (this.drawerState == "hide") {
        this.$store.commit("TOGGLE_DRAWER", "full");
      }
    },
    changeView(input) {
      this.$store.commit("TOGGLE_VIEW", input);
      this.$store.dispatch("pushUrlParametersToRoute");
    },
    changeMobileView(input) {
      return new Promise((resolve) => {
        resolve(this.$store.commit("CHANGE_MOBILE_VIEW", input));
      });
    },
    closeDialog() {
      this.$store.commit("CLOSE_DIALOG");
    },
    logout() {
      this.$store.dispatch("logout");
    },
    changeTab(input) {
      this.$store.commit("SWITCH_TAB", { module: this.module, input });
    },
  },
};
</script>
