<template>
  <container-config>
    <div slot-scope="config" class="mb-4">
      <v-btn
        small
        v-if="noPrivateRecord"
        @click="dialog = true"
        color="white"
        class="mb-n4"
        >Privatadresse eintragen</v-btn
      >
      <v-card class="mt-1 elevation-1" style="border: 0.25px solid #aaa" v-else>
        <v-card-text class="black--text">
          <v-row>
            <v-col class="py-1" cols="10">
              <div
                class="font-weight-bold"
                :style="{ color: config.app.colorPrimary }"
                style="font-size: 0.9375rem"
              >
                {{ record.full_name }}
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="py-0 pr-4">
              <v-btn small icon @click="dialog = true">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div id="addressBlock">
            <div
              class="body-2 mt-3"
              v-if="
                record.street_1 ||
                (record.addresses && record.addresses[0].street_1)
              "
            >
              {{ record.street_1 || record.addresses[0].street_1 }}
            </div>
            <div
              class="body-2"
              v-if="
                record.street_2 ||
                (record.addresses && record.addresses[0].street_2)
              "
            >
              {{ record.street_2 || record.addresses[0].street_2 }}
            </div>
            <div class="body-2">
              <span
                v-if="
                  record.post_code ||
                  (record.addresses && record.addresses[0].post_code)
                "
              >
                {{ record.post_code || record.addresses[0].post_code }}
              </span>
              <span
                v-if="
                  record.city || (record.addresses && record.addresses[0].city)
                "
              >
                {{ record.city || record.addresses[0].city }}
              </span>
            </div>
            <div
              class="body-2"
              v-if="
                record.country ||
                (record.addresses && record.addresses[0].country)
              "
            >
              {{
                record.country
                  ? record.country.name_de
                  : record.addresses
                  ? record.addresses[0].country.name_de
                  : ""
              }}
            </div>
          </div>
          <div class="mt-2">
            <div
              class="body-2 ml-n1"
              v-if="
                record.email ||
                record.mail ||
                (record.addresses &&
                  (record.addresses[0].email || record.addresses[0].mail))
              "
            >
              <v-btn icon x-small @click="sendEmail">
                <v-icon small>mdi-email</v-icon>
              </v-btn>
              {{
                record.email ||
                record.mail ||
                record.addresses[0].email ||
                record.addresses[0].mail
              }}
            </div>
            <div
              class="body-2 ml-n1"
              v-if="
                record.telephone ||
                record.phone ||
                (record.addresses &&
                  (record.addresses[0].telephone || record.addresses[0].phone))
              "
            >
              <v-btn
                icon
                x-small
                @click="
                  showInOverlay(
                    record.telephone ||
                      record.phone ||
                      record.addresses[0].telephone ||
                      record.addresses[0].phone
                  )
                "
              >
                <v-icon small>mdi-phone</v-icon>
              </v-btn>
              {{
                record.telephone ||
                record.phone ||
                record.addresses[0].telephone ||
                record.addresses[0].phone
              }}
            </div>
            <div
              class="body-2 ml-n1"
              v-if="
                record.mobile ||
                (record.addresses && record.addresses[0].mobile)
              "
            >
              <v-btn
                icon
                x-small
                @click="
                  showInOverlay(record.mobile || record.addresses[0].mobile)
                "
              >
                <v-icon small>mdi-cellphone</v-icon>
              </v-btn>
              {{ record.mobile || record.addresses[0].mobile }}
            </div>
            <div
              class="body-2 ml-n1"
              v-if="record.fax || (record.addresses && record.addresses[0].fax)"
            >
              <v-btn
                icon
                x-small
                @click="showInOverlay(record.fax || record.addresses[0].fax)"
              >
                <v-icon small>mdi-fax</v-icon>
              </v-btn>
              {{ record.fax || record.addresses[0].fax }}
            </div>
            <div
              class="body-2 ml-n1"
              v-if="
                record.website ||
                (record.addresses && record.addresses[0].website)
              "
            >
              <v-btn icon x-small @click="openWebsite">
                <v-icon small>mdi-web</v-icon>
              </v-btn>
              {{ record.website || record.addresses[0].website }}
            </div>
          </div>
          <v-overlay :value="showOverlay" opacity="0.8">
            <p class="white--text display-4">
              {{ overlayData }}
            </p>

            <v-btn
              right
              x-large
              outlined
              class="white--text"
              @click="showOverlay = false"
            >
              OK
            </v-btn>
          </v-overlay>
        </v-card-text>
      </v-card>
      <v-dialog
        :max-width="maxDialogWidth"
        v-model="dialog"
        @click:outside="closeDialog"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :hide-overlay="$vuetify.breakpoint.xsOnly"
      >
        <v-card :style="{ 'background-color': config.app.colorBackground }">
          <v-card-title
            class="px-md-9"
            :style="{ 'background-color': config.app.colorPrimary }"
            style="margin: 0px"
          >
            <h4
              class="white--text font-weight-medium text-uppercase"
              style="letter-spacing: 1.25px; font-size: 18px"
            >
              Privatadresse
            </h4>
          </v-card-title>
          <v-card-text class="pt-4 px-9">
            <app-subcomponents
              v-bind="{
                components,
                recordId,
                recordEndpoint,
                storeNewRecordImmediately,
                newRecord: newRecord,
                modalColor: modalColor,
                pathArray: [...pathArray, 'props', 'components'],
              }"
              @storeRecord="$emit('storeRecord')"
            />
          </v-card-text>

          <v-card-actions class="pb-6 px-md-9 px-5">
            <v-spacer></v-spacer>
            <v-btn
              class="font-weight-medium"
              :color="modalColor"
              dark
              @click="closeDialog()"
              >OK</v-btn
            >
            <!-- <GlobalEvents @keydown.enter.prevent="dialog = false" /> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </container-config>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseSubrecordsComponentMixin } from "../mixins/baseSubrecordsComponentMixin";

export default {
  mixins: [baseComponentMixin, baseSubrecordsComponentMixin],
  data() {
    return {
      showOverlay: false,
      overlayData: null,
    };
  },
  props: {
    maxDialogWidth: {
      type: Number,
      default: 750,
    },
  },
  computed: {
    noPrivateRecord() {
      return (
        !this.record ||
        !(
          this.record.street_1 ||
          this.record.street_2 ||
          this.record.post_code ||
          this.record.city ||
          this.record.country ||
          this.record.mobile ||
          this.record.phone ||
          this.record.fax ||
          this.record.email ||
          this.record.mail ||
          this.record.website ||
          (this.record.addresses &&
            this.record.addresses[0] &&
            (this.record.addresses[0].street_1 ||
              this.record.addresses[0].street_2 ||
              this.record.addresses[0].post_code ||
              this.record.addresses[0].city ||
              this.record.addresses[0].country ||
              this.record.addresses[0].mobile ||
              this.record.addresses[0].phone ||
              this.record.addresses[0].fax ||
              this.record.addresses[0].email ||
              this.record.addresses[0].mail ||
              this.record.addresses[0].website))
        )
      );
    },
  },
  methods: {
    openWebsite() {
      let urlPath = this.record.website;
      if (!(urlPath.includes("http://") || urlPath.includes("https://"))) {
        urlPath = "https://" + urlPath;
      }
      window.open(urlPath, "_blank");
    },
    sendEmail() {
      var mail = document.createElement("a");
      mail.href = "mailto:" + this.record.email || this.record.mail;
      mail.click();
    },
    showInOverlay(data) {
      this.showOverlay = true;
      this.overlayData = data;
    },
    closeDialog() {
      this.dialog = false;
      // console.log(this.recordEndpoint);
      // console.log(this.recordId);
      this.$store.dispatch("refreshOriginRecord", {
        endpoint: this.recordEndpoint,
        recordId: this.recordId,
      });
    },
  },
};
</script>
