<template>
  <div>
    <slot
      :recordId="recordId"
      :endpoint="endpoint"
      :record="record"
      :saveNewRecord="saveNewRecord"
      :newRecord="newRecord"
      :executeDeletion="deleteRecord"
      :deletionDialog="deletionDialog"
      :toggleDeletionDialog="toggleDeletionDialog"
      :updateRecordState="updateRecordState"
    ></slot>
  </div>
</template>

<script>
import axios from "axios";
import Inflector from "inflector-js";

export default {
  data() {
    return {
      deletionDialog: false,
      storedRecordId: null,
    };
  },
  props: {
    recordId: {
      type: [String, Number],
    },
    endpoint: {
      type: String,
    },
    preloadedRecordData: {
      type: [Object, Array],
    },
    topLevelRecord: {
      type: Boolean,
    },
    newRecord: {
      type: Boolean,
    },
    manyToMany: {
      type: Boolean,
    },
    joinEndpoint: {
      type: String,
    },
    originEndpoint: {
      type: String,
    },
    originRecordId: {
      type: [String, Number],
    },
    suppressAutoForeignKey: {
      type: Boolean,
    },
    foreignKeyField: {
      type: String,
    },
    load: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    computedRecordId() {
      return this.storedRecordId ? this.storedRecordId : this.recordId;
    },
    record() {
      if (this.$store.state[this.endpoint]) {
        return this.$store.state[this.endpoint].selectedRecords[
          this.computedRecordId
        ];
      } else {
        return null;
      }
    },
    modulePath() {
      return this.$route.params.module;
    },
    moduleConfig() {
      if (this.modulePath) {
        let modules = this.$store.state.config.app.modules;
        return modules.find((e) => e.basicConfig.path == this.modulePath);
      } else {
        return null;
      }
    },
    moduleEndpoint() {
      if (this.moduleConfig) {
        return this.moduleConfig.basicConfig.apiEndpoint;
      } else {
        return null;
      }
    },
  },
  methods: {
    loadRecord() {
      let endpoint = this.endpoint;
      let recordId = this.recordId;
      this.$store.dispatch("loadRecord", { endpoint, recordId });
    },
    saveNewRecord() {
      if (this.manyToMany) {
        let endpoint = this.endpoint;
        let payload = JSON.parse(JSON.stringify(this.record));
        delete payload["id"];
        delete payload["recordIsStoring"];
        this.persistRecord(endpoint, payload).then((data) => {
          //join record is created here
          let secondPayload = {};
          //foreign key of the record just created
          let foreignKeyFieldA = Inflector.singularize(endpoint) + "_id";
          secondPayload[foreignKeyFieldA] = data.id;
          //foreign key of the origin record
          let originEndpoint = this.originEndpoint;
          let foreignKeyFieldB = Inflector.singularize(originEndpoint) + "_id";
          secondPayload[foreignKeyFieldB] = this.originRecordId;
          //foreign key of the origin record
          let joinEndpoint = this.joinEndpoint;
          this.persistRecord(joinEndpoint, secondPayload, false).then(() =>
            this.$emit("handleNewRecordResponse", data)
          );
        });
      }
      //if the new record is not the root record
      else if (this.originEndpoint && this.originEndpoint !== this.endpoint) {
        let endpoint = this.endpoint;
        let payload = JSON.parse(JSON.stringify(this.record));
        if (!this.suppressAutoForeignKey) {
          let foreignKeyField;
          let calculatedForeignKeyField =
            Inflector.singularize(this.originEndpoint.replaceAll("-", "_")) +
            "_id";
          if (this.foreignKeyField) {
            foreignKeyField = this.foreignKeyField;
            delete payload[calculatedForeignKeyField];
          } else {
            foreignKeyField = calculatedForeignKeyField;
          }
          payload[foreignKeyField] = this.originRecordId;
        }
        delete payload["id"];
        delete payload["recordIsStoring"];
        this.persistRecord(endpoint, payload).then((data) => {
          // this.updateRecordState({ id: "new" });
          this.$emit("handleNewRecordResponse", data);
          let endpoint = this.endpoint;
          let recordId = data.id;
          this.$store.dispatch("loadRecord", { endpoint, recordId });
        });
      }
      //if the new record is a root record
      else {
        let endpoint = this.endpoint;
        let payload = JSON.parse(JSON.stringify(this.record));
        //pre-filter values added here
        if (this.moduleConfig) {
          let hasVariablePreFilter =
            this.moduleConfig.basicConfig.variablePreFilter;
          if (hasVariablePreFilter) {
            let variablePreFilterField =
              this.moduleConfig.basicConfig.variablePreFilterFieldPath;
            let variablePreFilterValue =
              this.$store.state.uiState.variablePreFilterValue;
            let variablePreFilterObject = {};
            variablePreFilterObject[variablePreFilterField] =
              variablePreFilterValue;
            payload = { ...payload, ...variablePreFilterObject };
          }
          let hasConstantPreFilter =
            this.moduleConfig.basicConfig.constantPreFilterFieldPath;
          if (hasConstantPreFilter) {
            let constantPreFilterField =
              this.moduleConfig.basicConfig.constantPreFilterFieldPath;
            let constantPreFilterValue =
              this.moduleConfig.basicConfig.constantPreFilterValue;
            let constantPreFilterObject = {};
            constantPreFilterObject[constantPreFilterField] =
              constantPreFilterValue;
            payload = { ...payload, ...constantPreFilterObject };
          }
        }
        delete payload["id"];
        delete payload["recordIsStoring"];
        this.persistRecord(endpoint, payload).then((data) => {
          if (this.$route.params.module) {
            this.$router.push({
              path: "/" + this.$route.params.module + "/" + data.id,
            });
            this.$store.dispatch("refreshPage", { selectFirstRecord: false });
          }
        });
        this.$emit("closeDialog");
      }
    },
    persistRecord(endpoint, payload, addStoredIdToTemporaryRecord = true) {
      this.setNewStateEndpointIfNecessary(endpoint);
      let payloadData = {};
      payloadData["data"] = JSON.parse(JSON.stringify(payload));
      payloadData["load"] = true;
      this.toggleStoringFlag(true);
      return new Promise((resolve, reject) => {
        axios
          .post(endpoint, payloadData)
          .then((r) => r.data)
          .then((data) => {
            if (addStoredIdToTemporaryRecord) {
              //had to add this conditional to debug the manyToMany scenario -> needs checking
              let recordId = this.recordId;
              this.storedRecordId = data.id;
              this.$store.commit("ADD_STORED_ID_TO_TEMPORARY_RECORD", {
                endpoint,
                recordId,
                newId: data.id,
              });
            }
            this.toggleStoringFlag(false);
            resolve(data);
          })
          .catch((error) => reject(this.$store.dispatch("handleError", error)));
      });
    },
    toggleStoringFlag(value) {
      this.$store.commit("TOGGLE_STORING_FLAG_TO_TEMPORARY_RECORD", {
        endpoint: this.endpoint,
        recordId: this.recordId,
        value,
      });
    },
    deleteRecord() {
      let endpoint = this.endpoint;
      let recordId = this.recordId;
      axios
        .delete(endpoint + "/" + recordId)
        .then(() => {
          if (this.endpoint == this.moduleEndpoint) {
            this.$store.dispatch("refreshPage", { selectFirstRecord: true });
          } else {
            this.$emit("handlePostDeletion");
          }
        })
        .catch((error) => this.$store.dispatch("handleError", error));
    },
    setNewStateEndpointIfNecessary(endpoint) {
      if (!this.$store.state[endpoint]) {
        this.$store.commit("SET_NEW_ENDPOINT", {
          endpoint,
          data: {
            selectedRecords: {},
          },
        });
      }
    },
    toggleDeletionDialog() {
      this.deletionDialog = !this.deletionDialog;
    },
    updateRecordState(input) {
      let data = JSON.parse(JSON.stringify(input));
      this.$store.commit("SET_RECORD_DATA", {
        endpoint: this.endpoint,
        recordId: this.recordId,
        data,
      });
    },
  },
  mounted() {
    //if endpoint is not set in state, set it
    this.setNewStateEndpointIfNecessary(this.endpoint);
    //if record data is empty and preloaded record data is passed on, display it!
    if (
      (!this.record || this.newRecord) &&
      this.preloadedRecordData &&
      this.recordId
    ) {
      this.updateRecordState(this.preloadedRecordData);
    }
    //either way, load the record and display fresh data on each mount
    if (this.recordId && !this.newRecord && this.load) {
      this.loadRecord();
    }
    //if top level record, store record id in module state
    if (this.topLevelRecord && this.recordId) {
      this.$store.commit("SET_SELECTED_RECORD_ID", {
        module: this.$route.params.module,
        recordId: this.recordId,
      });
    }
  },
};
</script>
