<template>
  <div>
    <span v-if="text && text.length > notesMaxStringLength">
      {{ text.substring(0, notesMaxStringLength) + "…" }}
      <br />
      <v-btn x-small class="mt-2 mb-2" @click="notesDialog = true">
        <v-icon x-small left>mdi-eye</v-icon> read notes
      </v-btn>
      <v-dialog
        :value="notesDialog"
        @input="notesDialog = !notesDialog"
        width="700"
      >
        <v-card>
          <v-card-text class="body-1 pa-6 pb-0">
            <span
              style="white-space: pre-wrap"
              v-html="'<p>' + text.replace(/\n/g, '</p><p>') + '</p>'"
            />
          </v-card-text>
          <v-card-actions class="px-8 pb-6 mt-0 d-flex flex-row-reverse">
            <v-btn @click="notesDialog = !notesDialog">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </span>
    <span v-else> {{ text }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notesDialog: false,
    };
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    notesMaxStringLength: {
      type: Number,
      default: 40,
    },
  },
};
</script>

<style lang="scss" scoped></style>
