<template>
  <container-config>
    <div class="mt-2" slot-scope="{ color }">
      <v-btn :color="color" class="white--text" small @click="eliminateRecord"
        >eliminieren</v-btn
      >
    </div>
  </container-config>
</template>

<script>
import axios from "axios";
import { baseComponentMixin } from "../mixins/baseComponentMixin";
export default {
  mixins: [baseComponentMixin],
  computed: {
    originRecordId() {
      return this.$route.params.record_id;
    },
    moduleEndpoint() {
      return this.$store.getters.moduleEndpoint;
    },
  },
  methods: {
    eliminateRecord() {
      let path =
        this.moduleEndpoint + "/" + this.recordId + "/" + this.originRecordId;
      axios
        .delete(path)
        .then((r) => r.data)
        .then((data) => {
          this.refreshOriginRecord(data);
          this.refreshPageRecords();
        });
    },
    setRerenderKey() {
      this.$store.commit("SET_RERENDER_KEY");
    },
    refreshPageRecords() {
      this.$store.dispatch("changeHandler", {
        clearRecords: false,
        doNotSelectFirstRecord: true,
      });
    },
    refreshOriginRecord(data) {
      let payload = {
        recordId: this.originRecordId,
        endpoint: this.moduleEndpoint,
        data,
      };
      this.$store.commit("SET_RECORD_DATA", payload);
      // this.setRerenderKey();
    },
  },
};
</script>
