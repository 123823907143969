var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticStyle:{"max-width":"700px"}},[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"queryFilter",attrs:{"prepend-inner-icon":"mdi-magnify","clearable":"","dense":"","width":"300","autocomplete":"disabled","color":"#444","placeholder":"Export suchen"},model:{value:(_vm.queryFilter),callback:function ($$v) {_vm.queryFilter=$$v},expression:"queryFilter"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-switch',{staticClass:"mt-1 mb-0 queryFilter",attrs:{"label":"nur meine Exporte anzeigen","color":"#444"},model:{value:(_vm.myQueriesOnly),callback:function ($$v) {_vm.myQueriesOnly=$$v},expression:"myQueriesOnly"}})],1)],1),_c('div',{staticStyle:{"height":"calc(100vh - 330px)","overflow":"scroll"}},[_c('v-data-table',{staticClass:"table",staticStyle:{"max-width":"700px"},attrs:{"dense":"","hide-default-footer":"","items-per-page":1000,"headers":_vm.headers_de,"items":_vm.filteredItems},scopedSlots:_vm._u([{key:"item.export",fn:function(ref){
var item = ref.item;
return [_c('app-excel-exporter',{attrs:{"headers":JSON.parse(item.selection),"moduleName":_vm.moduleName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var openDialog = ref.openDialog;
return _c('div',{},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":openDialog}},[_c('svg-excel',{staticClass:"mt-1",attrs:{"width":20}})],1)],1)}}],null,true)})]}},{key:"item.load",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.loadExcelExport(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(item.user_id == _vm.currentUserId)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }