<template>
  <container-record
    :endpoint="recordEndpoint"
    :recordId="recordId"
    :newRecord="newRecord"
    @handlePostDeletion="closeDialog"
  >
    <v-card style="background-color: #F1F0F1;" slot-scope="record">
      <v-card-text>
        <v-row>
          <v-col cols="auto" class="px-5 mb-0 pb-0 pt-1">
            <img :src="largePath" style="height: 600px; max-width: 1200px;" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto" class="px-5 mb-0 pt-0 pb-0">
            <span class="caption" v-html="asset[imageInformationField]"></span>
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <component
          class="px-2"
          v-for="(component, key) in components"
          :key="key"
          v-bind:is="component.componentType"
          v-bind="{
            ...component.props,
            recordId,
            recordEndpoint,
            modalColor,
            newRecord
          }"
        ></component>
      </v-card-text>
      <v-card-actions class="px-6 pt-0 pb-6">
        <v-btn small @click="deletionDialog = true">
          <v-icon small left>mdi-delete</v-icon>
          Bild löschen
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn small :href="originalPath" target="_blank" download>
          <v-icon small left>mdi-open-in-new</v-icon>
          Original in neuer Lasche öffnen
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn small @click="closeDialog">schliessen</v-btn>
      </v-card-actions>
      <v-dialog v-model="deletionDialog" width="500">
        <v-card>
          <v-card-title>
            <p class="pt-2">
              Soll das Bild wirklich gelöscht werden?
            </p>
          </v-card-title>
          <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <v-btn @click="deletionDialog = false">
              abbrechen
            </v-btn>
            <v-btn @click="record.executeDeletion">
              Ja
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </container-record>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
export default {
  mixins: [baseComponentMixin],
  data() {
    return {
      deletionDialog: false
    };
  },
  props: {
    asset: {
      type: Object
    },
    endpoint: {
      type: String
    },
    pathFields: {
      type: Array
    },
    urlThumbnail: {
      type: String
    },
    urlLarge: {
      type: String
    },
    urlOriginal: {
      type: String
    },
    imageInformationField: {
      type: String
    },
    imageRatioField: {
      type: String
    },
    uploadIdentifierField: {
      type: String
    }
  },
  computed: {
    largePath() {
      return (
        this.urlLarge.replaceAll("{field}", this.asset[this.pathFields]) +
        (this.urlLarge.includes("?") ? "&" : "?") +
        "timestamp=" +
        this.asset.updated_at
      );
    },
    originalPath() {
      return this.urlOriginal.replaceAll(
        "{field}",
        this.asset[this.pathFields]
      );
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    }
  }
};
</script>

<style lang="scss" scoped></style>
