import { render, staticRenderFns } from "./BaseInputRadio.vue?vue&type=template&id=6fcf1aae&"
import script from "./BaseInputRadio.vue?vue&type=script&lang=js&"
export * from "./BaseInputRadio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules.nosync/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules.nosync/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VRadio,VRadioGroup})
