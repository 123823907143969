<template>
  <v-card>
    <container-config>
      <div slot-scope="{ app, color, language }">
        <container-record
          :endpoint="endpoint"
          :recordId="preloadedRecord.id"
          :preloadedRecordData="preloadedRecord"
          :newRecord="newRecord"
          :manyToMany="manyToMany"
          :joinEndpoint="joinEndpoint"
          :originEndpoint="originEndpoint"
          :originRecordId="originRecordId"
          :foreignKeyField="foreignKeyField"
          @handleNewRecordResponse="saveNewRecord"
          @handlePostDeletion="closeDialog"
          @closeDialog="closeDialog"
        >
          <div
            slot-scope="record"
            v-if="record.record"
            :style="{ 'background-color': app.colorBackground }"
          >
            <v-card-title
              class="px-md-9"
              :style="{ 'background-color': modalColor || color }"
              style="margin: 0px"
            >
              <h4
                class="white--text font-weight-medium text-uppercase"
                style="letter-spacing: 1.25px; font-size: 18px"
              >
                {{
                  modalTitleText.replace(
                    "{field}",
                    record.record[modalTitleField]
                  )
                }}
              </h4>
            </v-card-title>

            <v-card-text class="px-md-9 px-5 py-md-6 py-2">
              <v-form v-model="valid" ref="form">
                <app-subcomponents
                  v-bind="{
                    components,
                    recordId: preloadedRecord.id,
                    recordEndpoint: endpoint,
                    newRecord: newRecord,
                    modalColor: modalColor,
                    pathArray: [...pathArray, 'props', 'components'],
                  }"
                />
                <!-- <component
                  v-for="(component, key) in components"
                  :key="key"
                  v-bind:is="component.componentType"
                  v-bind="{
                    ...component.props,
                    recordId: preloadedRecord.id,
                    recordEndpoint: endpoint,
                    newRecord: newRecord,
                    modalColor: modalColor,
                  }"
                ></component> -->
              </v-form>
            </v-card-text>

            <v-card-actions class="pb-6 px-md-9 px-5">
              <v-btn
                class="font-weight-medium mr-4"
                v-if="!newRecord && !noDelete"
                @click="deleteRecord()"
                ><v-icon left small>mdi-delete</v-icon
                >{{ texts["deleteEntry"][language] }}</v-btn
              >
              <v-btn
                tile
                class="font-weight-medium mr-4"
                v-if="newRecord"
                @click="closeDialog()"
                >{{ texts["cancel"][language] }}</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                v-if="!newRecord"
                class="font-weight-medium"
                :color="modalColor || color"
                :disabled="!valid"
                :dark="valid"
                @click="closeDialog()"
                >OK</v-btn
              >
              <v-btn
                v-else-if="keepModalOpenOnNewRecordCommit"
                class="font-weight-medium"
                :color="modalColor || color"
                :disabled="!valid"
                :dark="valid"
                @click="record.saveNewRecord()"
                >{{ texts["continue"][language] }}</v-btn
              >
              <v-btn
                v-else
                class="font-weight-medium"
                :color="modalColor || color"
                :disabled="!valid"
                :dark="valid"
                @click="record.saveNewRecord()"
                >OK</v-btn
              >
              <GlobalEvents
                v-if="!newRecord && valid && !suppressCloseOnEnter"
                @keydown.enter.prevent="closeDialog()"
              />
              <GlobalEvents
                v-else-if="valid && !suppressCloseOnEnter"
                @keydown.enter.prevent="record.saveNewRecord()"
              />
            </v-card-actions>

            <v-dialog v-model="deletionDialog" width="500">
              <v-card>
                <v-card-title>
                  <p class="pt-2">
                    {{ texts["reallyDeleteEntry"][language] }}
                  </p>
                </v-card-title>
                <v-card-actions class="pa-5">
                  <v-spacer></v-spacer>
                  <v-btn @click="deletionDialog = false">
                    {{ texts["cancel"][language] }}
                  </v-btn>
                  <v-btn
                    dark
                    :color="modalColor || color"
                    @click="record.executeDeletion"
                  >
                    {{ texts["yes"][language] }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </container-record>
      </div>
    </container-config>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      deletionDialog: false,
      texts: {
        deleteEntry: {
          en: "Delete Entry",
          da: "Slet emne",
          de: "Eintrag löschen",
        },
        cancel: {
          en: "cancel",
          da: "afbryd",
          de: "abbrechen",
        },
        reallyDeleteEntry: {
          en: "Do you really want to delete this entry?",
          da: "Vil du slette emnet?",
          de: "Soll dieser Eintrag wirklich gelöscht werden?",
        },
        yes: {
          en: "Yes",
          da: "Ja",
          de: "Ja",
        },
        continue: {
          en: "continue",
          da: "fortsæt",
          de: "weiter",
        },
      },
    };
  },
  props: {
    preloadedRecord: {
      type: Object,
    },
    endpoint: {
      type: String,
    },
    originEndpoint: {
      type: String,
    },
    originRecordId: {
      type: [String, Number],
    },
    components: {
      type: Array,
    },
    newRecord: {
      type: Boolean,
    },
    modalColor: {
      type: String,
    },
    keepModalOpenOnNewRecordCommit: {
      type: Boolean,
    },
    manyToMany: {
      type: Boolean,
    },
    noDelete: {
      type: Boolean,
      default: false,
    },
    foreignKeyField: {
      type: String,
    },
    joinEndpoint: {
      type: String,
    },
    modalTitleText: {
      type: String,
      default: "",
    },
    modalTitleField: {
      type: String,
      default: "",
    },
    suppressCloseOnEnterProp: {
      type: Boolean,
    },
    pathArray: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    suppressCloseOnEnter() {
      return (
        this.$store.state.uiState.suppressCloseOnEnter ||
        this.suppressCloseOnEnterProp
      );
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
      setTimeout(() => {
        this.refreshOriginRecord();
      }, 300);
    },
    saveNewRecord(data) {
      this.$emit("handleNewRecordResponse", data);
      this.refreshOriginRecord();
      if (!this.keepModalOpenOnNewRecordCommit) {
        this.$emit("closeDialog");
      }
    },
    refreshOriginRecord() {
      let payload = {
        recordId: this.originRecordId,
        endpoint: this.originEndpoint,
      };
      if (this.originEndpoint) {
        this.$store.dispatch("refreshOriginRecord", payload);
      }
    },
    deleteRecord() {
      this.deletionDialog = true;
    },
  },
};
</script>
