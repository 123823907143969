<template>
  <container-exporter>
    <div class="pb-6">
      <v-tabs color="#444" v-model="tab" show-arrows>
        <v-tab>Export-Maker</v-tab>
        <v-tab>gespeicherte Exporte</v-tab>
      </v-tabs>
      <div v-if="tab == 0" class="pt-4" style="height: calc(100vh - 280px)">
        <app-exporter-excel-maker />
      </div>
      <div v-if="tab == 1" style="height: 450px">
        <app-exporter-excel-stored-exports @switchTab="tab = 0" />
      </div>
    </div>
  </container-exporter>
</template>

<script>
export default {
  data() {
    return {
      showExporter: false,
      tab: 0,
    };
  },
  methods: {
    toggleDialog() {
      this.showExporter = !this.showExporter;
    },
  },
};
</script>

<style lang="scss" scoped></style>
