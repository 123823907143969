<template>
  <div>
    <component
      v-bind:is="'base-input-tagger-' + type"
      :customClass="customClass"
      :recordId="recordId"
      :recordEndpoint="recordEndpoint"
      :endpointJoin="endpointJoin"
      :endpointValue="endpointValue"
      :displayFieldName="displayFieldName"
      :displayFieldValue="displayFieldValue"
      :endpointSort="endpointSort"
      :label="label"
      :newTagText="newTagText"
    />
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
export default {
  mixins: [baseComponentMixin],
  props: {
    endpointJoin: {
      type: String,
    },
    endpointValue: {
      type: String,
    },
    endpointSort: {
      type: String,
    },
    displayFieldName: {
      type: String,
      default: "name",
    },
    displayFieldValue: {
      type: String,
      default: "id",
    },
    label: {
      type: String,
    },
    type: {
      type: String,
      default: "combobox",
    },
    customClass: {
      type: String,
    },
    newTagText: {
      type: String,
    },
  },
};
</script>
