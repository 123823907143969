<template>
  <div>
    <slot
      :app="appConfig"
      :modules="modules"
      :settings="settings"
      :module="moduleConfig"
      :design="designConfig"
      :moduleName="moduleName"
      :tabs="tabs"
      :record="record"
      :color="primaryColor"
      :language="language"
      :exporterIsActive="exporterIsActive"
      :logoPath="logoPath"
      :alternativeDesign="true"
      :recordHeaderHeight="recordHeaderHeight"
    ></slot>
  </div>
</template>

<script>
export default {
  props: {
    record: {
      type: Object,
    },
  },
  computed: {
    appConfig() {
      return this.$store.state.config.app.basicConfig;
    },
    modules() {
      return this.$store.state.config.app.modules;
    },
    settings() {
      return this.$store.state.config.app.settings;
    },
    designConfig() {
      return this.$store.state.config.design;
    },
    moduleName() {
      return this.$route.params.module || null;
    },
    configMode() {
      return this.$store.state.uiState.configMode;
    },
    tabs() {
      if (this.moduleConfig && this.moduleConfig.tabs) {
        let tabs = this.moduleConfig.tabs;
        return tabs.filter((tab) => {
          return !this.hideTab(tab.hideIf || null);
        });
      } else {
        return null;
      }
    },
    moduleConfig() {
      if (this.moduleName) {
        let modules = this.$store.state.config.app.modules;
        return modules.find((e) => e.basicConfig.path == this.moduleName);
      } else {
        return null;
      }
    },
    primaryColor() {
      if (this.moduleName && this.moduleConfig.basicConfig.moduleColor) {
        return this.moduleConfig.basicConfig.moduleColor;
      } else {
        return this.appConfig.colorPrimary;
      }
    },
    language() {
      return this.appConfig.appLanguage;
    },
    exporterIsActive() {
      return this.appConfig.exporterIsActive;
    },
    drawerState() {
      return this.$store.state.uiState.drawerState;
    },
    logoPath() {
      if (this.drawerState == "hide" && this.appConfig.logoPathHidden) {
        return this.appConfig.logoPathHidden;
      } else if (this.drawerState == "mini" && this.appConfig.logoPathMini) {
        return this.appConfig.logoPathMini;
      } else {
        return this.appConfig.logoPath;
      }
    },
    recordHeaderHeight() {
      if (
        this.moduleConfig &&
        this.moduleConfig.recordTitleConfig &&
        this.moduleConfig.recordTitleConfig.headerHeight
      ) {
        return this.moduleConfig.recordTitleConfig.headerHeight;
      } else {
        return this.designConfig.recordHeaderHeight;
      }
    },
  },
  methods: {
    hideTab(hideIfConditions) {
      let record = this.record;
      if (
        !record ||
        !hideIfConditions ||
        !hideIfConditions.length > 0 ||
        this.configMode
      ) {
        return false;
      }
      let checkArray = [];
      for (let i = 0; i < hideIfConditions.length; i++) {
        let condition = hideIfConditions[i];
        if (
          (record[condition.field] != undefined &&
            //match for "==" operator or no defined operator (equivalent to "==")
            (((condition.operator == "==" || condition.operator == undefined) &&
              record[condition.field] == condition.value) ||
              //match for ">=" operator
              (condition.operator == ">=" &&
                record[condition.field] >= condition.value) ||
              //match for ">" operator
              (condition.operator == ">" &&
                record[condition.field] > condition.value) ||
              //match for "<=" operator
              (condition.operator == "<=" &&
                record[condition.field] <= condition.value) ||
              //match for "<" operator
              (condition.operator == "<" &&
                record[condition.field] < condition.value) ||
              //match for "contains" operator
              (condition.operator == "contains" &&
                record[condition.field].includes(condition.value) > 0) ||
              //match for "!contains" operator
              (condition.operator == "!contains" &&
                record[condition.field].includes(condition.value) == 0) ||
              //match for "!=" operator
              (condition.operator == "!=" &&
                record[condition.field] != condition.value))) ||
          //match for unequal on undefined field
          (record[condition.field] == undefined && condition.operator == "!=")
        ) {
          checkArray.push(true);
        } else {
          checkArray.push(false);
        }
      }
      return checkArray.some((v) => v === true);
    },
  },
};
</script>
