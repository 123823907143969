<template>
  <div>
    <v-navigation-drawer
      absolute
      app
      right
      :width="drawerWidth"
      class="px-0"
      style="background-color: #f2f2f2"
    >
      <h1
        class="py-4 px-3 title elevation-3"
        style="background-color: lightslategray; color: white"
      >
        {{ pathArray ? title : "select component" }}
      </h1>
      <config-component-editor
        v-if="pathArray"
        class="px-6 mx-n3"
        :pathArray="pathArray"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { get } from "lodash";
export default {
  computed: {
    pathArray() {
      return this.$store.state.uiState.configPathArray;
    },
    componentObject() {
      return get(this.$store.state, this.pathArray);
    },
    title() {
      return this.componentObject.componentType.replace("Base", "");
    },
    drawerWidth() {
      let windowWidth = window.innerWidth;
      return windowWidth * 0.25;
    },
  },
};
</script>

<style lang="scss" scoped></style>
