var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-datatable',{attrs:{"recordId":_vm.recordId,"recordEndpoint":_vm.recordEndpoint,"tableEndpoint":_vm.endpoint,"hasActionButton":_vm.hasActionButton,"headers":_vm.headers,"filters":_vm.filters},scopedSlots:_vm._u([{key:"default",fn:function(dataTable){return _c('div',{key:_vm.maxWidth,style:({ 'max-width': _vm.maxWidth + 'px' })},[(_vm.title && !_vm.smallTitle)?_c('h4',{staticClass:"font-weight-medium text-uppercase px-0 mt-3",class:{
        'mb-n6': _vm.noHeader && _vm.smallTitle,
        'mb-2': _vm.noHeader && !_vm.smallTitle,
        'mb-3': !_vm.noHeader,
      },staticStyle:{"letter-spacing":"1.25px","font-size":"0.875rem"}},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.newSubrecordButtonByTitle && !_vm.noNewRecord)?_c('v-btn',{staticClass:"mt-n1",attrs:{"small":"","icon":"","color":"#666"},on:{"click":_vm.createNewSubrecord}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus-circle-outline")])],1):_vm._e()],1):(_vm.smallTitle)?_c('div',{staticClass:"ml-3"},[_c('v-row',[_c('v-col',{staticClass:"pl-0 pa-0",attrs:{"cols":"auto"}},[_c('base-label',{attrs:{"label":_vm.title}})],1),_c('v-col',{staticClass:"pl-1 pa-0",attrs:{"cols":"auto"}},[(_vm.newSubrecordButtonByTitle)?_c('v-btn',{attrs:{"x-small":"","icon":"","color":"#666"},on:{"click":_vm.createNewSubrecord}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-plus-circle-outline")])],1):_vm._e()],1)],1)],1):_vm._e(),(_vm.newRecord)?_c('div',[_c('v-divider'),(dataTable.language == 'en')?_c('p',{staticClass:"px-1 pt-3"},[_vm._v(" Please fill in the other fields and press \"continue\" to be able to enter data here. ")]):_vm._e(),(dataTable.language == 'de')?_c('p',{staticClass:"px-1 pt-3"},[_vm._v(" Bitte die anderen Felder ausfüllen und \"weiter drücken\", um hier Daten eingeben zu können. ")]):_vm._e(),_c('v-divider')],1):_c('div',[_c('v-data-table',{staticClass:"data-table-dense",class:{
          'data-table-top-align': _vm.topAlign,
          'mt-6': _vm.noHeader && !dataTable.alternativeDesign,
          borderedTable: _vm.hasBorder,
          alternativeDataTableDesign: dataTable.alternativeDesign,
          'font-weight-bold': _vm.cellStyle.includes('bold'),
          'text-decoration-underline': _vm.cellStyle.includes('underline'),
        },staticStyle:{"margin-bottom":"7px"},style:({
          'background-color': _vm.whiteTable ? 'white' : 'transparent',
          'text-decoration': _vm.cellStyle.includes('underline')
            ? 'underline'
            : 'none',
        }),attrs:{"hide-default-footer":"","search":_vm.search,"items-per-page":1000,"items":dataTable.items,"headers":dataTable.extendedHeaders,"loading":dataTable.loading,"multi-sort":"","dense":_vm.dense,"hide-default-header":_vm.noHeader},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('tr',_vm._l((dataTable.extendedHeaders),function(header,key){return _c('td',{key:'item' + index + 'header' + key,class:{
                'font-weight-thin': header.fontWeight == 'thin',
                'font-weight-light': header.fontWeight == 'light',
                'font-weight-medium': header.fontWeight == 'medium',
                'font-weight-bold': header.fontWeight == 'bold',
              },style:({
                'text-align': header.align || 'left',
                color: header.fontColor || 'rgba(0, 0, 0, 0.87)',
              })},[(header.value == 'action')?_c('div',[(!_vm.noEdit)?_c('v-btn',{class:{
                    'mr-n6': _vm.hasActionButton,
                    'mr-n2': !_vm.hasActionButton,
                  },attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.editRecord(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.editIcon))])],1):_vm._e()],1):(header.value == 'actionButton')?_c('div',[_c('v-btn',{staticClass:"mr-n2",attrs:{"icon":"","small":"","disabled":_vm.actionButtonIsLoading},on:{"click":function($event){return _vm.executeActionButtonRequest(item)}}},[(_vm.actionButtonIsLoading)?_c('span',{staticClass:"custom-loader"},[_c('v-icon',[_vm._v("mdi-cached")])],1):_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.actionButtonIcon))])],1)],1):(header.isLink)?_c('div',[_c('base-record-data-table-link-item',{attrs:{"item":item,"linkColor":_vm.modalColor,"linkObject":_vm.getLinkObject(header)}})],1):(header.type == 'note')?_c('div',[_c('base-record-data-table-notes',{attrs:{"notesMaxStringLength":header.noteLength,"text":item[header['value']]}})],1):(header.type == 'icon')?_c('div',[_c('v-icon',{staticClass:"mr-n2",attrs:{"medium":"","icon":"","color":_vm.getNthPipeValue(item[header['value']], 2)}},[_vm._v(" "+_vm._s(_vm.getNthPipeValue(item[header["value"]], 1))+" ")])],1):(header.type == 'Boolean')?_c('div',{staticStyle:{"text-align":"center"}},[(item[header['value']])?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e()],1):(header.type == 'BooleanEdit')?_c('div',[_c('base-record-data-table-checkbox',{attrs:{"recordEndpoint":_vm.endpoint,"recordId":item.id,"field":header.value}})],1):(header.type == 'Download')?_c('div',[_c('base-record-data-table-download-button',{attrs:{"linkPath":header.linkPath,"record":item,"field":header.value,"buttonText":header.text}})],1):(header.type == 'actionButton')?_c('div',[_c('base-action-button',{attrs:{"path":header.linkPath,"recordId":item.id,"recordEndpoint":_vm.endpoint,"originRecordId":_vm.recordId,"field":header.value,"label":header.text,"type":"dataTableButton"}})],1):(
                  header.type == 'date' && _vm.displayValue(item, header) != null
                )?_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.displayValue(item, header))))])]):(header.type == 'time')?_c('div',[_c('span',[_vm._v(_vm._s((_vm.displayValue(item, header) || "").substring(0, 5)))])]):(header.type == 'urlButton')?_c('div',[_c('v-btn',{attrs:{"href":_vm.displayValue(item, header),"target":"_blank","x-small":"","tile":""}},[_c('v-icon',{staticClass:"mr-1 ml-n1",attrs:{"left":"","small":""}},[_vm._v("mdi-link")]),_vm._v(" open URL ")],1)],1):(header.type == 'url')?_c('div',[(item.url)?_c('v-btn',{attrs:{"href":item.url,"target":"_blank","x-small":"","tile":""}},[_c('v-icon',{staticClass:"mr-1 ml-n1",attrs:{"left":"","small":""}},[_vm._v("mdi-link")]),_vm._v(" open URL ")],1):(item.file_uuid)?_c('base-record-data-table-download-button',{attrs:{"fileUuid":item.file_uuid}}):(_vm.displayValue(item, header))?_c('span',[_vm._v(" "+_vm._s(_vm.displayValue(item, header))+" "),_c('v-btn',{staticClass:"mt-n1",attrs:{"icon":"","small":"","href":_vm.cleanUrl(_vm.displayValue(item, header)),"target":"_blank"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-earth")])],1)],1):_vm._e()],1):_c('div',{staticStyle:{"white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(_vm.displayValue(item, header))}})])}),0)]}}],null,true)},[(_vm.newSubrecordDirectEntry)?_c('template',{slot:"body.append"},[_c('base-record-data-table-direct-entry',{attrs:{"originRecordId":_vm.recordId,"originEndpoint":_vm.recordEndpoint,"recordEndpoint":_vm.endpoint,"components":_vm.components},on:{"handleNewRecordResponse":dataTable.pushToItems}})],1):_vm._e(),(_vm.includeSumFooter && dataTable.items.length > 0)?_c('template',{slot:"body.append"},[_c('tr',_vm._l((dataTable.extendedHeaders),function(header,key){return _c('td',{key:key,staticClass:"font-weight-medium",style:({ color: header.fontColor || 'rgba(0, 0, 0, 0.87)' }),attrs:{"align":"right"}},[(header.sumField)?_c('span',{style:(header.sumStyle == 'underline'
                    ? { 'text-decoration': 'underline' }
                    : { 'text-decoration': 'none' })},[_vm._v(" "+_vm._s(dataTable.items .map(function (item) { return item[header.sumField]; }) .reduce(function (prev, curr) { return prev + curr; }, 0) .toFixed(header.sumDecimals || 0) .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + _vm.separator))+" ")]):_vm._e()])}),0)]):_vm._e(),(_vm.includeAverageFooter && dataTable.items.length > 0)?_c('template',{slot:"body.append"},[_c('tr',_vm._l((dataTable.extendedHeaders),function(header,key){return _c('td',{key:key,staticClass:"font-weight-medium",style:({ color: header.fontColor || 'rgba(0, 0, 0, 0.87)' }),attrs:{"align":"right"}},[(header.sumField)?_c('span',{style:(header.sumStyle == 'underline'
                    ? { 'text-decoration': 'underline' }
                    : { 'text-decoration': 'none' })},[_vm._v(" Ø "+_vm._s(_vm.getAverage(dataTable, header))+" ")]):_vm._e()])}),0)]):_vm._e()],2),(!_vm.newSubrecordButtonByTitle && !_vm.noNewRecord)?_c('v-btn',{staticClass:"my-2 pl-2",attrs:{"small":"","text":"","color":"#666"},on:{"click":_vm.createNewSubrecord}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(_vm._s(_vm.newSubrecordButtonText)+" ")],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":_vm.maxDialogWidth,"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"hide-overlay":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.selectedRecord)?_c('base-record-modal',{key:_vm.modalKey,attrs:{"components":_vm.components,"preloadedRecord":_vm.selectedRecord,"pathArray":_vm.pathArray,"endpoint":_vm.manyToMany
              ? _vm.manyToManyEndpoint
              : _vm.dialogEndpoint
              ? _vm.dialogEndpoint
              : _vm.endpoint,"newRecord":_vm.newSubrecord,"noDelete":_vm.noDelete,"originEndpoint":_vm.recordEndpoint,"originRecordId":_vm.recordId,"manyToMany":_vm.manyToMany,"modalTitleText":_vm.modalTitleText,"modalTitleField":_vm.modalTitleField,"foreignKeyField":_vm.foreignKeyField,"joinEndpoint":_vm.manyToMany ? _vm.endpoint : null,"modalColor":_vm.modalColor,"suppressCloseOnEnterProp":_vm.suppressCloseOnEnter,"keepModalOpenOnNewRecordCommit":_vm.keepModalOpenOnNewRecordCommit},on:{"closeDialog":_vm.closeDialog,"handleNewRecordResponse":_vm.handleNewRecordResponse}}):_vm._e()],1)],1)])}}])})}
var staticRenderFns = []

export { render, staticRenderFns }