import { render, staticRenderFns } from "./BaseInputTaggerPortal.vue?vue&type=template&id=23ff8dbc&"
import script from "./BaseInputTaggerPortal.vue?vue&type=script&lang=js&"
export * from "./BaseInputTaggerPortal.vue?vue&type=script&lang=js&"
import style0 from "./BaseInputTaggerPortal.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules.nosync/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules.nosync/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VAutocomplete,VBtn,VIcon,VSimpleTable})
