<template>
  <container-exporter>
    <div class="pb-2">
      <v-tabs color="#444" v-model="tab" show-arrows>
        <v-tab>Templates</v-tab>
        <v-tab>Merge-Felder</v-tab>
      </v-tabs>
      <div
        v-if="tab == 0"
        class="pt-4"
        style="height: calc(100vh-220px); overflow: scroll"
      >
        <v-data-table
          class="template-table"
          hide-default-footer
          :items-per-page="1000"
          :headers="headers_de"
          :items="items"
        >
          <template v-slot:item.edit="{ item }">
            <v-btn
              small
              @click="exportDocument(item)"
              :loading="item.id == loadingTemplateId"
              :disabled="loadingTemplateId > 0"
            >
              Dokument generieren
            </v-btn>
          </template>
        </v-data-table>
        <v-btn class="mt-6 mb-2" small @click="uploadDialog = true">
          neues Template hochladen
        </v-btn>
        <v-dialog
          :value="uploadDialog"
          width="550"
          @input="uploadDialog = !uploadDialog"
        >
          <app-exporter-word-template-upload
            @closeDialog="uploadDialog = false"
            @refreshTemplateList="loadTemplates"
          />
        </v-dialog>
      </div>
      <div v-if="tab == 1">
        <app-exporter-word-fields />
      </div>
    </div>
  </container-exporter>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      showExporter: false,
      tab: 0,
      loadingTemplateId: 0,
      uploadDialog: false,
      items: [],
      headers_de: [
        {
          text: "Name",
          value: "file.name",
        },
        {
          text: "Benutzer*in",
          value: "user.name",
        },
        {
          text: "Typ",
          value: "type_display",
        },
        {
          text: "",
          value: "generate",
          sortable: false,
        },
        {
          text: "",
          value: "edit",
          sortable: false,
        },
      ],
      headers_en: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "User",
          value: "user.name",
        },
        {
          text: "Type",
          value: "type_display",
        },
        {
          text: "",
          value: "generate",
          sortable: false,
        },
        {
          text: "",
          value: "edit",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    toggleDialog() {
      this.showExporter = !this.showExporter;
    },
    exportDocument(item) {
      this.loadingTemplateId = item.id;
      this.templateId = item.id;
      if (item.type == "single") {
        this.generateSingleRecordDocument();
      }
      if (item.type == "multiple") {
        this.generateMultipleRecordDocument();
      }
    },
    generateSingleRecordDocument() {
      let url =
        "/create-document/" +
        this.endpoint +
        "/" +
        this.recordId +
        "?template_id=" +
        this.templateId;
      this.executeRequest(encodeURI(url));
    },
    generateMultipleRecordDocument() {
      const moduleState = this.$store.state[this.moduleName];
      const getters = this.$store.getters;
      const f = moduleState.filter ? "&filter=" + moduleState.filter : "";
      const s = moduleState.sort ? "&sort=" + moduleState.sort : "";
      const q = moduleState.query ? "&q=" + moduleState.query : "";
      const c = getters.constantPreFilter
        ? "&c=" + getters.constantPreFilter
        : "";
      const v = getters.variablePreFilter
        ? "&v=" + getters.variablePreFilter
        : "";
      let url =
        "/create-document/" +
        this.endpoint +
        "/" +
        "?page=all" +
        "&load=true" +
        "&template_id=" +
        this.templateId +
        f +
        s +
        c +
        v +
        q;
      // alert(url);
      this.executeRequest(encodeURI(url));
    },
    executeRequest(url) {
      axios({
        url,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          this.loading = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "export.docx");
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loadingTemplateId = 0;
        })
        .else(() => {
          alert("Es gab ein Problem mit dem Download");
          this.loadingTemplateId = 0;
        });
    },
    loadTemplates() {
      axios
        .get("document-templates/" + this.endpoint)
        .then((response) => response.data)
        .then((data) => {
          this.items = data;
        });
    },
  },
  computed: {
    endpoint() {
      return this.$store.getters.moduleEndpoint;
    },
    recordId() {
      return this.$route.params.record_id;
    },
    moduleName() {
      return this.$route.params.module;
    },
  },
  mounted() {
    this.loadTemplates();
  },
};
</script>

<style lang="scss" scoped>
.template-table {
  border: 0.25px #aaa solid;
}
</style>
