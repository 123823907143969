<template>
  <container-config>
    <div slot-scope="{ color }">
      <container-ui-state>
        <div slot-scope="uiState">
          <v-dialog
            :value="uiState.showDialog"
            width="500"
            @keydown.enter="uiState.closeDialog"
          >
            <v-card>
              <v-card-title class="headline">
                {{ uiState.dialogTitle }}
              </v-card-title>
              <v-card-text>
                <p class="text-justify body-1">
                  <span style="white-space: pre-wrap;">{{
                    uiState.dialogText
                  }}</span>
                </p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  dark
                  :color="color"
                  class="mr-4 mb-4"
                  @click="uiState.closeDialog"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <GlobalEvents
            v-if="!suppressCloseOnEnter"
            @keydown.enter.prevent="uiState.closeDialog"
          />
        </div>
      </container-ui-state>
    </div>
  </container-config>
</template>

<script>
export default {
  computed: {
    suppressCloseOnEnter() {
      return this.$store.state.uiState.suppressCloseOnEnter;
    }
  }
};
</script>
