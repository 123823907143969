<template>
  <container-config>
    <div class="px-2" slot-scope="{ module, language }">
      <v-btn medium text class="mt-3 pl-3" color="#666" @click="openDialog">
        <v-icon left>mdi-plus-circle</v-icon
        >{{ module.basicConfig.newRecordText || texts["newRecord"][language] }}
      </v-btn>
      <v-dialog
        persistent
        v-model="dialog"
        max-width="500px"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :hide-overlay="$vuetify.breakpoint.xsOnly"
      >
        <app-record-new-record-dialog
          :key="componentKey"
          @closeDialog="closeDialog()"
        ></app-record-new-record-dialog>
      </v-dialog>
    </div>
  </container-config>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      componentKey: 1,
      texts: {
        newRecord: {
          en: "new record",
          da: "tilføj emne",
          de: "neuer Datensatz"
        }
      }
    };
  },
  methods: {
    openDialog() {
      this.componentKey += 1;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    }
  }
};
</script>
