<template>
  <div>
    <container-input-field
      :recordId="recordId"
      :recordEndpoint="recordEndpoint"
      :field="field"
      :newRecord="newRecord"
      :modalColor="modalColor"
      @storeNewRecordImmediately="$emit('storeRecord')"
      :key="field"
    >
      <div slot-scope="field" v-if="!isHidden">
        <!-- <h4
          class="font-weight-medium text-uppercase mt-3"
          style="letter-spacing: 1.25px; font-size: 14px;"
          v-if="label"
        >
          {{ label }}
        </h4> -->
        <v-row style="max-width: 1025px">
          <v-col class="py-0">
            <base-label :label="label" />
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="py-0" cols="auto">
            <v-btn icon small @click="toggleHTMLView">
              <v-icon small v-if="!HTMLView">mdi-xml</v-icon>
              <v-icon small v-else>mdi-eye-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- <div v-else class="mt-3"></div> -->
        <transition name="flip">
          <base-input-wysiwyg-trix
            v-if="!HTMLView"
            class="card"
            :value="field.value"
            :isSmall="isSmall"
            :uploadIdentifierField="uploadIdentifierField"
            :record="field.record"
            @change="field.updateValue"
            :key="field.record.id"
          ></base-input-wysiwyg-trix>
          <v-textarea
            v-else
            :class="{
              alternativeTextareaFieldDesign: field.alternativeDesign,
            }"
            class="HTMLEditor card"
            placeholder="HTML Input"
            :value="prettify(field.value)"
            @change="field.updateValue"
            hide-details="auto"
            :solo="field.alternativeDesign"
            :flat="field.alternativeDesign"
            :color="field.color"
            autocomplete="disabled"
            spellcheck="false"
            :rows="isSmall ? 4 : 30"
          ></v-textarea>
        </transition>
      </div>
    </container-input-field>
  </div>
</template>
<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    isSmall: {
      type: Boolean,
    },
    uploadIdentifierField: {
      type: String,
    },
  },
  data() {
    return {
      HTMLView: false,
    };
  },
  methods: {
    toggleHTMLView() {
      this.HTMLView = !this.HTMLView;
    },
    prettify(input) {
      return input;
    },
  },
};
</script>

<style scoped>
.HTMLEditor {
  font-family: Courier, monospace !important;
  max-width: 1000px;
  padding-bottom: 20px;
}

.card {
  transition: all 0.5s ease;
  will-change: transform;
}

.flip-enter-active {
  transition: all 0.6s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 0;
}
</style>
