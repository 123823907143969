import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import config from "./config/appConfig";
import { sync } from "vuex-router-sync";
import vuetify from "./plugins/vuetify";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import GlobalEvents from "vue-global-events";
import VueMasonry from "vue-masonry-css";
import axios from "axios";
// import VuePlyr from "vue-plyr";
// import "vue-plyr/dist/vue-plyr.css";

// window.Vapor = require("laravel-vapor");

//sync store with router
sync(store, router);

Vue.config.productionTip = false;

Vue.use(VueMasonry);
// Vue.use(VuePlyr, {
//   plyr: {}
// });

//axios defaults
let apiUrl = config.basicConfig["apiUrl" + upperFirst(process.env.VUE_APP_ENV)];
axios.defaults.withCredentials = true;
axios.defaults.baseURL = apiUrl;

//globally register components here
Vue.component("GlobalEvents", GlobalEvents);

//********** global registration of all components here *************

const requireComponent = require.context(
  // The relative path of the components folder
  "./components",
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );

  //register capitalize filter
  Vue.filter("capitalize", function(value) {
    if (!value) return "";
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
  });

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});

new Vue({
  router,
  store,
  vuetify,
  created() {
    const userString = localStorage.getItem("user"); // grab user data from local storage
    if (userString) {
      // check to see if there is indeed a user
      const userData = JSON.parse(userString); // parse user data into JSON
      this.$store.commit("SET_USER_DATA", userData); // restore user data with Vuex
    }
    axios.interceptors.response.use(
      response => response, // simply return the response
      error => {
        if (
          error.response.status === 401 &&
          !this.$route.path.includes("login")
        ) {
          // if we catch a 401 error
          this.$store.dispatch("logout"); // force a log out
        }
        return Promise.reject(error); // reject the Promise, with the error as the reason
      }
    );
  },
  render: h => h(App)
}).$mount("#app");
