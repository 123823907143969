export default {
  module: rootState => {
    return rootState.route.params.module;
  },

  isLink: rootState => {
    return rootState.route.query.link;
  },

  moduleState(rootState, getters) {
    return rootState[getters.module];
  },

  moduleConfig(rootState, getters) {
    let modules = rootState.config.app.modules;
    return modules.find(e => e.basicConfig.path == getters.module);
  },

  moduleBasicConfig(getters, rootGetters) {
    if (rootGetters.moduleConfig) {
      return rootGetters.moduleConfig["basicConfig"];
    } else {
      return null;
    }
  },

  variablePreFilterValue(rootState) {
    if (rootState.uiState && rootState.uiState.variablePreFilterValue) {
      // return rootGetters.module;
      return rootState.uiState.variablePreFilterValue;
    } else {
      return null;
    }
  },

  moduleEndpoint(getters, rootGetters) {
    return rootGetters.moduleConfig["basicConfig"]["apiEndpoint"];
  },

  selectedModuleConfigData: rootState => {
    let array = rootState.config.app.modules;
    let module = rootState.route.params.module;
    let object = array.find(o => o.basicConfig.path === module);
    return object.basicConfig;
  },

  uriQuery(rootState) {
    return rootState.route.query;
  }
};
