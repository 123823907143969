<template>
  <div>
    <config-section-title v-if="sectionTitle" :title="sectionTitle" />
    <v-row class="mb-n1">
      <v-col class="ml-3 px-0 py-0">
        <base-label :label="label" />
      </v-col>
    </v-row>
    <v-row class="px-0 py-0">
      <v-col class="d-flex pt-1">
        <v-text-field
          :label="labelConverted"
          :value="fieldValue"
          @change="updateValue"
          :append-icon="appendedIcon"
          type="number"
          hide-details
          dense
          solo
          flat
          class="alternativeTextFieldDesign"
          style="max-width: 150px"
          :placeholder="labelConverted"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get, startCase } from "lodash";

export default {
  props: {
    getArray: {
      type: Array,
    },
    field: {
      type: String,
    },
    label: {
      type: String,
    },
    selfIcon: {
      type: Boolean,
      default: false,
    },
    sectionTitle: {
      type: String,
    },
  },
  data() {
    return {
      mock: null,
    };
  },
  computed: {
    fieldValue() {
      return get(this.$store.state, [...this.getArray, this.field]);
    },
    appendedIcon() {
      return this.selfIcon ? this.fieldValue : "";
    },
    labelConverted() {
      return startCase(this.label || this.field);
    },
  },
  methods: {
    updateValue(value) {
      let input = parseInt(value);
      this.$store.dispatch("updateConfigValue", {
        input,
        field: this.field,
        getArray: this.getArray,
      });
    },
  },
};
</script>
