import Vue from "vue";
export default {
  state: {
    drawerState: "full",
    view: "masterDetail",
    mobileView: "list",
    showDialog: false,
    dialogTitle: null,
    dialogText: null,
    pageIsLoading: false,
    recordIsLoading: false,
    configMode: false,
    suppressCloseOnEnter: false,
    variablePreFilterValue: null,
    variablePreFilterItems: null
  },
  mutations: {
    TOGGLE_DRAWER(state, payload) {
      state.drawerState = payload;
    },
    TOGGLE_VIEW(state, payload) {
      state.view = payload;
    },
    TOGGLE_CONFIG_MODE(state, payload) {
      state.configMode = payload;
    },
    SET_CONFIG_PATH_ARRAY(state, payload) {
      Vue.set(state, "configPathArray", payload);
    },
    CHANGE_MOBILE_VIEW(state, payload) {
      state.mobileView = payload;
    },
    SHOW_DIALOG(state, payload) {
      state.showDialog = true;
      state.dialogTitle = payload.title;
      state.dialogText = payload.text;
    },
    CLOSE_DIALOG(state) {
      state.showDialog = false;
    },
    TOGGLE_PAGE_LOADING(state, payload) {
      state.pageIsLoading = payload;
    },
    TOGGLE_RECORD_LOADING(state, payload) {
      state.recordIsLoading = payload;
    },
    TOGGLE_SUPPRESS_CLOSE_ON_ENTER(state, payload) {
      state.suppressCloseOnEnter = payload;
    },
    SET_GLOBAL_VARIABLE_PRE_FILTER(state, payload) {
      if (!state["variablePreFilterValue"]) {
        Vue.set(state, "variablePreFilterValue", {});
      }
      Vue.set(state["variablePreFilterValue"], payload.module, payload.value);
    },
    SET_GLOBAL_VARIABLE_PRE_FILTER_ITEMS(state, payload) {
      if (!state["variablePreFilterItems"]) {
        Vue.set(state, "variablePreFilterItems", {});
      }
      Vue.set(state["variablePreFilterItems"], payload.module, payload.values);
    }
  }
};
