<template>
  <div>
    <v-row class="mb-n1">
      <v-col class="ml-3 px-0 py-0">
        <base-label :label="label" />
      </v-col>
    </v-row>
    <v-row class="px-0 py-0">
      <v-col class="pt-1 pb-2 pr-0">
        <container-input-field
          :recordId="recordId"
          :recordEndpoint="recordEndpoint"
          :field="fieldAmount"
          :newRecord="newRecord"
          :modalColor="modalColor"
          :storeNewRecordImmediately="storeNewRecordImmediately"
          @storeNewRecordImmediately="$emit('storeRecord')"
        >
          <div slot-scope="field" v-if="!isHidden">
            <v-text-field
              :class="{
                alternativeCurrencyAmountFieldDesign: field.alternativeDesign,
                'body-2': field.alternativeDesign,
                p_TextInputReadOnly: isReadonly,
              }"
              reverse
              elevation="0"
              placeholder="amt."
              :value="addThousandSeparator(field.value)"
              @focus="$event.target.select()"
              @change="
                setValue($event);
                field.updateValue(value);
              "
              :rules="rules"
              hide-details="auto"
              autocomplete="disabled"
              :readonly="isReadonly"
              :solo="field.alternativeDesign"
              :dense="field.alternativeDesign"
              :flat="field.alternativeDesign"
            ></v-text-field>
          </div>
        </container-input-field>
      </v-col>
      <v-col class="pt-1 pb-2 pl-0" cols="auto">
        <container-input-field
          :recordId="recordId"
          :recordEndpoint="recordEndpoint"
          :field="fieldCurrency"
          :newRecord="newRecord"
          :modalColor="modalColor"
          :storeNewRecordImmediately="storeNewRecordImmediately"
          @storeNewRecordImmediately="$emit('storeRecord')"
        >
          <div slot-scope="field" v-if="!isHidden">
            <v-select
              style="width: 81px"
              :class="{
                alternativeCurrencySelectFieldDesign: field.alternativeDesign,
                'body-2': field.alternativeDesign,
              }"
              placeholder="ccy."
              :value="field.value"
              @change="field.updateValue"
              :color="field.color"
              :items="items"
              :solo="field.alternativeDesign"
              :dense="field.alternativeDesign"
              :flat="field.alternativeDesign"
              autocomplete="disabled"
              hide-details
            ></v-select>
          </div>
        </container-input-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    fieldAmount: {
      type: String,
    },
    fieldCurrency: {
      type: String,
    },
    items: {
      type: Array,
    },
    columns: {
      type: Number,
      default: 12,
    },
    validationRules: {
      type: Array,
      default: () => [],
    },
    buttonType: {
      type: String,
    },
    urlPath: {
      type: String,
      default: "",
    },
    urlSuffix: {
      type: String,
      default: "",
    },
    isReadonly: {
      type: Boolean,
    },
    accessorRegEx: {
      type: String,
    },
    thousandSeparator: {
      type: String,
      default: ",",
    },
  },
  data() {
    return {
      value: null,
      rulesCollection: {
        required: (value) => !!value || "Required value!",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail address!";
        },
        numericOnly: (value) =>
          value > 0 || "The entered value must be numeric!",
      },
    };
  },
  computed: {
    rules() {
      let array = [];
      this.validationRules.forEach((item) => {
        array = [...array, this.rulesCollection[item]];
      });
      return array;
    },
  },
  methods: {
    setValue(value) {
      this.value = value.replace(this.thousandSeparator, "");
    },
    addThousandSeparator(amount) {
      if (
        amount !== "" &&
        amount !== undefined &&
        amount !== 0 &&
        amount !== "0" &&
        amount !== null
      ) {
        return amount
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSeparator);
      } else {
        return amount;
      }
    },
    ignore() {
      return null;
    },
  },
};
</script>
<style>
.alternativeCurrencyAmountFieldDesign .v-input__slot {
  border: 0.25px solid #aaa;
  padding-left: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.alternativeCurrencySelectFieldDesign .v-input__slot {
  border: 0.25px solid #aaa;
  border-left: none;
  padding-left: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.alternativeCurrencyAmountFieldDesign .v-input__control {
  min-height: 34px !important;
}
.alternativeCurrencySelectFieldDesign .v-input__control {
  min-height: 34px !important;
}
.p_TextInputReadOnly > .v-input__control > .v-input__slot {
  background: none !important;
}
.alternativeCurrencySelectFieldDesign .v-icon {
  margin-top: 0px !important;
  font-size: 18px !important;
  color: #666 !important;
}
</style>
