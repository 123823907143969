<template>
  <container-query-builder>
    <div
      slot-scope="{
        language,
        color,
        queryBuilderIsActive,
        query,
        queryName,
        loading,
        addNewQuery,
        performFind,
        moduleName,
        showQueryBuilder,
        toggleQueryBuilder,
        showAllRecords,
      }"
    >
      <v-dialog
        :value="showQueryBuilder"
        @input="toggleQueryBuilder"
        width="800"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :hide-overlay="$vuetify.breakpoint.xsOnly"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            color="white"
            :input-value="queryBuilderIsActive"
          >
            <v-icon color="white">mdi-filter</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline grey lighten-4 pb-0 pt-2" primary-title>
            <v-tabs
              :color="color"
              background-color="grey lighten-4"
              v-model="tab"
              show-arrows
            >
              <v-tab>{{ texts["extendedSearch"][language] }}</v-tab>
              <v-tab>
                {{ texts["storedQueries"][language] }} ({{ moduleName }})
              </v-tab>
            </v-tabs>
          </v-card-title>
          <div v-if="tab == 0">
            <v-card-text
              v-if="loading || loading2"
              class="text-center pt-7"
              style="height: 75vh; overflow: scroll"
            >
              <v-progress-circular
                indeterminate
                :color="color"
              ></v-progress-circular>
            </v-card-text>
            <v-card-text
              class="pt-3 white"
              style="height: 75vh; overflow: scroll"
              v-if="!(loading || loading2)"
            >
              <h1 class="headline px-3 pb-3" v-if="queryName">
                {{ queryName }}:
              </h1>
              <div v-for="(orQuery, key) in query.orQueries" :key="key">
                <app-query-builder-query :key="key" />
                <p
                  class="mb-3 ml-3 subtitle-1 font-weight-medium"
                  v-if="key != query.orQueries.length - 1"
                  style="color: black !important"
                >
                  {{ texts["OR"][language] }}
                </p>
              </div>

              <v-btn text medium class="mt-2 px-2 mb-0" @click="addNewQuery()">
                <v-icon>mdi-plus</v-icon>
                <span>{{ texts["newQuery"][language] }}</span>
              </v-btn>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="px-5 py-3">
              <app-query-builder-save-buttons />
              <div class="flex-grow-1"></div>
              <!-- <v-spacer></v-spacer> -->
              <v-btn :color="color" outlined @click="showAllRecords">
                {{ texts["showAllRecords"][language] }}
              </v-btn>
              <div class="flex-grow-1"></div>
              <v-btn :color="color" dark @click="performFind">{{
                texts["performQuery"][language]
              }}</v-btn>
            </v-card-actions>
          </div>
          <div v-if="tab == 1">
            <app-query-builder-stored-queries @switchTabs="switchTabs" />
          </div>
        </v-card>
      </v-dialog>
    </div>
  </container-query-builder>
</template>

<script>
export default {
  data() {
    return {
      tab: 0,
      loading2: false,
      texts: {
        extendedSearch: {
          en: "Extended Query",
          da: "udvidet søgning",
          de: "erweiterte Suche",
        },
        storedQueries: {
          en: "Saved Queries",
          da: "Gemte søgninger",
          de: "Gespeicherte Abfragen",
        },
        OR: {
          en: "OR",
          da: "ELLER",
          de: "ODER",
        },
        newQuery: {
          en: "new query",
          da: "ny søgning",
          de: "neue Suchabfrage",
        },
        performQuery: {
          en: "perform query",
          da: "søg",
          de: "Suchabfrage ausführen",
        },
        showAllRecords: {
          en: "show all records",
          da: "vis alle emner",
          de: "alle Datensätze anzeigen",
        },
      },
    };
  },
  methods: {
    switchTabs() {
      this.loading2 = true;
      this.tab = 0;
      let self = this;
      setTimeout(function () {
        self.loading2 = false;
      }, 500);
    },
  },
  mounted() {
    this.tab = 0;
  },
};
</script>
