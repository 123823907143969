<template>
  <div>
    <slot :importOg="importOg" :loading="loading"></slot>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false
    };
  },
  props: {
    urlField: {
      type: String
    },
    titleField: {
      type: String
    },
    descriptionField: {
      type: String
    },
    imageField: {
      type: String
    },
    recordEndpoint: {
      type: String
    },
    recordId: {
      type: [String, Number]
    }
  },
  computed: {
    url() {
      return this.record[this.urlField];
    },
    record() {
      return this.$store.state[this.recordEndpoint]["selectedRecords"][
        this.recordId
      ];
    }
  },
  methods: {
    importOg() {
      this.loading = true;
      axios
        .get("/ogtags?url=" + this.url)
        .then(response => this.persistOnServer(response.data));
    },
    persistOnServer(data) {
      let path = this.recordEndpoint + "/" + this.recordId;
      let fieldData = {};
      if (this.titleField) {
        fieldData[this.titleField] = data.title;
      }
      if (this.imageField) {
        fieldData[this.imageField] = data.image;
      }
      if (this.descriptionField) {
        fieldData[this.descriptionField] = data.description;
      }
      let payloadData = {};
      payloadData["data"] = fieldData;
      payloadData["load"] = true;
      axios
        .patch(path, payloadData)
        .then(r => r.data)
        .then(data => {
          this.setRecord(data);
          this.loading = false;
        })
        .catch(error => this.$store.dispatch("handleError", error));
    },
    setRecord(data) {
      this.$store.commit("SET_RECORD_DATA", {
        recordId: this.recordId,
        endpoint: this.recordEndpoint,
        data
      });
    }
  }
};
</script>
