<template>
  <container-config>
    <div slot-scope="{ design, app }">
      <container-list ref="list">
        <div
          slot-scope="{ pageData, recordCount }"
          :style="{ 'background-color': app.colorBackground }"
        >
          <div
            style="border-bottom: 1px solid lightgrey;"
            :style="{
              height: design.listHeaderHeight + 'px'
            }"
          >
            <app-record-master-list-header />
          </div>
          <div
            :style="{
              height:
                'calc(' +
                windowHeight +
                'px' +
                ' - ' +
                (design.headerHeight +
                  design.listHeaderHeight +
                  design.listFooterHeight +
                  (recordCount > 100 ? design.listPaginationHeight : 0)) +
                'px)'
            }"
            style="overflow:scroll"
          >
            <app-record-master-list-progress />
            <app-record-master-list-body
              v-if="pageData != undefined"
              :pageData="pageData"
            />
          </div>
          <div
            v-if="recordCount > 100"
            style="border-top: 0.25px solid #aaa;"
            :style="{
              height: design.listPaginationHeight + 'px'
            }"
          >
            <app-pagination />
          </div>
          <div
            style="border-top: 0.25px solid #aaa;"
            :style="{
              height: design.listFooterHeight + 'px'
            }"
          >
            <app-record-master-list-footer />
          </div>
        </div>
      </container-list>
    </div>
  </container-config>
</template>

<script>
export default {
  data() {
    return {
      windowHeight: 0
    };
  },
  methods: {
    refreshPage() {
      this.$refs.list.refreshPage();
    },
    handleResize() {
      this.windowHeight = window.innerHeight;
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>
