<template>
  <container-config>
    <div slot-scope="{ color, language }">
      <container-alerts @closeDialog="dialog = false">
        <div slot-scope="{ alerts, saveAlert }">
          <div>
            <v-card>
              <v-card-title
                class="pl-8 white--text"
                :style="{ 'background-color': color }"
                ><v-icon dark left>mdi-alarm</v-icon
                >{{ texts["alerts"][language] }}
                <v-spacer></v-spacer>
                <v-btn outlined dark @click="newItem()"
                  ><v-icon small left>mdi-plus</v-icon
                  >{{ texts["addNewAlert"][language] }}</v-btn
                >
              </v-card-title>
              <v-card-text>
                <v-data-table
                  v-if="alerts"
                  :items="alerts"
                  :headers="headers"
                  fixed-header
                  hide-default-footer
                  :items-per-page="100"
                  style="border-bottom: solid 1pt #ddd; border-radius: 0px;"
                >
                  <template v-slot:item.action="{ item }">
                    <v-btn icon @click="editItem(item)">
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions class="px-5 pb-7">
                <v-spacer></v-spacer>
                <v-btn :color="color" @click="$emit('closeAlertsDialog')" dark>
                  {{ texts["ok"][language] }}
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-dialog v-model="dialog" width="700">
              <v-card>
                <v-card-title
                  class="pl-8 white--text pb-5"
                  :style="{ 'background-color': color }"
                >
                  {{ texts["pleaseEnterDateAndMessage"][language] }}
                </v-card-title>
                <v-card-text class="pt-3 pb-0">
                  <v-row>
                    <v-col cols="auto" class="pb-0">
                      <v-date-picker
                        :color="color"
                        :label="texts['alertDate'][language]"
                        v-model="inputFields.date"
                        first-day-of-week="1"
                      />
                    </v-col>
                    <v-col class="pb-0">
                      <v-textarea
                        :color="color"
                        :label="texts['message'][language]"
                        rows="13"
                        :placeholder="
                          texts['selectDateAndWriteMessage'][language]
                        "
                        v-model="inputFields.message"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="px-5 pb-7">
                  <v-spacer></v-spacer>
                  <v-btn @click="dialog = false" class="mr-3">
                    {{ texts["cancel"][language] }}
                  </v-btn>
                  <v-btn :color="color" @click="saveAlert(inputFields)" dark>
                    {{ texts["ok"][language] }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </container-alerts>
    </div>
  </container-config>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  },
  data() {
    return {
      dialog: false,
      headers: [
        { text: "Date", value: "date" },
        { text: "Message", value: "message" },
        { value: "action" }
      ],
      inputFields: {
        date: null,
        message: null,
        record_name: this.title
      },
      texts: {
        alerts: {
          en: "Alerts",
          da: "Notifikationer",
          de: "Reminder"
        },
        ok: {
          en: "OK",
          da: "OK",
          de: "OK"
        },
        cancel: {
          en: "cancel",
          da: "afbryd",
          de: "abbrechen"
        },
        addNewAlert: {
          en: "add new alert",
          da: "tilføj ny notifikation",
          de: "neuen Reminder erstellen"
        },
        alertDate: {
          en: "Alert Date",
          da: "Datum for notifikation",
          de: "Reminder Datum"
        },
        message: {
          en: "Message",
          da: "Meddelelse",
          de: "Mitteilung"
        },
        selectDateAndWriteMessage: {
          en: "enter alert message here",
          da: "skriv beskedet for notifikationen her",
          de: "Nachricht für Reminder hier eintragen"
        },
        pleaseEnterDateAndMessage: {
          en: "Please enter the date and message for the alert",
          da: "Venligst vælg dato og skriv besked for notifikationen",
          de: "Bitte das Datum und die Mitteilung für den Reminder eingeben"
        }
      }
    };
  },
  methods: {
    editItem(item) {
      this.dialog = true;
      this.inputFields.date = item.date;
      this.inputFields.message = item.message;
      this.inputFields.id = item.id;
    },
    newItem() {
      this.dialog = true;
      this.inputFields.date = null;
      this.inputFields.message = null;
      this.inputFields.id = "new";
    }
  }
};
</script>
