<template>
  <container-config>
    <div slot-scope="config">
      <container-list>
        <div slot-scope="{ selectedRecord }">
          <container-record
            :endpoint="config.module.basicConfig.apiEndpoint"
            :recordId="recordId"
            :key="recordId"
            :preloadedRecordData="selectedRecord"
            :topLevelRecord="true"
            @refreshPage="refreshPage()"
          >
            <div
              slot-scope="{ record, executeDeletion }"
              v-if="recordId && record"
              :style="{ 'background-color': config.app.colorBackground }"
            >
              <!-- header -->
              <div
                :style="{
                  height: config.recordHeaderHeight + 'px',
                }"
                style="border-bottom: 1px solid lightgrey"
              >
                <app-record-detail-header
                  :record="record"
                  @executeDeletion="executeDeletion"
                />
              </div>
              <!-- tabs -->
              <div>
                <app-record-detail-tabs :record="record" />
              </div>
              <!-- record detail body -->
              <div
                :style="{
                  height:
                    'calc(' +
                    windowHeight +
                    'px' +
                    ' - ' +
                    (config.recordHeaderHeight +
                      config.design.recordFooterHeight +
                      (config.module.tabs.length > 1
                        ? config.design.recordTabsHeight
                        : 0) +
                      config.design.headerHeight) +
                    'px)',
                }"
                class="px-3"
                style="overflow: scroll"
              >
                <app-record-detail-body
                  :key="$store.state.recordComponentRerenderKey"
                  :record="record"
                  :recordId="record.id"
                  :recordEndpoint="config.module.basicConfig.apiEndpoint"
                />
              </div>
              <!-- record detail footer -->
              <div
                style="border-top: 1px solid lightgrey"
                :style="{
                  height: config.design.recordFooterHeight + 'px',
                }"
              >
                <app-record-detail-footer-mobile
                  v-if="isMobile"
                  :record="record"
                  @executeDeletion="executeDeletion"
                />
                <app-record-detail-footer v-else />
              </div>
            </div>
          </container-record>
        </div>
      </container-list>
    </div>
  </container-config>
</template>

<script>
export default {
  data() {
    return {
      windowHeight: null,
    };
  },
  computed: {
    recordId() {
      return this.$route.params.record_id;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    refreshPage() {
      this.$emit("refreshPage");
    },
    handleResize() {
      this.windowHeight = window.innerHeight;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
