<template>
  <container-record
    :endpoint="endpoint"
    :recordId="1"
    :key="1"
    :topLevelRecord="true"
    style="background-color: #f2f2f4; min-height: 100vh"
  >
    <v-row justify="center">
      <div class="pa-6" style="min-width: 800px; max-width: 2000px">
        <app-subcomponents
          v-bind="{
            components,
            recordId: 1,
            recordEndpoint: endpoint,
            pathArray,
          }"
        />
        <!-- <component
          v-for="(component, key) in components"
          :key="key"
          v-bind:is="component.componentType"
          v-bind="{ ...component.props, recordId: 1, recordEndpoint: endpoint }"
        ></component> -->
      </div>
    </v-row>
  </container-record>
</template>

<script>
export default {
  props: {
    components: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
    },
    endpoint: {
      type: String,
    },
  },
  computed: {
    modulePathName() {
      return this.$route.params.module;
    },
    selectedModuleIndex() {
      let modules = this.$store.state.config.app.modules;
      let index = modules.findIndex(
        (item) => item.basicConfig.path == this.modulePathName
      );
      return index;
    },
    pathArray() {
      return [
        "config",
        "app",
        "modules",
        this.selectedModuleIndex,
        "components",
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
