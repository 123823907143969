<template>
  <div>
    <container-input-field
      :recordEndpoint="recordEndpoint"
      :recordId="recordId"
      :field="field"
      :newRecord="newRecord"
      :modalColor="modalColor"
      :storeNewRecordImmediately="storeNewRecordImmediately"
      @storeNewRecordImmediately="$emit('storeRecord')"
    >
      <div slot-scope="field">
        <div style="min-height: 34px; padding-top: 13px">
          <v-checkbox
            @change="field.updateValue"
            :input-value="field.value"
            value
            :label="label"
            hide-details="auto"
          />
        </div>
      </div>
    </container-input-field>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    items: {
      type: Array,
    },
    row: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
