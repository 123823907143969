var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isHidden)?_c('v-col',{staticClass:"py-0",class:{ customClass: _vm.customClass },style:({
    'max-width': _vm.maxWidth + 'px',
    'border-right': _vm.borderRightStyle,
  }),attrs:{"cols":"12","md":_vm.columns}},[_c('div',{class:{
      config: _vm.configMode,
      columnSelected: _vm.columnSelected,
      'elevation-9': _vm.configMode && !_vm.columnSelected,
      'elevation-2': _vm.columnSelected,
    }},[(_vm.configMode)?_c('div',{staticClass:"configButtons"},[_c('v-btn',{staticClass:"small-icon",staticStyle:{"margin-right":"2px","margin-top":"5px"},style:({
          'background-color': _vm.columnSelected ? 'cornflowerblue' : '#aaa',
        }),attrs:{"x-small":"","icon":""},on:{"click":_vm.editComponent}},[_c('v-icon',{attrs:{"x-small":"","color":"white"}},[_vm._v("mdi-pencil")])],1),_c('br'),_c('v-btn',{staticStyle:{"margin-top":"0px","margin-left":"-5px","margin-bottom":"5px"},attrs:{"x-small":"","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-drag")])],1)],1):_vm._e(),_c('app-subcomponents',_vm._b({class:{ subcomponentsConfig: _vm.configMode },attrs:{"pathArray":_vm.pathArray.concat( ['props'], ['components'])},on:{"closeModal":function($event){return _vm.$emit('closeModal')}}},'app-subcomponents',{
        components: _vm.components,
        recordId: _vm.recordId,
        recordEndpoint: _vm.recordEndpoint,
        newRecord: _vm.newRecord,
      },false)),_c('v-dialog',{attrs:{"width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"background-color":"#f2f2f2"}},[_c('v-card-title',{staticStyle:{"background-color":"lightslategray","color":"white"}},[_vm._v(" Column ")]),_c('v-card-text',[_c('config-component-editor',{attrs:{"pathArray":_vm.pathArray,"component":_vm.component}})],1),_c('v-card-actions',{staticStyle:{"background-color":"lightslategray"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",on:{"click":function($event){_vm.dialog = false}}},[_vm._v("OK")])],1)],1)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }