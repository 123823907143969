<template>
  <container-record
    :endpoint="recordEndpoint"
    :recordId="recordId"
    :load="false"
  >
    <div slot-scope="record" class="gallery pa-3 mt-3 mb-3" v-if="!isHidden">
      <masonry
        v-if="record.record[endpoint] && record.record[endpoint].length > 0"
        :cols="4"
        :gutter="10"
      >
        <div v-for="asset in record.record[endpoint]" :key="asset.id">
          <base-image-gallery-tile
            :recordId="asset.id"
            :recordEndpoint="endpoint"
            :asset="asset"
            @refreshOriginRecord="refreshOriginRecord"
            v-bind="$props"
            class="mb-3"
          />
        </div>
      </masonry>
      <v-row v-else class="px-1">
        <v-col></v-col>
        <v-col cols="6">
          <div class="overline grey--text darken-3">
            No images uploaded in this gallery.
          </div>
        </v-col>
        <v-col></v-col>
      </v-row>
      <v-row class="px-3">
        <v-col>
          <base-dropzone-s3
            :recordId="recordId"
            :recordEndpoint="recordEndpoint"
            :uniqueSerialId="record.record[uploadIdentifierField]"
          >
          </base-dropzone-s3>
        </v-col>
      </v-row>
    </div>
  </container-record>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
export default {
  mixins: [baseComponentMixin],
  props: {
    endpoint: {
      type: String,
    },
    pathFields: {
      type: Array,
    },
    urlThumbnail: {
      type: String,
    },
    urlLarge: {
      type: String,
    },
    urlOriginal: {
      type: String,
    },
    imageInformationField: {
      type: String,
    },
    imageRatioField: {
      type: String,
    },
    uploadIdentifierField: {
      type: String,
    },
  },
  methods: {
    refreshOriginRecord() {
      let payload = {
        recordId: this.recordId,
        endpoint: this.recordEndpoint,
      };
      this.$store.dispatch("refreshOriginRecord", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  border: 0.25px solid #aaa !important;
  border-radius: 3px;
}
</style>
