<template>
  <container-uiState>
    <div slot-scope="{ changeView }">
      <container-config>
        <div slot-scope="{ design, moduleName }">
          <container-list>
            <div
              slot-scope="{
                pageData,
                recordCount,
                sortOrder,
                showProgressIndicator,
                selectedModuleList,
                page,
                updateSortBy,
                updateSortDesc
              }"
            >
              <div
                :style="{
                  height:
                    'calc(100vh - ' +
                    (design.listHeaderHeight +
                      (recordCount > 100 ? design.listFooterHeight : 0) +
                      design.headerHeight) +
                    'px)'
                }"
                style="overflow:scroll"
              >
                <v-data-table
                  fixed-header
                  hide-default-footer
                  :items-per-page="100"
                  :headers="selectedModuleList.headers"
                  :items="pageData"
                  :loading="showProgressIndicator"
                  multi-sort
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  @update:sort-by="updateSortBy"
                  @update:sort-desc="updateSortDesc(value)"
                >
                  <template v-slot:item.action="{ item }">
                    <v-btn
                      icon
                      :to="{
                        path: `/${moduleName}/${item.id}`,
                        query: $route.query
                      }"
                      @click="changeView('masterDetail')"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
            </div>
          </container-list>
        </div>
      </container-config>
    </div>
  </container-uiState>
</template>

<script>
export default {
  data() {
    return {
      headers: [],
      sortBy: [],
      sortDesc: [],
      value: null
    };
  }
};
</script>
