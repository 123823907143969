<template>
  <div>
    <v-row>
      <v-col>
        <v-switch
          label="Config Mode"
          v-model="configMode"
          class="config-switch"
        ></v-switch>
      </v-col>
      <v-col class="pa-0 ma-0">
        <v-btn icon @click="dialog = true">
          <v-icon color="#ddd">mdi-content-save</v-icon>
        </v-btn>
        <v-dialog max-width="500" v-model="dialog">
          <v-card style="background-color: #f2f2f2">
            <v-card-title style="background-color: lightslategray; color: white"
              >Save Config</v-card-title
            >
            <v-card-text>
              <v-textarea
                solo
                placeholder="Changelog"
                v-model="changelog"
                label="Changelog"
                class="mt-6"
              ></v-textarea>
            </v-card-text>
            <v-card-actions class="px-6 pb-6">
              <v-btn @click="dialog = false"> cancel </v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="saveConfig">
                <v-icon left>mdi-content-save</v-icon> save config
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog max-width="400" v-model="storageDialog">
          <v-card
            :style="{ 'background-color': storageDialogColor }"
            class="pt-6"
          >
            <v-card-text>
              <p class="title" style="color: white">{{ storageMessage }}</p>
            </v-card-text>
            <v-card-actions class="px-6 pb-6">
              <v-spacer></v-spacer>
              <v-btn
                @click="
                  dialog = false;
                  storageDialog = false;
                "
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col class="pa-0 ma-0">
        <v-btn icon @click="refreshLocally">
          <v-icon color="#ddd">mdi-download-box-outline</v-icon>
        </v-btn>
      </v-col>
      <GlobalEvents
        @keydown.meta.alt.75.exact.prevent="configMode = !configMode"
      />
      <GlobalEvents @keydown.meta.83.exact.prevent="dialog = true" />
      <GlobalEvents @keydown.meta.alt.82.exact.prevent="refreshLocally" />
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      changelog: null,
      storageDialog: null,
      storageDialogColor: "lightslategray",
      storageMessage: "default here",
    };
  },
  computed: {
    configMode: {
      get() {
        return this.$store.state.uiState.configMode;
      },
      set(value) {
        return this.$store.commit("TOGGLE_CONFIG_MODE", value);
      },
    },
  },
  methods: {
    refreshLocally() {
      axios.get("refresh-config").then(() => {
        this.$store.dispatch("loadConfigFromServer");
        console.log("config refreshed");
      });
    },
    saveConfig() {
      let payload = {};
      payload.config = JSON.parse(JSON.stringify(this.$store.state.config.app));
      payload.changelog = this.changelog;
      payload.newVersion = true;
      axios
        .post("/pianola-config", payload)
        .then(() => {
          this.storageMessage = "The config settings were successfully saved";
          this.changelog = null;
          this.dialog = false;
          this.storageDialog = true;
          this.storageDialogColor = "lightslategray";
        })
        .catch(() => {
          this.storageMessage =
            "There was a server error - the config settings could not be saved.";
          this.dialog = false;
          this.storageDialog = true;
          this.storageDialogColor = "crimson";
        });
    },
  },
};
</script>

<style>
.config-switch .v-label {
  font-size: 14px !important;
}
.config-switch {
  width: 130px;
}
</style>
