<template>
  <container-config>
    <div slot-scope="{ design, language }">
      <container-list>
        <div slot-scope="{ recordCount }">
          <v-row class="px-3 py-0">
            <v-spacer></v-spacer>
            <v-col cols="auto" class="pb-0 pt-3">
              <span>
                {{ recordCount }} {{ texts["foundRecords"][language] }}
              </span>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </div>
      </container-list>
    </div>
  </container-config>
</template>

<script>
export default {
  data() {
    return {
      texts: {
        foundRecords: {
          en: "found records",
          da: "emner fundet",
          de: "gefundene Datensätze"
        }
      }
    };
  }
};
</script>
