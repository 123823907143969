import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import auth from "./modules/auth";
import config from "./modules/config";
import components from "./modules/components";
import uiState from "./modules/uiState";
import list from "./modules/list";
import dropdownValues from "./modules/dropdownValues";
import queryBuilder from "./modules/queryBuilder";
import excelExporter from "./modules/excelExporter";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    recordComponentRerenderKey: 1
  },
  modules: {
    auth,
    config,
    components,
    uiState,
    list,
    dropdownValues,
    queryBuilder,
    excelExporter
  },
  mutations,
  actions,
  getters
});
