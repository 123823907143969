<template>
  <container-config>
    <div slot-scope="{ color, alternativeDesign }">
      <slot
        :value="displayedValue"
        :storedValue="storedValue"
        :updateValue="updateValue"
        :field="field"
        :alternativeDesign="alternativeDesign"
        :color="modalColor || color"
        :record="record"
      ></slot>
    </div>
  </container-config>
</template>

<script>
import axios from "axios";
import { get } from "lodash";
export default {
  props: {
    recordId: {
      type: [String, Number],
    },
    recordEndpoint: {
      type: String,
    },
    newRecord: {
      type: Boolean,
    },
    reloadRecordOnChange: {
      type: Boolean,
    },
    storeNewRecordImmediately: {
      type: Boolean,
    },
    field: {
      type: String,
    },
    displayField: {
      type: String,
    },
    modalColor: {
      type: String,
    },
  },
  computed: {
    storedValue() {
      return get(this.record, this.field);
    },
    displayedValue() {
      if (this.displayField) {
        return get(this.record, this.displayField);
      } else {
        return this.storedValue;
      }
    },
    persistedValue() {
      if (this.record) {
        return this.record[this.field];
      } else {
        return null;
      }
    },
    storedRecordId() {
      return this.record.id;
    },
    record() {
      if (this.$store.state[this.recordEndpoint]) {
        return this.$store.state[this.recordEndpoint].selectedRecords[
          this.recordId
        ];
      } else {
        return null;
      }
    },
    module() {
      return this.$route.params.module;
    },
    moduleEndpoint() {
      if (this.module) {
        let moduleConfig = this.$store.state.config.app.modules.find(
          (e) => e.basicConfig.path == this.module
        );
        return moduleConfig.basicConfig.apiEndpoint;
      } else {
        return null;
      }
    },
    moduleRecordId() {
      return this.$route.params.record_id;
    },
  },
  methods: {
    updateValue(value) {
      if (value === undefined) {
        value = null;
      }
      this.mutateState(value);
      if (!this.newRecord) {
        this.persistOnServer();
      } else if (this.storeNewRecordImmediately) {
        this.$emit("storeNewRecordImmediately");
      }
      if (this.moduleEndpoint && this.moduleEndpoint == this.recordEndpoint) {
        this.replaceRecordInPageArray();
      }
    },
    mutateState(value) {
      this.$store.commit("SET_FIELD_VALUE", {
        endpoint: this.recordEndpoint,
        recordId: this.recordId,
        field: this.field,
        value,
      });
    },
    waitForRecordToStore() {
      const conditionFunction = () => this.record.recordIsStoring == false;
      const poll = (resolve) => {
        if (conditionFunction()) resolve();
        else setTimeout(() => poll(resolve), 400);
      };
      return new Promise(poll);
    },
    async persistOnServer() {
      if (this.record.recordIsStoring) {
        await this.waitForRecordToStore();
      }
      let path = this.recordEndpoint + "/" + this.storedRecordId;
      let fieldData = {};
      fieldData[this.field] = this.persistedValue;
      let payloadData = {};
      payloadData["data"] = fieldData;
      if (this.displayField != undefined) {
        payloadData["load"] = true;
      }
      axios.defaults.withCredentials = true;
      axios
        .patch(path, payloadData)
        .then((r) => r.data)
        .then((data) => {
          if (this.displayField != undefined) {
            this.setRecord(data);
            this.$emit("handleUpdate");
          }
          if (this.reloadRecordOnChange) {
            this.refreshOriginRecord();
            this.refreshPage();
          }
        })
        .catch((error) => this.$store.dispatch("handleError", error));
    },
    setRecord(data) {
      this.$store.commit("SET_RECORD_DATA", {
        recordId: this.recordId,
        endpoint: this.recordEndpoint,
        data,
      });
    },
    replaceRecordInPageArray() {
      let page = this.$store.state[this.module].selectedPage;
      let pageData = this.$store.state[this.module].data[page];
      let recordIndex = pageData.findIndex((e) => e.id == this.recordId);
      this.$store.commit("REPLACE_PAGE_RECORD", {
        endpoint: this.module,
        page,
        recordIndex,
        record: this.record,
      });
    },
    refreshOriginRecord() {
      let payload = {
        recordId: this.recordId,
        endpoint: this.recordEndpoint,
      };
      this.$store.dispatch("refreshOriginRecord", payload);
      if (this.moduleEndpoint != this.recordEndpoint) {
        let payload = {
          recordId: this.moduleRecordId,
          endpoint: this.moduleEndpoint,
        };
        this.$store.dispatch("refreshOriginRecord", payload);
      }
    },
    refreshPage() {
      let payload = {};
      this.$store.dispatch("refreshPage", payload);
    },
  },
};
</script>
