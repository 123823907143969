<template>
  <container-config>
    <div class="pt-12" :style="background" slot-scope="{ color, language }">
      <v-container>
        <v-row>
          <v-col
            class="mx-auto"
            sm="8"
            lg="6"
            xl="4"
            style="margin-top: calc(50vh - 300px)"
          >
            <v-card class="mt-12">
              <v-card-title
                class="px-8 pb-4 white--text"
                :style="{ 'background-color': color }"
              >
                <h1 class="headline">
                  {{ texts["pleaseChangePassword"][language] }}
                </h1>
              </v-card-title>
              <v-card-text class="px-8 py-6">
                <v-text-field
                  :label="texts['newPassword'][language]"
                  prepend-inner-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  clearable
                  :color="color"
                  autocomplete="new-password"
                  :error-messages="errorMessage1"
                ></v-text-field>
                <v-text-field
                  :label="texts['repeatPassword'][language]"
                  prepend-inner-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password2"
                  clearable
                  :color="color"
                  autocomplete="new-password"
                  :error-messages="errorMessage2"
                ></v-text-field>
                <v-row>
                  <v-col>
                    <v-btn
                      outlined
                      :color="color"
                      class="my-2"
                      @click="generatePassword"
                      >{{ texts["generatePassword"][language] }}</v-btn
                    >
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="auto" class="justify-right">
                    <v-btn
                      :dark="!invalidPassword"
                      :color="color"
                      class="my-2"
                      type="submit"
                      @click="changePassword"
                      :disabled="invalidPassword"
                      >{{ texts["storePassword"][language] }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </container-config>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  data: () => ({
    showPassword: false,
    email: "",
    password: "",
    password2: "",
    error: null,
    minLength: 9,
    texts: {
      pleaseChangePassword: {
        en: "Please change your password!",
        da: "Skift venligst din adgangskode!",
        de: "Bitte Passwort ändern!",
      },
      generatePassword: {
        en: "generate password",
        da: "foreslå adgangskode",
        de: "Passwort generieren",
      },
      storePassword: {
        en: "change password",
        da: "skfit adgangskode",
        de: "Passwort ändern",
      },
      newPassword: {
        en: "new password",
        da: "ny adganskode",
        de: "neues Passwort",
      },
      repeatPassword: {
        en: "repeat password",
        da: "gentag adgangskode",
        de: "Passwort wiederholen",
      },
      noReuse: {
        en: "This password can not be used again!",
        da: "Denne adgangskode kan ikke bruges igen!",
        de: "Dieses Passwort darf nicht wieder benutzt werden!",
      },
      passwordNoMatch: {
        en: "The passwords do not match.",
        da: "Adgangskoderne matcher ikke.",
        de: "Die Passwörter stimmen nicht überein.",
      },
      passwordMustHave: {
        en: "The password must have at least ",
        da: "Adgangskoden skal bestå af mindst ",
        de: "Das Passwort muss mindestens",
      },
      characters: {
        en: " characters.",
        da: " tegn.",
        de: " Zeichen haben.",
      },
    },
  }),
  computed: {
    background() {
      return {
        height: "100vh",
        background:
          "linear-gradient(rgba(52, 52, 52, 0.6), rgba(52, 52, 52, 0.6)), url(" +
          this.url +
          ") no-repeat center center fixed",
        "background-size": "cover",
      };
    },
    url() {
      return this.$store.state.config.app.basicConfig.backgroundPath;
    },
    invalidPassword() {
      return (
        this.password !== this.password2 ||
        this.password === "" ||
        this.password === this.config.design.defaultPassword
      );
    },
    errorMessage1() {
      if (this.password === this.config.design.defaultPassword) {
        return this.textReturn("noReuse");
      } else if (
        this.password &&
        this.password.length < this.minLength &&
        this.password.length > 0
      ) {
        return (
          this.returnText("passwordMustHave") +
          this.minLength +
          this.returnText("characters")
        );
      } else {
        return null;
      }
    },
    errorMessage2() {
      if (this.invalidPassword && this.password2 && this.password2.length > 0) {
        return this.returnText("passwordNoMatch");
      } else {
        return null;
      }
    },
    ...mapState(["config"]),
  },
  methods: {
    generatePassword() {
      let CharacterSet =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789![]{}()%&*$#^<>~@|";
      let password = "";
      for (let i = 0; i < 20; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.showPassword = true;
      this.password = password;
      this.password2 = password;
    },
    changePassword() {
      let payload = { password: this.password };
      axios.post("change-password", payload).then(() => {
        if (
          this.$store.state.config.app.basicConfig.twoFactor &&
          (!this.$store.state.auth.user.two_factor_secret ||
            this.$store.state.auth.user.two_factor_secret.length == 0)
        ) {
          this.$router.push({ path: "/2fa-setup" });
        } else {
          this.$router.push({ path: "/" });
        }
      });
    },
    returnText(input) {
      let language = this.$store.state.config.app.basicConfig.appLanguage;
      let text = this.texts[input];
      return text[language];
    },
  },
  mounted() {
    this.password = "";
  },
};
</script>
