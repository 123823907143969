var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-ui-state',{scopedSlots:_vm._u([{key:"default",fn:function(uiState){return _c('div',{},[_c('container-config',{scopedSlots:_vm._u([{key:"default",fn:function(config){return _c('div',{},[_c('v-navigation-drawer',{staticClass:"elevation-3",attrs:{"app":"","clipped":_vm.$vuetify.breakpoint.lgAndUp,"color":config.app.colorBackgroundDrawer || config.app.colorBackground,"value":uiState.drawerState !== 'hide',"mini-variant":uiState.drawerState !== 'full',"width":"220","mini-variant-width":"80"},on:{"update:miniVariant":function($event){return _vm.$set(uiState, "drawerState !== 'full'", $event)},"update:mini-variant":function($event){return _vm.$set(uiState, "drawerState !== 'full'", $event)}}},[_c('v-list',{staticStyle:{"overflow":"scroll"},style:({
              height: 'calc(100vh - 180px)',
            }),attrs:{"color":config.app.colorBackgroundDrawer || config.app.colorBackground,"dark":config.app.drawerIsDark}},[_vm._l((config.modules),function(item,key){return _c('div',{key:key},[(
                  (!item.basicConfig.adminOnly || uiState.user.is_admin) &&
                  (JSON.parse(uiState.user.modules).includes(
                    item.basicConfig.name
                  ) ||
                    JSON.parse(uiState.user.modules).length == 0)
                )?_c('div',[(item.basicConfig.divider)?_c('v-divider',{staticClass:"mx-4 my-3",style:({
                    'border-color': config.app.drawerIsDark ? '#aaa' : '#666',
                  })}):_vm._e(),(
                    item.basicConfig.subheader &&
                    uiState.drawerState === 'full'
                  )?_c('v-subheader',{staticClass:"px-5"},[_vm._v(" "+_vm._s(item.basicConfig.subheader)+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","color":item.basicConfig.moduleColor || config.app.colorPrimary,"disabled":uiState.drawerState === 'full'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"to":("/" + (item.basicConfig.path))},on:{"click":function($event){_vm.isMobile ? uiState.toggleDrawer() : null}}},on),[_c('v-list-item-action',{staticClass:"ml-2 mr-4"},[_c('v-icon',{style:({
                            color:
                              config.app.colorItemDrawer ||
                              item.basicConfig.moduleColor ||
                              config.app.colorPrimary,
                          })},[_vm._v(_vm._s(item.basicConfig.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2",style:({
                            color:
                              config.app.colorItemDrawer ||
                              item.basicConfig.moduleColor ||
                              config.app.colorPrimary,
                          })},[_vm._v(" "+_vm._s(item.basicConfig.name.toUpperCase())+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.basicConfig.name))])])],1):_vm._e()])}),_c('v-divider',{staticClass:"mx-4 my-3",style:({
                'border-color': config.app.drawerIsDark ? '#aaa' : '#666',
              })}),(config.settings && uiState.canSeeSettings)?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","disabled":uiState.drawerState === 'full'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"to":"/settings"},on:{"click":function($event){_vm.isMobile ? uiState.toggleDrawer() : null}}},on),[_c('v-list-item-action',{staticClass:"ml-2 mr-4"},[_c('v-icon',{attrs:{"color":config.app.drawerIsDark ? '#ddd' : '#666'}},[_vm._v("mdi-cog")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2",style:({
                          color: config.app.drawerIsDark ? '#ddd' : '#666',
                        })},[_vm._v(" "+_vm._s(_vm.texts["settings"][config.language])+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.texts["settings"][config.language]))])])],1):_vm._e(),(uiState.user.is_admin)?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","disabled":uiState.drawerState === 'full'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"to":"/users"},on:{"click":function($event){_vm.isMobile ? uiState.toggleDrawer() : null}}},on),[_c('v-list-item-action',{staticClass:"ml-2 mr-4"},[_c('v-icon',{attrs:{"color":config.app.drawerIsDark ? '#ddd' : '#666'}},[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2",style:({
                          color: config.app.drawerIsDark ? '#ddd' : '#666',
                        })},[_vm._v(" "+_vm._s(_vm.texts["users"][config.language])+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.texts["users"][config.language]))])])],1):_vm._e()],2),_c('v-footer',{staticClass:"ma-0 pa-0",staticStyle:{"width":"98%"},attrs:{"absolute":"","color":config.app.colorBackgroundDrawer || config.app.colorBackground}},[_c('v-list',{staticStyle:{"width":"100%"},attrs:{"color":config.app.colorBackgroundDrawer || config.app.colorBackground,"dense":"","dark":config.app.drawerIsDark}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","color":config.app.colorPrimary,"disabled":uiState.drawerState === 'full'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"to":"/change-password"}},on),[_c('v-list-item-action',{staticClass:"ml-2 mr-4"},[_c('v-icon',{attrs:{"color":config.app.drawerIsDark ? '#ddd' : '#666'}},[_vm._v("mdi-lock")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2",style:({
                          color: config.app.drawerIsDark ? '#ddd' : '#666',
                        })},[_vm._v(" "+_vm._s(_vm.texts["changePassword"][config.language])+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.texts["changePassword"][config.language]))])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","color":config.app.colorPrimary,"disabled":uiState.drawerState === 'full'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-list-item',_vm._g({on:{"click":uiState.logout}},on),[_c('v-list-item-action',{staticClass:"ml-2 mr-4"},[_c('v-icon',{attrs:{"color":config.app.drawerIsDark ? '#ddd' : '#666'}},[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2",style:({
                          color: config.app.drawerIsDark ? '#ddd' : '#666',
                        })},[_vm._v(" "+_vm._s(_vm.texts["logout"][config.language])+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.texts["logout"][config.language]))])])],1)],1)],1)],1)}}],null,true)})],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }