<template>
  <container-record
    :endpoint="recordEndpoint"
    :recordId="recordId"
    :load="false"
  >
    <div slot-scope="record" class="mt-2">
      <v-img
        v-if="record.record && record.record[pathField]"
        :src="record.record[pathField] + randomString(record.record[pathField])"
        :max-height="maxHeight"
        :max-width="maxWidth"
        style="border-radius: 3px"
        contain
        @click="reloadRecord"
      />
    </div>
  </container-record>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
export default {
  mixins: [baseComponentMixin],
  data() {
    return {
      random: 12345,
    };
  },
  props: {
    pathField: {
      type: String,
    },
    maxHeight: {
      type: Number,
    },
    maxWidth: {
      type: Number,
    },
  },
  methods: {
    reloadRecord() {
      this.random = Math.floor(100000 + Math.random() * 900000);
      let payload = {
        recordId: this.recordId,
        endpoint: this.recordEndpoint,
      };
      this.$store.dispatch("refreshOriginRecord", payload);
    },
    randomString(input) {
      if (input.includes("?")) {
        return "&random=" + this.random;
      } else {
        return "?random=" + this.random;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
