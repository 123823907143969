<template>
  <div>
    <v-btn @click="triggerAction" tile x-small :loading="loading">
      <v-icon left small class="mr-1 ml-n1">mdi-download</v-icon>
      {{ buttonText }}
      <template v-slot:loader>
        <span class="custom-loader">
          <v-icon light small>mdi-cached</v-icon>
        </span>
      </template>
    </v-btn>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
    };
  },
  props: {
    fileUuid: {
      type: String,
    },
    linkPath: {
      type: String,
      default: null,
    },
    record: {
      type: Object,
    },
    originRecordId: {
      type: Number,
    },
    field: {
      type: String,
    },
    buttonText: {
      type: String,
      default: "click to download file",
    },
    buttonIcon: {
      type: String,
      default: null,
    },
  },
  computed: {
    url() {
      let computedLinkPath = this.linkPath
        .replace("{field}", this.record[this.field])
        .replace("{recordId}", this.record["id"])
        .replace("{originRecordId}", this.originRecordId);
      let url = computedLinkPath;
      return url;
    },
  },
  methods: {
    triggerAction() {
      this.loading = true;
      axios.get(this.url).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
