var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-config',{scopedSlots:_vm._u([{key:"default",fn:function(config){return _c('div',{},[_c('container-list',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var selectedRecord = ref.selectedRecord;
return _c('div',{},[_c('container-record',{key:_vm.recordId,attrs:{"endpoint":config.module.basicConfig.apiEndpoint,"recordId":_vm.recordId,"preloadedRecordData":selectedRecord,"topLevelRecord":true},on:{"refreshPage":function($event){return _vm.refreshPage()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var record = ref.record;
var executeDeletion = ref.executeDeletion;
return (_vm.recordId && record)?_c('div',{style:({ 'background-color': config.app.colorBackground })},[_c('div',{staticStyle:{"border-bottom":"1px solid lightgrey"},style:({
                height: config.recordHeaderHeight + 'px',
              })},[_c('app-record-detail-header',{attrs:{"record":record},on:{"executeDeletion":executeDeletion}})],1),_c('div',[_c('app-record-detail-tabs',{attrs:{"record":record}})],1),_c('div',{staticClass:"px-3",staticStyle:{"overflow":"scroll"},style:({
                height:
                  'calc(' +
                  _vm.windowHeight +
                  'px' +
                  ' - ' +
                  (config.recordHeaderHeight +
                    config.design.recordFooterHeight +
                    (config.module.tabs.length > 1
                      ? config.design.recordTabsHeight
                      : 0) +
                    config.design.headerHeight) +
                  'px)',
              })},[_c('app-record-detail-body',{key:_vm.$store.state.recordComponentRerenderKey,attrs:{"record":record,"recordId":record.id,"recordEndpoint":config.module.basicConfig.apiEndpoint}})],1),_c('div',{staticStyle:{"border-top":"1px solid lightgrey"},style:({
                height: config.design.recordFooterHeight + 'px',
              })},[(_vm.isMobile)?_c('app-record-detail-footer-mobile',{attrs:{"record":record},on:{"executeDeletion":executeDeletion}}):_c('app-record-detail-footer')],1)]):_vm._e()}}],null,true)})],1)}}],null,true)})],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }