<template>
  <container-config>
    <div slot-scope="{ design, language, moduleName, color }">
      <container-list>
        <div
          slot-scope="{
            recordCount,
            list,
            moduleLists,
            selectedModuleList,
            changeList,
          }"
          style="
            border-bottom: 1px solid lightgrey;
            background-color: #f3f3f3;
            overflow: hidden;
          "
          :style="{
            height: design.listHeaderHeight + 'px',
          }"
        >
          <v-row class="px-4">
            <v-col
              cols="auto"
              class="py-0 mb-0"
              style="
                border-right: thin #ddd solid;
                overflow: hidden;
                width: 300px;
              "
            >
              <app-record-filter />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="pt-5 text-center body-2">
              <!-- TO DO: translate this! -->
              {{ recordCount }} {{ texts["foundRecords"][language] }}</v-col
            >
            <v-spacer></v-spacer>
            <v-col
              class="mt-n3 px-4 listSelector"
              cols="auto"
              v-if="moduleLists.length > 1"
              style="
                padding-top: 10px;
                border-left: thin #ddd solid;
                border-right: thin #ddd solid;
              "
            >
              <v-select
                :color="color"
                :items="moduleLists"
                item-value="index"
                item-text="name"
                :value="list"
                ref="listSelector"
                @change="changeList"
                @input="blur"
                hide-details="auto"
                prepend-inner-icon="mdi-playlist-edit"
                style="width: 250px"
              />
            </v-col>
            <v-col
              class="py-2 mr-0"
              cols="auto"
              style="border-right: thin #ddd solid"
            >
              <app-excel-exporter
                :headers="selectedModuleList.headers"
                :moduleName="moduleName"
              >
                <div slot-scope="{ openDialog }">
                  <v-btn icon @click="openDialog">
                    <v-icon>mdi-file-excel</v-icon>
                  </v-btn>
                </div>
              </app-excel-exporter>
            </v-col>
            <v-col class="py-2 pr-3" cols="auto">
              <app-list-print-button :moduleName="moduleName" :list="list" />
            </v-col>
          </v-row>
        </div>
      </container-list>
    </div>
  </container-config>
</template>

<script>
export default {
  data() {
    return {
      texts: {
        foundRecords: {
          en: "found records",
          da: "emne fundet",
          de: "gefundene Datensätze",
        },
        list: {
          en: "list",
          da: "liste",
          de: "Liste",
        },
      },
    };
  },
  methods: {
    blur() {
      this.$refs.listSelector.blur();
    },
  },
};
</script>

<style>
.listSelector > .v-text-field > .v-input__control > .v-input__slot::before {
  border-style: none !important;
}
</style>
