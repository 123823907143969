var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-n1"},[_c('v-col',{staticClass:"ml-3 px-0 py-0"},[_c('base-label',{attrs:{"label":_vm.label}})],1)],1),_c('v-row',{staticClass:"px-0 py-0"},[_c('v-col',{staticClass:"pt-1 pb-2 pr-0"},[_c('container-input-field',{attrs:{"recordId":_vm.recordId,"recordEndpoint":_vm.recordEndpoint,"field":_vm.fieldAmount,"newRecord":_vm.newRecord,"modalColor":_vm.modalColor,"storeNewRecordImmediately":_vm.storeNewRecordImmediately},on:{"storeNewRecordImmediately":function($event){return _vm.$emit('storeRecord')}},scopedSlots:_vm._u([{key:"default",fn:function(field){return (!_vm.isHidden)?_c('div',{},[_c('v-text-field',{class:{
              alternativeCurrencyAmountFieldDesign: field.alternativeDesign,
              'body-2': field.alternativeDesign,
              p_TextInputReadOnly: _vm.isReadonly,
            },attrs:{"reverse":"","elevation":"0","placeholder":"amt.","value":_vm.addThousandSeparator(field.value),"rules":_vm.rules,"hide-details":"auto","autocomplete":"disabled","readonly":_vm.isReadonly,"solo":field.alternativeDesign,"dense":field.alternativeDesign,"flat":field.alternativeDesign},on:{"focus":function($event){return $event.target.select()},"change":function($event){_vm.setValue($event);
              field.updateValue(_vm.value);}}})],1):_vm._e()}}],null,true)})],1),_c('v-col',{staticClass:"pt-1 pb-2 pl-0",attrs:{"cols":"auto"}},[_c('container-input-field',{attrs:{"recordId":_vm.recordId,"recordEndpoint":_vm.recordEndpoint,"field":_vm.fieldCurrency,"newRecord":_vm.newRecord,"modalColor":_vm.modalColor,"storeNewRecordImmediately":_vm.storeNewRecordImmediately},on:{"storeNewRecordImmediately":function($event){return _vm.$emit('storeRecord')}},scopedSlots:_vm._u([{key:"default",fn:function(field){return (!_vm.isHidden)?_c('div',{},[_c('v-select',{class:{
              alternativeCurrencySelectFieldDesign: field.alternativeDesign,
              'body-2': field.alternativeDesign,
            },staticStyle:{"width":"81px"},attrs:{"placeholder":"ccy.","value":field.value,"color":field.color,"items":_vm.items,"solo":field.alternativeDesign,"dense":field.alternativeDesign,"flat":field.alternativeDesign,"autocomplete":"disabled","hide-details":""},on:{"change":field.updateValue}})],1):_vm._e()}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }