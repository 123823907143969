var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-config',{scopedSlots:_vm._u([{key:"default",fn:function(config){return _c('div',{},[_c('container-datatable',{attrs:{"recordId":_vm.recordId,"recordEndpoint":_vm.recordEndpoint,"tableEndpoint":_vm.endpoint},scopedSlots:_vm._u([{key:"default",fn:function(dataTable){return _c('div',{},[_c('base-title-input-section',{attrs:{"text":_vm.title}}),_vm._l((dataTable.items),function(card,key){return _c('v-card',{key:key,staticClass:"mt-1 elevation-1 mb-4",staticStyle:{"border":"0.25px solid #aaa"}},[_c('v-card-text',{staticClass:"black--text"},[_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"10"}},[(
                    (card.company && card.company.id) ||
                    (card.organisation && card.organisation.id)
                  )?_c('router-link',{staticClass:"font-weight-bold",staticStyle:{"font-size":"0.95rem"},style:({ color: config.app.colorPrimary }),attrs:{"to":'/' +
                    _vm.linkPath +
                    '/' +
                    (card.company ? card.company.id : card.organisation.id) +
                    '?link=true'}},[_vm._v(" "+_vm._s(card.company ? card.company.name : card.organisation.name)+" ")]):_vm._e()],1),_c('v-spacer'),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.editRecord(card)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1)],1),_c('div',{attrs:{"id":"addressBlock"}},[(
                  (card.company && card.company.street_1) ||
                  (card.organisation && card.organisation.street_1)
                )?_c('div',{staticClass:"body-2 mt-2"},[_vm._v(" "+_vm._s(card.company ? card.company.street_1 : card.organisation.street_1)+" ")]):_vm._e(),(
                  (card.company && card.company.street_2) ||
                  (card.organisation && card.organisation.street_2)
                )?_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(card.company ? card.company.street_2 : card.organisation.street_2)+" ")]):_vm._e(),(
                  (card.company && card.company.city) ||
                  (card.organisation && card.organisation.city)
                )?_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(card.company ? card.company.post_code : card.organisation.post_code)+" "+_vm._s(card.company ? card.company.city : card.organisation.city)+" ")]):_vm._e(),(
                  (card.company && card.company.country_id) ||
                  (card.organisation && card.organisation.country_id)
                )?_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(card.company ? card.company.country ? card.company.country.name_de : "" : card.organisation.country ? card.organisation.country.name_de : "")+" ")]):_vm._e()]),_c('div',{staticClass:"mt-2",attrs:{"id":"mailPhoneBlock"}},[_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(card.function_display || card.function)+_vm._s(card.function && card.department ? " – " : "")+_vm._s(card.department)+" ")]),(card.email || card.mail)?_c('div',{staticClass:"body-2 ml-n1"},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.sendEmail(card.email || card.mail)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email")])],1),_vm._v(" "+_vm._s(card.email || card.mail)+" ")],1):_vm._e(),(card.telephone || card.phone)?_c('div',{staticClass:"body-2 ml-n1"},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.showInOverlay(card.telephone || card.phone)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-phone")])],1),_vm._v(" "+_vm._s(card.telephone || card.phone)+" ")],1):_vm._e(),(card.mobile)?_c('div',{staticClass:"body-2 ml-n1"},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.showInOverlay(card.mobile)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cellphone")])],1),_vm._v(" "+_vm._s(card.mobile)+" ")],1):_vm._e(),(card.fax)?_c('div',{staticClass:"body-2 ml-n1"},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.showInOverlay(card.fax)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-fax")])],1),_vm._v(" "+_vm._s(card.fax)+" ")],1):_vm._e(),(
                  (card.company && card.company.website) ||
                  (card.organisation && card.organisation.website)
                )?_c('div',{staticClass:"body-2 ml-n1"},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.openWebsite(
                      card.company
                        ? card.company.website
                        : card.organisation.website
                    )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-web")])],1),_vm._v(" "+_vm._s(card.company ? card.company.website : card.organisation.website)+" ")],1):_vm._e()])],1),_c('v-overlay',{attrs:{"value":_vm.showOverlay,"opacity":"0.8"}},[_c('p',{staticClass:"white--text display-4"},[_vm._v(" "+_vm._s(_vm.overlayData)+" ")]),_c('v-btn',{staticClass:"white--text",attrs:{"right":"","x-large":"","outlined":""},on:{"click":function($event){_vm.showOverlay = false}}},[_vm._v(" OK ")])],1)],1)}),(!_vm.noNewRecord)?_c('v-btn',{staticClass:"mb-2 pl-3",attrs:{"small":"","text":"","color":"#666"},on:{"click":_vm.createNewSubrecord}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(_vm._s(_vm.newSubrecordButtonText)+" ")],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":_vm.maxDialogWidth,"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"hide-overlay":_vm.$vuetify.breakpoint.xsOnly},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.selectedRecord)?_c('base-record-modal',{key:_vm.modalKey,attrs:{"components":_vm.components,"preloadedRecord":_vm.selectedRecord,"endpoint":_vm.dialogEndpoint || _vm.endpoint,"newRecord":_vm.newSubrecord,"originEndpoint":_vm.recordEndpoint,"originRecordId":_vm.recordId,"modalColor":_vm.modalColor,"keepModalOpenOnNewRecordCommit":_vm.keepModalOpenOnNewRecordCommit},on:{"closeDialog":_vm.closeDialog,"handleNewRecordResponse":_vm.handleNewRecordResponse}}):_vm._e()],1)],2)}}],null,true)})],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }