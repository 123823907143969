<template>
  <div class="pb-4">
    <config-section-title v-if="sectionTitle" :title="sectionTitle" />
    <v-switch
      :label="labelConverted"
      v-model="fieldValue"
      hide-details
    ></v-switch>
  </div>
</template>

<script>
import { get, startCase } from "lodash";

export default {
  props: {
    getArray: {
      type: Array,
    },
    field: {
      type: String,
    },
    label: {
      type: String,
    },
    selfIcon: {
      type: Boolean,
      default: false,
    },
    sectionTitle: {
      type: String,
    },
  },
  computed: {
    fieldValue: {
      get() {
        return get(this.$store.state, [...this.getArray, this.field]);
      },
      set(input) {
        this.$store.dispatch("updateConfigValue", {
          input,
          field: this.field,
          getArray: this.getArray,
        });
      },
    },
    appendedIcon() {
      return this.selfIcon ? this.fieldValue : "";
    },
    labelConverted() {
      return startCase(this.label || this.field);
    },
  },
};
</script>
