<template>
  <v-row class="mt-0 pb-0 pt-1">
    <v-col class="my-0 px-0 py-0">
      <vue-dropzone
        ref="vueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        @vdropzone-files-added="addFiles"
        @vdropzone-processing="processFile"
        @vdropzone-sending="sending"
        @vdropzone-complete="uploadComplete"
        useCustomSlot
        style="width: 100%;"
      >
        <slot></slot>
        <div>
          <v-icon large>mdi-cloud-upload-outline</v-icon>
          <h3>
            Drag and drop to upload content!
          </h3>
          <div>
            ...or click to select a file from your computer
          </div>
        </div>
      </vue-dropzone>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { baseComponentMixin } from "../mixins/baseComponentMixin";

export default {
  mixins: [baseComponentMixin],
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    uniqueSerialId: {
      type: Number
    }
  },
  data() {
    return {
      dropzoneOptions: {
        url: "https://httpbin.org/post", //dummy url, replaced by AWS url below
        autoProcessQueue: false,
        // parallelUploads: 1,
        method: "put", //put necessary for S3 upload
        thumbnailWidth: null,
        thumbnailHeight: 100
      },
      numberOfFilesToBeUploaded: 0,
      storedSignedStorageDetails: {},
      storedFileRecordDetails: {}
    };
  },
  methods: {
    addFiles(files) {
      //create an array of promises
      var promises = [];
      for (var i = 0; i < files.length; i++) {
        var promise = this.prepareUpload(files[i]);
        promises.push(promise);
      }
      //only process the queue when all files have been prepared for upload
      Promise.all(promises).then(() => this.processQueue());
    },
    prepareUpload(file) {
      return new Promise(resolve => {
        //axios call
        axios
          /*get signed storage URL from AWS via server*/
          .post("/vapor/signed-storage-url", {
            file,
            size: file.size,
            content_type: file.type,
            unique_serial_id: this.uniqueSerialId
          })
          .then(response => {
            /*store signed storage URL and headers to data
            object for retrieval when actual upload is processed*/
            this.storeSignedStorageDetails(response.data);
            /*record details are stored in data so that they can be
            retrieved after successful upload and persisted to database*/
            this.storeRecordDetails(file, response.data);
            /*file is given an awsUuid parameter, so the stored record details
            can be identified after a successful upload*/
            file.awsUuid = response.data.uuid;
            //add 1 to number of files uploaded
            this.numberOfFilesToBeUploaded++;
            resolve(() => {});
          });
      });
    },
    storeSignedStorageDetails(data) {
      this.storedSignedStorageDetails[data.uuid] = {
        url: data.url,
        headers: data.headers
      };
    },
    storeRecordDetails(file, response) {
      let payload = {
        uuid: response.uuid,
        key: response.key,
        bucket: response.bucket,
        name: file.name,
        content_type: file.type
      };
      this.storedFileRecordDetails[response.uuid] = payload;
    },
    setDropzoneParameters(data) {
      this.$refs.vueDropzone.setOption("url", data.url);
      this.$refs.vueDropzone.setOption("headers", data.headers);
    },
    processQueue() {
      this.$refs.vueDropzone.processQueue();
    },
    processFile(file) {
      let uuid = file.awsUuid;
      this.setDropzoneParameters(this.storedSignedStorageDetails[uuid]);
    },
    //check here why this is necessary: https://github.com/rowanwins/vue-dropzone/issues/411
    sending(file, xhr) {
      var _send = xhr.send;
      xhr.send = function() {
        _send.call(xhr, file);
      };
    },
    uploadComplete(file) {
      let payload = this.storedFileRecordDetails[file.awsUuid];
      payload["width"] = file.width;
      payload["height"] = file.height;
      this.persistFileRecord(payload);
    },
    persistFileRecord(payload) {
      axios.post("file-upload", payload).then(() => {
        this.numberOfFilesToBeUploaded--;
        this.submitEventIfAllUploadsComplete();
      });
    },
    submitEventIfAllUploadsComplete() {
      if (this.numberOfFilesToBeUploaded == 0) {
        this.allUploadsComplete();
      }
    },
    allUploadsComplete() {
      this.refreshOriginRecord();
      setTimeout(
        function() {
          this.$refs.vueDropzone.removeAllFiles();
        }.bind(this),
        1000
      );
    },
    refreshOriginRecord() {
      let payload = {
        recordId: this.recordId,
        endpoint: this.recordEndpoint
      };
      this.$store.dispatch("refreshOriginRecord", payload);
    }
  }
};
</script>
<style>
.dropzone {
  border: none !important;
}
</style>
