import { render, staticRenderFns } from "./BaseRecordDataTableDownloadButton.vue?vue&type=template&id=cfc6f7ea&scoped=true&"
import script from "./BaseRecordDataTableDownloadButton.vue?vue&type=script&lang=js&"
export * from "./BaseRecordDataTableDownloadButton.vue?vue&type=script&lang=js&"
import style0 from "./BaseRecordDataTableDownloadButton.vue?vue&type=style&index=0&id=cfc6f7ea&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules.nosync/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfc6f7ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules.nosync/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
