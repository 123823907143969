import Inflector from "inflector-js";
import { get } from 'lodash';

export const baseComponentMixin = {
  props: {
    recordId: {
      type: [String, Number]
    },
    recordEndpoint: {
      type: String
    },
    components: {
      type: Array
    },
    modalColor: {
      type: String
    },
    newRecord: {
      type: Boolean
    },
    storeNewRecordImmediately: {
      type: Boolean
    },
    hideIf: {
      type: Array
    },
    pathArray: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    singularize(input) {
      return Inflector.singularize(input);
    }
  },
  computed: {
    record() {
      if (this.$store.state[this.recordEndpoint]) {
        return this.$store.state[this.recordEndpoint].selectedRecords[
          this.recordId
        ];
      } else {
        return null;
      }
    },
    isHidden() {
      let hideIfConditions = this.hideIf;
      if (!this.hideIf) {
        return null;
      }
      let checkArray = [];
      for (let i = 0; i < hideIfConditions.length; i++) {
        let condition = hideIfConditions[i];
        let compareValue = get(this.record,condition.field);
        let conditionValue = condition.value;
        let conditionOperator = condition.operator;
        if (
          (compareValue != undefined &&
            (
              //match for "==" operator or no defined operator (equivalent to "==")
              ((condition.operator == "==" || conditionOperator == undefined) &&
              compareValue == conditionValue) ||
              //match for ">=" operator
              (conditionOperator == ">=" &&
                compareValue >= conditionValue) ||
              //match for ">" operator
              (conditionOperator == ">" &&
                compareValue > conditionValue) ||
              //match for "<=" operator
              (conditionOperator == "<=" &&
                compareValue <= conditionValue) ||
              //match for "<" operator
              (conditionOperator == "<" &&
                compareValue < conditionValue) ||
              //match for "contains" operator
              (conditionOperator == "contains" &&
                compareValue.includes(conditionValue) > 0) ||
              //match for "!contains" operator
              (conditionOperator == "!contains" &&
                compareValue.includes(conditionValue) == 0) ||
              //match for "!=" operator
              (conditionOperator == "!=" &&
                compareValue != conditionValue)
            )
          )
          ||
          //match for unequal or empty on undefined field
          (compareValue == undefined &&
            (conditionOperator == "!=" || conditionOperator == "ø")
          )
        ) {
          checkArray.push(true);
        } else {
          checkArray.push(false);
        }
      }
      return checkArray.some(v => v === true);
    }
  }
};
