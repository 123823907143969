<template>
  <div>
    <slot
      :joinRecord="joinRecord"
      :display="display"
      :displayLabel="displayLabel"
      :aOrB="aOrB"
      :deleteRecord="deleteRecord"
    ></slot>
  </div>
</template>

<script>
import Inflector from "inflector-js";
import axios from "axios";
export default {
  props: {
    recordEndpoint: {
      type: String,
    },
    endpoint: {
      type: String,
    },
    recordId: {
      type: Number,
    },
    fieldA: {
      type: String,
    },
    fieldB: {
      type: String,
    },
  },
  computed: {
    record() {
      return this.$store.state[this.recordEndpoint]["selectedRecords"][
        this.recordId
      ];
    },
    fieldAPluralized() {
      return Inflector.pluralize(this.fieldA);
    },
    fieldBPluralized() {
      return Inflector.pluralize(this.fieldB);
    },
    joinRecordA() {
      if (this.record && this.record[this.fieldAPluralized]) {
        return this.record[this.fieldAPluralized][0] || null;
      } else {
        return null;
      }
    },
    joinRecordB() {
      if (this.record && this.record[this.fieldBPluralized]) {
        return this.record[this.fieldBPluralized][0] || null;
      } else {
        return null;
      }
    },
    displayA() {
      if (this.joinRecordA) {
        return this.joinRecordA[this.fieldA];
      } else {
        return null;
      }
    },
    displayB() {
      if (this.joinRecordB) {
        return this.joinRecordB[this.fieldB];
      } else {
        return null;
      }
    },
    aOrB() {
      if (
        this.record &&
        this.record[this.fieldAPluralized] &&
        this.record[this.fieldAPluralized][0]
      ) {
        return "a";
      } else if (
        this.record &&
        this.record[this.fieldBPluralized] &&
        this.record[this.fieldBPluralized][0]
      ) {
        return "b";
      } else {
        return null;
      }
    },
    display() {
      return this.aOrB == "a"
        ? this.displayA
        : this.displayB
        ? this.displayB
        : null;
    },
    displayLabel() {
      return this.aOrB == "a" ? "b" : "a";
    },
    joinRecord() {
      return this.aOrB == "a"
        ? this.joinRecordA
        : this.joinRecordB
        ? this.joinRecordB
        : null;
    },
  },
  methods: {
    deleteRecord() {
      let endpoint = this.endpoint;
      let recordId = this.joinRecord.id;
      axios
        .delete(endpoint + "/" + recordId)
        .then(() => {
          this.$emit("refreshOriginRecord");
        })
        .catch((error) => this.$store.dispatch("handleError", error));
    },
  },
};
</script>
