<template>
  <div
    style="background-color: #f2f2f2; width: 100vw; height: 100vh"
    :style="{ 'background-color': bgColor }"
  >
    <div style="padding-top: 30vh; margin: 0 auto">
      <v-row justify="center">
        <v-col style="max-width: 180px" class="ma-0 pa-0">
          <img
            :src="logoSrc"
            style="max-width: 120px; max-height: 150px; position: absolute"
            :style="{
              'margin-top': this.topMargin + 'px',
              'margin-left': this.leftMargin + 'px',
            }"
          />
          <v-progress-circular
            size="200"
            :width="1"
            color="white"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    logoSrc() {
      if (this.$store.state.config.app) {
        let src = this.$store.state.config.app.basicConfig.logoPath;
        return src;
      } else {
        return "https://res.cloudinary.com/ballet-mecanique/image/upload/v1594329661/pianola-apps/logo-standalone-black-background-rgb_qdfta7.png";
      }
    },
    bgColor() {
      if (this.$store.state.config.app) {
        let color = this.$store.state.config.app.basicConfig.colorPrimary;
        return color;
      } else {
        return "#36454F";
      }
    },
    topMargin() {
      if (this.$store.state.config.app) {
        let logoPadding = this.$store.state.config.app.basicConfig.logoPadding;
        return 40 + (logoPadding - 1) * 13;
      } else {
        return 25;
      }
    },
    leftMargin() {
      if (this.$store.state.config.app) {
        return 40;
      } else {
        return 67;
      }
    },
  },
};
</script>
