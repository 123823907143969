<template>
  <div :draggable="configMode" @dragstart="pickupComponent($event, pathArray)">
    <v-row
      :class="{
        configMode,
        pickedUp,
        componentSelected,
        'elevation-9': configMode && !componentSelected,
        'elevation-1': componentSelected,
      }"
    >
      <v-col class="py-0">
        <slot></slot>
      </v-col>
      <v-col v-if="configMode" cols="auto" class="pr-2 pl-1 py-0 ma-0">
        <v-btn
          v-if="configMode"
          x-small
          icon
          @click="editComponent"
          class="small-icon"
          :style="{
            'background-color': componentSelected ? 'cornflowerblue' : '#aaa',
          }"
          style="
            margin-right: 0px;
            margin-left: -2px;
            margin-top: -2px;
            margin-bottom: -2px;
          "
        >
          <v-icon x-small color="white">mdi-pencil</v-icon>
        </v-btn>
        <br v-if="configMode" />
        <v-btn
          x-small
          icon
          style="margin-top: -6px; margin-left: -5px; margin-right: 0px"
        >
          <v-icon small>mdi-drag</v-icon>
        </v-btn>
        <v-dialog width="1000" v-model="dialog">
          <v-card style="background-color: #f2f2f2">
            <v-card-title
              style="background-color: lightslategray; color: white"
            >
              {{ component.componentType }}
            </v-card-title>
            <v-card-text>
              <config-component-editor
                :pathArray="pathArray"
                :component="component"
              />
            </v-card-text>
            <v-card-actions style="background-color: lightslategray">
              <v-spacer />
              <v-btn class="mr-4" @click="dialog = false">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { isEqual } from "lodash";

export default {
  mixins: [baseComponentMixin],
  props: {
    component: {
      type: Object,
    },
    pathArray: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      pickedUp: false,
    };
  },
  computed: {
    configMode() {
      let configModeIsToggled = this.$store.state.uiState.configMode;
      let componentTypeIsOK = this.component.componentType != "BaseLayoutRow";
      return configModeIsToggled && componentTypeIsOK;
    },
    componentSelected() {
      const selectedArray = this.$store.state.uiState.configPathArray;
      return this.configMode && isEqual(this.pathArray, selectedArray);
    },
  },
  methods: {
    editComponent() {
      this.$store.commit("SET_CONFIG_PATH_ARRAY", this.pathArray);
    },
    alert() {
      alert("this works");
    },
    pickupComponent(event) {
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.dropEffect = "move";
    },
  },
};
</script>
<style>
.configMode {
  /* border: 0.25px solid #aaa; */
  margin-bottom: 5px;
  background-color: #fff9f0;
  min-height: 25px;
  border-radius: 3px;
  /* margin-top: 5px; */
}
.componentSelected {
  /* background-color: #b7c0c9; */
  /* background-color: #fff9f0; */
  border: 0.25px solid #a0a0a0;
}
.pickedUp {
  transform: rotate(2deg);
}
</style>
