<template>
  <container-config>
    <div slot-scope="{ color }">
      <container-record
        :endpoint="endpoint"
        :recordId="preloadedRecord.id"
        :preloadedRecordData="preloadedRecord"
        :newRecord="newRecord"
        :originEndpoint="recordEndpoint"
        :suppressAutoForeignKey="true"
        :storeNewRecordImmediately="storeNewRecordImmediately"
        @storeNewRecordImmediately="$emit('storeRecord')"
        @handleNewRecordResponse="$emit('ok')"
        @handlePostDeletion="$emit('ok')"
      >
        <div slot-scope="record" v-if="record.record">
          <v-card>
            <v-card-title
              class="px-9"
              :style="{ 'background-color': modalColor || color }"
              style="margin: 0px;"
            >
              <span class="headline white--text">{{ newEntryLabel }}</span>
            </v-card-title>

            <v-card-text class="px-9 py-2">
              <v-radio-group
                :value="direction"
                @change="
                  changeDirection($event);
                  record.updateRecordState(freshRecordState);
                "
                row
                hide-details="auto"
                class="mb-3"
              >
                <v-radio :label="labelA" value="a" :color="color"></v-radio>
                <v-radio :label="labelB" value="b" :color="color"></v-radio>
              </v-radio-group>
              <base-input-search-or-create
                v-if="direction"
                displayField="full_name"
                :endpoint="recordEndpoint"
                :recordId="preloadedRecord.id"
                :key="key + 1"
                :field="searchField"
                :recordEndpoint="endpoint"
                :newRecord="newRecord"
                :modalColor="modalColor"
                label="link to contact"
                :headers="[
                  {
                    text: 'Name',
                    value: 'full_name'
                  },
                  {
                    text: 'City',
                    value: 'city'
                  }
                ]"
                :components="newRecordComponents"
              ></base-input-search-or-create>
              <v-text-field
                class="mt-4 mb-2"
                prepend-inner-icon="mdi-link"
                v-else
                disabled
                hide-details="auto"
                label="link to contact"
              ></v-text-field>
            </v-card-text>

            <v-card-actions class="pt-3 pb-6 px-9">
              <v-btn @click="$emit('ok')">cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :color="color"
                dark
                :disabled="!record.record[searchField]"
                @click="record.saveNewRecord()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
      </container-record>
    </div>
  </container-config>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import Inflector from "inflector-js";

export default {
  mixins: [baseComponentMixin],
  props: {
    endpoint: {
      type: String
    },
    fieldA: {
      type: String
    },
    fieldB: {
      type: String
    },
    labelA: {
      type: String
    },
    labelB: {
      type: String
    },
    newEntryLabel: {
      type: String
    },
    preloadedRecord: {
      type: Object
    },
    selectedLabel: {
      type: String
    }
  },
  data() {
    return {
      dialog: false,
      direction: this.selectedLabel,
      fieldValueA: null,
      fieldValueB: null,
      key: 1,
      newRecordComponents: [
        {
          props: {
            row: true,
            field: "gender",
            items: [
              {
                label: "Mr",
                value: "m"
              },
              {
                label: "Ms",
                value: "f"
              }
            ],
            label: "Title",
            columns: 6
          },
          componentType: "BaseInputRadio"
        },
        {
          props: {
            field: "first_name",
            label: "First Name"
          },
          componentType: "BaseInputText"
        },
        {
          props: {
            field: "last_name",
            label: "Last Name",
            validationRules: ["required"]
          },
          componentType: "BaseInputText"
        }
      ]
    };
  },
  methods: {
    changeDirection(input) {
      this.direction = input;
      this.fieldValueA = this.direction == "a" ? this.recordId : null;
      this.fieldValueB = this.direction == "b" ? this.recordId : null;
      this.key++;
    }
  },
  computed: {
    foreignKeyFieldNameA() {
      return Inflector.singularize(this.recordEndpoint) + "_id__" + this.fieldA;
    },
    foreignKeyFieldNameB() {
      return Inflector.singularize(this.recordEndpoint) + "_id__" + this.fieldB;
    },
    freshRecordState() {
      let recordObject = {};
      recordObject["id"] = this.preloadedRecord.id;
      recordObject[this.foreignKeyFieldNameA] = this.fieldValueA;
      recordObject[this.foreignKeyFieldNameB] = this.fieldValueB;
      return recordObject;
    },
    searchField() {
      if (this.direction == "a") {
        return this.foreignKeyFieldNameB;
      } else {
        return this.foreignKeyFieldNameA;
      }
    }
  }
};
</script>
