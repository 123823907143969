import { render, staticRenderFns } from "./ConfigDrawer.vue?vue&type=template&id=77f8ffb4&scoped=true&"
import script from "./ConfigDrawer.vue?vue&type=script&lang=js&"
export * from "./ConfigDrawer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules.nosync/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77f8ffb4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules.nosync/vuetify-loader/lib/runtime/installComponents.js"
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VNavigationDrawer})
