import router from "../../router/index.js";
import axios from "axios";

export default {
  mutations: {},
  actions: {
    changeHandler({ commit, dispatch, getters }, payload) {
      dispatch("pushUrlParametersToRoute");
      if (payload.clearRecords) {
        commit("SET_PAGE", { module: getters.module, value: 1 });
        dispatch("clearModuleRecords");
      }
      //if page data is available and triggered change does not clear the stored data:
      if (getters.pageData && !payload.clearRecords) {
        //select first record before reloading data for speed

        dispatch("selectFirstRecord");
        dispatch("loadModuleRecords");
      } else {
        dispatch("loadModuleRecords").then(() => {
          if (!payload.doNotSelectFirstRecord && !getters.isLink) {
            dispatch("selectFirstRecord");
          }
        });
      }
    },

    loadModuleRecords({ commit, dispatch, getters }) {
      if (!getters.pageData || getters.pageData.length == 0) {
        commit("TOGGLE_PAGE_LOADING", true);
      }
      return new Promise(resolve => {
        dispatch("loadRecordCount").then(() => {
          dispatch("loadPageRecords", getters.selectedPage).then(() => {
            resolve(commit("TOGGLE_PAGE_LOADING", false));
          });
          // TO DO: load page (n+1, n+2, n+3) [if applicable*]
          // *if not already preloaded, and if record count means these pages exist
        });
      });
    },

    refreshPage({ dispatch }, payload) {
      dispatch("loadModuleRecords").then(() => {
        if (payload.selectFirstRecord) {
          dispatch("selectFirstRecord");
        }
      });
    },

    clearModuleRecords({ commit, getters }) {
      commit("CLEAR_DATA", getters.module);
    },

    loadRecordCount({ commit, dispatch, getters }) {
      const q = getters.query ? "&q=" + getters.query : "";
      const f = getters.filter ? "&filter=" + getters.filter : "";
      const c = getters.constantPreFilter
        ? "&c=" + getters.constantPreFilter
        : "";
      const v = getters.variablePreFilter
        ? "&v=" + getters.variablePreFilter
        : "";
      const path = "/count/" + getters.moduleEndpoint + "?" + q + f + c + v;
      return new Promise((resolve, reject) => {
        axios
          .get(encodeURI(path))
          .then(r => r.data)
          .then(data => {
            if (data == 0) {
              dispatch("showNoRecordDialog");
            }
            resolve(
              commit("SET_RECORD_COUNT", {
                module: getters.module,
                recordCount: data
              })
            );
          })
          .catch(error => reject(dispatch("handleError", error)));
      });
    },

    loadPageRecords({ commit, dispatch, getters }, page) {
      const load = getters.moduleBasicConfig.loadRelatedData;
      const f = getters.filter ? "&filter=" + getters.filter : "";
      const s = getters.sort ? "&sort=" + getters.sort : "";
      const q = getters.query ? "&q=" + getters.query : "";
      const c = getters.constantPreFilter
        ? "&c=" + getters.constantPreFilter
        : "";
      const v = getters.variablePreFilter
        ? "&v=" + getters.variablePreFilter
        : "";
      const p = "&page=" + page;
      return new Promise((resolve, reject) => {
        let module = getters.module;
        let moduleEndpoint = getters.moduleEndpoint;
        let path = moduleEndpoint + "?" + q + f + p + s + c + v;
        axios
          .get(encodeURI(path))
          .then(r => r.data)
          .then(data => {
            resolve(
              commit("SET_PAGE_DATA", {
                module,
                page,
                data
              })
            );
          })
          .catch(error => reject(dispatch("handleError", error)));
        //if module has pre-load of data, fetch data with loaded relationships
        if (load) {
          axios
            .get(encodeURI(path + "&load=true"))
            .then(r => r.data)
            .then(data => {
              resolve(
                commit("SET_PAGE_DATA", {
                  module,
                  page,
                  data
                })
              );
            })
            .catch(error => reject(dispatch("handleError", error)));
        }
      });
    },

    selectFirstRecord({ getters }) {
      let pageData = getters.moduleState.data[getters.selectedPage];
      let firstRecordId = pageData[0]["id"];
      let path = "/" + getters.module + "/" + firstRecordId;
      let query = getters.uriQuery;
      router
        .push({
          path,
          query
        })
        .catch(() => {});
    },

    showNoRecordDialog({ commit, rootState }) {
      let texts = {
        en: {
          title: "No entries found.",
          text:
            "No entries have been found with these search criteria." +
            (this.query && this.filter
              ? " PLEASE NOTE: you have set both a filter and a query. Please check if this is intentional, as it can reduce the number of results more than expected."
              : "")
        },
        de: {
          title: "Keine Einträge gefunden.",
          text:
            "Mit diesen Suchkriterien sind keine Einträge gefunden worden." +
            (this.query && this.filter
              ? " NB: sowohl ein Filter wie auch eine Suche wurden definiert. Bitte überprüfe, ob dies so gemeint ist, da die Ergebnismenge noch mehr reduziert wird."
              : "")
        },
        da: {
          title: "Intet fundet.",
          text:
            "Der kunne ikke findes noget med disse søgekriterier." +
            (this.query && this.filter
              ? " NB: sowohl ein Filter wie auch eine Suche wurden definiert. Bitte überprüfe, ob dies so gemeint ist, da die Ergebnismenge noch mehr reduziert wird."
              : "")
        }
      };
      let language = rootState.config.app.basicConfig.appLanguage;
      commit("SHOW_DIALOG", {
        title: texts[language].title,
        text: texts[language].text
      });
    }
  },
  getters: {
    selectedPage(getters, rootGetters) {
      return rootGetters.moduleState.selectedPage;
    },
    query(getters, rootGetters) {
      return rootGetters.moduleState.query;
    },
    filter(getters, rootGetters) {
      return rootGetters.moduleState.filter;
    },
    constantPreFilter(getters, rootGetters) {
      if (rootGetters.moduleConfig.basicConfig.constantPreFilterFieldPath) {
        return (
          rootGetters.moduleConfig.basicConfig.constantPreFilterFieldPath +
          "|" +
          rootGetters.moduleConfig.basicConfig.constantPreFilterValue
        );
      } else {
        return null;
      }
    },

    variablePreFilter(getters, rootGetters) {
      if (
        rootGetters.moduleConfig.basicConfig.variablePreFilter &&
        rootGetters.variablePreFilterValue &&
        rootGetters.variablePreFilterValue[rootGetters.module]
      ) {
        return (
          rootGetters.moduleConfig.basicConfig.variablePreFilterFieldPath +
          "|" +
          rootGetters.variablePreFilterValue[rootGetters.module]
        );
      } else {
        return null;
      }
    },
    sort(getters, rootGetters) {
      return rootGetters.moduleState.sort;
    },
    pageData(getters, rootGetters) {
      return rootGetters.moduleState.data[getters.selectedPage];
    }
  }
};
