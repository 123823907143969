var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-ui-state',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var changeMobileView = ref.changeMobileView;
return _c('div',{},[_c('container-config',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var app = ref.app;
var color = ref.color;
var module = ref.module;
return _c('div',{},[_c('v-list',{staticClass:"py-0",attrs:{"color":app.colorBackground}},[_c('v-list-item-group',_vm._l((_vm.pageData),function(item){return _c('div',{key:item.id},[_c('v-list-item',{attrs:{"color":color,"to":{
                  path: ("/" + (_vm.$route.params.module) + "/" + (item.id)),
                  query: _vm.$route.query,
                }},on:{"click":function($event){return changeMobileView('form')}}},[(module.recordTitleConfig.avatarField)?_c('v-list-item-avatar',{attrs:{"tile":module.recordTitleConfig.avatarTypeList == 'tile',"size":module.recordTitleConfig.avatarSizeList || 48}},[(item[module.recordTitleConfig.avatarField])?_c('v-img',{attrs:{"src":module.recordTitleConfig.avatarUrl.replace(
                        '{field}',
                        item[module.recordTitleConfig.avatarField]
                      )}}):_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-account")])],1):_vm._e(),(module.recordTitleConfig.hasStatusDot)?_c('v-list-item-avatar',{attrs:{"tile":"","size":"18","height":"55"}},[_c('v-icon',{staticClass:"pa-0 mt-n10",attrs:{"small":"","color":_vm.backgroundColor(
                        item,
                        module.recordTitleConfig.statusDotColorRules
                      )}},[_vm._v("mdi-checkbox-blank-circle")])],1):_vm._e(),_c('v-list-item-content',[(module.recordTitleConfig.fieldSubtitleBefore)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item[module.recordTitleConfig.fieldSubtitleBefore])+" ")]):_vm._e(),_c('v-list-item-title',[_vm._v(" "+_vm._s(item[module.recordTitleConfig.fieldTitle])+" ")]),(module.recordTitleConfig.fieldSubtitleAfter)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item[module.recordTitleConfig.fieldSubtitleAfter])+" ")]):_vm._e(),(module.recordTitleConfig.fieldSubtitleAfter2)?_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(" "+_vm._s(item[module.recordTitleConfig.fieldSubtitleAfter2])+" ")]):_vm._e()],1)],1)],1)}),0)],1)],1)}}],null,true)})],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }