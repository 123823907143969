<template>
  <container-config>
    <div
      slot-scope="{ app, color }"
      class="mt-6"
      :class="customClass"
      v-if="!isHidden"
    >
      <v-btn
        :icon="buttonType == 'icon'"
        :tile="buttonType == 'tile'"
        :text="buttonType == 'text'"
        small
        class="mt-1"
        :x-small="buttonSize == 'small'"
        @click="dialog = true"
      >
        <v-icon
          small
          :x-small="buttonSize == 'small'"
          class="grey--text lighten-3"
          :left="buttonText.length > 0"
          >{{ icon }}</v-icon
        >
        {{ buttonText }}
      </v-btn>
      <v-dialog v-model="dialog" :width="modalWidth">
        <v-card :style="{ 'background-color': app.colorBackground }">
          <v-card-title
            v-if="title"
            class="py-3"
            :style="{ 'background-color': color }"
          >
            <h4
              class="white--text font-weight-medium text-uppercase"
              style="letter-spacing: 1.25px; font-size: 14px"
              v-if="title"
            >
              {{ title }}
            </h4>
          </v-card-title>
          <v-card-text class="py-4">
            <component
              v-for="(component, key) in components"
              :key="key"
              v-bind:is="component.componentType"
              v-bind="{
                ...component.props,
                recordId,
                recordEndpoint,
                newRecord,
              }"
              @closeModal="dialog = false"
            ></component>
          </v-card-text>
          <v-card-actions class="justify-end pt-1 pb-4 pr-6">
            <v-btn
              @click="
                dialog = false;
                refreshOriginRecord();
              "
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </container-config>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";

export default {
  mixins: [baseComponentMixin],
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    buttonType: {
      type: String,
    },
    buttonSize: {
      type: String,
    },
    buttonText: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
    },
    modalWidth: {
      type: Number,
      default: 700,
    },
    title: {
      type: String,
    },
    customClass: {
      type: String,
    },
  },
  methods: {
    refreshOriginRecord() {
      let payload = {
        recordId: this.recordId,
        endpoint: this.recordEndpoint,
      };
      this.$store.dispatch("refreshOriginRecord", payload);
    },
  },
};
</script>

<style lang="scss" scoped></style>
