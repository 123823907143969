export const baseInputMixin = {
  props: {
    field: {
      type: String
    },
    label: {
      type: String
    },
    modalColor: {
      type: String
    },
    validationRules: {
      type: Array
    }
  }
};
