<template>
  <container-ui-state>
    <div slot-scope="uiState">
      <container-config :record="record">
        <div slot-scope="config">
          <div class="pt-3 py-md-3 px-md-5 px-1">
            <app-subcomponents
              v-bind="{
                components: config.tabs[uiState.tab].components,
                recordId,
                recordEndpoint,
                pathArray,
              }"
            />
            <!-- <component
              v-for="(component, key) in config.tabs[uiState.tab].components"
              :key="key"
              v-bind:is="component.componentType"
              v-bind="{ ...component.props, recordId, recordEndpoint }"
            ></component> -->
          </div>
        </div>
      </container-config>
    </div>
  </container-ui-state>
</template>

<script>
export default {
  props: {
    recordId: {
      type: [String, Number],
    },
    recordEndpoint: {
      type: String,
    },
    record: {
      type: Object,
    },
  },
  computed: {
    modulePathName() {
      return this.$route.params.module;
    },
    selectedModuleIndex() {
      let modules = this.$store.state.config.app.modules;
      let index = modules.findIndex(
        (item) => item.basicConfig.path == this.modulePathName
      );
      return index;
    },
    selectedTabIndex() {
      return this.$store.state[this.modulePathName]
        ? this.$store.state[this.modulePathName].selectedTab
        : 0;
    },
    pathArray() {
      return [
        "config",
        "app",
        "modules",
        this.selectedModuleIndex,
        "tabs",
        this.selectedTabIndex,
        "components",
      ];
    },
  },
};
</script>
