<template>
  <container-query-builder-subquery
    :orQueryKey="orQueryKey"
    :tableGroupingKey="tableGroupingKey"
    :subqueryKey="subqueryKey"
  >
    <div slot-scope="{ deleteSubquery, fieldName, queryLength }">
      <v-row class="pl-2 pr-1">
        <v-col class="py-0 px-1">
          <app-query-builder-subquery-field-selector
            :orQueryKey="orQueryKey"
            :subqueryKey="subqueryKey"
            :tableGroupingKey="tableGroupingKey"
            :baseTable="baseTable"
          />
        </v-col>
        <v-col cols="auto" class="py-0 px-1" style="max-width: 83px;">
          <app-query-builder-subquery-operator-selector
            :orQueryKey="orQueryKey"
            :subqueryKey="subqueryKey"
            :tableGroupingKey="tableGroupingKey"
            :baseTable="baseTable"
          />
        </v-col>
        <v-col class="py-0 px-1">
          <app-query-builder-subquery-value-input
            :key="fieldName"
            :orQueryKey="orQueryKey"
            :subqueryKey="subqueryKey"
            :tableGroupingKey="tableGroupingKey"
            :baseTable="baseTable"
          />
        </v-col>
        <v-col cols="auto" class="px-0 pt-0">
          <v-btn
            icon
            @click="deleteSubquery(orQueryKey, tableGroupingKey, subqueryKey)"
            v-if="queryLength > 1"
            dark
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn v-else icon disabled></v-btn>
        </v-col>
      </v-row>
    </div>
  </container-query-builder-subquery>
</template>

<script>
export default {
  props: {
    orQueryKey: {
      type: Number
    },
    tableGroupingKey: {
      type: Number
    },
    baseTable: {
      type: String
    }
  },
  data() {
    return {
      subqueryKey: this.$vnode.key
    };
  }
};
</script>
