<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="180"
      height="90"
      viewBox="0 0 12.288 6.144"
      preserveAspectRatio="xMidYMid"
    >
      <path
        d="M7.876 2.136a1.73 1.73 0 1 1-3.464 0 1.73 1.73 0 1 1 3.464 0z"
        fill="#ec1c24"
      />
      <path
        d="M5.995 1.77l.415.415c.06.06.157.06.217 0s.06-.157 0-.217l-.415-.415c-.297-.297-.777-.302-1.08-.015l-.016.015a.78.78 0 0 0 .008 1.089l.415.415c.06.06.157.06.217 0s.06-.157 0-.217l-.415-.415a.47.47 0 0 1-.004-.658.47.47 0 0 1 .658.004zm.536-.553c-.06.06-.06.157 0 .217l.415.415a.47.47 0 0 1 .004.658.47.47 0 0 1-.658-.004l-.415-.415c-.06-.06-.157-.06-.217 0s-.06.158 0 .217l.415.415c.297.297.778.302 1.08.015l.016-.015a.78.78 0 0 0-.008-1.089l-.415-.415c-.06-.06-.157-.06-.217 0z"
        fill="#fff"
      />
      <path
        d="M7.822 5.024V4.46c0-.094.037-.138.1-.14s.12.046.12.14v.424c0 .05.016.066.066.066h.54c.053 0 .064-.023.062-.07v-.424c0-.064.007-.126.094-.126.108 0 .135.025.135.126v1.137c0 .066-.01.126-.096.128-.105.002-.133-.023-.133-.133v-.362c0-.05-.01-.073-.066-.073h-.54c-.053 0-.064.02-.062.07V5.6c0 .064-.016.12-.094.124-.092.007-.13-.028-.133-.12l-.002-.582zM2.6 5.618c-.002.057-.028.092-.076.103-.06.016-.112.007-.144-.053l-.115-.23c-.023-.05-.05-.07-.105-.07h-.573c-.05 0-.08.014-.103.062-.034.078-.078.15-.115.227s-.1.066-.156.057-.085-.083-.053-.144l.6-1.2c.044-.083.163-.08.206.002l.608 1.192a.18.18 0 0 1 .016.053zm-.727-.96l-.257.5h.447c.025 0 .07.014.044-.04l-.234-.47zm1.585.142v-.362c0-.062.018-.1.092-.112.1-.002.138.023.138.1l.002.65c.002.225.163.413.38.42.18.007.436-.128.436-.4v-.66c0-.087.044-.138.115-.135s.115.04.115.115l-.005.73c-.016.337-.296.566-.656.562a.6.6 0 0 1-.615-.578l-.002-.342zm6.945.563c.04-.14-.04-.27-.144-.397L9.913 4.5c-.044-.057-.062-.115.002-.17.055-.048.133-.034.183.034l.37.514c.04.055.057.046.092-.002l.37-.504c.032-.046.07-.08.133-.07.087.016.117.103.06.183l-.332.443c-.128.147-.186.305-.16.495.007.055.002.112 0 .167a.1.1 0 0 1-.112.108c-.07.002-.112-.037-.115-.105v-.24zM6.28 4.34h.502c.092 0 .14.04.14.1s-.046.108-.14.108h-.307c-.062-.002-.08.018-.078.08l.002.96c0 .07-.016.124-.092.138-.085.016-.138-.034-.138-.135v-.933c.002-.083-.016-.117-.103-.108s-.18.002-.273.002c-.1 0-.147-.037-.144-.112s.048-.108.15-.108l.48-.002z"
        fill="#51575c"
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
