<template>
  <container-config>
    <div slot-scope="{ color, app }">
      <v-row class="mt-0 pb-0 pt-1">
        <v-col cols="12" class="my-0 py-0">
          <v-file-input
            :placeholder="texts['clickHere'][app.appLanguage]"
            ref="file"
            v-model="file"
            prepend-inner-icon="mdi-paperclip"
            prepend-icon=""
            :append-icon="
              !suppressCancelButton && !newRecord && !noStoredValue
                ? 'mdi-cancel'
                : ''
            "
            class="mt-n3"
            :rules="rules"
            @change="storeFile"
            @click="$emit('triggerEdit')"
            @click:append="$emit('closeEdit')"
            show-size
            :loading="loading"
            :clearable="false"
            :color="color"
            hide-details
          >
            <template slot="append">
              <v-progress-circular
                :value="uploadProgress"
                width="1"
                class="mb-1"
                size="30"
                v-if="uploadProgress > 0 && uploadProgress < 100"
                :color="color"
              >
                {{ uploadProgress }}
              </v-progress-circular>
              <v-icon v-else-if="uploadProgress == 100"> mdi-check </v-icon>
            </template>
          </v-file-input>
        </v-col>
        <!-- <v-col cols="auto" class="my-0 py-0">
          <v-btn
            small
            @click="$emit('closeEdit')"
            v-if="!suppressCancelButton && !newRecord && !noStoredValue"
          >
            {{ texts["cancel"][app.appLanguage] }}
          </v-btn>
        </v-col> -->
      </v-row>
    </div>
  </container-config>
</template>

<script>
import axios from "axios";
import config from "../config/appConfig";
import upperFirst from "lodash/upperFirst";
import Vapor from "../services/vapor";

export default {
  props: {
    value: {
      type: String,
    },
    validationRules: {
      type: Array,
      default: () => [],
    },
    newRecord: {
      type: Boolean,
    },
    noStoredValue: {
      type: Boolean,
    },
  },
  data() {
    return {
      file: null,
      uploadProgress: 0,
      suppressCancelButton: false,
      loading: false,
      rulesCollection: {
        required: (value) => !!value || "Required!",
      },
      texts: {
        clickHere: {
          en: "upload file",
          de: "hochladen",
        },
        cancel: {
          en: "cancel",
          de: "abbrechen",
        },
      },
    };
  },
  computed: {
    rules() {
      let array = [];
      this.validationRules.forEach((item) => {
        array = [...array, this.rulesCollection[item]];
      });
      return [...array, !this.loading];
    },
  },
  methods: {
    storeFile() {
      this.loading = true;
      this.suppressCancelButton = true;
      let self = this;
      // let user = JSON.parse(localStorage.getItem("user"));
      // let token = "Bearer " + user.token;
      let apiUrl =
        config.basicConfig["apiUrl" + upperFirst(process.env.VUE_APP_ENV)];
      Vapor.store(this.file, {
        baseURL: apiUrl,
        // headers: { Authorization: token },
        progress: (progress) => {
          this.uploadProgress = Math.round(progress * 100);
        },
      }).then((response) => {
        axios
          .post("file-upload", {
            uuid: response.uuid,
            key: response.key,
            bucket: response.bucket,
            name: this.file.name,
            content_type: this.file.type,
          })
          .then((response) => {
            this.$emit("input", response.data.uuid);
            self.loading = false;
            this.$refs.file.blur();
          });
      });
    },
  },
};
</script>

<style>
.v-file-input__text {
  font-size: 14px;
}
.mdi-paperclip,
.mdi-cancel {
  font-size: 16px !important;
}
</style>
