<template>
  <container-config style="width: 100%">
    <div slot-scope="config" style="width: 100%">
      <v-stepper
        :value="record[field]"
        class="elevation-0"
        style="background-color: transparent"
      >
        <v-stepper-header class="pa-0 ma-0">
          <template v-for="(item, key) in items">
            <v-stepper-step
              :step="key + 1"
              :key="`${key}-step`"
              :color="config.app.colorBackgroundDrawer"
              class="px-12"
            >
              {{ item }}
            </v-stepper-step>
            <v-divider v-if="key + 1 !== items.length" :key="key"></v-divider>
          </template>
        </v-stepper-header>
      </v-stepper>
    </div>
  </container-config>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
export default {
  mixins: [baseComponentMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    field: {
      type: [String, Number],
      default: 2,
    },
  },
};
</script>
