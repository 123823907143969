import axios from "axios";
import Vue from "vue";
export default {
  state: {
    id: null,
    showQueryBuilder: false,
    user_id: null,
    name: null,
    queryFilter: null,
    myQueriesOnly: false,
    query: {
      baseTable: null,
      orQueries: [
        {
          tableGroupings: []
        }
      ]
    }
  },
  mutations: {
    CHANGE_BASE_TABLE(state, name) {
      state.query.baseTable = name;
    },
    CLEAR_QUERY(state) {
      state.query.orQueries = [{ tableGroupings: [] }];
      state.id = null;
      state.user_id = null;
      state.name = null;
      state.queryFilter = null;
    },
    ADD_NEW_QUERY(state) {
      state.query.orQueries.push({
        tableGroupings: []
      });
    },
    DELETE_QUERY(state, queryIndex) {
      state.query.orQueries.splice(queryIndex, 1);
    },
    ADD_NEW_TABLE_GROUPING(state, index) {
      let tableGroupings = state.query.orQueries[index].tableGroupings;
      let newTableGrouping = {
        table: "",
        type: "include",
        andQueries: [
          {
            id: Math.random()
              .toString(36)
              .substring(7),
            field: "",
            operator: "=",
            value: ""
          }
        ]
      };
      let newTableGroupingArray = [...tableGroupings, newTableGrouping];
      Vue.set(
        state.query.orQueries[index],
        "tableGroupings",
        newTableGroupingArray
      );
    },
    DELETE_TABLE_GROUPING(state, payload) {
      state.query.orQueries[payload.orQueryIndex].tableGroupings.splice(
        payload.tableGroupingIndex,
        1
      );
    },
    TOGGLE_INCLUDE_STATE(state, payload) {
      state.query.orQueries[payload.orQueryIndex].tableGroupings[
        payload.tableGroupingIndex
      ].type = payload.value;
    },
    SELECT_BASE_TABLE(state, payload) {
      Vue.set(
        state.query.orQueries[payload.orQueryIndex].tableGroupings[
          payload.tableGroupingIndex
        ],
        "table",
        payload.value
      );
    },
    DELETE_ASSOCIATED_SUBQUERIES(state, payload) {
      state.query.orQueries[payload.orQueryIndex].tableGroupings[
        payload.tableGroupingIndex
      ].andQueries = [
        {
          id: Math.random()
            .toString(36)
            .substring(7),
          field: "",
          operator: "=",
          value: ""
        }
      ];
    },
    ADD_NEW_SUBQUERY(state, payload) {
      let queryArray =
        state.query.orQueries[payload.orQueryIndex].tableGroupings[
          payload.tableGroupingIndex
        ].andQueries;
      queryArray.push({
        id: Math.random()
          .toString(36)
          .substring(7),
        field: "",
        operator: "=",
        value: ""
      });
    },
    DELETE_SUBQUERY(state, payload) {
      state.query.orQueries[payload.orQueryIndex].tableGroupings[
        payload.tableGroupingIndex
      ].andQueries.splice(payload.subQueryIndex, 1);
    },
    UPDATE_OPERATOR(state, payload) {
      state.query.orQueries[payload.orQueryIndex].tableGroupings[
        payload.tableGroupingIndex
      ].andQueries[payload.subqueryIndex]["operator"] = payload.operator;
    },
    UPDATE_FIELD(state, payload) {
      state.query.orQueries[payload.orQueryIndex].tableGroupings[
        payload.tableGroupingIndex
      ].andQueries[payload.subqueryIndex]["field"] = payload.field;
    },
    CLEAR_VALUE(state, payload) {
      state.query.orQueries[payload.orQueryIndex].tableGroupings[
        payload.tableGroupingIndex
      ].andQueries[payload.subqueryIndex]["value"] = "";
    },
    UPDATE_VALUE(state, payload) {
      state.query.orQueries[payload.orQueryIndex].tableGroupings[
        payload.tableGroupingIndex
      ].andQueries[payload.subqueryIndex]["value"] = payload.value;
    },
    TOGGLE_QUERY_BUILDER(state) {
      Vue.set(state, "showQueryBuilder", !state.showQueryBuilder);
    },
    UPDATE_QUERY_NAME(state, value) {
      state.name = value;
    },
    REMOVE_QUERY_ID(state) {
      state.id = null;
    },
    LOAD_RESPONSE_TO_STATE(state, payload) {
      state.id = payload.id;
      state.name = payload.name;
      state.user_id = payload.user_id;
      let base64Query = payload.query;
      let uriEncodedQuery = atob(base64Query);
      let stringifiedQuery = decodeURI(uriEncodedQuery);
      let queryJson = JSON.parse(stringifiedQuery);
      state.query = queryJson;
    },
    LOAD_URL_QUERY_TO_STATE(state, query) {
      let base64Query = query;
      let uriEncodedQuery = atob(base64Query);
      let stringifiedQuery = decodeURI(uriEncodedQuery);
      let queryJson = JSON.parse(stringifiedQuery);
      Vue.set(state, "query", queryJson);
    },
    TOGGLE_MY_QUERIES_ONLY(state) {
      state.myQueriesOnly = !state.myQueriesOnly;
    },
    UPDATE_QUERY_FILTER(state, value) {
      state.queryFilter = value;
    }
  },
  actions: {
    performFind({ commit, dispatch, getters }) {
      let query = getters.encodedQuery;
      commit("SET_QUERY", { module: getters.module, query });
      dispatch("changeHandler", { clearRecords: true });
      commit("TOGGLE_QUERY_BUILDER");
    },
    saveQueryToDatabase({ getters, state, commit }) {
      let payload = {
        name: state.name,
        query: getters.encodedQuery,
        user_id: state.user_id,
        base_table: state.query.baseTable
      };
      axios
        .post("queries", payload)
        .then(r => r.data)
        .then(data => commit("LOAD_RESPONSE_TO_STATE", data));
    },
    updateQueryToDatabase({ getters, state, commit }) {
      let payload = {
        name: state.name,
        query: getters.encodedQuery,
        user_id: state.user_id,
        base_table: state.query.baseTable
      };
      axios
        .patch("queries/" + state.id, payload)
        .then(r => r.data)
        .then(data => commit("LOAD_RESPONSE_TO_STATE", data));
    },
    loadQuery({ commit }, id) {
      axios
        .get("queries/" + id)
        .then(r => r.data)
        .then(data => commit("LOAD_RESPONSE_TO_STATE", data));
    },
    loadAndPerformQuery({ commit, dispatch }, id) {
      axios
        .get("queries/" + id)
        .then(r => r.data)
        .then(data => commit("LOAD_RESPONSE_TO_STATE", data))
        .then(() => dispatch("performFind"));
    },
    toggleMyQueriesOnly({ commit }) {
      commit("TOGGLE_MY_QUERIES_ONLY");
    },
    updateQueryFilter({ commit }, value) {
      commit("UPDATE_QUERY_FILTER", value);
    }
  },
  getters: {
    encodedQuery(state) {
      let stringifiedQuery = JSON.stringify(state.query);
      let uriEncodedQuery = encodeURI(stringifiedQuery);
      let base64Query = btoa(uriEncodedQuery);
      return base64Query;
    }
  }
};
