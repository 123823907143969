<template>
  <div class="mr-12 mt-6" style="max-width: 280px">
    <v-select
      v-if="items"
      outlined
      dense
      v-model="preFilter"
      :items="items"
      item-text="text"
      item-value="value"
    ></v-select>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    filterField: {
      type: String,
    },
  },
  computed: {
    items: {
      get() {
        if (this.$store.state.uiState.variablePreFilterItems) {
          return this.$store.state.uiState.variablePreFilterItems[
            this.moduleName
          ];
        } else {
          return null;
        }
      },
      set(values) {
        let payload = { values, module: this.moduleName };
        this.$store.commit("SET_GLOBAL_VARIABLE_PRE_FILTER_ITEMS", payload);
      },
    },
    preFilter: {
      get() {
        if (this.$store.state.uiState.variablePreFilterValue) {
          return this.$store.state.uiState.variablePreFilterValue[
            this.moduleName
          ];
        } else {
          return null;
        }
      },
      set(value) {
        this.$store.commit("SET_GLOBAL_VARIABLE_PRE_FILTER", {
          module: this.moduleName,
          value,
        });
        this.$store.dispatch("changeHandler", { clearRecords: true });
        this.$store.commit("SET_MODULE_VARIABLE_PRE_FILTER", {
          module: this.moduleName,
          value,
        });
      },
    },
    moduleName() {
      return this.$route.params.module;
    },
  },
  mounted() {
    if (this.preFilter == null) {
      axios.get("/global-filter/" + this.filterField).then((response) => {
        this.items = response.data.items;
        this.preFilter = response.data.default;
      });
    }
  },
};
</script>
