<template>
  <div>
    <container-input-field
      :recordId="recordId"
      :recordEndpoint="recordEndpoint"
      :field="field"
      :newRecord="newRecord"
      :modalColor="modalColor"
      :reloadRecordOnChange="reloadRecordOnChange"
      :storeNewRecordImmediately="storeNewRecordImmediately"
      @storeNewRecordImmediately="$emit('storeRecord')"
    >
      <div slot-scope="field" v-if="!isHidden">
        <v-row class="mb-n1">
          <v-col class="ml-3 px-0 py-0">
            <base-label :label="label" />
          </v-col>
        </v-row>
        <v-row class="px-0 py-0">
          <v-col class="d-flex pt-1 pb-2" :cols="columns">
            <v-text-field
              :class="{
                alternativeTextFieldDesign: field.alternativeDesign,
                'body-2': field.alternativeDesign,
                p_TextInputReadOnly: isReadonly,
                hasCurrency: currency,
              }"
              reverse
              elevation="0"
              placeholder="0"
              :value="addThousandSeparator(field.value)"
              @focus="$event.target.select()"
              @change="
                setValue($event);
                field.updateValue(value);
              "
              :rules="rules"
              hide-details="auto"
              autocomplete="disabled"
              :readonly="isReadonly"
              :solo="field.alternativeDesign"
              :dense="field.alternativeDesign"
              :flat="field.alternativeDesign"
            ></v-text-field>
            <span
              style="
                font-size: 0.875rem;
                padding-top: 7px;
                margin-left: -32px;
                z-index: 10;
                color: #bbb;
              "
              :style="{ color: field.value ? '#666' : '#bbb' }"
            >
              {{ currency }}
            </span>
          </v-col>
        </v-row>
      </div>
    </container-input-field>
  </div>
</template>
<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    fieldAmount: {
      type: String,
    },
    fieldCurrency: {
      type: String,
    },
    items: {
      type: Array,
    },
    columns: {
      type: Number,
      default: 12,
    },
    validationRules: {
      type: Array,
      default: () => [],
    },
    buttonType: {
      type: String,
    },
    urlPath: {
      type: String,
      default: "",
    },
    urlSuffix: {
      type: String,
      default: "",
    },
    isReadonly: {
      type: Boolean,
    },
    reloadRecordOnChange: {
      type: Boolean,
      default: false,
    },
    accessorRegEx: {
      type: String,
    },
    thousandSeparator: {
      type: String,
      default: ",",
    },
    currency: {
      type: String,
    },
  },
  data() {
    return {
      value: null,
      rulesCollection: {
        required: (value) => !!value || "Required value!",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail address!";
        },
        numericOnly: (value) =>
          value > 0 || "The entered value must be numeric!",
      },
    };
  },
  computed: {
    rules() {
      let array = [];
      this.validationRules.forEach((item) => {
        array = [...array, this.rulesCollection[item]];
      });
      return array;
    },
  },
  methods: {
    setValue(value) {
      this.value = value.replace(this.thousandSeparator, "").replace(" ", "");
    },
    addThousandSeparator(amount) {
      if (
        amount !== "" &&
        amount !== undefined &&
        amount !== 0 &&
        amount !== "0" &&
        amount !== null
      ) {
        return amount
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSeparator);
      } else {
        return amount;
      }
    },
    ignore() {
      return null;
    },
  },
};
</script>
<style>
.alternativeCurrencyAmountFieldDesign .v-input__slot {
  border: 0.25px solid #aaa;
  padding-left: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.alternativeCurrencySelectFieldDesign .v-input__slot {
  border: 0.25px solid #aaa;
  border-left: none;
  padding-left: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.alternativeCurrencyAmountFieldDesign .v-input__control {
  min-height: 34px !important;
}
.alternativeCurrencySelectFieldDesign .v-input__control {
  min-height: 34px !important;
}
.p_TextInputReadOnly > .v-input__control > .v-input__slot {
  background: none !important;
}
.alternativeCurrencySelectFieldDesign .v-icon {
  margin-top: 0px !important;
  font-size: 18px !important;
  color: #666 !important;
}
.hasCurrency .v-input__slot {
  padding-right: 35px !important;
}
</style>
