<template>
  <div slot-scope="{ items }">
    <container-input-field
      :recordEndpoint="recordEndpoint"
      :recordId="recordId"
      :field="field"
      :newRecord="newRecord"
      :modalColor="modalColor"
    >
      <div slot-scope="field">
        <v-select
          :class="{
            alternativeAutocompleteFieldDesign: field.alternativeDesign,
            'body-2': field.alternativeDesign
          }"
          class="directEntryAutocompleteField"
          ref="autocomplete"
          :label="label"
          :value="field.value"
          @change="field.updateValue"
          :color="field.color"
          :item-color="field.color"
          :items="items"
          :placeholder="'+ ' + label"
          :solo="field.alternativeDesign"
          :dense="field.alternativeDesign"
          :flat="field.alternativeDesign"
          hide-details
        ></v-select>
      </div>
    </container-input-field>
  </div>
</template>
<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    items: {
      type: Array
    },
    endpoint: {
      type: String
    },
    endpointSort: {
      type: String
    },
    originEndpoint: {
      type: String
    },
    originRecordId: {
      type: [String, Number]
    },
    columns: {
      type: Number,
      default: 12
    },
    fieldSequence: {
      type: Number
    },
    date: {
      type: String
    }
  },
  methods: {
    focusField() {
      this.$refs.autocomplete.$refs.input.focus();
      // alert("selectThisField: ");
    }
  }
};
</script>
<style>
.directEntryAutocompleteField .v-input__slot {
  border-right: 0.25px solid #aaa;
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-radius: 0px;
  border-bottom-left-radius: 5px !important;
}
.alternativeAutocompleteFieldDesign .v-input__control {
  min-height: 34px !important;
}
.alternativeAutocompleteFieldDesign .mdi-close,
.alternativeAutocompleteFieldDesign .mdi-menu-down {
  margin-top: 0px !important;
  margin-right: -10px;
  font-size: 14px !important;
  color: #aaa;
}
.alternativeAutocompleteFieldDesign .mdi-close {
  margin-right: -30px;
}
</style>
