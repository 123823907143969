<template>
  <div>
    <slot
      :selectedOperator="selectedOperator"
      :changeOperator="changeOperator"
    ></slot>
  </div>
</template>

<script>
export default {
  props: {
    orQueryKey: {
      type: Number
    },
    tableGroupingKey: {
      type: Number
    },
    subqueryKey: {
      type: Number
    }
  },
  computed: {
    selectedOperator() {
      return this.$store.state.queryBuilder.query.orQueries[this.orQueryKey]
        .tableGroupings[this.tableGroupingKey].andQueries[this.subqueryKey][
        "operator"
      ];
    }
  },
  methods: {
    changeOperator(value) {
      let payload = {
        operator: value,
        orQueryIndex: this.orQueryKey,
        tableGroupingIndex: this.tableGroupingKey,
        subqueryIndex: this.subqueryKey
      };
      this.$store.commit("UPDATE_OPERATOR", payload);
    }
  }
};
</script>
