<template>
  <div :class="topMargin" v-if="!isHidden">
    <v-btn
      :small="buttonSize == 'small'"
      :x-small="buttonSize == 'x-small'"
      @click="executeActionButtonRequest"
      :disabled="isLoading"
    >
      <div v-if="isLoading" style="margin-left: -1px; margin-right: 7px">
        <span class="custom-loader">
          <v-icon small>mdi-cached</v-icon>
        </span>
      </div>
      <v-icon v-else-if="icon" small left>{{ icon }}</v-icon>
      {{ computedLabel }}
    </v-btn>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import axios from "axios";
import { get } from "lodash";
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  mixins: [baseComponentMixin],
  props: {
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    path: {
      type: String,
    },
    field: {
      type: String,
    },
    labelField: {
      type: String,
    },
    hasExtraTopPadding: {
      type: Boolean,
    },
    confirmationDialog: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  computed: {
    buttonSize() {
      if (this.type == "dataTableButton") {
        return "x-small";
      } else {
        return "small";
      }
    },
    topMargin() {
      if (this.type == "dataTableButton") {
        return "mt-0";
      } else if (this.hasExtraTopPadding) {
        return "mt-6";
      } else {
        return "mt-2";
      }
    },
    computedLabel() {
      let field = get(this.record, this.labelField);
      return this.label.replace("{field}", field);
    },
    module() {
      return this.$route.params.module;
    },
    moduleEndpoint() {
      if (this.module) {
        let moduleConfig = this.$store.state.config.app.modules.find(
          (e) => e.basicConfig.path == this.module
        );
        return moduleConfig.basicConfig.apiEndpoint;
      } else {
        return null;
      }
    },
    moduleRecordId() {
      return this.$route.params.record_id;
    },
  },
  methods: {
    executeActionButtonRequest() {
      if (this.confirmationDialog) {
        let confirmation = confirm(this.confirmationDialog);
        if (!confirmation) {
          return;
        }
      }
      this.isLoading = true;
      let fieldValue = get(this.record, this.field);
      let path = this.path
        .replace("{recordId}", this.recordId)
        .replace("{originRecordId}", this.moduleRecordId)
        .replace("{field}", fieldValue);
      axios.post(path).then(() => {
        this.refreshOriginRecord();
        this.refreshPage();
        this.isLoading = false;
      });
    },
    refreshOriginRecord() {
      if (!this.moduleEndpoint.includes("duplicates")) {
        let payload = {
          recordId: this.recordId,
          endpoint: this.recordEndpoint,
        };
        this.$store.dispatch("refreshOriginRecord", payload);
      }
      if (
        this.moduleEndpoint != this.recordEndpoint ||
        this.moduleEndpoint.includes("duplicates")
      ) {
        let payload = {
          recordId: this.moduleRecordId,
          endpoint: this.moduleEndpoint,
        };
        this.$store.dispatch("refreshOriginRecord", payload);
      }
    },
    refreshPage() {
      let payload = {};
      this.$store.dispatch("refreshPage", payload);
    },
  },
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
