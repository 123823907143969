<template>
  <div
    :class="{ 'mt-2': !hasExtraTopPadding, 'mt-6': hasExtraTopPadding }"
    v-if="!isHidden"
  >
    <v-btn small @click="executePrint" style="height: 32px; margin-top: 2px">
      <v-icon small left>mdi-printer</v-icon>
      {{ label }}
    </v-btn>
  </div>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { get } from "lodash";
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  mixins: [baseComponentMixin],
  props: {
    label: {
      type: String,
    },
    path: {
      type: String,
    },
    field: {
      type: String,
    },
    labelField: {
      type: String,
    },
    hasExtraTopPadding: {
      type: Boolean,
    },
    confirmationDialog: {
      type: String,
    },
  },
  computed: {
    computedPath() {
      let field = get(this.record, this.field);
      let path = this.path.replace("{field}", field);
      path = path.replace("{recordId}", this.recordId);
      return path;
    },
  },
  methods: {
    executePrint() {
      window.open(this.computedPath, "", "width=1000,height=700");
    },
  },
};
</script>
