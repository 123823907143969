<template>
  <container-ui-state>
    <div slot-scope="uiState">
      <container-config>
        <div slot-scope="config">
          <v-navigation-drawer
            app
            class="elevation-3"
            :clipped="$vuetify.breakpoint.lgAndUp"
            :color="
              config.app.colorBackgroundDrawer || config.app.colorBackground
            "
            :value="uiState.drawerState !== 'hide'"
            :mini-variant.sync="uiState.drawerState !== 'full'"
            width="220"
            mini-variant-width="80"
          >
            <v-list
              :color="
                config.app.colorBackgroundDrawer || config.app.colorBackground
              "
              :dark="config.app.drawerIsDark"
              :style="{
                height: 'calc(100vh - 180px)',
              }"
              style="overflow: scroll"
            >
              <div v-for="(item, key) in config.modules" :key="key">
                <div
                  v-if="
                    (!item.basicConfig.adminOnly || uiState.user.is_admin) &&
                    (JSON.parse(uiState.user.modules).includes(
                      item.basicConfig.name
                    ) ||
                      JSON.parse(uiState.user.modules).length == 0)
                  "
                >
                  <v-divider
                    v-if="item.basicConfig.divider"
                    :style="{
                      'border-color': config.app.drawerIsDark ? '#aaa' : '#666',
                    }"
                    class="mx-4 my-3"
                  ></v-divider>
                  <v-subheader
                    v-if="
                      item.basicConfig.subheader &&
                      uiState.drawerState === 'full'
                    "
                    class="px-5"
                  >
                    {{ item.basicConfig.subheader }}
                  </v-subheader>
                  <v-tooltip
                    bottom
                    open-delay="500"
                    :color="
                      item.basicConfig.moduleColor || config.app.colorPrimary
                    "
                    :disabled="uiState.drawerState === 'full'"
                  >
                    <template v-slot:activator="{ on }">
                      <v-list-item
                        :to="`/${item.basicConfig.path}`"
                        @click="isMobile ? uiState.toggleDrawer() : null"
                        v-on="on"
                      >
                        <v-list-item-action class="ml-2 mr-4">
                          <v-icon
                            :style="{
                              color:
                                config.app.colorItemDrawer ||
                                item.basicConfig.moduleColor ||
                                config.app.colorPrimary,
                            }"
                            >{{ item.basicConfig.icon }}</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            class="subtitle-2"
                            :style="{
                              color:
                                config.app.colorItemDrawer ||
                                item.basicConfig.moduleColor ||
                                config.app.colorPrimary,
                            }"
                          >
                            {{ item.basicConfig.name.toUpperCase() }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>{{ item.basicConfig.name }}</span>
                  </v-tooltip>
                </div>
              </div>
              <v-divider
                :style="{
                  'border-color': config.app.drawerIsDark ? '#aaa' : '#666',
                }"
                class="mx-4 my-3"
              ></v-divider>
              <div v-if="config.settings && uiState.canSeeSettings">
                <v-tooltip
                  bottom
                  open-delay="500"
                  :disabled="uiState.drawerState === 'full'"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item
                      to="/settings"
                      @click="isMobile ? uiState.toggleDrawer() : null"
                      v-on="on"
                    >
                      <v-list-item-action class="ml-2 mr-4">
                        <v-icon
                          :color="config.app.drawerIsDark ? '#ddd' : '#666'"
                          >mdi-cog</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          class="subtitle-2"
                          :style="{
                            color: config.app.drawerIsDark ? '#ddd' : '#666',
                          }"
                        >
                          {{ texts["settings"][config.language] }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <span>{{ texts["settings"][config.language] }}</span>
                </v-tooltip>
              </div>
              <div v-if="uiState.user.is_admin">
                <v-tooltip
                  bottom
                  open-delay="500"
                  :disabled="uiState.drawerState === 'full'"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item
                      to="/users"
                      @click="isMobile ? uiState.toggleDrawer() : null"
                      v-on="on"
                    >
                      <v-list-item-action class="ml-2 mr-4">
                        <v-icon
                          :color="config.app.drawerIsDark ? '#ddd' : '#666'"
                          >mdi-account</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          class="subtitle-2"
                          :style="{
                            color: config.app.drawerIsDark ? '#ddd' : '#666',
                          }"
                        >
                          {{ texts["users"][config.language] }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <span>{{ texts["users"][config.language] }}</span>
                </v-tooltip>
              </div>
              <!-- <v-list-group
                v-if="config.settings"
                prepend-icon="mdi-cog"
                value="true"
              >
                <template v-slot:activator>
                  <v-list-item-title>Indstillinger</v-list-item-title>
                </template>

                <v-list-item
                  v-for="(setting, key) in config.settings"
                  :key="key"
                  link
                >
                  <v-list-item-title v-text="setting.name"></v-list-item-title>
                  <v-list-item-icon>
                    <v-icon v-text="setting.icon"></v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <div v-if="config.settings">{{ config.settings }}</div>
              </v-list-group> -->
            </v-list>
            <v-footer
              absolute
              class="ma-0 pa-0"
              :color="
                config.app.colorBackgroundDrawer || config.app.colorBackground
              "
              style="width: 98%"
            >
              <v-list
                :color="
                  config.app.colorBackgroundDrawer || config.app.colorBackground
                "
                dense
                :dark="config.app.drawerIsDark"
                style="width: 100%"
              >
                <v-tooltip
                  bottom
                  open-delay="500"
                  :color="config.app.colorPrimary"
                  :disabled="uiState.drawerState === 'full'"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" to="/change-password">
                      <v-list-item-action class="ml-2 mr-4">
                        <v-icon
                          :color="config.app.drawerIsDark ? '#ddd' : '#666'"
                          >mdi-lock</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          class="subtitle-2"
                          :style="{
                            color: config.app.drawerIsDark ? '#ddd' : '#666',
                          }"
                        >
                          {{ texts["changePassword"][config.language] }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <span>{{ texts["changePassword"][config.language] }}</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  open-delay="500"
                  :color="config.app.colorPrimary"
                  :disabled="uiState.drawerState === 'full'"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" @click="uiState.logout">
                      <v-list-item-action class="ml-2 mr-4">
                        <v-icon
                          :color="config.app.drawerIsDark ? '#ddd' : '#666'"
                          >mdi-logout-variant</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          class="subtitle-2"
                          :style="{
                            color: config.app.drawerIsDark ? '#ddd' : '#666',
                          }"
                        >
                          {{ texts["logout"][config.language] }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <span>{{ texts["logout"][config.language] }}</span>
                </v-tooltip>
              </v-list>
            </v-footer>
          </v-navigation-drawer>
        </div>
      </container-config>
    </div>
  </container-ui-state>
</template>

<script>
export default {
  data() {
    return {
      texts: {
        changePassword: {
          en: "change password",
          da: "skift adgangskode",
          de: "Passwort ändern",
        },
        logout: {
          en: "log out",
          da: "log ud",
          de: "abmelden",
        },
        settings: {
          en: "Settings",
          da: "Indstillinger",
          de: "Einstellungen",
        },
        users: {
          en: "Users",
          da: "Brugere",
          de: "Benutzer",
        },
      },
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>
