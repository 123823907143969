var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-config',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var design = ref.design;
var app = ref.app;
return _c('div',{},[_c('container-list',{ref:"list",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pageData = ref.pageData;
var recordCount = ref.recordCount;
return _c('div',{style:({ 'background-color': app.colorBackground })},[_c('div',{staticStyle:{"border-bottom":"1px solid lightgrey"},style:({
            height: design.listHeaderHeight + 'px'
          })},[_c('app-record-master-list-header')],1),_c('div',{staticStyle:{"overflow":"scroll"},style:({
            height:
              'calc(' +
              _vm.windowHeight +
              'px' +
              ' - ' +
              (design.headerHeight +
                design.listHeaderHeight +
                design.listFooterHeight +
                (recordCount > 100 ? design.listPaginationHeight : 0)) +
              'px)'
          })},[_c('app-record-master-list-progress'),(pageData != undefined)?_c('app-record-master-list-body',{attrs:{"pageData":pageData}}):_vm._e()],1),(recordCount > 100)?_c('div',{staticStyle:{"border-top":"0.25px solid #aaa"},style:({
            height: design.listPaginationHeight + 'px'
          })},[_c('app-pagination')],1):_vm._e(),_c('div',{staticStyle:{"border-top":"0.25px solid #aaa"},style:({
            height: design.listFooterHeight + 'px'
          })},[_c('app-record-master-list-footer')],1)])}}],null,true)})],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }