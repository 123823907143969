<template>
  <v-row class="mt-0 pb-0 pt-1">
    <v-col class="my-0 px-0 py-0">
      <vue-dropzone
        ref="vueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        useCustomSlot
        style="width: 100%;"
      >
        <slot></slot>
        <div>
          <v-icon large>mdi-cloud-upload-outline</v-icon>
          <h3>
            Drag and drop to upload content here!!!!
          </h3>
          <div>
            ...or click to select a file from your computer
          </div>
        </div>
      </vue-dropzone>
    </v-col>
  </v-row>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import upperFirst from "lodash/upperFirst";

export default {
  mixins: [baseComponentMixin],
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    field_key: {
      type: String,
      default: "asset"
    },
    field_value: {
      type: String,
      default: "1919"
    }
  },
  computed: {
    dropzoneOptions() {
      return {
        url: this.apiUrl + "file",
        thumbnailWidth: null,
        thumbnailHeight: 100,
        withCredentials: true,
        params: { field_key: this.field_key, field_value: this.field_value },
        headers: {
          "X-XSRF-TOKEN": this.xsrf
        }
      };
    },
    xsrf() {
      return decodeURIComponent(this.check_cookie_name("XSRF-TOKEN"));
    },
    config() {
      return this.$store.state.config.app;
    },
    apiUrl() {
      let url = this.config.basicConfig[
        "apiUrl" + upperFirst(process.env.VUE_APP_ENV)
      ];
      return url;
    }
  },
  methods: {
    check_cookie_name(name) {
      var match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
      );
      if (match) {
        return match[2];
      } else {
        return "--something went wrong---";
      }
    },
    allUploadsComplete() {
      this.refreshOriginRecord();
      setTimeout(
        function() {
          this.$refs.vueDropzone.removeAllFiles();
        }.bind(this),
        1000
      );
    },
    refreshOriginRecord() {
      let payload = {
        recordId: this.recordId,
        endpoint: this.recordEndpoint
      };
      this.$store.dispatch("refreshOriginRecord", payload);
    }
  }
};
</script>
<style>
.dropzone {
  border: none !important;
}
</style>
