var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-config',{scopedSlots:_vm._u([{key:"default",fn:function(config){return _c('div',{},[_c('v-row',[_c('v-col',{staticClass:"pl-md-10 pr-0",attrs:{"cols":"auto"}},[(
            config.module.recordTitleConfig &&
              config.module.recordTitleConfig.avatarField &&
              config.module.recordTitleConfig.avatarTypeTitle == 'tile'
          )?_c('v-img',{staticClass:"mr-3",attrs:{"src":config.module.recordTitleConfig.titleUrl.replace(
              '{field}',
              _vm.record[config.module.recordTitleConfig.avatarField]
            ),"max-height":config.module.recordTitleConfig.avatarMaxHeightTitle,"max-width":config.module.recordTitleConfig.avatarMaxHeightTitle *
              _vm.record[
                config.module.recordTitleConfig.avatarFactorFieldTitle
              ] || config.module.recordTitleConfig.avatarMaxHeightTitle,"contain":""}}):(
            config.module.recordTitleConfig &&
              config.module.recordTitleConfig.avatarField &&
              _vm.record[config.module.recordTitleConfig.avatarField]
          )?_c('v-list-item-avatar',{staticClass:"my-0 p-0",attrs:{"size":config.module.recordTitleConfig.avatarSizeTitle || 48}},[_c('v-img',{attrs:{"src":config.module.recordTitleConfig.titleUrl.replace(
                '{field}',
                _vm.record[config.module.recordTitleConfig.avatarField]
              ),"max-height":config.module.recordTitleConfig.avatarSizeTitle,"max-width":config.module.recordTitleConfig.avatarSizeTitle}})],1):_c('v-icon',{staticClass:"ml-0",staticStyle:{"margin-top":"-3px"},attrs:{"large":!config.module.recordTitleConfig.avatarField,"x-large":config.module.recordTitleConfig.avatarField != '',"left":"","color":config.color}},[_vm._v(" "+_vm._s(config.module.basicConfig.iconRecord || config.module.basicConfig.icon)+" ")])],1),_c('v-col',{staticClass:"px-0 mt-0",attrs:{"cols":config.module.recordTitleConfig &&
          config.module.recordTitleConfig.avatarField
            ? 7
            : 9}},[(config.module.recordTitleConfig.fieldSubtitleBefore)?_c('h3',{staticClass:"font-weight-light uppercase",staticStyle:{"max-width":"100%","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm.record[config.module.recordTitleConfig.fieldSubtitleBefore])+" ")]):_vm._e(),_c('h1',{staticClass:"headline font-weight-light uppercase",staticStyle:{"max-width":"100%","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm.record[config.module.recordTitleConfig.fieldTitle])+" ")]),(config.module.recordTitleConfig.fieldSubtitleAfter)?_c('h3',{staticClass:"font-weight-light uppercase",staticStyle:{"max-width":"100%","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm.record[config.module.recordTitleConfig.fieldSubtitleAfter])+" ")]):_vm._e(),(config.module.recordTitleConfig.fieldSubtitleAfter2)?_c('h4',{staticClass:"font-weight-light uppercase",staticStyle:{"max-width":"100%","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm.record[config.module.recordTitleConfig.fieldSubtitleAfter2])+" ")]):_vm._e()]),_c('v-spacer'),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"auto"}},[_c('app-record-detail-alert-button',{staticClass:"d-none d-sm-flex",attrs:{"title":_vm.title}})],1),_c('v-col',{staticClass:"pa-0 mr-5",attrs:{"cols":"auto"}},[_c('app-record-detail-deletion-button',{staticClass:"d-none d-sm-flex",attrs:{"endpoint":config.module.basicConfig.apiEndpoint,"recordId":_vm.record.id},on:{"executeDeletion":function($event){return _vm.executeDeletion()}}})],1)],1)],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }