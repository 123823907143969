import axios from "axios";

// had to rewrite this to allow for the auth token to be deleted
// and subsequently reinstated for the axios request to S3

class Vapor {
  /**
   * Store a file in S3 and return its UUID, key, and other information.
   */
  async store(file, options = {}) {
    const response = await axios.post(
      "/vapor/signed-storage-url",
      {
        bucket: options.bucket || "",
        content_type: options.contentType || file.type,
        expires: options.expires || "",
        visibility: options.visibility || "",
        filename: file.name || "",
        folder: options.folder || "",
        parameters: options.parameters || {}
      },
      {
        baseURL: options.baseURL || null,
        headers: options.headers || {}
      }
    );

    let headers = response.data.headers;

    if ("Host" in headers) {
      delete headers.Host;
    }

    if (typeof options.progress === "undefined") {
      options.progress = () => {};
    }

    const cancelToken = options.cancelToken || "";

    // delete axios.defaults.headers.common.Authorization;
    axios.defaults.withCredentials = false;

    await axios.put(response.data.url, file, {
      cancelToken: cancelToken,
      headers: headers,
      onUploadProgress: progressEvent => {
        options.progress(progressEvent.loaded / progressEvent.total);
      }
    });

    axios.defaults.withCredentials = true;
    // let localStorageUser = JSON.parse(localStorage.getItem("user"));
    // axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Bearer ${localStorageUser.token}`;

    response.data.extension = file.name.split(".").pop();

    return response.data;
  }
}

let object = new Vapor();

export default object;
