<template>
  <container-ui-state>
    <div slot-scope="{ changeMobileView }">
      <container-config>
        <div slot-scope="{ app, color, module }">
          <v-list class="py-0" :color="app.colorBackground">
            <v-list-item-group>
              <div v-for="item in pageData" :key="item.id">
                <v-list-item
                  :color="color"
                  @click="changeMobileView('form')"
                  :to="{
                    path: `/${$route.params.module}/${item.id}`,
                    query: $route.query,
                  }"
                >
                  <v-list-item-avatar
                    v-if="module.recordTitleConfig.avatarField"
                    :tile="module.recordTitleConfig.avatarTypeList == 'tile'"
                    :size="module.recordTitleConfig.avatarSizeList || 48"
                  >
                    <v-img
                      v-if="item[module.recordTitleConfig.avatarField]"
                      :src="
                        module.recordTitleConfig.avatarUrl.replace(
                          '{field}',
                          item[module.recordTitleConfig.avatarField]
                        )
                      "
                    />
                    <v-icon x-large v-else>mdi-account</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-avatar
                    v-if="module.recordTitleConfig.hasStatusDot"
                    tile
                    size="18"
                    height="55"
                  >
                    <v-icon
                      small
                      class="pa-0 mt-n10"
                      :color="
                        backgroundColor(
                          item,
                          module.recordTitleConfig.statusDotColorRules
                        )
                      "
                      >mdi-checkbox-blank-circle</v-icon
                    >
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      v-if="module.recordTitleConfig.fieldSubtitleBefore"
                    >
                      {{ item[module.recordTitleConfig.fieldSubtitleBefore] }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ item[module.recordTitleConfig.fieldTitle] }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="module.recordTitleConfig.fieldSubtitleAfter"
                    >
                      {{ item[module.recordTitleConfig.fieldSubtitleAfter] }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-if="module.recordTitleConfig.fieldSubtitleAfter2"
                      class="caption"
                    >
                      {{ item[module.recordTitleConfig.fieldSubtitleAfter2] }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list-item-group>
          </v-list>
        </div>
      </container-config>
    </div>
  </container-ui-state>
</template>

<script>
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
export default {
  props: {
    pageData: {
      type: Array,
      default: null,
    },
    dateFormattingString: {
      type: String,
      default: "iii, d MMMM y",
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    backgroundColor(item, rules) {
      var color;
      if (rules) {
        rules.forEach((rule) => {
          if (item[rule.field] == rule.value) {
            color = rule.color;
          }
        });
      }
      return color;
    },
    formatDate(input) {
      if (input) {
        let parsedDate = parseISO(input);
        return format(parsedDate, this.dateFormattingString);
      } else {
        return null;
      }
    },
  },
};
</script>
