<template>
  <container-exporter>
    <div slot-scope="{ color, exporterIsActive }">
      <v-btn icon color="white" @click="toggleDialog" v-if="exporterIsActive">
        <v-icon color="white">mdi-cloud-download-outline</v-icon>
      </v-btn>
      <v-dialog
        :value="showExporter"
        @input="toggleDialog"
        width="1250"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :hide-overlay="$vuetify.breakpoint.xsOnly"
        :key="endpoint"
      >
        <v-card style="height: calc(100vh - 120px)">
          <v-card-title class="headline grey lighten-4 pb-0 pt-2" primary-title>
            <v-tabs
              :color="color"
              background-color="grey lighten-4"
              v-model="tab"
              show-arrows
            >
              <v-tab><svg-excel :width="25" class="mr-3" />Excel</v-tab>
              <v-tab><svg-word :width="25" class="mr-3" />Word</v-tab>
            </v-tabs>
          </v-card-title>
          <v-card-text>
            <app-exporter-excel v-if="tab == 0" />
            <app-exporter-word v-if="tab == 1" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </container-exporter>
</template>

<script>
import AppExporterWord from "./AppExporterWord.vue";
export default {
  components: { AppExporterWord },
  data() {
    return {
      showExporter: false,
      tab: 0,
    };
  },
  methods: {
    toggleDialog() {
      this.showExporter = !this.showExporter;
    },
  },
  computed: {
    endpoint() {
      return this.$store.getters.moduleEndpoint;
    },
  },
};
</script>

<style lang="scss" scoped></style>
