<template>
  <div>
    <slot
      :page="page"
      :changePage="changePage"
      :filter="filter"
      :changeFilter="changeFilter"
      :sort="sort"
      :sortBy="sortBy"
      :sortDesc="sortDesc"
      :updateSortBy="updateSortBy"
      :updateSortDesc="updateSortDesc"
      :changeSort="changeSort"
      :pageData="pageData"
      :recordCount="recordCount"
      :paginationLength="paginationLength"
      :selectedRecord="selectedRecord"
      :selectedModuleList="selectedModuleList"
      :moduleLists="moduleLists"
      :list="list"
      :changeList="changeList"
      :refreshPage="refreshPage"
    ></slot>
  </div>
</template>

<script>
import { debounce } from "lodash";

export default {
  //TO DO: try to split this up into discrete container components!
  data() {
    return {
      sortBy: null,
    };
  },
  computed: {
    //module name and state

    moduleName() {
      return this.$route.params.module;
    },

    moduleState() {
      return this.$store.state[this.moduleName];
    },

    // page, filter, query, sort
    page() {
      return this.moduleState.selectedPage;
    },
    filter() {
      return this.moduleState.filter;
    },
    query() {
      return this.moduleState.query;
    },
    list() {
      return this.moduleState.selectedList;
    },
    sort: {
      get() {
        return this.moduleState.sort;
      },
      set(value) {
        this.$store.commit("SET_SORT", { module: this.module, value });
        this.changeHandler({ clearRecords: true });
      },
    },
    sortDesc() {
      return null;
    },

    //page data, record count and selected record

    pageData() {
      return this.moduleState.data[this.page];
    },

    selectedRecord() {
      if (this.pageData) {
        let recordId = this.$route.params.record_id;
        return this.pageData.find((e) => e.id == recordId);
      } else {
        return null;
      }
    },

    recordCount() {
      return this.moduleState.recordCount;
    },

    paginationLength() {
      return Math.floor((this.recordCount - 1) / 100) + 1;
    },

    //module config and lists

    moduleConfig() {
      let modules = this.$store.state.config.app.modules;
      return modules.find((e) => e.basicConfig.path == this.moduleName);
    },

    selectedModuleList() {
      if (this.moduleConfig.lists) {
        return this.moduleConfig.lists[this.list];
      } else {
        return null;
      }
    },

    moduleLists() {
      if (this.moduleConfig.lists) {
        let lists = this.moduleConfig.lists;
        let output = [];
        lists.map(function (list, index) {
          let object = { name: list.name, index };
          output[index] = object;
        });
        return output;
      } else {
        return null;
      }
    },
  },

  methods: {
    changePage(value) {
      this.$store.commit("SET_PAGE", { module: this.moduleName, value });
      this.$store.dispatch("changeHandler", { clearRecords: false });
    },

    changeList(value) {
      this.$store.commit("SET_LIST", { module: this.moduleName, value });
    },

    changeFilter: debounce(function (value) {
      this.$store.commit("SET_FILTER", { module: this.moduleName, value });
      this.$store.dispatch("changeHandler", { clearRecords: true });
    }, 400),

    updateSortBy(input) {
      this.sortBy = input;
    },

    updateSortDesc() {},

    changeSort(input) {
      this.sort = input;
    },

    refreshPage() {
      this.$store.dispatch("refreshPage", { selectFirstRecord: true });
    },

    loadModuleRecords() {
      return new Promise((resolve) => {
        this.$store.dispatch("loadModuleRecords").then(() => resolve());
      });
    },

    selectFirstRecord() {
      this.$store.dispatch("selectFirstRecord");
    },
  },

  mounted() {
    if (this.pageData == undefined) {
      //set an empty array to make sure this is only triggered once
      this.$store.commit("SET_PAGE_DATA", {
        module: this.moduleName,
        page: this.page,
        data: [],
      });
      this.loadModuleRecords().then(() => {
        if (!this.$route.query.link) {
          this.selectFirstRecord();
        }
      });
    } else if (
      this.$store.state.uiState.variablePreFilterValue !=
      this.moduleState.variablePreFilterValue
    ) {
      this.$store.commit("SET_MODULE_VARIABLE_PRE_FILTER", {
        module: this.moduleName,
        value: this.$store.state.uiState.variablePreFilterValue,
      });
      this.$store.dispatch("changeHandler", { clearRecords: true });
    }
  },
};
</script>
