<template>
  <div>
    <h4
      class="font-weight-medium text-uppercase mt-3"
      style="letter-spacing: 1.25px; font-size: 14px"
      v-if="label"
    >
      {{ label }}
    </h4>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped></style>
