<template>
  <div>
    <v-row>
      <v-col class="pa-0 ma-0">
        <v-tabs
          v-if="tabs.length > 1"
          v-model="selectedTab"
          class="px-0"
          color="#778899"
          background-color="transparent"
        >
          <v-tab v-for="(tab, key) in tabs" :key="key">
            {{ labelConverted(tab.name) }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item
            style="overflow: scroll; background-color: #f2f2f2"
            class="px-3 py-3"
            v-for="(tab, key) in tabs"
            :key="key"
          >
            <div v-for="(prop, key) in filterProps(key)" :key="key">
              <component
                v-bind:is="componentTypeMap[prop.type]"
                :label="prop.name | capitalize"
                :field="prop.name"
                :documentation="prop.documentation"
                :required="prop.required"
                :sectionTitle="prop.sectionTitle"
                :headers="prop.headers"
                :itemsName="prop.name"
                :getArray="[...pathArray, 'props']"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get, startCase } from "lodash";
export default {
  props: {
    // component: {
    //   type: Object,
    // },
    pathArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTab: 0,
      componentTypeMap: {
        String: "ConfigInputText",
        Number: "ConfigInputNumber",
        Boolean: "ConfigInputSwitch",
        Array: "ConfigInputArray",
        DataTable: "ConfigInputDataTable",
      },
    };
  },
  methods: {
    labelConverted(input) {
      return startCase(input);
    },
    filterProps(tab) {
      if (this.tabs[tab]) {
        let tabName = this.tabs[tab]["name"];
        let tabsOwnProps = this.props.filter((e) => e.tab == tabName);
        let undefinedProps =
          tab == 0 ? this.props.filter((e) => e.tab == null) : [];
        return [...tabsOwnProps, ...undefinedProps];
      } else {
        return [];
      }
    },
  },
  computed: {
    components() {
      return this.$store.state.components.components;
    },
    componentConfig() {
      let name = this.componentObject.componentType.replace("Base", "");
      // return this.component.componentType;
      return this.components.find((item) => item.name == name);
    },
    componentObject() {
      return get(this.$store.state, this.pathArray);
    },
    props() {
      return this.componentConfig.props;
    },
    tabs() {
      return this.componentConfig.tabs || [{ name: "BasicDetails" }];
    },
  },
};
</script>
