<template>
  <container-ui-state>
    <div slot-scope="{ changeMobileView }">
      <container-config>
        <div slot-scope="config">
          <v-row class="px-3">
            <div>
              <v-btn class="ma-3" @click="changeMobileView('list')">
                <v-icon left>mdi-chevron-left</v-icon> back to list
              </v-btn>
            </div>
            <v-spacer></v-spacer>
            <app-record-detail-deletion-button
              @executeDeletion="
                changeMobileView('list');
                executeDeletion();
              "
              :endpoint="config.module.basicConfig.apiEndpoint"
              :recordId="record.id"
            />
          </v-row>
        </div>
      </container-config>
    </div>
  </container-ui-state>
</template>

<script>
export default {
  props: {
    record: {
      type: Object
    }
  },
  methods: {
    executeDeletion() {
      this.$emit("executeDeletion");
    }
  }
};
</script>
