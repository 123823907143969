<template>
  <div>
    <v-row class="mb-0">
      <v-col class="ml-3 px-0 py-0">
        <base-label label="Geburtstag" />
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col cols="3" class="py-0 mb-0">
        <v-select
          :items="days"
          solo
          dense
          flat
          placeholder="Tag"
          v-model="day"
          hide-details="auto"
          class="alternativeSelectFieldDesign body-2"
        ></v-select>
      </v-col>
      <v-col cols="5" class="pl-0 py-0 mb-0">
        <v-select
          :items="months"
          item-text="name_de"
          item-value="month"
          solo
          dense
          flat
          placeholder="Monat"
          v-model="month"
          hide-details="auto"
          class="alternativeSelectFieldDesign body-2"
        ></v-select>
      </v-col>
      <v-col cols="3" class="pl-0 pr-1 py-0 mb-0">
        <v-select
          :items="years"
          solo
          dense
          flat
          placeholder="Jahr"
          v-model="year"
          hide-details="auto"
          class="alternativeSelectFieldDesign body-2"
        ></v-select>
      </v-col>
      <v-col cols="1" class="pl-0 mb-0 pt-1 pb-0">
        <v-btn small @click="clearInput" icon v-if="day || month || year"
          ><v-icon small>mdi-close</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
  methods: {
    clearInput() {
      this.$emit("change", "");
    },
  },
  computed: {
    day: {
      get() {
        return this.birthdayArray[2];
      },
      set(input) {
        let birthday =
          (this.birthdayArray[0] || "0000") +
          "-" +
          (this.birthdayArray[1] || "00") +
          "-" +
          input;
        this.$emit("change", birthday);
      },
    },
    month: {
      get() {
        return this.birthdayArray[1];
      },
      set(input) {
        let birthday =
          (this.birthdayArray[0] || "0000") +
          "-" +
          input +
          "-" +
          (this.birthdayArray[2] || "00");
        this.$emit("change", birthday);
      },
    },
    year: {
      get() {
        return this.birthdayArray[0];
      },
      set(input) {
        let birthday =
          input +
          "-" +
          (this.birthdayArray[1] || "00") +
          "-" +
          (this.birthdayArray[2] || "00");
        this.$emit("change", birthday);
      },
    },
    birthday() {
      let dayText =
        this.day == null ? "00" : this.day < 10 ? "0" + this.day : this.day;
      let monthText =
        this.month == null
          ? "00"
          : this.month < 10
          ? "0" + this.month
          : this.month;
      let yearText = this.year == null ? "0000" : this.year;
      return yearText + "-" + monthText + "-" + dayText;
    },
    birthdayArray() {
      if (this.value == null) {
        return "0000-00-00".split("-");
      } else {
        return this.value.split("-");
      }
    },
    days() {
      let i;
      let days = new Array();
      for (i = 1; i < 32; i++) {
        days.push((i < 10 ? "0" + i : i).toString());
      }
      return days;
    },
    months() {
      return [
        {
          month: "01",
          name_en: "January",
          name_de: "Januar",
          name_da: "Januar",
        },
        {
          month: "02",
          name_en: "February",
          name_de: "Februar",
          name_da: "Februar",
        },
        {
          month: "03",
          name_en: "March",
          name_de: "März",
          name_da: "Marts",
        },
        {
          month: "04",
          name_en: "April",
          name_de: "April",
          name_da: "April",
        },
        {
          month: "05",
          name_en: "May",
          name_de: "Mai",
          name_da: "Maj",
        },
        {
          month: "06",
          name_en: "June",
          name_de: "Juni",
          name_da: "Juni",
        },
        {
          month: "07",
          name_en: "July",
          name_de: "Juli",
          name_da: "Juli",
        },
        {
          month: "08",
          name_en: "August",
          name_de: "August",
          name_da: "August",
        },
        {
          month: "09",
          name_en: "September",
          name_de: "September",
          name_da: "September",
        },
        {
          month: "10",
          name_en: "October",
          name_de: "Oktober",
          name_da: "Oktober",
        },
        {
          month: "11",
          name_en: "November",
          name_de: "November",
          name_da: "November",
        },
        {
          month: "12",
          name_en: "December",
          name_de: "Dezember",
          name_da: "December",
        },
      ];
    },
    years() {
      let y;
      let years = new Array();
      for (y = 2020; y > 1909; y--) {
        years.push(y.toString());
      }
      return years;
    },
  },
};
</script>
