<template>
  <div>
    <container-input-field
      :recordId="recordId"
      :recordEndpoint="recordEndpoint"
      :field="field"
      :newRecord="newRecord"
      :modalColor="modalColor"
      :reloadRecordOnChange="reloadRecordOnChange"
      :storeNewRecordImmediately="storeNewRecordImmediately"
      @storeNewRecordImmediately="$emit('storeRecord')"
      v-if="!isHidden"
    >
      <div slot-scope="field">
        <v-menu
          v-model="menuDatepicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-row v-if="field.alternativeDesign">
              <v-col class="ml-3 px-0 py-0">
                <base-label :label="label" />
              </v-col>
            </v-row>
            <v-text-field
              :class="{
                alternativeDatepickerFieldDesign: field.alternativeDesign,
                'body-2': field.alternativeDesign,
                p_TextInputReadOnly: isReadonly,
              }"
              :value="formatDate(field.value)"
              @input="field.updateValue"
              :color="field.color"
              :solo="field.alternativeDesign"
              :dense="field.alternativeDesign"
              :flat="field.alternativeDesign"
              append-icon="mdi-calendar"
              readonly
              hide-details
              class="pb-2"
              v-on="on"
              :clearable="!isReadonly"
            ></v-text-field>
          </template>
          <v-date-picker
            v-if="!isReadonly"
            :color="field.color"
            :value="field.value"
            @change="field.updateValue"
            @input="menuDatepicker = false"
            :first-day-of-week="1"
            :locale="locale"
          ></v-date-picker>
        </v-menu>
      </div>
    </container-input-field>
  </div>
</template>

<script>
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
// eslint-disable-next-line
import { da } from "date-fns/locale";
// eslint-disable-next-line
import { de } from "date-fns/locale";
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      menuDatepicker: false,
    };
  },
  props: {
    dateFormattingString: {
      type: String,
      default: "eee, d MMMM y",
    },
    reloadRecordOnChange: {
      type: Boolean,
    },
    isReadonly: {
      type: Boolean,
    },
  },
  computed: {
    language() {
      return this.$store.state.config.app.basicConfig.appLanguage;
    },
    locale() {
      let localeArray = {
        en: "en-us",
        de: "de-ch",
      };
      return localeArray[this.language];
    },
  },
  methods: {
    formatDate(input) {
      if (input) {
        let parsedDate = parseISO(input);
        let languageObject =
          this.language == "de" ? de : this.language == "da" ? da : null;
        return format(parsedDate, this.dateFormattingString, {
          locale: languageObject,
        });
      } else {
        return null;
      }
    },
  },
};
</script>
<style scope="local">
.alternativeDatepickerFieldDesign .mdi-close {
  margin-top: 0px !important;
  margin-right: -24px !important;
  font-size: 14px !important;
  color: #aaa !important;
}
</style>
