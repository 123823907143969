<template>
  <div></div>
</template>

<script>
export default {
  computed: {
    firstModulePath() {
      if (this.$store.state.config.app) {
        return this.$store.state.config.app.modules[0].basicConfig.path;
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.$router.push("/" + this.firstModulePath);
  },
};
</script>
