<template>
  <div>
    <v-row style="max-width: 700px">
      <v-col cols="auto" class="ml-3">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          clearable
          dense
          width="300"
          v-model="queryFilter"
          class="queryFilter"
          autocomplete="disabled"
          color="#444"
          placeholder="Export suchen"
        />
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-switch
          v-model="myQueriesOnly"
          label="nur meine Exporte anzeigen"
          class="mt-1 mb-0 queryFilter"
          color="#444"
        />
      </v-col>
    </v-row>
    <div style="height: calc(100vh - 330px); overflow: scroll">
      <v-data-table
        dense
        class="table"
        hide-default-footer
        :items-per-page="1000"
        :headers="headers_de"
        :items="filteredItems"
        style="max-width: 700px"
      >
        <template v-slot:item.export="{ item }">
          <app-excel-exporter
            :headers="JSON.parse(item.selection)"
            :moduleName="moduleName"
          >
            <div slot-scope="{ openDialog }">
              <v-btn icon small @click="openDialog">
                <svg-excel class="mt-1" :width="20" />
              </v-btn>
            </div>
          </app-excel-exporter>
        </template>
        <template v-slot:item.load="{ item }">
          <v-btn icon small @click="loadExcelExport(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-btn
            icon
            small
            @click="deleteItem(item)"
            v-if="item.user_id == currentUserId"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      items: [],
      myQueriesOnly: false,
      queryFilter: null,
      headers_de: [
        {
          text: "Export",
          value: "name",
        },
        {
          text: "Benutzer*in",
          value: "user.name",
        },
        {
          text: "",
          value: "export",
          sortable: false,
          width: 35,
        },
        {
          text: "",
          value: "load",
          sortable: false,
          width: 35,
        },
        {
          text: "",
          value: "delete",
          sortable: false,
          width: 35,
        },
      ],
    };
  },
  computed: {
    moduleName() {
      return this.$route.params.module;
    },
    currentUserId() {
      return this.$store.state.auth.user.id;
    },
    filteredItems() {
      return this.items.filter((item) => {
        let myQueriesTest = false;
        if (this.myQueriesOnly) {
          myQueriesTest = item.id == this.currentUserId;
        } else {
          myQueriesTest = true;
        }
        let filterTest = false;
        if (this.queryFilter) {
          filterTest = item.name
            .toLowerCase()
            .includes(this.queryFilter.toLowerCase());
        } else {
          filterTest = true;
        }
        return myQueriesTest && filterTest;
      });
    },
  },
  methods: {
    loadStoredExports() {
      axios
        .get("excel-exports?module=" + this.moduleName)
        .then((r) => r.data)
        .then((data) => (this.items = data));
    },
    loadExcelExport(item) {
      let payload = {
        id: item.id,
        name: item.name,
        selection: item.selection,
      };
      this.$store.commit("SET_ALL_EXCEL_EXPORT_STATE_FIELDS", payload);
      this.$emit("switchTab");
    },
    deleteItem(item) {
      let ok = confirm("Soll dieser Excel-Export wirklich gelöscht werden?");
      if (ok) {
        let url = "/excel-exports/" + item.id;
        axios.delete(url).then(() => this.loadStoredExports());
      }
    },
  },
  mounted() {
    this.loadStoredExports();
  },
};
</script>

<style lang="scss" scoped>
.table {
  border: 0.25px #aaa solid;
}
</style>
