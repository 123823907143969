<template>
  <div>
    <div style="height: 57px">
      <app-users-details-header :user="user" v-if="user" />
    </div>
    <div
      :style="{
        height: 'calc(' + windowHeight + 'px' + ' - ' + (64 + 57) + 'px)',
      }"
      style="overflow: scroll"
    >
      <app-users-details-body
        style="background-color: #f1f1f1; height: calc(100vh - 124px)"
        ref="body"
        :user="user"
        v-if="user"
      ></app-users-details-body>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      user: null,
      windowHeight: null,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    handleResize() {
      this.windowHeight = window.innerHeight;
    },
    addNewRecord() {
      this.$refs.body.addNewRecord();
    },
    loadUser() {
      const userId = this.$route.params.user;
      axios
        .get("/user/" + userId)
        .then((r) => r.data)
        .then((data) => (this.user = data));
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.loadUser();
  },
};
</script>
