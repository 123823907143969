<template>
  <div>
    <v-btn @click="download" tile x-small :loading="loading">
      <v-icon left small class="mr-1 ml-n1">mdi-download</v-icon>
      {{ buttonText }}
      <template v-slot:loader>
        <span class="custom-loader">
          <v-icon light small>mdi-cached</v-icon>
        </span>
      </template>
    </v-btn>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
    };
  },
  props: {
    fileUuid: {
      type: String,
    },
    linkPath: {
      type: String,
      default: null,
    },
    record: {
      type: Object,
    },
    field: {
      type: String,
    },
    buttonText: {
      type: String,
      default: "click to download file",
    },
    buttonIcon: {
      type: String,
      default: null,
    },
  },
  computed: {
    url() {
      let computedLinkPath = this.linkPath.replace(
        "{field}",
        this.record[this.field]
      );
      let url = this.linkPath ? computedLinkPath : "download/" + this.fileUuid;
      return url;
    },
  },
  methods: {
    download() {
      this.loading = true;
      axios.get(this.url).then((response) => {
        let url = response.data.url;
        let name = response.data.name;
        axios.defaults.withCredentials = false;
        axios({
          url,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          this.loading = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", name);
          document.body.appendChild(fileLink);
          fileLink.click();
          axios.defaults.withCredentials = true;
        });
      });
    },
  },
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
