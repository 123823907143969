<template>
  <container-value-list
    :endpoint="endpoint"
    :endpointSort="endpointSort"
    :itemText="itemText"
    :itemHeader="itemHeader"
    :items="items"
    :filters="filters"
  >
    <div slot-scope="{ items }" v-if="!isHidden">
      <container-input-field
        :recordEndpoint="recordEndpoint"
        :recordId="recordId"
        :field="field"
        :newRecord="newRecord"
        :modalColor="modalColor"
        :reloadRecordOnChange="reloadRecordOnChange"
        :storeNewRecordImmediately="storeNewRecordImmediately"
        @storeNewRecordImmediately="$emit('storeRecord')"
      >
        <div slot-scope="field">
          <v-row class="mb-n1" v-if="field.alternativeDesign">
            <v-col class="ml-3 px-0 py-0">
              <base-label :label="label" />
            </v-col>
          </v-row>
          <v-row class="px-0 py-0">
            <v-col class="d-flex pt-1 pb-2" :cols="columns">
              <v-autocomplete
                :class="{
                  alternativeAutocompleteFieldDesign: field.alternativeDesign,
                  'body-2': field.alternativeDesign,
                  p_TextInputReadOnly: isReadonly,
                }"
                :label="label"
                :value="field.value"
                @change="field.updateValue"
                :color="field.color"
                :item-color="field.color"
                :items="items"
                :item-value="itemValue"
                :item-text="itemText"
                autocomplete="disabled"
                :readonly="isReadonly"
                :placeholder="field.alternativeDesign ? ' ' : label"
                :solo="field.alternativeDesign"
                :dense="field.alternativeDesign"
                :flat="field.alternativeDesign"
                :clearable="!isReadonly"
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
      </container-input-field>
    </div>
  </container-value-list>
</template>
<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    items: {
      type: Array,
    },
    itemText: {
      type: String,
    },
    itemValue: {
      type: String,
    },
    itemHeader: {
      type: String,
    },
    endpoint: {
      type: String,
    },
    endpointSort: {
      type: String,
    },
    isReadonly: {
      type: Boolean,
    },
    columns: {
      type: Number,
      default: 12,
    },
    reloadRecordOnChange: {
      type: Boolean,
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style>
.alternativeAutocompleteFieldDesign .v-input__slot {
  border: 0.25px solid #aaa;
  padding-left: 8px !important;
}
.alternativeAutocompleteFieldDesign .v-input__control {
  min-height: 34px !important;
}
.alternativeAutocompleteFieldDesign .mdi-close,
.alternativeAutocompleteFieldDesign .mdi-menu-down {
  margin-top: 0px !important;
  margin-right: -10px;
  font-size: 14px !important;
  color: #aaa;
}
.alternativeAutocompleteFieldDesign .mdi-close {
  margin-right: -30px;
}
</style>
