var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isHidden)?_c('v-row',{class:[
    _vm.customClass,
    {
      configRow: _vm.configMode,
      rowSelected: _vm.rowSelected,
      'elevation-9': _vm.configMode && !_vm.rowSelected,
      'elevation-2': _vm.rowSelected,
    } ],style:({
    'max-width': _vm.maxWidth + 'px',
  }),attrs:{"dense":_vm.configMode || _vm.hasNarrowGutter,"justify":_vm.justify}},[_vm._l((_vm.components),function(component,key){return _c(component.componentType,_vm._b({key:key,tag:"component",attrs:{"pathArray":_vm.pathArray.concat( ['props'], ['components'], [key])},on:{"closeModal":function($event){return _vm.$emit('closeModal')}}},'component',Object.assign({}, component.props,
      {recordId: _vm.recordId,
      recordEndpoint: _vm.recordEndpoint,
      component: component,
      modalColor: _vm.modalColor,
      newRecord: _vm.newRecord}),false))}),(_vm.configMode)?_c('div',{staticStyle:{"margin-right":"-32px","margin-left":"3px"}},[_c('v-btn',{staticClass:"small-icon",staticStyle:{"color":"white","margin-top":"-6px","margin-left":"3px","z-index":"10"},style:({ 'background-color': _vm.rowSelected ? 'cornflowerblue' : '#aaa' }),attrs:{"x-small":"","icon":""},on:{"click":_vm.editComponent}},[_c('v-icon',{staticClass:"small-icon",attrs:{"x-small":"","color":"white"}},[_vm._v("mdi-pencil")])],1),_c('br'),_c('v-btn',{staticClass:"small-icon",staticStyle:{"background-color":"#aaa","color":"white","margin-top":"-10px","margin-left":"3px","z-index":"10"},attrs:{"x-small":"","icon":""}},[_c('v-icon',{staticClass:"small-icon",attrs:{"x-small":"","color":"white"}},[_vm._v("mdi-shape-square-plus")])],1),_c('br'),_c('v-btn',{staticStyle:{"margin-top":"-10px","margin-right":"8px","margin-bottom":"5px"},attrs:{"x-small":"","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-drag")])],1)],1):_vm._e(),_c('v-dialog',{attrs:{"width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"background-color":"#f2f2f2"}},[_c('v-card-title',{staticStyle:{"background-color":"lightslategray","color":"white"}},[_vm._v(" Row ")]),_c('v-card-text',[_c('config-component-editor',{attrs:{"pathArray":_vm.pathArray,"component":_vm.component}})],1),_c('v-card-actions',{staticStyle:{"background-color":"lightslategray"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",on:{"click":function($event){_vm.dialog = false}}},[_vm._v("OK")])],1)],1)],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }