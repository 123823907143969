<template>
  <container-config>
    <div slot-scope="config">
      <container-datatable
        :recordId="recordId"
        :recordEndpoint="recordEndpoint"
        :tableEndpoint="endpoint"
      >
        <div slot-scope="dataTable">
          <base-title-input-section :text="title"></base-title-input-section>
          <v-card
            v-for="(card, key) in dataTable.items"
            :key="key"
            class="mt-1 elevation-1 mb-4"
            style="border: 0.25px solid #aaa"
          >
            <v-card-text class="black--text">
              <v-row>
                <v-col class="py-1" cols="10">
                  <router-link
                    v-if="
                      (card.company && card.company.id) ||
                      (card.organisation && card.organisation.id)
                    "
                    class="font-weight-bold"
                    :to="
                      '/' +
                      linkPath +
                      '/' +
                      (card.company ? card.company.id : card.organisation.id) +
                      '?link=true'
                    "
                    :style="{ color: config.app.colorPrimary }"
                    style="font-size: 0.95rem"
                  >
                    {{
                      card.company ? card.company.name : card.organisation.name
                    }}
                  </router-link>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="2" class="py-0">
                  <v-btn small icon @click="editRecord(card)">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <div id="addressBlock">
                <div
                  class="body-2 mt-2"
                  v-if="
                    (card.company && card.company.street_1) ||
                    (card.organisation && card.organisation.street_1)
                  "
                >
                  {{
                    card.company
                      ? card.company.street_1
                      : card.organisation.street_1
                  }}
                </div>
                <div
                  class="body-2"
                  v-if="
                    (card.company && card.company.street_2) ||
                    (card.organisation && card.organisation.street_2)
                  "
                >
                  {{
                    card.company
                      ? card.company.street_2
                      : card.organisation.street_2
                  }}
                </div>
                <div
                  class="body-2"
                  v-if="
                    (card.company && card.company.city) ||
                    (card.organisation && card.organisation.city)
                  "
                >
                  {{
                    card.company
                      ? card.company.post_code
                      : card.organisation.post_code
                  }}
                  {{
                    card.company ? card.company.city : card.organisation.city
                  }}
                </div>
                <div
                  class="body-2"
                  v-if="
                    (card.company && card.company.country_id) ||
                    (card.organisation && card.organisation.country_id)
                  "
                >
                  {{
                    card.company
                      ? card.company.country
                        ? card.company.country.name_de
                        : ""
                      : card.organisation.country
                      ? card.organisation.country.name_de
                      : ""
                  }}
                </div>
              </div>
              <div id="mailPhoneBlock" class="mt-2">
                <div class="body-2">
                  {{ card.function_display || card.function
                  }}{{ card.function && card.department ? " – " : ""
                  }}{{ card.department }}
                </div>
                <div class="body-2 ml-n1" v-if="card.email || card.mail">
                  <v-btn
                    icon
                    x-small
                    @click="sendEmail(card.email || card.mail)"
                  >
                    <v-icon small>mdi-email</v-icon>
                  </v-btn>
                  {{ card.email || card.mail }}
                </div>
                <div class="body-2 ml-n1" v-if="card.telephone || card.phone">
                  <v-btn
                    icon
                    x-small
                    @click="showInOverlay(card.telephone || card.phone)"
                  >
                    <v-icon small>mdi-phone</v-icon>
                  </v-btn>
                  {{ card.telephone || card.phone }}
                </div>
                <div class="body-2 ml-n1" v-if="card.mobile">
                  <v-btn icon x-small @click="showInOverlay(card.mobile)">
                    <v-icon small>mdi-cellphone</v-icon>
                  </v-btn>
                  {{ card.mobile }}
                </div>
                <div class="body-2 ml-n1" v-if="card.fax">
                  <v-btn icon x-small @click="showInOverlay(card.fax)">
                    <v-icon small>mdi-fax</v-icon>
                  </v-btn>
                  {{ card.fax }}
                </div>
                <div
                  class="body-2 ml-n1"
                  v-if="
                    (card.company && card.company.website) ||
                    (card.organisation && card.organisation.website)
                  "
                >
                  <v-btn
                    icon
                    x-small
                    @click="
                      openWebsite(
                        card.company
                          ? card.company.website
                          : card.organisation.website
                      )
                    "
                  >
                    <v-icon small>mdi-web</v-icon>
                  </v-btn>
                  {{
                    card.company
                      ? card.company.website
                      : card.organisation.website
                  }}
                </div>
              </div>
            </v-card-text>
            <v-overlay :value="showOverlay" opacity="0.8">
              <p class="white--text display-4">
                {{ overlayData }}
              </p>

              <v-btn
                right
                x-large
                outlined
                class="white--text"
                @click="showOverlay = false"
              >
                OK
              </v-btn>
            </v-overlay>
          </v-card>
          <v-btn
            v-if="!noNewRecord"
            small
            text
            class="mb-2 pl-3"
            color="#666"
            @click="createNewSubrecord"
          >
            <v-icon left small>mdi-plus-circle-outline</v-icon
            >{{ newSubrecordButtonText }}
          </v-btn>
          <v-dialog
            :max-width="maxDialogWidth"
            v-model="dialog"
            @click:outside="closeDialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
          >
            <base-record-modal
              v-if="selectedRecord"
              :components="components"
              :preloadedRecord="selectedRecord"
              :key="modalKey"
              :endpoint="dialogEndpoint || endpoint"
              :newRecord="newSubrecord"
              :originEndpoint="recordEndpoint"
              :originRecordId="recordId"
              :modalColor="modalColor"
              :keepModalOpenOnNewRecordCommit="keepModalOpenOnNewRecordCommit"
              @closeDialog="closeDialog"
              @handleNewRecordResponse="handleNewRecordResponse"
            />
          </v-dialog>
        </div>
      </container-datatable>
    </div>
  </container-config>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseSubrecordsComponentMixin } from "../mixins/baseSubrecordsComponentMixin";

export default {
  mixins: [baseComponentMixin, baseSubrecordsComponentMixin],
  data() {
    return {
      showOverlay: false,
      overlayData: null,
    };
  },
  props: {
    newSubrecordButtonText: {
      type: String,
      default: "new entry",
    },
    maxDialogWidth: {
      type: Number,
      default: 750,
    },
    title: {
      type: String,
    },
    linkPath: {
      type: String,
    },
    noNewRecord: {
      type: Boolean,
    },
  },
  methods: {
    openWebsite(urlPath) {
      if (!(urlPath.includes("http://") || urlPath.includes("https://"))) {
        urlPath = "https://" + urlPath;
      }
      window.open(urlPath, "_blank");
    },
    sendEmail(value) {
      var mail = document.createElement("a");
      mail.href = "mailto:" + value;
      mail.click();
    },
    showInOverlay(data) {
      this.showOverlay = true;
      this.overlayData = data;
    },
  },
};
</script>
