<template>
  <div
    style="
      background-color: #f1f1f1;
      height: calc(100vh - 65px);
      overflow: scroll;
    "
  >
    <v-list class="py-0">
      <v-list-item-group>
        <div v-for="user in users" :key="user.id">
          <v-list-item
            :to="{
              path: `/users/${user.id}`,
            }"
            style="background-color: #f1f1f1"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ user.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      users: [],
    };
  },
  methods: {
    loadUsers() {
      axios
        .get("/users")
        .then((r) => r.data)
        .then((data) => (this.users = data));
    },
  },
  mounted() {
    this.loadUsers();
  },
};
</script>
