var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"ml-3 px-0 py-0"},[_c('base-label',{attrs:{"label":_vm.label}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-0 mr-4"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"pb-2",class:{
              alternativeDatepickerFieldDesign: true,
              'body-2': true,
              p_TextInputReadOnly: _vm.isReadonly,
            },attrs:{"value":_vm.formatDate(_vm.dateFromValue),"solo":"","dense":"","flat":"","append-icon":"mdi-calendar","readonly":"","hide-details":"","clearable":!_vm.isReadonly,"color":_vm.color},on:{"click:clear":_vm.clearValue}},on))]}}]),model:{value:(_vm.menuDatepicker),callback:function ($$v) {_vm.menuDatepicker=$$v},expression:"menuDatepicker"}},[(!_vm.isReadonly)?_c('v-date-picker',{attrs:{"value":_vm.dateFromValue,"first-day-of-week":1,"locale":_vm.locale,"color":_vm.color},on:{"change":_vm.updateDate,"input":function($event){_vm.menuDatepicker = false}}}):_vm._e()],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-select',{class:{
          alternativeSelectFieldDesign: true,
          'body-2': true,
        },attrs:{"placeholder":"hh","value":String(_vm.hoursFromValue),"items":_vm.hours,"solo":"","dense":"","flat":"","color":_vm.color,"autocomplete":"disabled","hide-details":"","disabled":!_vm.dateFromValue},on:{"change":_vm.updateHour}})],1),_c('v-col',{staticClass:"py-0 pt-1 px-0",attrs:{"cols":"auto"}},[_vm._v(":")]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-select',{class:{
          alternativeSelectFieldDesign: true,
          'body-2': true,
        },attrs:{"placeholder":"mm","value":String(_vm.minutesFromValue),"items":_vm.minutes,"solo":"","dense":"","flat":"","color":_vm.color,"autocomplete":"disabled","hide-details":"","disabled":!_vm.hoursFromValue},on:{"change":_vm.updateMinute}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }