<template>
  <container-query-builder-stored-queries @switchTabs="switchTabs">
    <div
      slot-scope="{
        color,
        language,
        queryFilter,
        updateQueryFilter,
        myQueriesOnly,
        toggleMyQueriesOnly,
        queries,
        filteredQueries,
        deletionDialog,
        toggleDeletionDialog,
        runQuery,
        editQuery,
        deleteQuery,
        executeDeletion,
        user
      }"
    >
      <v-card-text class="pt-3 white" style="height: 83vh; overflow:scroll;">
        <v-row class="pl-3 pr-5 py-0 mb-1">
          <v-col class="py-0 mb-0" cols="6">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              clearable
              dense
              width="300"
              :value="queryFilter"
              @input="updateQueryFilter"
              class="queryFilter"
              autocomplete="disabled"
              :color="color"
            />
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="py-0 mb-0">
            <v-switch
              :value="myQueriesOnly"
              @input="toggleMyQueriesOnly"
              :label="texts['myQueriesOnly'][language]"
              class="mt-1 mb-0 queryFilter"
              :color="color"
            />
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-data-table
          v-if="queries"
          hide-default-footer
          disable-pagination
          :items="filteredQueries"
          :headers="headers"
          sort-by="name"
          dense
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-btn icon class="pa-0 mr-9 ml-3" :color="color">
                  <v-icon :color="color" @click="runQuery(item.id)" v-on="on"
                    >mdi-play</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ texts["performQuery"][language] }}</span>
            </v-tooltip>
            <v-tooltip bottom :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-btn icon>
                  <v-icon small @click="editQuery(item.id)" v-on="on"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ texts["editQuery"][language] }}</span>
            </v-tooltip>
            <v-tooltip bottom :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-if="item.user_id == user.id || user.is_admin === 1"
                  v-on="on"
                >
                  <v-icon small @click="deleteQuery(item.id)"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ texts["deleteQuery"][language] }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-dialog :value="deletionDialog" width="500">
        <v-card>
          <v-card-title>
            <p class="pt-2">
              {{ texts["confirmDeleteQuery"][language] }}
            </p>
          </v-card-title>
          <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <v-btn color="grey lighten-1" @click="toggleDeletionDialog">
              {{ texts["cancel"][language] }}
            </v-btn>
            <v-btn dark :color="color" @click="executeDeletion">
              {{ texts["yes"][language] }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </container-query-builder-stored-queries>
</template>

<script>
export default {
  data() {
    return {
      texts: {
        performQuery: {
          en: "Perform Query",
          da: "Søg",
          de: "Suche ausführen"
        },
        editQuery: {
          en: "edit query",
          da: "ret søgning",
          de: "Suche bearbeiten"
        },
        deleteQuery: {
          en: "delete query",
          da: "slet søgning",
          de: "Suche löschen"
        },
        confirmDeleteQuery: {
          en: "Do you really want to delete this query?",
          da: "Vil du slette denne søgning?",
          de: "Soll diese Suche wirklich gelöscht werden?"
        },
        cancel: {
          en: "cancel",
          da: "afbryd",
          de: "abbrechen"
        },
        yes: {
          en: "yes",
          da: "Ja",
          de: "Ja"
        },
        myQueriesOnly: {
          en: "show my queries only",
          da: "vis kun mine søgninger",
          de: "nur meine Suchen anzeigen"
        }
      },
      //TO DO: internationalise!!
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: "Benutzer*in",
          align: "left",
          sortable: true,
          value: "user_name",
          width: 180
        },
        {
          value: "actions",
          width: 190,
          sortable: false
        }
      ]
    };
  },
  methods: {
    switchTabs() {
      this.$emit("switchTabs");
    }
  }
};
</script>

<style>
.queryFilter .v-text-field__details,
.queryFilter .v-messages {
  display: none;
}

.queryFilter .v-label {
  font-size: 14px;
}
</style>
