<template>
  <div>
    <slot :settingNames="settingNames"></slot>
  </div>
</template>

<script>
export default {
  computed: {
    settings() {
      return this.$store.state.config.app.settings;
    },
    settingNames() {
      return this.settings.map(item => {
        return item.name;
      });
    }
  }
};
</script>
