<template>
  <div>
    <slot
      :items="items"
      :itemValue="itemValue"
      :itemText="itemText"
      :fieldContent="fieldContent"
      :fieldType="fieldType"
      :operator="operator"
      :updateFieldContent="updateFieldContent"
    ></slot>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    orQueryKey: {
      type: Number,
    },
    tableGroupingKey: {
      type: Number,
    },
    subqueryKey: {
      type: Number,
    },
  },
  data() {
    return {
      items: [],
      texts: [],
    };
  },
  computed: {
    itemValue() {
      if (this.selectedQueryField && this.selectedQueryField.itemValue) {
        return this.selectedQueryField.itemValue;
      } else {
        return "id";
      }
    },
    itemText() {
      if (this.selectedQueryField && this.selectedQueryField.itemText) {
        return this.selectedQueryField.itemText;
      } else {
        return "name";
      }
    },
    fieldContent() {
      return this.selectedSubquery["value"];
    },
    //the field type determines if values for an autoselect are loaded in the mount process or not
    fieldType() {
      if (this.selectedQueryField) {
        return this.selectedQueryField.type;
      } else {
        return "text";
      }
    },
    operator() {
      return this.selectedSubquery["operator"];
    },

    //query and selected sub-elements
    query() {
      return this.$store.state.queryBuilder.query;
    },
    selectedOrQuery() {
      return this.query.orQueries[this.orQueryKey];
    },
    selectedTableGrouping() {
      return this.selectedOrQuery.tableGroupings[this.tableGroupingKey];
    },
    baseTable() {
      return this.selectedTableGrouping.table;
    },
    selectedSubquery() {
      return this.selectedTableGrouping.andQueries[this.subqueryKey];
    },

    //field, operator and value

    fieldName() {
      return this.selectedSubquery["field"];
    },

    //module config and query fields
    moduleName() {
      return this.$route.params.module;
    },
    selectedQueryField() {
      let moduleConfig = this.$store.state.config.app.modules.find(
        (e) => e.basicConfig.path == this.moduleName
      );
      let queryFieldTableArrayForSelectedBaseTable =
        moduleConfig.queryFields.find((e) => e.tableName == this.baseTable);
      let queryFields = queryFieldTableArrayForSelectedBaseTable.fields;
      let queryField = queryFields.find((o) => o.value === this.fieldName);
      return queryField;
    },
    endpoint() {
      return this.selectedQueryField.endpoint;
    },
  },
  methods: {
    updateFieldContent(value) {
      let payload = {
        value: value,
        orQueryIndex: this.orQueryKey,
        tableGroupingIndex: this.tableGroupingKey,
        subqueryIndex: this.subqueryKey,
      };
      this.$store.commit("UPDATE_VALUE", payload);
    },
  },
  mounted() {
    if (this.fieldType == "autocomplete") {
      let endpoint = this.endpoint;
      if (endpoint.includes("?")) {
        endpoint = endpoint + "&page=all";
      } else {
        endpoint = endpoint + "?sort=" + this.itemText + "&page=all";
      }
      axios
        .get(endpoint)
        .then((r) => r.data)
        .then((data) => {
          this.items = data;
        });
    } else if (this.fieldType == "select" || this.fieldType == "radio") {
      this.items = this.selectedQueryField.items;
    }
  },
};
</script>
