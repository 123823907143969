import design from "@/config/designConfig.json";
// import app from "@/config/appConfig.json";
import { set } from "lodash";
import Vue from "vue";
import axios from "axios";
import { get } from "lodash";

export default {
  state: {
    app: null,
    design
  },
  actions: {
    updateConfigValue({ commit, dispatch }, payload) {
      commit("UPDATE_CONFIG_VALUE", payload);
      dispatch('saveConfig', false);
    },
    loadConfigFromServer({ commit }) {
      return new Promise(resolve => {
        axios
          .get("/pianola-config")
          .then(r => r.data)
          .then(data => {
            commit("SET_APP_CONFIG", data);
            resolve();
          });
      });
    },
    saveConfig({state}, newVersion) {
      let payload = {};
      payload.config = JSON.parse(JSON.stringify(state.app));
      payload.changelog = this.changelog;
      payload.newVersion = newVersion;
      axios
        .post("/pianola-config", payload)
        .catch(() => {
          alert('there was an error storing the config!')
        });
    },
    loadLoginConfigFromServer({ commit }) {
      return new Promise(resolve => {
      axios
        .get("/login-config")
        .then(r => r.data)
        .then(data => {commit("SET_APP_CONFIG", data); resolve();});
      });
    },
    deleteConfigObject({ commit, dispatch }, payload) {
      let r = confirm("Are you sure you want to delete this?");
      if (r == true) {
        commit("DELETE_CONFIG_OBJECT", payload);
        dispatch('saveConfig', false);
      }
    }
  },
  mutations: {
    SET_APP_CONFIG(state, payload) {
      Vue.set(state, "app", payload);
    },
    UPDATE_CONFIG_VALUE(state, payload) {
      let getArray = JSON.parse(JSON.stringify(payload.getArray));
      getArray.shift();
      let stateCopy = JSON.parse(JSON.stringify(state));
      set(stateCopy, [...getArray, payload.field], payload.input);
      Vue.set(state, "app", stateCopy.app);
    },
    DELETE_CONFIG_OBJECT(state, payload) {
      let getArray = JSON.parse(JSON.stringify(payload.getArray));
      getArray.shift();
      let subarray = get(state, getArray);
      subarray.splice(payload.index, 1);
      set(state, getArray, subarray);
    }
  }
};
