<template>
  <container-input-field
    :recordId="recordId"
    :recordEndpoint="recordEndpoint"
    :field="cityField"
    :newRecord="newRecord"
    :modalColor="modalColor"
    :storeNewRecordImmediately="storeNewRecordImmediately"
    @storeNewRecordImmediately="$emit('storeRecord')"
  >
    <div slot-scope="cityField" v-if="!isHidden">
      <container-input-field
        :recordId="recordId"
        :recordEndpoint="recordEndpoint"
        :field="field"
        :newRecord="newRecord"
        :modalColor="modalColor"
        :storeNewRecordImmediately="storeNewRecordImmediately"
        @storeNewRecordImmediately="$emit('storeRecord')"
      >
        <div slot-scope="field" v-if="!isHidden">
          <v-row class="mb-n1" v-if="field.alternativeDesign">
            <v-col class="ml-3 px-0 py-0">
              <base-label :label="label" />
            </v-col>
          </v-row>
          <v-row class="px-0 py-0">
            <v-col class="d-flex pt-1 pb-2" :cols="columns">
              <v-text-field
                :class="{
                  alternativeTextFieldDesign: field.alternativeDesign,
                  'body-2': field.alternativeDesign,
                }"
                elevation="0"
                :label="label"
                :placeholder="field.alternativeDesign ? ' ' : label"
                :value="field.value"
                @change="
                  setValue($event);
                  getCity(value);
                  field.updateValue(value);
                  city ? cityField.updateValue(city) : null;
                "
                :rules="rules"
                hide-details="auto"
                autocomplete="disabled"
                :solo="field.alternativeDesign"
                :dense="field.alternativeDesign"
                :flat="field.alternativeDesign"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </container-input-field>
    </div>
  </container-input-field>
</template>
<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";
import axios from "axios";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  props: {
    columns: {
      type: Number,
      default: 12,
    },
    cityField: {
      type: String,
    },
    validationRules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
      overlay: false,
      city: null,
      cities: null,
      rulesCollection: {
        required: (value) => !!value || "Required value!",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail address!";
        },
        numericOnly: (value) =>
          value > 0 || "The entered value must be numeric!",
      },
    };
  },
  computed: {
    rules() {
      let array = [];
      this.validationRules.forEach((item) => {
        array = [...array, this.rulesCollection[item]];
      });
      return array;
    },
  },
  methods: {
    setValue(value) {
      if (this.accessorRegEx) {
        this.value = value.match(this.accessorRegEx)[0];
      } else {
        this.value = value;
      }
    },
    getCity(value) {
      let city = this.cities.find((city) => city.zipcode == value);
      this.city = city ? city.place : null;
    },
    ignore() {
      return null;
    },
  },
  mounted() {
    axios.defaults.withCredentials = false;
    let url =
      "https://raw.githubusercontent.com/zauberware/postal-codes-json-xml-csv/master/data/CH/zipcodes.ch.json";
    axios
      .get(url)
      .then((r) => r.data)
      .then((data) => {
        this.cities = data;
      });
    axios.defaults.withCredentials = true;
  },
};
</script>
<style>
.alternativeTextFieldDesign .v-input__slot {
  border: 0.25px solid #aaa;
  padding-left: 8px !important;
}
.alternativeTextFieldDesign .v-input__control {
  min-height: 34px !important;
}
.alternativeTextFieldDesign .v-icon {
  margin-top: 0px !important;
  font-size: 18px !important;
  color: #666 !important;
}
.p_TextInputReadOnly > .v-input__control > .v-input__slot {
  background: none !important;
}
</style>
