<template>
  <container-record
    :recordId="recordId"
    :endpoint="recordEndpoint"
    :newRecord="newRecord"
    v-if="!isHidden"
  >
    <div slot-scope="{ record }" v-if="record" style="margin-top: -1px">
      <v-alert
        :type="alertType"
        dense
        :icon="icon"
        :color="color"
        :class="customClass"
        class="overrideAlert"
      >
        {{ result }}
      </v-alert>
      <!-- <div
        class="body-2"
        style="color: rgba(0, 0, 0, 0.6)"
        :style="{ 'font-size': calculatedFontSize + 'px !important' }"
        :class="customClass"
      ></div> -->
    </div>
  </container-record>
</template>

<script>
import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { get } from "lodash";
export default {
  mixins: [baseComponentMixin],
  props: {
    text: {
      type: String,
      default: "Title",
    },
    alertType: {
      type: String,
    },
    field: {
      type: String,
    },
    icon: {
      type: String,
    },
    color: {
      type: String,
    },
    customClass: {
      type: String,
      default: "mt-4 mb-3",
    },
  },
  computed: {
    calculatedFontSize() {
      return this.fontSize || 14;
    },
    result() {
      return this.text.replace("{field}", get(this.record, this.field));
    },
  },
};
</script>
<style>
.overrideAlert .v-icon {
  margin-right: 8px;
  font-size: 20px;
}
</style>
