<template>
  <container-app v-if="isReady">
    <container-config>
      <div slot-scope="{ color }" :style="background">
        <app-loading v-if="loading" />
        <div v-else class="pt-12">
          <app-login-header />
          <v-container>
            <v-row>
              <v-col
                class="mx-auto"
                sm="8"
                lg="6"
                xl="4"
                style="margin-top: calc(50vh - 300px)"
              >
                <v-card
                  class="mt-12"
                  style="background-color: rgba(255, 255, 255, 1)"
                >
                  <v-card-title
                    class="px-8 pb-4 white--text"
                    :style="{ 'background-color': color }"
                    style="opacity: 1"
                  >
                    <h1 class="headline">Login</h1>
                  </v-card-title>
                  <v-card-text class="px-8 py-6">
                    <v-alert v-if="error" color="red" text class="mb-m10"
                      >Login fehlgeschlagen</v-alert
                    >
                    <form>
                      <v-text-field
                        label="User Name"
                        placeholder=" "
                        :color="color"
                        prepend-inner-icon="mdi-account-circle"
                        v-model="email"
                        id="username"
                        type="email"
                        autocomplete="username"
                      ></v-text-field>
                      <v-text-field
                        label="Password"
                        placeholder=" "
                        :color="color"
                        prepend-inner-icon="mdi-lock"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        :type="showPassword ? 'text' : 'password'"
                        v-model="password"
                        autocomplete="current-password"
                        id="password"
                      ></v-text-field>
                    </form>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col cols="auto" class="justify-right">
                        <v-btn
                          :color="color"
                          class="white--text my-2"
                          @click="login"
                          :disabled="buttonDisabled"
                          >Anmelden</v-btn
                        >
                      </v-col>
                      <GlobalEvents
                        v-if="!buttonDisabled"
                        @keydown.enter="login"
                      />
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </container-config>
  </container-app>
</template>

<script>
import AppLoading from "./AppLoading.vue";
export default {
  components: { AppLoading },
  data: () => ({
    showPassword: false,
    email: "",
    password: "",
    error: null,
    loading: false,
  }),
  computed: {
    isReady() {
      return this.$store.state.config.app !== null;
    },
    buttonDisabled() {
      return this.email == "" || this.password == "";
    },
    background() {
      return {
        height: "100vh",
        background:
          "linear-gradient(rgba(52, 52, 52, 0.6), rgba(52, 52, 52, 0.6)), url(" +
          this.url +
          ") no-repeat center center fixed",
        "background-size": "cover",
      };
    },
    url() {
      return this.$store.state.config.app.basicConfig.backgroundPath;
    },
    firstModulePath() {
      if (this.$store.state.config.app) {
        return this.$store.state.config.app.modules[0].basicConfig.path;
      } else {
        return null;
      }
    },
  },
  methods: {
    login() {
      this.loading = true;
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          if (this.$store.state.auth.user.reset_password) {
            this.$router.push({ path: "/change-password" });
          } else if (
            this.$store.state.config.app.basicConfig.twoFactor &&
            !this.$store.state.auth.user.two_factor_secret //&&
            // process.env.VUE_APP_ENV !== "development"
          ) {
            this.$router.push({ path: "/2fa-setup" });
          } else {
            this.$router.push({ path: "/" + this.firstModulePath });
          }
        })
        .catch((err) => {
          this.error = err;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch("loadLoginConfigFromServer").then(() => {
      if (this.$store.state.auth.user) {
        this.$router.push({ path: "/" + this.firstModulePath });
      }
    });
  },
};
</script>
