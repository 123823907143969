<template>
  <container-config>
    <div slot-scope="{ color, language, app }">
      <container-input-field
        :recordId="recordId"
        :recordEndpoint="recordEndpoint"
        :field="field"
        :displayField="displayFieldConverted"
        :newRecord="newRecord"
        :modalColor="modalColor"
        :storeNewRecordImmediately="storeNewRecordImmediately"
        @storeNewRecordImmediately="$emit('storeRecord')"
      >
        <div slot-scope="field" class="mt-0 mb-2" v-if="!isHidden">
          <div
            class="body-1 black--text pb-0"
            :class="{
              standardSearchOrCreateFieldDesign: !field.alternativeDesign,
            }"
          >
            <base-label :label="label" />
            <div v-if="temporaryDisplay == null && (newRecord || !field.value)">
              <v-row
                class="pt-1"
                :class="{
                  alternativeSearchOrCreateFieldDesign: field.alternativeDesign,
                  readOnly,
                }"
              >
                <v-col cols="auto" class="pt-0 pb-1">
                  <div
                    @click="openDialog"
                    style="color: #666; padding-top: 2px; cursor: pointer"
                    class="body-2"
                    v-if="!readOnly"
                  >
                    <v-icon small left class="ml-0 mr-0">mdi-link</v-icon>
                    {{ label }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row
                :class="{
                  alternativeSearchOrCreateFieldDesign: field.alternativeDesign,
                  readOnly,
                }"
                justify="space-between"
              >
                <v-col class="py-0 pl-2 pr-0" cols="10">
                  <router-link
                    v-if="displayAsLink"
                    style="
                      cursor: pointer;
                      text-decoration: underline;
                      height: 22px !important;
                      display: block;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                    :style="{
                      color: app.colorPrimary,
                    }"
                    :to="
                      '/' +
                      (linkPath || endpoint) +
                      '/' +
                      field.storedValue +
                      '?link=true'
                    "
                  >
                    {{ field.value || temporaryDisplay }}
                  </router-link>
                  <div
                    v-else
                    style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ field.value || temporaryDisplay }}
                  </div>
                </v-col>
                <v-col class="py-0 pr-1 px-0" cols="auto" v-if="!readOnly">
                  <v-btn right icon small @click="openDialog">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-dialog persistent v-model="searchOrCreateDialog" width="700">
            <v-card style="min-height: 650px" v-if="!newEntry">
              <v-card-text class="px-6 pt-6 pb-0">
                <v-text-field
                  solo
                  :placeholder="'search ' + label"
                  v-model="searchTerm"
                  @input="loadResults"
                  prepend-inner-icon="mdi-magnify"
                  :color="field.color"
                  hide-details="auto"
                  class="mb-2"
                ></v-text-field>
                <v-data-table :headers="extendedHeaders" :items="results">
                  <template v-slot:item.action="{ item }">
                    <v-btn
                      @click="
                        field.updateValue(item.id);
                        searchOrCreateDialog = false;
                        temporaryDisplay = item[displayField];
                        refreshRecord();
                      "
                      small
                    >
                      select
                    </v-btn>
                  </template></v-data-table
                >
              </v-card-text>
              <v-card-actions class="pb-6 px-6">
                <v-btn
                  :disabled="searchTerm == null"
                  tile
                  class="font-weight-medium ml-2"
                  @click="enterNewRecord"
                  ><v-icon small left>mdi-plus</v-icon
                  >{{ newRecordLabel || texts["newRecord"][language] }}</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  tile
                  class="font-weight-medium mr-2"
                  @click="searchOrCreateDialog = false"
                  >{{ texts["cancel"][language] }}</v-btn
                >
              </v-card-actions>
            </v-card>
            <div v-if="newEntry">
              <container-record
                :endpoint="endpoint"
                recordId="new"
                :newRecord="true"
                :suppressAutoForeignKey="true"
                :originEndpoint="recordEndpoint"
                :key="modalKey"
                :preloadedRecordData="selectedRecord"
                @handleNewRecordResponse="
                  field.updateValue($event.id);
                  newRecordButtonLoading = false;
                  searchOrCreateDialog = false;
                  newEntry = false;
                  temporaryDisplay = $event[displayField];
                  refreshRecord();
                "
              >
                <v-card
                  style="min-height: 450px; background-color: #efefef"
                  slot-scope="record"
                  v-if="record.record"
                >
                  <v-card-title
                    :style="{ 'background-color': modalColor || color }"
                    style="margin: 0px"
                  >
                    <span class="white--text title font-weight-regular">{{
                      (
                        newRecordLabel || texts["newRecord"][language]
                      ).toUpperCase()
                    }}</span>
                  </v-card-title>
                  <v-card-text class="pa-6">
                    <component
                      v-for="(component, key) in components"
                      :key="key"
                      v-bind:is="component.componentType"
                      v-bind="{
                        ...component.props,
                        recordId: 'new',
                        recordEndpoint: endpoint,
                        newRecord: true,
                        modalColor,
                        pathArray: [...pathArray, 'props', 'components', key],
                      }"
                    ></component>
                  </v-card-text>
                  <v-card-actions
                    class="pa-6"
                    style="background-color: #efefef"
                  >
                    <v-btn
                      tile
                      class="font-weight-medium mr-2"
                      @click="
                        searchOrCreateDialog = false;
                        newEntry = false;
                      "
                      >{{ texts["cancel"][language] }}</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      :color="modalColor"
                      dark
                      tile
                      class="font-weight-medium mr-2"
                      :loading="newRecordButtonLoading"
                      @click="
                        newRecordButtonLoading = true;
                        record.saveNewRecord();
                      "
                      >OK</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </container-record>
            </div>
          </v-dialog>
        </div>
      </container-input-field>
    </div>
  </container-config>
</template>

<script>
import { debounce } from "lodash";
import axios from "axios";

import { baseComponentMixin } from "../mixins/baseComponentMixin";
import { baseInputMixin } from "../mixins/baseInputMixin";

export default {
  mixins: [baseComponentMixin, baseInputMixin],
  data() {
    return {
      searchOrCreateDialog: false,
      searchTerm: null,
      results: [],
      temporaryDisplay: null,
      newEntry: false,
      modalKey: null,
      selectedRecord: null,
      newRecordButtonLoading: null,
      texts: {
        cancel: {
          en: "cancel",
          da: "afbryd",
          de: "abbrechen",
        },
        newRecord: {
          en: "add new record",
          da: "tast ind ny",
          de: "neuer Datensatz erfassen",
        },
      },
    };
  },
  props: {
    displayField: {
      type: String,
    },
    endpoint: {
      type: String,
    },
    sortField: {
      type: String,
    },
    newRecordLabel: {
      type: String,
    },
    headers: {
      type: Array,
    },
    components: {
      type: Array,
    },
    displayAsLink: {
      type: Boolean,
    },
    linkPath: {
      type: String,
    },
    linkColor: {
      type: String,
    },
    objectName: {
      type: String,
    },
    showAllRecordsInEmptySearch: {
      type: Boolean,
    },
    readOnly: {
      type: Boolean,
    },
  },
  computed: {
    extendedHeaders() {
      let actionItem = { value: "action" };
      return [...this.headers, actionItem];
    },
    displayFieldConverted() {
      return (
        this.singularize(this.objectName || this.endpoint) +
        "." +
        this.displayField
      );
    },
  },
  methods: {
    refreshRecord() {
      if (!this.newRecord) {
        this.$store.dispatch("refreshOriginRecord", {
          endpoint: this.recordEndpoint,
          recordId: this.recordId,
        });
      }
    },
    openDialog() {
      this.searchOrCreateDialog = true;
      if (this.showAllRecordsInEmptySearch) {
        this.loadResults();
      }
    },
    openLink() {
      alert("link here");
    },
    enterNewRecord() {
      this.modalKey = Date();
      this.newEntry = true;
      this.selectedRecord = { id: "new" };
    },
    handleNewRecordEntry(data) {
      alert("handle new record entry here, data id: " + data.id);
    },
    // TO DO: this should ideally go in a container
    loadResults: debounce(function () {
      let searchTermOfQuery = this.searchTerm;
      let params =
        "?page=all" +
        (this.searchTerm ? "&filter=" + this.searchTerm : "") +
        (this.sortField ? "&sort=" + this.sortField : "");
      let self = this;
      if (this.searchTerm !== "") {
        axios
          .get(this.endpoint + params)
          .then((r) => r.data)
          .then((data) => {
            if (searchTermOfQuery == self.searchTerm) {
              self.results = data;
            }
          });
      } else {
        this.results = [];
      }
    }, 300),
  },
};
</script>
<style>
.standardSearchOrCreateFieldDesign {
  border-bottom: 1pt #949494 solid;
}
.alternativeSearchOrCreateFieldDesign {
  border: 0.25px solid #aaa;
  background-color: white !important;
  border-radius: 3px;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 4px;
  padding-left: 0px;
  font-size: 14px;
  height: 34px;
}
</style>
<style scoped>
.readOnly {
  background-color: transparent !important;
}
</style>
